import React from "react";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import './styles.scss';


export default function LiveClassListView({
    visible, sessions, live_classes_count, setLive,
    setSelectedClass, available__recorded_live_classes, setSelectedEvent,
    recordedClass, available_live_classes, liveClass, completed_live_classes
}) {

    let history = useHistory();

    return (
        <>

            <ListGroup className={visible ? 'd-none' : 'd-block'}>

                {
                    sessions.map(e => (
                        <ListGroupItem className="d-flex flex-column justify-content-between" key={e._id}>
                            <h5 className='text-lg d-flex justify-content-between w-100'>
                                {e.title}

                                {
                                    completed_live_classes[e._id] &&
                                    <i className="lni lni-checkmark mr-2 text-green"></i>
                                }
                            </h5>
                            <h6 className='text-muted text-sm my-2'>
                                {moment(e?.date).format('ll')} {moment(e?.date).format('LT')} - {moment(e.date).add(e.duration_in_minutes, 'minutes').format('LT')}
                            </h6>
                            <div>
                                <Button
                                    type='button' color='primary'
                                    disabled={moment().isBefore(moment(e.date).add(e.duration_in_minutes, 'minutes')) || !(e.recording_link || e.s3_link)} size='sm'
                                    className='mr-2 my-1'
                                    onClick={() => {
                                        if (live_classes_count) {
                                            setLive(false);
                                            if (!available__recorded_live_classes.includes(e?._id)) {
                                                setSelectedClass(e?._id);
                                                return setSelectedEvent(null);
                                            }
                                            recordedClass(e?._id);
                                        }
                                        else {
                                            toast.info('Buy more slots to watch this video');
                                            history.push('/cart');
                                        }
                                    }}
                                >
                                    Watch Now
                                </Button>
                                <Button
                                    type='button' color='primary'
                                    onClick={() => {
                                        if (available_live_classes.includes(e._id)) {
                                            liveClass(e?._id);
                                            setSelectedEvent(null);
                                            return;
                                        }
                                        if (live_classes_count) {
                                            setLive(true);
                                            setSelectedEvent(null);
                                        }
                                        else {
                                            toast.info('Buy more slots to watch this video');
                                            history.push('/buy-classes');
                                        }
                                    }}
                                    disabled={!moment().isBetween(moment(e?.date).subtract(30, 'minutes'), moment(e.date).add(e.duration_in_minutes, 'minutes'))}
                                    size='sm'
                                    className='mr-auto my-1'
                                >
                                    Join Live
                                </Button>
                            </div>
                        </ListGroupItem>
                    ))
                }
            </ListGroup>

        </>
    );
};

import React, { useEffect, useState } from "react";
import {
	Button, FormGroup, Form,
	Input, Row, Col, Label, Modal, ModalBody, ModalHeader, ModalFooter
} from "reactstrap";
import { toast } from "react-toastify";

import _fetch from "../../../_fetch.js";
import Loader from "../../../components/Loader/loader.js";
import moment from "moment";
import './styles.scss';
import DeleteVideoModal from "./deleteVideo.js";

const initialState = {
	type: '',
	title: '',
	description: '',
	date: '',
	time: '',
	s3_link: '',
	duration_in_minutes: 0,
	meeting_link: '',
	recording_link: '',
	category: '',
	order: 0,
	_id: '',
	meta: ''
};

export default function AddClassModal({
	id, isOpen, toggle, order = 0, category = '', type = '', duration = 0, title = '', refresh, clone
}) {

	const [file, setFile] = useState(null);
	const [categories, setCategories] = useState([]);
	const [focusArea, setFocusArea] = useState([]);
	const [loading, setLoading] = useState(null);
	const [data, setData] = useState(initialState);
	const [backgroundColorValue, setBackgroundColorValue] = useState(false);
	const [isDeleteVideoModalOpen, setIsDeleteVideoModalOpen] = useState(false);

	useState(() => {
		let obj = {};
		if (title) {
			obj.title = title;
		}
		if (order) {
			obj.order = order;
		}
		if (category) {
			obj.category = category;
		}
		if (type) {
			obj.type = type;
		}
		if (duration) {
			obj.duration_in_minutes = duration;
		}
		setData(val => ({ ...val, ...obj }));
	}, [order, category, type, duration])

	useEffect(() => {

		async function init() {
			try {
				setLoading(true);
				const url = id ? `${process.env.REACT_APP_API}/category?classId=${id}` : `${process.env.REACT_APP_API}/category?platform=WEB,MOBILE`;
				let res = await _fetch(url)
				if (res.success && Array.isArray(res.response)) {
					setCategories(res.response);
				}
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
			try {
				setLoading(true);
				let res = await _fetch(`${process.env.REACT_APP_API}/v2/focus-area`)
				if (res.success && Array.isArray(res.response)) {
					setFocusArea(res.response);
				}
			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		}

		init();

		if (id) {
			_fetch(`${process.env.REACT_APP_API}/classes?ids=["${id}"]`)
				.then(res => {
					if (res.success && Array.isArray(res.response)) {
						let date = res.response[0].date;
						setData(() => {
							let obj = {
								...res.response[0],
								date: date ? moment(date).format('YYYY-MM-DD') : '',
								time: date ? moment(date).format('HH:mm') : '',
								category: res.response[0].category._id,
							};

							if (clone) {
								obj.s3_link = '';
								obj.recording_link = '';
							}

							return obj;
						})
					}
				})
				.catch(err => {
					console.log(err);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);


	useEffect(() => {
		const hasWebAndMobile = categories.filter(e => e.platform === 'WEB').length > 0 && categories.filter(e => e.platform === 'MOBILE').length > 0;
		setBackgroundColorValue(hasWebAndMobile)
	}, [categories]);

	const getVideoThumbnail = async (videoFile) => {
		let videoUrl = URL.createObjectURL(videoFile);
		let videoElement = document.createElement('video');

		videoElement.src = videoUrl;
		videoElement.muted = true;
		videoElement.width = 1920;
		videoElement.height = 1080;

		await new Promise((resolve) => {
			videoElement.addEventListener('loadeddata', resolve);
			videoElement.addEventListener('error', () => resolve());
		});

		videoElement.currentTime = videoElement.duration / 2;
		const duration_in_seconds = videoElement.duration;
		videoElement.play();

		await new Promise((resolve) => {
			videoElement.addEventListener('seeked', resolve);
		});

		let canvas = document.createElement('canvas');
		canvas.width = videoElement.videoWidth;
		canvas.height = videoElement.videoHeight;
		let ctx = canvas.getContext('2d');
		ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

		let data = canvas.toDataURL('image/jpeg');
		let blob = await fetch(data).then((r) => r.blob());
		let file = new File([blob], 'thumbnail.jpg', { type: 'image/jpeg' });

		videoElement.pause();
		videoElement.src = '';
		URL.revokeObjectURL(videoUrl);
		return {
			thumbnail: file,
			duration_in_seconds,
		};
	};

	const getThumbnailUrl = async (video) => {
		try {
			let {
				thumbnail,
				duration_in_seconds,
			} = await getVideoThumbnail(video);
			let res = await _fetch(`${process.env.REACT_APP_API}/media/admin_upload_public`);
			const formData = new FormData();
			let ext = thumbnail.name.split('.');
			if (ext.length > 1) ext = '.' + ext[ext.length - 1];
			else ext = '.jpg';
			let url = `kwm_web/${data.title}-${new Date().getMilliseconds()}${ext}`
			formData.append('key', url);
			formData.append('acl', 'public-read');
			formData.append('Content-Type', thumbnail.type);
			for (let a in res.response) {
				formData.append(a, res.response[a]);
			}
			formData.append('file', thumbnail);
			res = await fetch(`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}`, {
				method: 'POST',
				body: formData
			});
			if (res.status === 204) {
				return {
					thumbnail_url: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/${url}`,
					duration_in_seconds,
				};
			} else {
				throw Error('Failed to upload thumbnail');
			}

		} catch (err) {
			console.log(err);
		}
	}

	async function upload() {
		let res = await _fetch(`${process.env.REACT_APP_API}/media/admin_upload_video?category_id=${data.category}`);

		const formData = new FormData();

		let ext = file.name.split('.');
		if (ext.length > 1) ext = '.' + ext[ext.length - 1];
		else ext = '.mp4';

		let url = `${data.type === 'LIVE' ? `${data.category}/live` : `${data.category}`}/vid-${new Date().getMilliseconds()}${ext}`

		formData.append('key', url);
		formData.append('Content-Type', file.type);

		for (let a in res.response) {
			formData.append(a, res.response[a]);
		}

		formData.append('file', file);
		res = await fetch(`${process.env.REACT_APP_AWS_BUCKET}`, {
			method: 'POST',
			body: formData
		});

		if (res.status === 204) {
			console.log('res', res);
			setFile(null);
			return `/${url}`;
		} else {
			throw Error('Failed to upload video');
		}
	}

	async function addClass(ev) {
		ev.preventDefault();
		if (!data.title) return toast.error('title is required');
		if (!data.type) return toast.error('type is required');
		if (!data.category) {
			return toast.error('category is required');
		}
		if (data.type === 'LIVE' && (!data.date || !data.time)) {
			return toast.error('date and time are required')
		}
		setLoading(true);
		let body = {
			...data,
			date: moment(`${data.date} ${data.time}`).toDate()
		}
		try {

			if (file) {
				if (body.type === 'LIVE') {
					body.s3_link = await upload();
				} else {
					body.recording_link = await upload();
				}
				let {
					thumbnail_url,
					duration_in_seconds,
				} = await getThumbnailUrl(file);
				body.thumbnail = thumbnail_url;
				body.duration_in_seconds = duration_in_seconds;
				const duration_in_minutes = Math.round(duration_in_seconds / 60);
				body.duration_in_minutes = duration_in_minutes;
			}

			if (id) body._id = id;

			const res = await _fetch(`${process.env.REACT_APP_API}/classes`, { method: (id && !clone) ? "PATCH" : "POST", body: body });
			if (res.success) {
				toast.success(`Class ${id ? 'updated' : 'added'} successfully`);
				if (typeof refresh === 'function') {
					refresh();
				}
				toggle();
			} else toast.error(res.response);
		} catch (err) {
			console.log(err);
			toast.error('Some error occurred while adding the class');
		}
		setLoading(false);
	}

	const onChange = (ev) => {

		if (ev.target.name === 'date' || ev.target.name === 'time') {
			console.log(ev.target.value)
		}

		setData(prev => ({ ...prev, [ev.target.name]: ev.target.value }));
	}

	const onFileChange = (evt) => {

		const temp = evt.target.files;

		// push raw photo/video file into arr1
		for (let i = 0; i < temp.length; i++) {
			const file = temp[i];
			console.log(file);
			if (file.type.startsWith('video/'))
				return setFile(file);
		}
	}

	return (
		<Modal isOpen={isOpen} toggle={toggle} size="lg">
			<ModalHeader className="w-100 add-class-modal-header">
				<Row className="align-items-center w-100">
					<Col xs="8">
						<h3 className="mb-0">
							{!data._id ? 'Add' : 'Update'} Session
						</h3>
					</Col>
					<Col className="text-right" xs="4">
						<Button
							color="primary"
							onClick={addClass}
							disabled={!!loading}
						>
							{loading ? (
								<Loader size={20} />
							) : (
								`${(!data._id || clone) ? 'Add' : 'Update'} Session`
							)}
						</Button>
					</Col>
				</Row>
			</ModalHeader>
			<ModalBody className="bg-secondary">
				<Form onSubmit={addClass}>
					<div>
						<Row>
							<Col lg="4">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="input-first-name"
									>
										Session Title
									</label>
									<Input
										className="form-control-alternative"
										name="title"
										id="input-first-name"
										type="text"
										required={false}
										onChange={onChange}
										value={data.title}
									/>
								</FormGroup>
							</Col>
							<Col lg="4">
								<label
									className="form-control-label"
									htmlFor="input-first-name"
								>
									Session Type
								</label>
								<div>
									<FormGroup check inline>
										<Label check>
											<Input
												type="radio"
												name="type"
												value="LIVE"
												checked={data.type === 'LIVE'}
												onChange={onChange}
											/>{' '}
											Live Class
										</Label>
									</FormGroup>
									<FormGroup check inline>
										<Label check>
											<Input
												type="radio"
												name="type"
												value="RECORDED"
												checked={
													data.type === 'RECORDED'
												}
												onChange={onChange}
											/>{' '}
											Workout Video
										</Label>
									</FormGroup>
								</div>
							</Col>
							<Col lg="4">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="input-first-name"
									>
										Session Category
									</label>
									<Input
										className="form-control-alternative"
										id="input-gender"
										name="category"
										type="select"
										onChange={onChange}
										value={data.category}
									>
										<option value="" disabled>
											Select Category
										</option>
										{
											categories.map((e) => (
												<option value={e._id} key={e._id}
													style={{
														backgroundColor: backgroundColorValue ? (e.platform === 'WEB' ? '#11cbef' : '#118cef') : 'inherit',
														color: backgroundColorValue ? 'white' : 'grey'
													}}>
													{e.name}
												</option>
											))
										}
									</Input>
								</FormGroup>
							</Col>
							{
								data.category &&
								<Col lg="4">
									<FormGroup>
										<label
											className="form-control-label"
											htmlFor="input-first-name"
										>
											Session Focus Area
										</label>
										<Input
											className="form-control-alternative"
											id="input-gender"
											name="focus_area"
											type="select"
											onChange={onChange}
											value={data.focus_area}
										>
											<option value="" disabled>
												Select Focus Area
											</option>
											{
												focusArea
													.filter(e => e.category === data.category)
													.map((e) => (
														<option value={e._id} key={e._id}>
															{e.name}
														</option>
													))
											}
										</Input>
									</FormGroup>
								</Col>
							}
							<Col lg="4">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="input-first-name"
									>
										Session Intensity
									</label>
									<Input
										className="form-control-alternative"
										id="input-gender"
										name="intensity"
										type="select"
										onChange={onChange}
										value={data.intensity}
									>
										<option value="" disabled>
											Select Intensity
										</option>
										<option value='BEGINNER'>
											Beginner
										</option>
										<option value='INTERMEDIATE'>
											Intermediate
										</option>
										<option value='ADVANCED'>
											Advanced
										</option>
									</Input>
								</FormGroup>
							</Col>
						</Row>

						<Row>
							{data.type === 'LIVE' && (
								<Col lg="4">
									<FormGroup>
										<label
											className="form-control-label"
											htmlFor="input-first-name"
										>
											Meeting Link
										</label>
										<Input
											className="form-control-alternative"
											name="meeting_link"
											id="input-first-name"
											type="text"
											required={false}
											onChange={onChange}
											value={data.meeting_link}
										/>
									</FormGroup>
								</Col>
							)}
							<Col lg="4">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="input-first-name"
									>
										Recording Link
									</label>
									<Input
										className="form-control-alternative"
										name="recording_link"
										id="input-first-name"
										type="text"
										required={false}
										onChange={onChange}
										value={data.recording_link}
									/>
								</FormGroup>
							</Col>
							<Col lg="4">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="input-first-name"
									>
										Class Duration
									</label>
									<Input
										className="form-control-alternative"
										name="duration_in_minutes"
										id="input-first-name"
										type="text"
										required={false}
										onChange={onChange}
										value={data.duration_in_minutes}
									/>
								</FormGroup>
							</Col>
							<Col lg="8">
								{data.s3_link ? (
									<Button
										color="danger"
										onClick={() => {
											setIsDeleteVideoModalOpen(true);
										}}
									>
										{' '}
										Delete Video
									</Button>
								) : (
									<FormGroup>
										<label
											className="btn btn-primary mt-auto"
											htmlFor="input-profile-picture"
										>
											{data['s3_link']
												? 'Delete Video'
												: 'Select Video'}
										</label>
										<input
											id="input-profile-picture"
											type="file"
											hidden
											accept="video/*"
											multiple={false}
											onChange={(ev) => {
												onFileChange(ev);
											}}
										/>
										{file && (
											<div className="d-flex">
												<span>{file.name}</span>
												<span
													className="ml-2 pointer"
													onClick={() =>
														setFile(null)
													}
												>
													<i className="lni lni-close text-red"></i>
												</span>
											</div>
										)}
										{!file && data.s3_link && (
											<div className="d-flex">
												<span>{data.s3_link}</span>
											</div>
										)}
									</FormGroup>
								)}
							</Col>
						</Row>

						<Row>
							{data.type === 'LIVE' && (
								<Col lg="4">
									<label
										className="form-control-label"
										htmlFor="input-first-name"
									>
										Class Date and Time
									</label>
									<div>
										<FormGroup check inline>
											<Label check>
												<Input
													type="date"
													name="date"
													onChange={onChange}
													value={data.date}
												/>
											</Label>
										</FormGroup>
										<FormGroup check inline>
											<Label check>
												<Input
													type="time"
													name="time"
													onChange={onChange}
													value={data.time}
												/>
											</Label>
										</FormGroup>
									</div>
								</Col>
							)}
							{data.type === 'RECORDED' && (
								<Col lg="4">
									<label
										className="form-control-label"
										htmlFor="input-first-name"
									>
										Order
									</label>
									<Input
										className="form-control-alternative"
										name="order"
										id="input-first-name"
										type="text"
										required={false}
										onChange={onChange}
										value={data.order}
									/>
								</Col>
							)}
							<Col lg="4">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="input-first-name"
									>
										Class Summary
									</label>
									<Input
										className="form-control-alternative"
										rows="3"
										type="textarea"
										name="description"
										onChange={onChange}
										value={data.description}
									/>
								</FormGroup>
							</Col>
							<Col lg="4">
								<FormGroup>
									<label
										className="form-control-label"
										htmlFor="input-first-name"
									>
										Meta
									</label>
									<Input
										className="form-control-alternative"
										rows="3"
										type="textarea"
										name="meta"
										onChange={onChange}
										value={data.meta}
									/>
								</FormGroup>
							</Col>
						</Row>
					</div>
					<Button
						color="primary"

						disabled={!!loading}
					>
						{loading ? (
							<Loader size={20} />
						) : (
							`${(!data._id || clone) ? 'Add' : 'Update'} Class`
						)}
					</Button>
				</Form>
				<DeleteVideoModal id={id} isOpen={isDeleteVideoModalOpen} toggle={setIsDeleteVideoModalOpen} refresh={refresh} />
			</ModalBody>
			<ModalFooter>
			</ModalFooter>
		</Modal>
	);
}

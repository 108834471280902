import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import {
    Card, CardHeader, Table, Container
} from "reactstrap";
import AdminHeader from "../../../components/Headers/AdminHeader.js";
import _fetch from '../../../_fetch';
import Loader from "../../../components/Loader/loader.js";

export default function InterestedUsers(){
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState('');
    const [excelData, setExcelData] = useState([]);
    const headers = [
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Mobile Number", key: "mobileNo" },
        { label: "Interested For", key: "interestedFor" },
    ];

    async function getExportUser() {
        try {
            setLoading(true);

            let url = `${process.env.REACT_APP_API_V2}/interested-user`;

            let res = await _fetch(url);
            if (res.success) {

                let arr = []
                res.response.forEach((cur, i) => {
                    let temp = {};
                    temp.name = cur?.name
                    temp.email = cur?.email;
                    temp.mobileNo = cur?.mobileNo;
                    temp.interestedFor = cur?.interestedFor;
                    arr.push(temp);
                });
                setExcelData(arr);
            }

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const getUsers = async () => {
        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_API_V2}/interested-user`;
            let res = await _fetch(url);
            if (res.success) {
                setUsers(res.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    if (loading) return (
        <>
            <AdminHeader />
            <Container className="mt--7" fluid>
                <Card className="bg-secondary shadow" style={{ height: 400 }}>
                    <Loader />
                </Card>
            </Container>
        </>
    );
    return (
        <>
            <AdminHeader />
            <Container className="mt--9" fluid>
                <Card className="shadow">

                    <CardHeader className="border-0">
                        <div className='d-flex justify-content-between'>
                            <h3 className="mb-0">
                                All Interested Users ({users.length})
                            </h3>
                            {excelData.length ?
                                <CSVLink
                                    data={excelData} headers={headers}
                                    className="btn btn-primary"
                                    onClick={() => setExcelData([])}
                                >
                                    <i className="lni lni-download"></i>Download Excel
                                </CSVLink>
                                :
                                <button className="btn btn-primary" onClick={getExportUser}>Create Excel</button>
                            }

                        </div>
                    </CardHeader>

                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Mobile Number</th>
                                <th scope="col">Interested For</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(users) && users?.map((e, i) => (
                                    <tr key={e._id}>
                                        <th scope="row">
                                            {e?.name}
                                        </th>
                                        <td>{e?.email}</td>
                                        <td>
                                            {e?.mobileNo}
                                        </td>
                                        <td>
                                            {e?.interestedFor}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Card>
            </Container>
        </>
    )
}
import React from "react";
import { Container, Row, Col } from "reactstrap";

import Header from "../../components/Headers/Header.js";
import UserInfo from "./userInfo.js";
import OrderDetail from "./orderDetail";

export default function Cart() {

	return (
		<>
			<Header />
			{/* Page content */}
			<Container className="mt--7" fluid>
				<Row>
					<Col xs={12} lg={6} className='my-2'>
						<UserInfo />
					</Col>
					<Col xs={12} lg={6} className='my-2'>
						<OrderDetail />
					</Col>
				</Row>
			</Container>
		</>
	);
};

import React, { useEffect, useState } from "react";
import { Card, Container, Button, Modal, ModalHeader, ModalBody, ModalFooter, Badge } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-quill/dist/quill.snow.css';

import _fetch from '../../_fetch';
import Loader from "../../components/Loader/loader.js";
import Header2 from "../../components/Headers/Header2";
import Footer from '../../components/Footers/AuthFooter';
import { useSelector } from "react-redux";
import './styles.scss';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Recipe() {

    const { admin, _id } = useSelector(st => st.user);

    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [admin_comments, setAdminComments] = useState(0);
    const [recipe, setRecipe] = useState([]);
    let history = useHistory();
    let recipe_id = useQuery().get('id');
    const [isOpen, setIsOpen] = useState(false);

    const getRecipe = async () => {
        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_API}/recipe/${recipe_id}`;
            let res = await _fetch(url);
            if (res.success) {
                setRecipe(res.response);
            }

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const updateRecipe = async (status) => {
        setUpdating(true);
        try {
            let res = await _fetch(`${process.env.REACT_APP_API}/recipe/${recipe._id}`, { method: 'PATCH', body: { status, admin_comments } });
            if (res.success) {
                setRecipe({ ...recipe, status });
                toast.success('recipe updated successfully');
                setIsOpen(false);
            } else {
                toast.error(res.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setUpdating(false);
        }
    }

    const likeRecipe = async (status) => {
        try {
            if (!_id) return toast.error('Please sign up to like to this recipe');
            if (recipe.liked) return;
            let res = await _fetch(`${process.env.REACT_APP_API}/recipe/${recipe_id}/like`, { method: 'POST' });
            if (res.success) {
                setRecipe({ ...recipe, likes: recipe.likes ? recipe.likes + 1 : 1, liked: true });
            } else {
                toast.error(res.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
        }
    }

    useEffect(() => {
        if (recipe_id) {
            getRecipe();
        } else {
            history.push('/')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recipe_id]);

    if (loading) return (
        <>
            <Header2 />

            <Container className="mt--7" fluid>
                <Card className="bg-secondary shadow" style={{ height: 400 }}>
                    <Loader />
                </Card>
            </Container>
        </>
    );

    return (
        <>

            <Header2 />

            <div className="pricing-area recipe pad90">
                <div className="container" style={{maxWidth: 764}}>

                    <h1 className="text-left" style={{ fontSize: 35 }} >{recipe.title}</h1>
                    <div className="d-flex align-items-center">
                        <div className='text-dark font-weight-600'>
                            {recipe?.author?.first_name} {recipe?.author?.last_name}
                        </div>
                    </div>

                    {
                        recipe.photo_url && (
                            <div className="my-5 w-100 text-center">
                                <img
                                    className="img img-fluid rounded"
                                    src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}${recipe.photo_url}`}
                                    alt={recipe.title}
                                    style={{maxWidth: 600}}
                                />
                            </div>
                        )
                    }
                    <div dangerouslySetInnerHTML={{ __html: recipe.recipe }} className="ql-editor p-0 mt-4">
                    </div>

                    <div className="d-flex flex-column flex-lg-row w-100">
                        <div className="d-flex align-items-center my-2">
                            <div onClick={likeRecipe} className='text-dark'>
                                {recipe.likes ?? 0} {
                                    recipe.liked ?
                                        <i className="lni lni-heart-filled text-dark-pink"></i>
                                        :
                                        <i className="lni lni-heart text-primary"></i>
                                }
                            </div>

                            {
                                admin ?
                                    <Badge pill color="primary" className='ml-3'>{recipe.status}</Badge>
                                    :
                                    null
                            }
                        </div>

                        {
                            admin
                                ?
                                <div className="ml-auto my-2">
                                    <Button onClick={() => setIsOpen(true)} disabled={updating}>
                                        Request Changes
                                    </Button>
                                    {
                                        !['DRAFT', 'PUBLISHED'].includes(recipe.status) ?
                                            <Button onClick={() => updateRecipe('PUBLISHED')} disabled={updating}>
                                                Publish
                                            </Button>
                                            :
                                            null
                                    }
                                </div>
                                :
                                null
                        }
                    </div>

                </div>

            </div>

            <Modal isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <ModalHeader>
                    Comments For User
                </ModalHeader>
                <ModalBody>
                    <textarea className="w-100" onChange={ev => setAdminComments(ev.target.value)} rows={4} />
                </ModalBody>
                <ModalFooter className="d-flex">
                    <Button
                        disabled={updating}
                        onClick={() => updateRecipe('CHANGE_REQUESTED')}
                    >
                        Request Changes
                    </Button>
                </ModalFooter>
            </Modal>

            <Footer />

        </>
    );
};

const initialState = {
    user: {},
    order: {}
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_CART_USER_INFO': {
            let user = {
                ...state.user,
                ...action.payload
            };

            return ({
                ...state,
                user
            });
        }
        case 'SET_OPEN_CART_ORDER': {
            return ({
                ...state,
                order: action.payload
            })
        }
        case 'RESET_CART': {
            return initialState;
        }
        default: return state;
    }
}
import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

const CheckoutForm = ({ amount }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    try {
      setLoading(true);
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }
      const return_url = `${process.env.REACT_APP_HOME}/subscription/success`;

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: return_url,
        },
      });
      if (error) {
        setErrorMessage(error.message);
      } else {
      }
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button disabled={!stripe || loading} className='btn btn-primary my-2'>
        Pay {
          amount.net_amount === amount.gross_amount ?
          `£${amount.gross_amount}`
          :
          <span><strike>£{amount.gross_amount}</strike> £{amount.net_amount}</span>
        }
      </button>
      {errorMessage && <div style={{
        color: 'red',
      }}>{errorMessage}</div>}
    </form>
  )
};

export default CheckoutForm;
import PaymentSuccessGif from "../../assets/gifs/payment-success.gif";

export default function SubscriptionSuccess() {

    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <img
                src={PaymentSuccessGif}
                alt="Payment Success"
                style={{
                    height: 'auto',
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                }}
            />
            <h2>
                Payment Successful!
            </h2>
            <p
                style={{
                    fontSize: "1.5rem",
                }}
            >
                Please download the CoreSculpt app to continue...
            </p>

            <div
                className='d-flex flex-wrap mt-3' style={{marginBottom: 100}}
            >
                <a
                    href="https://play.google.com/store/apps/details?id=com.keepwellmantras"
                >
                    <img
                        src="https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/google-play-store-badge.png"
                        height={50}
                        width="auto"
                        alt='Playstore'
                    />
                </a>
                <a href="https://apps.apple.com/us/app/coresculpt/id6450386191" className='ml-4'>
                    <img
                        src="https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/apple-app-store-badge.png"
                        height="50px"
                        width="auto"
                        alt='Appstore'
                    />
                </a>
            </div>

        </div>
    )
}
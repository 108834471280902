import React, { useEffect, useRef, useState } from "react";
import { Card, FormGroup, Row, Col, Pagination, PaginationItem, PaginationLink, Modal, ModalBody, ModalFooter, Button, ModalHeader, CardHeader, CardBody } from "reactstrap";
import moment from "moment";
import { useSelector } from "react-redux";
import AvatarEditor from 'react-avatar-editor';
import update from 'immutability-helper';

import _fetch from "../../_fetch";
import Loader from "../../components/Loader/loader";
import { toast } from "react-toastify";

export default function Activities() {

    const pageLength = 6;
    const { _id } = useSelector(st => st.user);
    const [activityInd, setActivityInd] = useState(null);
    const [zoom, setZoom] = useState('');
    const [activityFile, setActivityFile] = useState(null);
    const [uploading, setUploading] = useState(null);
    const [activity, setActivity] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(activity.length / pageLength);
    const activityEditorRef = useRef();

    const onActivityFileChange = (evt) => {

        const temp = evt.target.files;

        console.log(temp);

        // push raw photo/video file into arr1
        for (let i = 0; i < temp.length; i++) {
            const file = temp[i];
            console.log(file);
            if (file.type.startsWith('image/'))
                return setActivityFile(file);
        }
    }

    async function uploadPictureForActivity() {
        try {

            setUploading(true);
            let res = await _fetch(`${process.env.REACT_APP_API}/media/image_signed_url`);

            const formData = new FormData();

            let ext = activityFile.name.split('.');
            if (ext.length > 1) ext = '.' + ext[ext.length - 1];
            else ext = '.png';

            let url = `${_id}/activity/activity${new Date().getMilliseconds()}${ext}`

            formData.append('key', url);
            formData.append('acl', 'public-read');
            formData.append('Content-Type', activityFile.type);

            for (let a in res.response) {
                formData.append(a, res.response[a]);
            }

            activityEditorRef.current.getImageScaledToCanvas().toBlob(async (Blob) => {
                formData.append('file', Blob);
                res = await fetch(`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}`, {
                    method: 'POST',
                    body: formData
                });

                if (res.status === 204) {
                    console.log('res', res);
                    try {
                        console.log(activityInd, activity);
                        const res = await _fetch(
                            `${process.env.REACT_APP_API}/activity`,
                            {
                                method: "PATCH",
                                body: {
                                    picture: `/${url}`,
                                    activity_id: activity[activityInd]._id
                                }
                            }
                        );
                        if (res.success) {
                            toast.success('Picture uploaded successfully');
                            setActivity(update(activity, { [activityInd]: { picture: { $set: `/${url}` } } }))
                        }
                    } catch (err) {
                        console.log(err);
                        toast.error('Some error occurred while updating your profile');
                    }
                    setActivityFile(null);
                }
                setUploading(false);
            });

        } catch (err) {
            console.log(err);
            toast.error('Some error occurred while uploading image');
            setUploading(false);
        }

    }

    useEffect(() => {
        _fetch(`${process.env.REACT_APP_API}/activity`)
            .then(res => {
                if (res.success) {
                    setActivity(res.response);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    useEffect(() => {
        setTotalPages(activity.length / pageLength);
    }, [activity]);

    return (
        <>

            {/* Activity */}
            <Card className="shadow">
                <CardHeader className="border-0">
                    <h2 className="w-100 mb-0 text-center">My Activity</h2>
                </CardHeader>
                <CardBody className="p-3">
                    {
                        activity.slice(page * pageLength, (page + 1) * pageLength).map((e, i) => (
                            <Card className='my-2 shadow bg-secondary' key={i}>
                                <Row className='align-items-center'>
                                    <Col xs={12}>
                                        <div className='px-3 pt-3 pb-2 text-xs text-muted'>
                                            {moment(e.createdAt).format('lll')}
                                        </div>
                                        <div className='px-3 pb-3 text-xs'>
                                            {
                                                e.event_type === 'WATCH_VIDEO' ?
                                                    `You ${e.video_type === 'LIVE' ? 'attended a live session.' : e.video_type === 'RECORDED_LIVE' ? `watched recording of live session organized on ${moment(e?.class_id?.date).format('lll')}` : `watched ${e.video_name} workout video.`}`
                                                    :
                                                    `You bought: ${e.description}.`
                                            }
                                        </div>
                                    </Col>
                                    {/* <Col xl={2} lg={3} className='d-none d-xl-block'>
                                        {
                                            e.picture
                                                ?
                                                <div className='p-2 pointer mx-auto' style={{ maxWidth: 100 }} onClick={() => setZoom(e.picture)}>
                                                    <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}${e.picture}`} alt='...' className='img img-fluid' />
                                                </div>
                                                :
                                                <FormGroup className='mb-0 text-center'>
                                                    <label
                                                        className="btn btn-info btn-sm"
                                                        htmlFor={`input-activity-picture-${i}`}
                                                    >
                                                        <i className='lni lni-upload text-lg align-text-bottom'></i>&nbsp;
                                                    </label>
                                                    <input
                                                        id={`input-activity-picture-${i}`}
                                                        type="file" hidden accept='image/*'
                                                        multiple={false} onChange={(ev) => {
                                                            setActivityInd(i);
                                                            onActivityFileChange(ev);
                                                        }}
                                                    />
                                                </FormGroup>
                                        }
                                    </Col> */}
                                </Row>
                            </Card>
                        ))
                    }
                </CardBody>
            </Card>

            <nav aria-label="..." className="py-4">
                <Pagination
                    className="pagination justify-content-end mb-0"
                    listClassName="justify-content-end mb-0"
                >
                    <PaginationItem className="pointer">
                        <PaginationLink
                            href="#"
                            onClick={(e) => page === 0 ? '' : setPage(page - 1)}
                        >
                            <i className="lni lni-chevron-left"></i>
                            <span className="sr-only">Previous</span>
                        </PaginationLink>
                    </PaginationItem>

                    <PaginationItem className={page === 0 ? 'active' : ''}>
                        <PaginationLink
                            href="#"
                            onClick={(e) => page === 0 ? '' : setPage(page - 1)}
                        >
                            {page === 0 ? 1 : page}
                        </PaginationLink>
                    </PaginationItem>

                    {
                        (page === 0 ? totalPages > 1 : true) ?
                            <PaginationItem className={page === 0 ? '' : 'active'}>
                                <PaginationLink
                                    href="#"
                                    onClick={(e) => page === 0 ? setPage(1) : ''}
                                >
                                    {page === 0 ? 2 : page + 1}
                                </PaginationLink>
                            </PaginationItem>
                            :
                            null
                    }

                    {
                        (page === 0 ? 2 < totalPages : page + 1 < totalPages) ?
                            <PaginationItem>
                                <PaginationLink
                                    href="#"
                                    onClick={(e) => page === 0 ? setPage(2) : setPage(1)}
                                >
                                    {page === 0 ? 3 : page + 2}
                                </PaginationLink>
                            </PaginationItem>
                            :
                            null
                    }

                    <PaginationItem>
                        <PaginationLink
                            href="#"
                            onClick={(e) => page + 1 < totalPages ? setPage(page + 1) : ''}
                        >
                            <i className="lni lni-chevron-right"></i>
                            <span className="sr-only">Next</span>
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>
            </nav>


            {/* Modal for activity pic upload */}
            <Modal isOpen={!!(activityFile)} toggle={() => setActivityFile(null)}>
                <ModalHeader toggle={() => setActivityFile(null)}>Upload Picture</ModalHeader>
                <ModalBody>
                    <AvatarEditor
                        ref={activityEditorRef}
                        image={activityFile}
                        width={350}
                        height={350}
                        border={20}
                        color={[255, 255, 255, 0.6]} // RGBA
                        scale={1}
                        rotate={0}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={() => uploadPictureForActivity()} className='mr-2' disabled={uploading}>
                        {uploading ? <Loader size={20} /> : 'Upload'}
                    </Button>
                    <Button color="secondary" onClick={() => setActivityFile(null)} disabled={uploading}>Cancel</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={!!(zoom)} toggle={() => setZoom(null)}>
                <ModalBody className='text-center'>
                    <img src={zoom} className='img img-fluid' alt='...' />
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => setZoom(null)}>Close</Button>
                </ModalFooter>
            </Modal>

        </>
    );
};

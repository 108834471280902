export const changeFilterType = (type) => ({
    type: 'SET_TYPE',
    payload: type
});

export const changeFilterCategory = (type) => ({
    type: 'SET_CATEGORY',
    payload: type
});

export const changeFilterDate = (date) => ({
    type: 'SET_DATE',
    payload: date
});

export const changeRecipePage = (page) => ({
    type: 'SET_RECIPE_PAGE',
    payload: page
});

export const changeRecipeStatus = (type) => ({
    type: 'SET_RECIPE_STATUS',
    payload: type
});
import { useEffect, useState } from "react";
import {
    Container,
    Card,
    CardBody,
    Row,
    Col
} from "reactstrap";
import {
    toast
} from 'react-toastify';
import Loader from "../../components/Loader/loader.js";
import _fetch from '../../_fetch';
import './style.scss';
import Header2 from "../../components/Headers/Header2.js";
import { Link } from "react-router-dom";

export default function Affilations(){
    const [affilations, setAffilations] = useState([])
    const [loading, setLoading] = useState({
        type: "global",
        status: true
    });
    const [hoverCardId, setHoverCardId] = useState(null);

    async function init(){
        try {
            let url = `${process.env.REACT_APP_API_V2}/affilation`;
            let res = await _fetch(url);
            if (res.success) {
                setAffilations(res.response);
            } else {
                toast.error(res.response);
            }
        } catch (err) {
            console.log(err);
            toast.error("An error occured while fetching affilations");
        } finally {
            setLoading({
                type: "global",
                status: false
            })
        }
    }

    useEffect(() => {
        init();
    }, [])


    if (loading.status && loading.type === "global") {
        return (
            <>
                <Header2 />

                <Container className="mt--7" fluid>
                    <Card className="bg-secondary shadow" style={{ height: 400 }}>
                        <Loader />
                    </Card>
                </Container>
            </>   
        )
    }


    return (
        <>
            <Header2 />
            <div className='about-sec'>
                <Container>
                    <Row>
                        <Col md="12">
                            <div className='section-head'>
                                <h3 className='head-font tag-head'>Affilations</h3>
                            </div>
                        </Col>

                        <Col md="12">
                            <ol className="breadcrumb">
                                <li><Link to="/">Home</Link></li>
                                <li className='section-head'>{`>`}</li>
                                <li className='section-head'>Affilations</li>
                            </ol>
                        </Col>
                    </Row>
                </Container>
            </div>
            <hr></hr>
            <div
                id="affilations-container"
                className="w-100 container"
            >
                <Row
                    className="w-100 p-4 m-0"
                    style={{
                        gap: 20
                    }}
                >
                    {
                        affilations.map((affilation) => (
                            <Col
                                xs="12"
                                md="6"
                                lg="4"
                                key={affilation._id}
                                className="p-0"
                            >
                                <a
                                    href={affilation.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="text-decoration-none"
                                >
                                    <Card
                                        className="affilation-card overflow-hidden"
                                        onMouseEnter={() => setHoverCardId(affilation._id)}
                                        onMouseLeave={() => setHoverCardId(null)}
                                    >
                                        <CardBody
                                            className="bg-transparent p-0"
                                        >
                                            <div
                                                style={{
                                                    width: "100%",
                                                    height: 450,
                                                    backgroundImage: `url('${affilation.image}')`,
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center",
                                                    borderTopLeftRadius: 36,
                                                    borderBottomRightRadius: 36,
                                                    overflow: "hidden"
                                                }}
                                                className="d-flex flex-column justify-content-end align-items-center p-2 position-relative"
                                            >
                                                <div
                                                    className="w-100 h-100 position-absolute"
                                                    style={{
                                                        top: 0,
                                                        left: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        zIndex: 1,
                                                        backgroundImage: `linear-gradient(rgba(0,0,0,${hoverCardId === affilation._id ? 0.5 : 0.0}), rgba(0,0,0,${hoverCardId === affilation._id ? 0.5 : 0.2}))`,
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        fontSize: "20px",
                                                        color: "white",
                                                        fontWeight: "bold",
                                                        zIndex: 2
                                                    }}
                                                >{affilation.title}</span>
                                                {
                                                    hoverCardId === affilation._id && (
                                                        <a
                                                            href={affilation.link}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <i
                                                                className="lni lni-link text-white"
                                                                style={{
                                                                    fontSize: "24px",
                                                                    zIndex: 2,
                                                                    position: "absolute",
                                                                    top: "50%",
                                                                    left: "50%",
                                                                    transform: "translate(-50%, -50%)"
                                                                }}
                                                            />
                                                        </a>
                                                    )
                                                }
                                            </div>
                                            {
                                                hoverCardId === affilation._id && (
                                                    <div
                                                        className="p-2"
                                                    >
                                                        <span
                                                            className="text-center d-block"
                                                            style={{
                                                                fontSize: "16px",
                                                                color: "black",
                                                            }}
                                                        >{affilation.description}</span>
                                                    </div>
                                                )
                                            }
                                        </CardBody>
                                    </Card>
                                </a>
                            </Col>
                        ))
                    }
                </Row>
            </div>
        </>
    )
}
import {
    Container,
    Card,
    CardHeader,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Col,
    Input,
    Table
} from "reactstrap";
import { toast } from "react-toastify";
import AdminHeader from "../../../components/Headers/AdminHeader";
import { useEffect, useState } from "react";
import _fetch from '../../../_fetch';
import Loader from "../../../components/Loader/loader.js";

export default function ManageAffilations(){
    const [affilations, setAffilations] = useState([]);
    const [loading, setLoading] = useState({
        type: "global",
        status: true
    });
    const [modalInfo, setModalInfo] = useState({
        isOpen: false,
        mode: "add",
    });
    const [formData, setFormData] = useState({})

    async function init(){
        try {
            let url = `${process.env.REACT_APP_API_V2}/affilation`;
            let res = await _fetch(url);
            if (res.success) {
                setAffilations(res.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading({
                type: "global",
                status: false
            })
        }
    }

    function handleFormChange(e){
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    }

    function isValidForm() {
        const valid = {
            isValid: true,
            message: ""
        };

        if (!formData.title) {
            valid.isValid = false;
            valid.message = "Please enter affilation title";
        } else if (!formData.description) {
            valid.isValid = false;
            valid.message = "Please enter affilation description";
        } else if (!formData.link) {
            valid.isValid = false;
            valid.message = "Please enter affilation link";
        } else if (!formData.image && !formData.rawImage) {
            valid.isValid = false;
            valid.message = "Please select affilation image";
        }

        return valid;
    }

    async function upload(doc) {
        let res = await _fetch(`${process.env.REACT_APP_API}/media/admin_upload_public`);
        const uploadFormData = new FormData();

        let ext = doc.name.split('.');
        if (ext.length > 1) ext = '.' + ext[ext.length - 1];
        else ext = '.png';

        let url = `kwm_web/affilations/${formData.title}-${new Date().valueOf()}${ext}`

        uploadFormData.append('key', url);
        uploadFormData.append('acl', 'public-read');
        uploadFormData.append('Content-Type', doc.type);

        for (let a in res.response) {
            uploadFormData.append(a, res.response[a]);
        }

        uploadFormData.append('file', doc);
        res = await fetch(`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}`, {
            method: 'POST',
            body: uploadFormData
        });
        if (res.status === 204) {
            return `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/${url}`;
        }
        else {
            throw Error('File upload failed');
        }
    }

    async function handleAddAffilation(){
        try {
            const valid = isValidForm();
            if (!valid?.isValid) {
                toast.error(valid.message);
                return;
            }
            setLoading({
                type: "modal",
                status: true
            });
            let body = {
                ...formData,
                rawImage: null
            }
            if (formData.rawImage){
                body.image = await upload(formData.rawImage);
            }
            const url = `${process.env.REACT_APP_API_V2}/affilation`;
            const res = await _fetch(url, {
                method: "POST",
                body: body
            });
            if (res.success){
                toast.success("Affilation added successfully");
                setAffilations([
                    ...affilations,
                    res.response
                ]);
                setModalInfo({
                    isOpen: false,
                    mode: "add"
                });
                setFormData({});
            } else {
                toast.error(res.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading({
                type: "modal",
                status: false
            })
        }
    }

    async function handleEditAffilation(){
        try {
            const valid = isValidForm();
            if (!valid?.isValid) {
                toast.error(valid.message);
                return;
            }
            setLoading({
                type: "modal",
                status: true
            });
            let body = {
                ...formData,
                rawImage: null
            }
            if (formData.rawImage){
                body.image = await upload(formData.rawImage);
            }
            const url = `${process.env.REACT_APP_API_V2}/affilation/${formData._id}`;
            const res = await _fetch(url, {
                method: "PATCH",
                body: body
            });
            if (res.success){
                toast.success("Affilation updated successfully");
                let index = affilations.findIndex(affilation => affilation._id === formData._id);
                affilations[index] = res.response;
                setAffilations([...affilations]);
                setModalInfo({
                    isOpen: false,
                    mode: "add"
                });
                setFormData({});
            } else {
                toast.error(res.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading({
                type: "modal",
                status: false
            })
        }
    
    }

    async function handleDeleteAffilation(id){
        try {
            setLoading({
                type: "delete",
                status: true
            });
            const url = `${process.env.REACT_APP_API_V2}/affilation/${id}`;
            const res = await _fetch(url, {
                method: "DELETE"
            });
            if (res.success){
                toast.success("Affilation deleted successfully");
                setAffilations(affilations.filter(affilation => affilation._id !== id));
            } else {
                toast.error(res.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading({
                type: "delete",
                status: false
            });
        }
    }



    useEffect(() => {
        init();
    }, []);

    if (loading.status && loading.type === "global") {
        return (
            <>
                <AdminHeader />
                <Container className="mt--7" fluid>
                    <Card className="bg-secondary shadow" style={{ height: 400 }}>
                        <Loader />
                    </Card>
                </Container>
            </>   
        )
    }

    return (
        <>
            <AdminHeader />   
            <Container className="mt--9" fluid>
                <Card className="shadow">
                    <CardHeader className="border-0">
                        <div className='d-flex justify-content-between'>
                            <h3 className="mb-0">
                                Affilations ({affilations.length})
                            </h3>
                            <button className="btn btn-primary" onClick={() => {
                                setModalInfo({
                                    isOpen: true,
                                    mode: "add"
                                });
                            }}>Add Affilation</button>
                        </div>
                    </CardHeader>
                    <Table
                        className="align-items-center table-flush"
                        responsive
                    >
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Affilation Image</th>
                                <th scope="col">Affilation Title</th>
                                <th scope="col">Affilation Description</th>
                                <th scope="col">Affilation Link</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                affilations.map((affilation) => (
                                    <tr key={affilation._id}>
                                        <td>
                                            <img
                                                src={affilation.image}
                                                alt="affilation"
                                                style={{
                                                    width: 200,
                                                    height: 150,
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </td>
                                        <td>
                                            {affilation.title}
                                        </td>
                                        <td
                                            style={{
                                                maxWidth: 300,
                                                whiteSpace: "pre-wrap"
                                            }}
                                        >
                                            {affilation.description}
                                        </td>
                                        <td>
                                            <a href={affilation.link} target="_blank" rel="noreferrer">
                                                {affilation.link}
                                            </a>
                                        </td>
                                        <td>
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    setFormData(affilation);
                                                    setModalInfo({
                                                        isOpen: true,
                                                        mode: "edit"
                                                    });
                                                }}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                className="btn btn-danger"
                                                disabled={
                                                    loading.status && loading.type === "delete"
                                                }
                                                onClick={() => {
                                                    handleDeleteAffilation(affilation._id);
                                                }}
                                            >
                                                {
                                                    loading.status && loading.type === "delete" ? (
                                                        <Loader
                                                            size={20}
                                                        />
                                                    ) : (
                                                        <i
                                                            className="lni lni-trash-can"
                                                        />
                                                    )
                                                }
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>  
                </Card>
                <Modal
                    isOpen={modalInfo.isOpen}
                    toggle={() => {
                        setModalInfo({
                            isOpen: false,
                            mode: "add"
                        });
                    }}
                >
                    <ModalHeader>
                        <h4>
                            {modalInfo.mode === "add" ? "Add Affilation" : "Edit Affilation"}
                        </h4>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col
                                lg="12"
                                className="d-flex flex-column justify-content-center align-items-center mb-3"
                            >
                                <div
                                    className="w-100"
                                >
                                    <label
                                        className="d-block"
                                    >
                                        Affilation Image
                                    </label>
                                    <div
                                        className="d-flex justify-content-center align-items-center w-100"
                                        style={{
                                            height: formData.image || formData.rawImage ? 200 : "auto"
                                        }}
                                    >
                                        <div
                                            className="position-relative h-100 w-auto"
                                        >
                                            {
                                                formData?.image ? (
                                                    <img
                                                        src={formData.image}
                                                        alt="affilation"
                                                        className="h-100 w-auto"
                                                    />
                                                ) : (
                                                    formData?.rawImage && (
                                                        <img
                                                            src={URL.createObjectURL(formData.rawImage)}
                                                            alt="affilation"
                                                            className="h-100 w-auto"
                                                        />
                                                    )
                                                )
                                            }
                                            {
                                                (formData?.image || formData?.rawImage) && (
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            top: -15,
                                                            right: -15,
                                                            cursor: "pointer",
                                                            borderRadius: 999,
                                                            width: 30,
                                                            height: 30,
                                                        }}
                                                        className="p-1 bg-red d-flex justify-content-center align-items-center"
                                                        onClick={() => {
                                                            setFormData({
                                                                ...formData,
                                                                image: null,
                                                                rawImage: null
                                                            });
                                                        }}
                                                    >
                                                        <i
                                                            className="lni lni-cross-circle text-white"
                                                        />
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    {
                                        !formData.image && !formData.rawImage && (
                                            <div
                                                className="w-100"
                                            >
                                                <input
                                                    type="file"
                                                    name="image"
                                                    onChange={(e) => {
                                                        setFormData({
                                                            ...formData,
                                                            rawImage: e.target.files[0]
                                                        });
                                                    }}
                                                    className="w-100"
                                                    placeholder="Choose Affilation Image"
                                                />
                                            </div>
                                        )
                                    }
                                </div>
                            </Col>
                            <Col lg='12'>
                                <label>
                                    Affilation Title
                                </label>
                                <Input
                                    placeholder="Affilation Title"
                                    type="text"
                                    name="title"
                                    onChange={handleFormChange}
                                    value={formData.title}
                                />
                            </Col>
                            <Col
                                lg='12'
                                className="mt-3"
                            >
                                <label>
                                    Affilation Description
                                </label>
                                <Input
                                    placeholder="Affilation Description"
                                    type="textarea"
                                    name="description"
                                    onChange={handleFormChange}
                                    value={formData.description}
                                />
                            </Col>
                            <Col
                                lg='12'
                                className="mt-3"
                            >
                                <label>
                                    Affilation Link
                                </label>
                                <Input
                                    placeholder="Affilation Link"
                                    type="text"
                                    name="link"
                                    onChange={handleFormChange}
                                    value={formData.link}
                                />
                            </Col>
                            <Col
                                lg='12'
                                className="mt-3"
                            >
                                <button
                                    className="btn btn-primary w-100"
                                    disabled={
                                        loading.status && loading.type === "modal"
                                    }
                                    onClick={
                                        modalInfo.mode === "add" ? handleAddAffilation : handleEditAffilation
                                    }
                                >
                                    {
                                        loading.status && loading.type === "modal" ? (
                                            <Loader
                                                size={20}
                                            />
                                        ) : (
                                            modalInfo.mode === "add" ? "Add Affilation" : "Edit Affilation"
                                        )
                                    }
                                </button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                
            </Container >
        </>
    )
}
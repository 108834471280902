import React, { useCallback, useEffect, useState } from "react";
import {
	Container, Badge, Button, Card, CardText, CardBody, Popover, CardHeader,
	Modal, ModalBody, ModalFooter, ButtonGroup, Tooltip,
	ModalHeader,
	Row,
	Col,
	FormGroup,
	Input
} from "reactstrap";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import _fetch from '../../_fetch';
import Header from "../../components/Headers/Header.js";
import './styles.scss';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/loader";
import { getEnrollments } from "../../actions/userActions";
import Reviews from "../Reviews";
import LiveClassListView from "./ListView";
import AddClassModal from '../Admin/AddClass/AddClassModal';

const localizer = momentLocalizer(moment);

export default function LiveClasses() {

	const [tooltipOpen, setTooltipOpen] = useState(false);
	const [sessions, setSessions] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [selectedClass, setSelectedClass] = useState(false);
	const [editClass, setEditClass] = useState(false);
	const [cloneClass, setCloneClass] = useState(false);
	const [live, setLive] = useState(false);
	const [loading, setLoading] = useState(false);
	const [live_classes_count, setLiveClassesCount] = useState(0);
	const [available__recorded_live_classes, setAvailableRecordedLiveClasses] = useState([]);
	const [completed_live_classes, setCompletedLiveClasses] = useState({});
	const [available_live_classes, setAvailableLiveClasses] = useState([]);
	const [view, setView] = useState('CALENDAR');
	const [url, setUrl] = useState('');
	const [sessionRange, setSessionRange] = useState({
		startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 21),
		endDate: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 7),
		type: 'month',
		initial: true
	});

	const [selectedAddToCS, setSelectedAddToCS] = useState(null);
	const [newCSData, setNewCSData] = useState({
		title: '',
		CScategory: '',
		CSfocus_area: '',
		CSintensity: '',
	});
	const [mobileCategory, setMobileCategory] = useState([]);
	const [focusArea, setFocusArea] = useState([]);
	


	const { total_live_classes, watched_live_classes, points, live_enrollments, admin, expired } = useSelector(st => st.user);
	let history = useHistory();
	let dispatch = useDispatch();


	async function getClasses() {
		try {
			let url = `${process.env.REACT_APP_API}/classes?type=LIVE`;
			if (view !== 'LIST') {
				let startDate = sessionRange?.startDate;
				let endDate = sessionRange?.endDate;
				url = `${process.env.REACT_APP_API}/classes?type=LIVE&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
			} else {
				url = `${process.env.REACT_APP_API}/classes?type=LIVE&endDate=${new Date().toISOString()}`;
			}
			let res = await _fetch(url);
			if (res.success) {
				setSessions(res.response);
			}
		} catch (err) {
			console.log(err);
		}
	}

	async function getCategoriesAndFA(){
		try {
			let categoryValue = await _fetch(`${process.env.REACT_APP_API}/category?platform=MOBILE`)
			if (categoryValue.success) {
				setMobileCategory(categoryValue.response);
			}
			let focusAreaValue = await _fetch(`${process.env.REACT_APP_API_V2}/focus-area`)
			if (focusAreaValue.success) {
				setFocusArea(focusAreaValue.response);
			}
		} catch (err) {
			console.log(err);
		}	
	}

	async function init() {
		try {
			setLoading(true);
			await getClasses();
			await getEnrollments(dispatch);
			await getCategoriesAndFA();
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		const l = live_enrollments;
		if (Array.isArray(l)) {
			console.log(l);
			let arr = [];
			let arr2 = [];
			let obj = {};
			l.forEach(e => {
				if (Array.isArray(e.classes)) {
					e.classes.forEach(c => {
						obj[c._id] = 1;
						if (c.type === 'RECORDED_LIVE' && moment().isBefore(moment(c.watch_start).add(12, 'h'))) {
							arr.push(c._id);
						} else if (c.type === 'LIVE' && moment().isBefore(moment(c.watch_start).add(12, 'h'))) {
							arr2.push(c._id);
						}
					})
				}
			});
			expired.filter(e => e.type === 'LIVE').forEach(e => {
				e.classes.forEach(c => {
					obj[c._id] = 1;
				})
			});
			setAvailableRecordedLiveClasses(arr);
			setAvailableLiveClasses(arr2);
			setCompletedLiveClasses(obj);
		}
	}, [live_enrollments, expired])

	useEffect(() => {
		if (!isNaN(total_live_classes) && !isNaN(watched_live_classes))
			setLiveClassesCount(total_live_classes - watched_live_classes)
	}, [total_live_classes, watched_live_classes])

	useEffect(() => {
		async function handleViewChange() {
			try {
				setLoading(true);
				await getClasses();
			} catch (e) {
				console.log(e);
			} finally {
				setLoading(false);
			}
		}

		handleViewChange();
	}, [view, sessionRange]);

	useEffect(() => {
		init();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function recordedClass(id) {

		let _class = sessions.find(e => e._id === (selectedClass || id));
		if (_class.s3_link) {
			return history.push('/watch', selectedClass || id);
		}

		_fetch(`${process.env.REACT_APP_API}/classes/recording_url?class_id=${selectedClass || id}`)
			.then(res => {
				if (res.success) {
					if (!res.response.class_recording && !res.response.s3_link) {
						return;
					}
					if (res.response.type === 'LIVE') {
						setSelectedEvent(null);
						if (res.response.class_recording) {
							if (!selectedClass) {
								setSelectedClass(id);
							}
							setUrl(res.response.class_recording);
						} else {
							return history.push(`/watch/${id}`);
						}
						init();
						return;
					}
				} else {
					toast.error(res.response);
				}
			})
			.catch(err => {
				console.log(err);
				toast.error(err.message);
			})
	}

	function liveClass(class_id) {
		_fetch(`${process.env.REACT_APP_API}/classes/meeting_link?class_id=${class_id}`)
			.then(res => {
				if (res.success) {
					if (res.response) {
						setSelectedEvent(null);
						if (!selectedClass) {
							setSelectedClass(class_id);
						}
						setUrl(res.response);
						init();
					} else {
						toast.error('some error occurred please try again');
						init();
					}
				} else {
					toast.error(res.response);
				}
			})
			.catch(err => {
				console.log(err);
				toast.error(err.message);
			})
	}

	function closeLinkModal() {
		setUrl('');
		setSelectedClass('');
	}

	const onChange = (ev) => {
		setNewCSData(prev => ({ ...prev, [ev.target.name]: ev.target.value }));
	}

	const isValid = () => {
		let valid = true;
		if (!newCSData.title){
			valid = false
			toast.error("Please enter class Title");
		} else if (!newCSData.CScategory){
			valid = false
			toast.error("Please select class Category");
		} else if (!newCSData.CSfocus_area){
			valid = false
			toast.error("Please select class Focus Area");
		} else if (!newCSData.CSintensity){
			valid = false
			toast.error("Please select class Intensity");
		}
		return valid;
	}

	const uploadClasstoCS = async () => {
		try {
			const valid = isValid();
			if (!valid) return;
			let res = await _fetch(`${process.env.REACT_APP_API_V2}/class/add-live-class`, {
				method: 'POST', body: {
					...selectedAddToCS,
					...newCSData
				}
			});
			if (res.success) {
				setSessions((prev) => {
					let updated = [];
					prev.forEach((item) => {
						if (item._id === selectedAddToCS._id) {
							updated.push({
								...item,
								addedToCS: true
							})
						} else updated.push(item)
					})
					return updated
				})
				setSelectedAddToCS(null);
				setNewCSData({
					title: '',
					CScategory: '',
					CSfocus_area: '',
					CSintensity: '',
				});
				toast.success('Class added to Core Sculpt successfully');
			} else {
				toast.error(res.response);
			}
		} catch (err) {
			console.log(err);
			toast.error('An error occurred');
		}
	}

	if (loading) return (
		<>
			<Header />

			<Container className="mt--7" fluid>
				<Card className="bg-secondary shadow" style={{ height: 400 }}>
					<Loader />
				</Card>
			</Container>
		</>
	);

	return (
		<>
			<Header />

			{/* Page content */}
			<Container className="mt--7" fluid>

				<Card className="bg-secondary shadow">

					<CardHeader className="bg-white border-0">
						<div className='d-flex flex-column flex-lg-row justify-content-between'>
							<div>
								<div className='d-flex mb-2 align-items-center'>
									<span className='text-lg font-weight-600'>Available Live Classes: {live_classes_count ? live_classes_count : 0}</span>
									<Button
										color='primary'
										className='ml-auto d-none d-lg-block live-class-checkout'
										onClick={() => history.push('/buy-classes')}
									>
										Buy Live Classes
									</Button>
									{
										admin &&
										<Button
											onClick={() => {
												setSelectedEvent(null);
												setEditClass(true)
											}}
											className='ml-2 d-none d-lg-block'
										>
											Add Class
										</Button>
									}
									{/* <Button
										onClick={() => {
											setSelectedEvent(null);
											setEditClass(true)
										}}
										className='ml-2 d-none d-lg-block'
									>
										Add Class
									</Button> */}
								</div>
								<div>
									Below is the calendar of Live classes. Click on 'Join Live' at the time of class to join live. If you cant make it to Live session, just click on 'Watch now' to replay the session. Please ensure to complete the session within 12 hours once played. Enjoy!
								</div>
							</div>
							<div className='ml-0 mt-2 mb-auto d-block d-lg-none'>
								<div className="d-flex">
									{
										admin &&
										<Button
											onClick={() => {
												setSelectedEvent(null);
												setEditClass(true)
											}}
											className='my-2 recorded-class-checkout align-self-end'
										>
											Add Class
										</Button>
									}
									<Button
										color='primary'
										className='my-2 ml-auto recorded-class-checkout align-self-end'
										onClick={() => history.push('/buy-classes')}
									>
										Buy Live Classes
									</Button>
								</div>
								<div>
									<ButtonGroup className='mt-3'>
										<Button onClick={() => setView('LIST')}><i className="lni lni-list text-lg text-dark"></i></Button>
										<Button onClick={() => setView('CALENDAR')}><i className="lni lni-calendar text-lg text-dark"></i></Button>
									</ButtonGroup>
								</div>
							</div>
						</div>
					</CardHeader>

					<CardBody className={view === 'CALENDAR' ? 'd-block' : 'd-none'}>
						<div className='live-classes-container live-calendar'>
							<Calendar
								localizer={localizer}
								events={
									sessions.map(e => ({
										title: e.title,
										start: moment(e.date).toDate(),
										end: moment(e.date).add(e.duration_in_minutes, 'minutes').toDate(),
										allDay: false,
										description: e.description,
										_id: e._id,
										id: `a${e._id}`,
										category: e.category,
										recording_link: e.recording_link,
										s3_link: e.s3_link,
										meta: e.meta,
										addedToCS: e.addedToCS
									}))
								}
								eventPropGetter={(ev) => ({
									className: completed_live_classes[ev._id] ? 'bg-green' : ''
								})}
								startAccessor="start"
								endAccessor="end"
								titleAccessor={(event) => (
									<div className='d-flex flex-row align-items-center justify-content-between' id={event.id}>
										<span className='ml-2'>{event.title}</span>
										{
											completed_live_classes[event._id] &&
											<i className="lni lni-checkmark mr-2"></i>
										}
									</div>
								)}
								views={['month', 'week', 'day']}
								tooltipAccessor={null}
								onSelectEvent={(e) => setSelectedEvent(e)}
								onRangeChange={(e) => {
									if (e?.length === 1) {
										setSessionRange({
											startDate: new Date(e[0]),
											endDate: new Date(e[0]),
											type: 'day',
											initial: false
										})
									} else if (e?.length === 7) {
										setSessionRange({
											startDate: new Date(e[0]),
											endDate: new Date(e[6]),
											type: 'week',
											initial: false
										})
									} else if (e?.start && e?.end) {
										setSessionRange({
											startDate: new Date(e.start),
											endDate: new Date(e.end),
											type: 'month',
											initial: false
										})
									}
								}}
								defaultDate={new Date()}
								defaultView="month"
								date={
									sessionRange?.type === 'month' ? sessionRange?.initial ? new Date(sessionRange?.startDate?.getTime() + (14 * 24 * 60 * 60 * 1000)) : new Date(sessionRange?.startDate?.getTime() + (7 * 24 * 60 * 60 * 1000)) : (sessionRange?.startDate || new Date())
								}
								view={sessionRange?.type || 'month'}
							/>
							{
								selectedEvent && !editClass ?
									<Popover isOpen={true} target={selectedEvent?.id} trigger="legacy" toggle={() => setSelectedEvent(null)}>
										<Card style={{ width: 300 }}>
											<CardBody>
												<h5
													style={{ fontSize: 18 }}
													className='d-flex justify-content-between'
												>
													{selectedEvent?.title}
													{
														admin &&
														<span className="pointer"
															onClick={() => {
																setCloneClass(false);
																setEditClass(true);
															}}
														>
															<i className="lni lni-pencil text-dark" />
														</span>
													}
													{
														admin &&
														<span className="pointer"
															onClick={() => {
																setCloneClass(true);
																setEditClass(true);
															}}
														>
															<i className="lni lni-files text-dark" />
														</span>
													}
												</h5>
												<div>
													<Badge
														color="info"
														style={{ textTransform: 'capitalize', fontSize: 12 }}
														className="my-2 mr-auto"
													>
														{selectedEvent?.category.name}
													</Badge>
												</div>
												<h6 className="mb-2 text-muted" style={{ fontSize: 14 }}>
													{moment(selectedEvent?.start).format('ll')} {moment(selectedEvent?.start).format('LT')} - {moment(selectedEvent?.end).format('LT')}
													{
														admin &&
														<React.Fragment>
															<i className='lni lni-question-circle text-dark pl-2' id='meta-tooltip'></i>
															<Tooltip target='meta-tooltip' isOpen={tooltipOpen} toggle={() => setTooltipOpen(!tooltipOpen)}>{selectedEvent.meta}</Tooltip>
														</React.Fragment>
													}
												</h6>
												<CardText style={{ fontSize: 12 }}>{selectedEvent?.description}</CardText>
												<div className='my-3'>
													<h5>Review this class on:</h5>
													<Reviews size='xs' />
												</div>
												<div className='d-flex justify-content-center'>
													<Button
														type='button' color='primary'
														disabled={!(selectedEvent.recording_link || selectedEvent.s3_link) || moment().isBefore(selectedEvent.end)}
														size='small' className='mr-2'
														onClick={() => {
															if (available_live_classes.includes(selectedEvent._id) || available__recorded_live_classes.includes(selectedEvent._id)) {
																setLive(false);
																recordedClass(selectedEvent?._id);
																return;
															}
															if (live_classes_count || points) {
																setLive(false);
																if (!available__recorded_live_classes.includes(selectedEvent?._id)) {
																	setSelectedClass(selectedEvent?._id);
																	return setSelectedEvent(null);
																}
																recordedClass(selectedEvent?._id);
															}
															else {
																toast.info('Buy more live classes to watch this video');
																history.push('/cart');
															}
														}}
													>
														Watch Now
													</Button>
													<Button
														type='button' color='primary'
														onClick={() => {
															if (available_live_classes.includes(selectedEvent._id) || available__recorded_live_classes.includes(selectedEvent._id)) {
																setSelectedEvent(null);
																liveClass(selectedEvent?._id);
																return;
															}
															if (live_classes_count) {
																setLive(true);
																setSelectedClass(selectedEvent?._id);
																setSelectedEvent(null);
															}
															else {
																toast.info('Buy more slots to watch this video');
																history.push('/buy-classes');
															}
														}}
														disabled={!moment().isBetween(moment(selectedEvent?.start).subtract(30, 'minutes'), selectedEvent.end)} size='small'
														className='ml-2'
													>
														Join Live
													</Button>
												</div>
												{
													admin && (
														<div
															className="mt-2 d-flex px-2 justify-content-center"
														>
															{
																selectedEvent?.addedToCS ? (
																	<Badge
																		color="info"
																		style={{ textTransform: 'capitalize', fontSize: 12 }}
																		className="my-2 mx-auto"
																	>
																		Already Added to Core Sculpt
																	</Badge>
																) : (
																	<Button
																		type='button' color='primary'
																		className="w-100"
																		onClick={() => {
																			if(!selectedEvent?.s3_link){
																				toast.error("Add recording for the class first");
																				setCloneClass(false);
																				setEditClass(true);
																			} else {
																				const selectedClass = sessions.find((sessionItem) => sessionItem?._id?.toString() === selectedEvent?._id?.toString());
																				if (selectedClass) {
																					setNewCSData({
																						...newCSData,
																						title: selectedClass.title
																					});
																					setSelectedAddToCS(selectedClass);
																					setSelectedEvent(null);
																				}
																			}
																		}}
																	>
																		Add Class to CS
																	</Button>
																)
															}
														</div>
													)
												}
											</CardBody>
										</Card>
									</Popover> :
									<AddClassModal
										id={selectedEvent?._id}
										isOpen={editClass}
										toggle={() => setEditClass(false)}
										refresh={() => getClasses()}
										clone={cloneClass}
									/>
							}
						</div>
					</CardBody>

					<LiveClassListView
						visible={view === 'CALENDAR'} sessions={sessions}
						live_classes_count={live_classes_count} setLive={setLive}
						setSelectedClass={setSelectedClass} setSelectedEvent={setSelectedEvent}
						available__recorded_live_classes={available__recorded_live_classes}
						recordedClass={recordedClass} liveClass={liveClass}
						available_live_classes={available_live_classes}
						completed_live_classes={completed_live_classes}
					/>

				</Card>

				<Modal isOpen={!!selectedClass} centered={true}
					toggle={() => {
						setUrl('');
						setSelectedClass('');
					}}
				>
					<ModalBody>
						{
							url ?
								<div className="w-100 text-center">
									<a href={url} target="_blank" rel="noreferrer" onClick={closeLinkModal} >
										<Button type="button" color="primary">Click here to start watching</Button>
									</a>
								</div>
								:
								<p className='font-weight-500'>
									{
										live_classes_count ?
											<React.Fragment>
												Watching this recording will reduce 1 live class from your purchased live classes.<br />
												Do you want to continue ?
											</React.Fragment>
											:
											points ?
												<React.Fragment>
													You do not have any live classes left, Watching this recording will reduce 50 points from points you have earned by referrals.<br />
													Do you want to continue ?
												</React.Fragment>
												:
												'Please purchase more live classes'
									}
								</p>
						}
					</ModalBody>

					{
						url ?
							null
							:
							<ModalFooter>
								<Button color='danger' onClick={() => setSelectedClass('')}>No</Button>
								<Button color='primary' onClick={() => live ? liveClass(selectedClass) : recordedClass()}>Yes</Button>
							</ModalFooter>
					}

				</Modal>
				{
					selectedAddToCS && (
						<Modal isOpen={selectedAddToCS !== null} toggle={() => setSelectedAddToCS(null)}>
							<ModalHeader toggle={() => setSelectedAddToCS(null)}>
								{
									selectedAddToCS.s3_link === "" ? "No Video Available for this class" : "Video Available for this class"
								}
							</ModalHeader>
							<ModalBody>
								{
									selectedAddToCS.s3_link === "" ? "Attention! It appears that the video for this class hasn't been uploaded yet.Kindly upload the video for this class. " : (
										<Row>
											<Col lg="12">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-first-name"
													>
														Class Title
													</label>
													<Input
														className="form-control-alternative"
														id="input-gender"
														name='title'
														onChange={onChange}
														value={newCSData.title}
														defaultValue={newCSData.title}
														placeholder="Enter Class Title"
													>
													</Input>
												</FormGroup>
											</Col>
											<Col lg="12">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-first-name"
													>
														Class Category
													</label>
													<Input
														className="form-control-alternative"
														id="input-gender"
														name='CScategory'
														type="select"
														onChange={onChange}
														value={newCSData.CScategory}
														defaultValue=''
													>
														<option value='' disabled>Select Category</option>
														{
															mobileCategory.map(e => (
																<option value={e._id} key={e._id}>{e.name}</option>
															))
														}
													</Input>
												</FormGroup>
											</Col>
											<Col lg="12">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-first-name"
													>
														Session Focus Area
													</label>
													<Input
														className="form-control-alternative"
														id="input-gender"
														name="CSfocus_area"
														type="select"
														onChange={onChange}
														value={newCSData.CSfocus_area}
														defaultValue={''}
													>
														<option value="" disabled>
															Select Focus Area
														</option>
														{
															focusArea
																.filter(e => e.category === newCSData.CScategory)
																.map((e) => (
																	<option value={e._id} key={e._id}>
																		{e.name}
																	</option>
																))
														}
													</Input>
												</FormGroup>
											</Col>
											<Col lg="12">
												<FormGroup>
													<label
														className="form-control-label"
														htmlFor="input-first-name"
													>
														Session Intensity
													</label>
													<Input
														className="form-control-alternative"
														id="input-gender"
														name="CSintensity"
														type="select"
														onChange={onChange}
														value={newCSData.CSintensity}
													>
														<option value="" disabled>
															Select Intensity
														</option>
														<option value='BEGINNER'>
															Beginner
														</option>
														<option value='INTERMEDIATE'>
															Intermediate
														</option>
														<option value='ADVANCED'>
															Advanced
														</option>
													</Input>
												</FormGroup>
											</Col>

										</Row>)
								}
							</ModalBody>
							<ModalFooter>
								{
									selectedAddToCS.s3_link === "" ? (
										<Button color="primary" onClick={() => (
											history.push(`/admin/add-class/${selectedAddToCS._id}`)
										)}>Upload Class Video</Button>
									) : (<Button color="primary" onClick={() => {
										uploadClasstoCS();
									}}>Add to Core Sculpt</Button>)
								}
							</ModalFooter>
						</Modal>
					)
				}

			</Container>
		</>
	);
};

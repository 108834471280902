import React, { useState } from "react";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
	CardFooter,
} from "reactstrap";
import validator from "validator";
import GoogleLogin from 'react-google-login';
import leftTwigSVG from '../../assets/subscription/flower-1.svg'
import rightTwigSVG from '../../assets/subscription/flower-2.svg'
import './login.scss';
import _fetch from "../../_fetch";
import Loader from '../../components/Loader/loader';
import { LoginAction } from "../../actions/userActions";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function Register() {

	const [first_name, setFirstName] = useState('');
	const [last_name, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [otp, setOtp] = useState('');
	const [err, setErr] = useState('');
	const [loading, setLoading] = useState(false);
	const [disabled, setDisabled] = useState(true);
	const [otpSent, setOtpSent] = useState(false);
	const history = useHistory();
	const dispatch = useDispatch();

	let referral_code = useQuery().get('referrer');

	function register(ev) {
		ev.preventDefault();

		setErr('');

		if (!email || !validator.isEmail(email))
			return setErr('Please enter a valid email id');
		else if (!password || !validator.isStrongPassword(password))
			return setErr('Password must be at least 8 character long, and must have at least 1 lower case letter, 1 upper case letter, 1 number and 1 special symbol.');

		setLoading(true);

		_fetch(process.env.REACT_APP_API + '/user', { method: 'POST', body: { first_name, last_name, email, password, referral_code } })
			.then(res => {
				if (res.success) {
					setOtpSent(true);
					toast.success(res.response.message);
				} else {
					toast.error(res.response);
				}
			})
			.catch(err => {
				console.log(err);
				toast.error('Some error occurred please try again later.')
			})
			.finally(() => {
				setLoading(false);
			});

		return false;
	}

	async function verifyOTP(ev) {
		ev.preventDefault();
		if (!otp || otp.length !== 6) return setErr('Please enter a valid OTP');
		try {
			setLoading(true);
			let res = await _fetch(process.env.REACT_APP_API + '/user/verify-otp', { method: 'POST', body: { email, otp } });
			if (res.success) {
				toast.success('OTP verified successfully');
				dispatch(LoginAction(res.response));
				history.push('/');
			} else {
				toast.error(res.response);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}

	function facebookLogin(response) {
		console.log(response);
		if (response.accessToken)
			_fetch(process.env.REACT_APP_API + '/user/facebook', { method: "POST", body: { access_token: response.accessToken } })
				.then(res => {
					if (res.success) {
						dispatch(LoginAction(res.response));
						history.push('/');
					} else {
						toast.error(res.response);
					}
				})
				.catch(err => {
					console.log(err);
					toast.error('Some error occurred please try again later.')
				})
				.finally(() => {
					setLoading(false);
				});
		else toast.error('Some error occurred please try again');
	}

	function googleLogin(response) {
		console.log(response);
		if (response.accessToken)
			_fetch(process.env.REACT_APP_API + '/user/google', { method: "POST", body: { id_token: response.tokenObj.id_token } })
				.then(res => {
					if (res.success) {
						dispatch(LoginAction(res.response));
						history.push('/');
					} else {
						toast.error(res.response);
					}
				})
				.catch(err => {
					console.log(err);
					toast.error('Some error occurred please try again later.')
				})
				.finally(() => {
					setLoading(false);
				});
	}

	function googleLoginError(error) {
		if (error.error === 'idpiframe_initialization_failed') return;
		toast.error('Some error occurred please try again later');
	}

	return (
		<>
			<Col lg={5} md={8} className="m-2">
				<div className="d-none d-lg-flex">
					<img
						src={leftTwigSVG}
						alt='..'
						className='left-twig-svg'
						style={{
							position: 'absolute',
							zIndex: '3',
							height: '15%',
							width: '15%',
							right: '-12%',
							top: '-2%'
						}}
					/>
				</div>
				<div className="login-style ">
					<div className="navbar-header  mar-top-bot">
						<Link className="navbar-brand logo-space" to='/'>
							<img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/logo-keepwell.png`} alt="" style={{
								maxWidth: '200px',
								objectFit: 'contain',
							}} />
						</Link>
						{/* <button
						className="navbar-toggler"
						type="button"
						onClick={toggleCollapse}
					>
						<BiMenu size={20} color='white' className='navbar-toggler-icon' />
					</button> */}
					</div>
					<div>
						<h2 className="head-font mar-top-bot my-4">Welcome Back</h2>
					</div>
					<div className="btn-wrapper mar-top-bot text-center d-flex">

						<GoogleLogin
							clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
							buttonText="Login"
							onSuccess={googleLogin}
							onFailure={googleLoginError}
							cookiePolicy={'single_host_origin'}
							scopes='profile'
							render={renderProps => (
								<Button
									className="btn-neutral btn-icon flex-fill mr-2 btn-border"
									color="default"
									onClick={renderProps.onClick}
								>
									<span className="btn-inner--icon">
										<img
											alt="Register With Google"
											src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/google.svg`}
										/>
									</span>
									<span className="btn-inner--text">Login With Google</span>
								</Button>
							)}
							autoLoad={false}
						/>

					</div>
					<div className="text-center mar-top-bot text-dark text-lg my-4">
						Or sign in with credentials
					</div>

					{
						otpSent ?
							<Form role="form" onSubmit={verifyOTP}>
								<p
									style={{
										fontSize: 14,
										fontWeight: 400,
										color: '#fff',
										marginBottom: 20,
										textAlign: 'center'
									}}
								>
									We have sent an OTP to your email address. Please enter the OTP below to verify your email address.
								</p>

								<FormGroup>
									<InputGroup className="input-group-alternative mb-3">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-key-25" />
											</InputGroupText>
										</InputGroupAddon>
										<Input
											placeholder="OTP"
											type="text"
											onChange={ev => setOtp(ev.target.value)}
											value={otp}
										/>
									</InputGroup>
								</FormGroup>
								{err ? <p className='text-red text-xs font-weight-400'>{err}</p> : null}
								<div className="text-center">
									<Button className="my-4 mx-0 w-100" color="white" type="submit" disabled={otp.length !== 6} onSubmit={verifyOTP}>
										{loading ? <Loader size={20} /> : 'Verify OTP'}
									</Button>
								</div>

							</Form>
							:
							<Form role="form" onSubmit={register}>

								<FormGroup>
									<InputGroup className="input-group-alternative mb-3">
										{/* <InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-circle-08" />
											</InputGroupText>
										</InputGroupAddon> */}
										<Input
											placeholder="First Name"
											type="text"
											className="input-text-style"
											onChange={ev => setFirstName(ev.target.value)}
										/>
									</InputGroup>
								</FormGroup>

								<FormGroup>
									<InputGroup className="input-group-alternative mb-3">
										{/* <InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-hat-3" />
											</InputGroupText>
										</InputGroupAddon> */}
										<Input
											placeholder="Last Name"
											type="text"
											className="input-text-style"
											onChange={ev => setLastName(ev.target.value)}
										/>
									</InputGroup>
								</FormGroup>

								<FormGroup>
									<InputGroup className="input-group-alternative mb-3">
										{/* <InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon> */}
										<Input
											placeholder="Email"
											type="email"
											className="input-text-style"
											onChange={ev => setEmail(ev.target.value)}
										/>
									</InputGroup>
								</FormGroup>

								<FormGroup>
									<InputGroup className="input-group-alternative">
										{/* <InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-lock-circle-open" />
											</InputGroupText>
										</InputGroupAddon> */}
										<Input
											placeholder="Password"
											type="password"
											className="input-text-style"
											onChange={ev => setPassword(ev.target.value)}
										/>
									</InputGroup>
								</FormGroup>

								<Row className="my-4">
									<Col xs="12">
										<div className="custom-control custom-control-alternative custom-checkbox">
											<input
												className="custom-control-input"
												id="customCheckRegister"
												type="checkbox"
												onChange={(ev) => setDisabled(!ev.target.checked)}
											/>
											<label
												className="custom-control-label"
												htmlFor="customCheckRegister"
											>
												<span className="text-dark">
													I agree with the{" "}
													<Link to='/privacy'>
														Privacy Policy
													</Link>
												</span>
											</label>
										</div>
									</Col>
								</Row>

								{err ? <p className='text-red text-xs font-weight-400'>{err}</p> : null}

								<div className="text-center">
									<Button className="my-4 mx-0 w-100 " color="white"
										style={{
											border: "1px solid rgba(0,0,0,0.25)",
											color: "#fff",
											backgroundColor: "black",
											borderColor: "#212529",
											boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)"
										}}
										type="submit" disabled={disabled} onSubmit={register}>
										{loading ? <Loader size={20} /> : 'Create account'}
									</Button>
								</div>

							</Form>

					}
					<div className="d-flex mt-0">
						<Col xs="5 ">
							<Link className="text-dark" to='/forgot-password' >
								<small>Forgot password?</small>
							</Link>
						</Col>
						<Col className="text-right" xs="7">
							<Link
								className='text-dark'
								to='/login'
							>
								<small>Already have an account?</small>
							</Link>
						</Col>
					</div>

					<div className="d-none d-lg-flex">
						<img
							src={rightTwigSVG}
							alt='..'
							className='right-twig-svg'
							style={{
								position: 'absolute',
								zIndex: '3',
								height: '15%',
								width: '15%',
								left: '-15%',
								top: '95%'
							}}
						/>
					</div>

				</div>
			</Col >

			<Col lg={5} className='d-none d-lg-flex' style={{
				marginTop: "4%",
				marginLeft: '12%'
			}}>
				<div >
					<div
						style={{
							width: '90%',
							height: '95%',
							position: 'absolute',
							top: '-35px',
							marginTop: '10',
							left: '0',
							backgroundColor: '#E4D0F4',
							borderRadius: '80px 0px 80px 0px',
							zIndex: '1',
						}}
						className='underlay'
					/>
					<div>
						<img
							src={leftTwigSVG}
							alt='..'
							className='left-twig-svg'
							style={{
								position: 'absolute',
								zIndex: '3',
								height: '25%',
								width: '25%',
								right: '-70px',
								top: '50px'
							}}
						/>
					</div>

					<div className="about-opening">

						<img
							src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Section-6-Img.jpg`}
							alt=""
							style={{
								height: '95%',
								width: '90%',
								top: '0',
								objectFit: 'cover',
								position: 'absolute',
								bottom: '0',
								right: '0',
								borderRadius: '80px 0px 80px 0px',
								zIndex: '2',
							}}
							className='overlay-img'
						/>


					</div>



				</div>
			</Col>
			{/* <Col lg={6} className='d-none d-lg-flex'>
				<Card className="shadow h-100 overflow-hidden" style={{ background: "url('s-1.jpg')", backgroundSize: 'cover', backgroundPositionX: 'center', backgroundPositionY: 'center' }}>
					<CardBody className='h-100 p-5 d-flex flex-column' style={{ backgroundColor: 'rgba(0, 0, 0, 0.3' }}>
						<h1 className='mt-auto text-white' style={{ fontSize: 35, fontWeight: 700, color: '#fc328a', }}>
							Ready To Take Up Your 6 Weeks Fitness Challenge?<br />
							Sign Up to Fitness Stars - your personal Fitness dashboard
						</h1>

						<div className='mt-2 text-white text-xl'>
							Already have an account? <Link to='/login' className='text-white' style={{ fontWeight: 700 }}>Sign In</Link>
						</div>
					</CardBody>
				</Card>
			</Col>
			<Col lg="6" md="8">
				<Card className="bg-pink shadow">

					<CardHeader className="bg-transparent border-bottom">
						<h3 className='text-white'>Sign Up</h3>
					</CardHeader>

					<CardBody className="p-5">

						<div className="btn-wrapper text-center d-flex">

							<GoogleLogin
								clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
								buttonText="Login"
								onSuccess={googleLogin}
								onFailure={googleLoginError}
								cookiePolicy={'single_host_origin'}
								scopes='profile'
								render={renderProps => (
									<Button
										className="btn-neutral btn-icon flex-fill mr-2"
										color="default"
										onClick={renderProps.onClick}
									>
										<span className="btn-inner--icon">
											<img
												alt="Register With Google"
												src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/google.svg`}
											/>
										</span>
										<span className="btn-inner--text">Signup With Google</span>
									</Button>
								)}
								autoLoad={false}
							/>

						</div>

						<div className="text-center text-white text-lg my-5">
							Or sign up with credentials
						</div>
						{
							otpSent ?
								<Form role="form" onSubmit={verifyOTP}>
									<p
										style={{
											fontSize: 14,
											fontWeight: 400,
											color: '#fff',
											marginBottom: 20,
											textAlign: 'center'
										}}
									>
										We have sent an OTP to your email address. Please enter the OTP below to verify your email address.
									</p>

									<FormGroup>
										<InputGroup className="input-group-alternative mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ni ni-key-25" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder="OTP"
												type="text"
												onChange={ev => setOtp(ev.target.value)}
												value={otp}
											/>
										</InputGroup>
									</FormGroup>
									{err ? <p className='text-red text-xs font-weight-400'>{err}</p> : null}
									<div className="text-center">
										<Button className="my-4 mx-0 w-100" color="white" type="submit" disabled={otp.length !== 6} onSubmit={verifyOTP}>
											{loading ? <Loader size={20} /> : 'Verify OTP'}
										</Button>
									</div>

								</Form>
								:
								<Form role="form" onSubmit={register}>

									<FormGroup>
										<InputGroup className="input-group-alternative mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ni ni-circle-08" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder="First Name"
												type="text"
												onChange={ev => setFirstName(ev.target.value)}
											/>
										</InputGroup>
									</FormGroup>

									<FormGroup>
										<InputGroup className="input-group-alternative mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ni ni-hat-3" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder="Last Name"
												type="text"
												onChange={ev => setLastName(ev.target.value)}
											/>
										</InputGroup>
									</FormGroup>

									<FormGroup>
										<InputGroup className="input-group-alternative mb-3">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ni ni-email-83" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder="Email"
												type="email"
												onChange={ev => setEmail(ev.target.value)}
											/>
										</InputGroup>
									</FormGroup>

									<FormGroup>
										<InputGroup className="input-group-alternative">
											<InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ni ni-lock-circle-open" />
												</InputGroupText>
											</InputGroupAddon>
											<Input
												placeholder="Password"
												type="password"
												onChange={ev => setPassword(ev.target.value)}
											/>
										</InputGroup>
									</FormGroup>

									<Row className="my-4">
										<Col xs="12">
											<div className="custom-control custom-control-alternative custom-checkbox">
												<input
													className="custom-control-input"
													id="customCheckRegister"
													type="checkbox"
													onChange={(ev) => setDisabled(!ev.target.checked)}
												/>
												<label
													className="custom-control-label"
													htmlFor="customCheckRegister"
												>
													<span className="text-light">
														I agree with the{" "}
														<Link to='/privacy'>
															Privacy Policy
														</Link>
													</span>
												</label>
											</div>
										</Col>
									</Row>

									{err ? <p className='text-red text-xs font-weight-400'>{err}</p> : null}

									<div className="text-center">
										<Button className="my-4 mx-0 w-100" color="white" type="submit" disabled={disabled} onSubmit={register}>
											{loading ? <Loader size={20} /> : 'Create account'}
										</Button>
									</div>

								</Form>

						}
					</CardBody>
					<CardFooter className='bg-transparent border-top'>
						<Row>
							<Col xs="6">
								<Link
									className="text-white"
									to='/forgot-password'
								>
									<small>Forgot password?</small>
								</Link>
							</Col>
							<Col className="text-right" xs="6">
								<Link
									className='text-white'
									to='/login'
								>
									<small>Already have an account?</small>
								</Link>
							</Col>
						</Row>
					</CardFooter>
				</Card>
			</Col> */}
		</>
	);
}

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

const restricted_path = ['/live-sessions', '/recorded-sessions', '/buy-classes', '/diet-package'];

const Header = () => {

	const {
		total_live_classes, watched_live_classes, weight, waist, initial_waist, total_recorded_classes, watched_recorded_classes
	} = useSelector(st => st.user);
	let { initial_weight } = useSelector(st => st.user);
	initial_weight = initial_weight.value ? initial_weight.value : initial_weight;
	const [live_classes_count, setLiveClassesCount] = useState(0);
	const [recorded_classes_count, setRecordedClassesCount] = useState(0);
	let history = useHistory();
	let location = useLocation();

	useEffect(() => {
		if (!isNaN(total_live_classes) && !isNaN(watched_live_classes))
			setLiveClassesCount(total_live_classes - watched_live_classes)
	}, [total_live_classes, watched_live_classes]);

	useEffect(() => {
		if (!isNaN(total_live_classes) && !isNaN(watched_live_classes))
			setLiveClassesCount(total_live_classes - watched_live_classes)
	}, [total_live_classes, watched_live_classes]);

	useEffect(() => {
		if (!isNaN(total_recorded_classes) && !isNaN(watched_recorded_classes))
			setRecordedClassesCount(total_recorded_classes - watched_recorded_classes)
	}, [total_recorded_classes, watched_recorded_classes]);

	return (
		<>
			<div className="header bg-gradient-info pb-8 pt-sm-9" style={{ paddingTop: 230 }}>
				<Container fluid>
					<div className={`header-body d-lg-block ${restricted_path.includes(location.pathname) ? 'd-none' : 'd-block'}`}>
						{/* Card stats */}
						<Row>
							<Col xs="12" lg="6" xl="3" className="py-2">
								<Card
									className="h-100 card-stats mb-4 mb-xl-0 pointer stats-card-1"
									onClick={() => history.push('/profile')}
								>
									<div className="p-3">
										<Row>
											<Col className="pr-2">
												<CardTitle
													tag="h5"
													className="text-uppercase text-muted mb-0"
												>
													Weight
													{
														weight
															?
															<span className="text-sm ml-2 font-weight-400">
																<span className={`mr-2 text-${initial_weight < weight ? 'danger' : 'success'}`}>
																	<i className={`lni lni-arrow-${initial_weight < weight ? 'up text-danger text-md' : 'down text-success text-md'}`} /> {Math.abs(100 - (weight * 100 / initial_weight)).toFixed(1)}%
																</span>{" "}
															</span>
															:
															null
													}
												</CardTitle>
												<div className='d-flex align-items-end'>
													<span className="h2 font-weight-bold mb-0">
														{weight ? `${weight} Kg` : '—'}
													</span>
												</div>
											</Col>
											<Col className="col-auto pl-2">
												<div className="icon icon-shape bg-danger text-white rounded-circle shadow">
													<i className="lni lni-weight"></i>
												</div>
											</Col>
										</Row>
									</div>
								</Card>
							</Col>
							<Col xs="12" lg="6" xl="3" className="py-2">
								<Card
									className="h-100 card-stats mb-4 mb-xl-0 pointer stats-card-2"
									onClick={() => history.push('/profile')}
								>
									<CardBody>
										<Row>
											<Col className="pr-2">
												<CardTitle
													tag="h5"
													className="text-uppercase text-muted mb-0"
												>
													Waist
													{
														waist
															?
															<span className="text-sm ml-2 font-weight-400">
																<span className={`mr-2 text-${initial_waist < waist ? 'danger' : 'success'}`}>
																	<i className={`lni lni-arrow-${initial_waist < waist ? 'up text-danger text-md' : 'down text-success text-md'}`} /> {Math.abs(100 - (waist * 100 / initial_waist)).toFixed(1)}%
																</span>
															</span>
															:
															null
													}
												</CardTitle>
												<div className='d-flex align-items-end'>
													<span className="h2 font-weight-bold mb-0">
														{waist ? `${waist} Inch.` : '—'}
													</span>
												</div>
											</Col>
											<Col className="col-auto pl-2">
												<div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
													<i className="lni lni-ruler-alt"></i>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col xs="12" lg="6" xl="3" className="py-2">
								<Card
									className="h-100 card-stats mb-4 mb-xl-0 pointer stats-card-3"
									onClick={() => history.push('/live-sessions')}
								>
									<CardBody>
										<Row>
											<Col>
												<CardTitle
													tag="h5"
													className="text-uppercase text-muted mb-0"
												>
													Live Classes
												</CardTitle>
												<span className="h2 font-weight-bold mb-0">
													{live_classes_count}
												</span>
											</Col>
											<Col className="col-auto">
												<div className="icon icon-shape bg-success text-white rounded-circle shadow">
													<i className="lni lni-video"></i>
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
							<Col xs="12" lg="6" xl="3" className="py-2">
								<Card
									className="h-100 card-stats mb-4 mb-xl-0 pointer stats-card-4"
									onClick={() => history.push('/recorded-sessions')}
								>
									<CardBody>
										<Row>
											<Col>
												<CardTitle
													tag="h5"
													className="text-uppercase text-muted mb-0"
												>
													Workout Videos
												</CardTitle>
												<span className="h2 font-weight-bold mb-0">
													{recorded_classes_count}
												</span>
											</Col>
											<Col className="col-auto">
												<div className="icon icon-shape bg-info text-white rounded-circle shadow">
													<i className="lni lni-target-customer" style={{ fontSize: '1.5rem' }} />
												</div>
											</Col>
										</Row>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</div>
				</Container>
			</div>
		</>
	);
};

export default Header;

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import moment from "moment";
import update from 'immutability-helper';

import Payment from "./payment";
import _fetch from "../../_fetch.js";
import Loader from "../../components/Loader/loader";
import { setExpiredClasses, setLiveClasses, setRecordedClasses } from "../../actions/userActions";
import { toast } from "react-toastify";


export default function Enrollments() {

    const { enrollments, live_enrollments, total_live_classes, watched_live_classes, total_recorded_classes, watched_recorded_classes, expired } = useSelector(st => st.user);
    const [order, setOrder] = useState(null);
    const [extend, setExtend] = useState(false);
    let dispatch = useDispatch();

    function extendEnrollment(type, ind) {

        let id;

        if (type === 'LIVE') {
            dispatch(setLiveClasses({
                total: total_live_classes,
                watched: watched_live_classes,
                enrollments: update(live_enrollments, { [ind]: { loading: { $set: true } } })
            }));
            id = live_enrollments[ind]._id;
        } else if (type === 'RECORDED') {
            dispatch(setRecordedClasses({
                total: total_recorded_classes,
                watched: watched_recorded_classes,
                enrollments: update(enrollments, { [ind]: { loading: { $set: true } } })
            }));
            id = enrollments[ind]._id;
        } else {
            dispatch(setExpiredClasses(update(expired, { [ind]: { loading: { $set: true } } })));
            id = expired[ind]._id;
        }

        setExtend(true);
        _fetch(`${process.env.REACT_APP_API}/order/extension`, { method: 'POST', body: { enrollment_id: id } })
            .then(res => {
                if (res.success) {
                    return setOrder(res.response);
                }
                toast.error(res.response)

            })
            .catch(err => {
                console.log(err);
            })
            .finally(() => {
                if (type === 'LIVE') {
                    dispatch(setLiveClasses({
                        total: total_live_classes,
                        watched: watched_live_classes,
                        enrollments: update(live_enrollments, { [ind]: { loading: { $set: false } } })
                    }));
                } else if (type === 'RECORDED') {
                    dispatch(setRecordedClasses({
                        total: total_recorded_classes,
                        watched: watched_recorded_classes,
                        enrollments: update(enrollments, { [ind]: { loading: { $set: false } } })
                    }));
                } else {
                    dispatch(setExpiredClasses(update(expired, { [ind]: { loading: { $set: false } } })));
                }
                setExtend(false);
            });
    }

    return (
        <>
            <div className='mb-4 d-none d-md-block'>
                <Card className="shadow">
                    <CardHeader className="border-0">
                        <h2 className="w-100 mb-0 text-center">My Enrollments</h2>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Class Type</th>
                                <th scope="col">Available</th>
                                <th scope="col">Expiry Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                enrollments?.map((e, i) => (
                                    <tr key={`te-${i}`}>
                                        <th scope="row">
                                            Workout Videos
                                        </th>
                                        <td>{e.class_count - e.classes.length}/{e.class_count}</td>
                                        <td>
                                            {e.valid_till === null ? "Not Started" :moment(e.valid_till).format('llll')}
                                        </td>
                                        <td>
                                            {
                                                !e.extended && (e.class_count - e.classes.length) ?
                                                    <Button size='sm' color='primary' onClick={() => extendEnrollment("RECORDED", i)} disabled={extend}>
                                                        {e.loading ? <Loader size={20} /> : 'Extend Remaining Classes'}
                                                    </Button>
                                                    :
                                                    <Button disabled={true} size="sm">Extended</Button>
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                            {
                                live_enrollments?.map((e, i) => (
                                    <tr key={`tl-${i}`}>
                                        <th scope="row">Live Sessions</th>
                                        <td>{e.class_count - e.classes.length}/{e.class_count}</td>
                                        <td>
                                            {e.valid_till === null ? "Not Started" :moment(e.valid_till).format('llll')}
                                        </td>
                                        <td>
                                            {
                                                !e.extended && (e.class_count - e.classes.length) ?
                                                    <Button size='sm' color='primary' onClick={() => extendEnrollment("LIVE", i)} disabled={extend}>
                                                        {e.loading ? <Loader size={20} /> : 'Extend Remaining Classes'}
                                                    </Button>
                                                    :
                                                    <Button disabled={true} size="sm">Extended</Button>
                                            }
                                        </td>
                                    </tr>
                                ))
                            }

                            {
                                expired?.map((e, i) => (
                                    <tr key={`tex-${i}`}>
                                        <th scope="row">
                                            {
                                                e.type === 'LIVE' ?
                                                    'Live Sessions'
                                                    :
                                                    'Workout Videos'
                                            }
                                        </th>
                                        <td>{
                                            e.type === 'LIVE' ?
                                                `${e.class_count - e.classes.length}/${e.class_count}`
                                                :
                                                `${e.class_count - e.classes.filter((e) => e.watch_start).length}/${e.class_count}`
                                        }
                                        </td>
                                        <td>
                                            {moment(e.valid_till).format('llll')}
                                        </td>
                                        <td>
                                            {
                                                !e.extended && (e.class_count - e.classes.filter((e) => e.watch_start).length) ?
                                                    <Button size='sm' color='primary' onClick={() => extendEnrollment("EXPIRED", i)} disabled={extend}>
                                                        {e.loading ? <Loader size={20} /> : 'Extend Remaining Classes'}
                                                    </Button>
                                                    :
                                                    <Button disabled={true} size="sm">Extended</Button>
                                            }
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Card>
            </div>


            {
                enrollments && Array.isArray(enrollments) && enrollments.length !== 0
                    ?
                    <Card className="shadow d-md-none mb-4">
                        <CardHeader className="border-0">
                            <h2 className="w-100 mb-0 text-center">Workout Videos</h2>
                        </CardHeader>
                        <CardBody className="p-3">
                            {
                                enrollments.map((e, i) => (
                                    <Card className="my-2" key={`w-${i}`}>
                                    <CardBody className="p-3">
                                            <h3>Available classes: {e.class_count - e.classes.length}/{e.class_count}</h3>
                                            <p className="text-xs">
                                                Expires on {moment(e.valid_till).format('llll')}
                                            </p>
                                            <div>
                                                {
                                                    !e.extended && (e.class_count - e.classes.length) ?
                                                        <Button size='sm' color='primary' onClick={() => extendEnrollment("RECORDED", i)} disabled={extend}>
                                                            {e.loading ? <Loader size={20} /> : 'Extend Remaining Classes'}
                                                        </Button>
                                                        :
                                                        <Button disabled={true} size="sm">Extended</Button>
                                                }
                                            </div>
                                        </CardBody>
                                    </Card>
                                ))
                            }
                        </CardBody>
                    </Card>
                    :
                    null
            }
            {
                Array.isArray(live_enrollments) && live_enrollments.length !== 0
                    ?
                    <Card className="shadow d-md-none mb-4">
                        <CardHeader className="border-0">
                            <h2 className="w-100 mb-0 text-center">Live Sessions</h2>
                        </CardHeader>
                        <CardBody className="p-3">
                            {
                                live_enrollments.map((e, i) => (
                                    <Card className="my-2" key={`l-${i}`}>
                                    <CardBody className="p-3">
                                            <h3>Available classes: {e.class_count - e.classes.length}/{e.class_count}</h3>
                                            <p className="text-xs">
                                                Expires on {moment(e.valid_till).format('llll')}
                                            </p>
                                            <div className="d-flex justify-content-center">
                                                {
                                                    !e.extended && (e.class_count - e.classes.length) ?
                                                        <Button size='sm' color='primary' onClick={() => extendEnrollment("LIVE", i)} disabled={extend}>
                                                            {e.loading ? <Loader size={20} /> : 'Extend Remaining Classes'}
                                                        </Button>
                                                        :
                                                        <Button disabled={true} size="sm">Extended</Button>
                                                }
                                            </div>
                                        </CardBody>
                                    </Card>
                                ))
                            }
                        </CardBody>
                    </Card>
                    :
                    null
            }

            {
                Array.isArray(expired) && expired.length !== 0 ?
                    <Card className="shadow d-md-none mb-4">
                        <CardHeader className="border-0">
                            <h2 className="w-100 mb-0 text-center">Expired Enrollments</h2>
                        </CardHeader>
                        <CardBody className="p-3">
                            {
                                expired.map((e, i) => (
                                    <Card className="my-2" key={`e-${i}`}>
                                        <CardBody className="p-3">
                                            <h3>Available classes: {e.class_count - e.classes.length}/{e.class_count}</h3>
                                            <p className="text-xs">
                                                Expires on {moment(e.valid_till).format('llll')}
                                            </p>
                                            <div className="d-flex justify-content-center">
                                                {
                                                    !e.extended && (e.class_count - e.classes.length) ?
                                                        <Button size='sm' color='primary' onClick={() => extendEnrollment("EXPIRED", i)} disabled={extend}>
                                                            {e.loading ? <Loader size={20} /> : 'Extend Remaining Classes'}
                                                        </Button>
                                                        :
                                                        <Button disabled={true} size="sm">Extended</Button>
                                                }
                                            </div>
                                        </CardBody>
                                    </Card>
                                ))
                            }
                        </CardBody>
                    </Card>
                    :
                    null
            }

            <Payment order={order} setOrder={setOrder} />

        </>
    );
};

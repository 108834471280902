import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from './app';
import store from "./store";
import Loader from './components/Loader/loader';

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_KEY,
	integrations: [new BrowserTracing()],
	tracesSampleRate: 0.5,
});

ReactDOM.render(
	<Suspense
		fallback={
			<div className='h-100 w-100 d-flex'>
				<Loader />
			</div>
		}
	>
		<Provider store={store}>
			<App />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar
				newestOnTop
				closeOnClick={true}
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
				closeButton={false}
			/>
		</Provider>
	</Suspense>,
	document.getElementById("root")
);

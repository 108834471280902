import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
	CardFooter,
} from "reactstrap";
import validator from "validator";
import GoogleLogin from 'react-google-login';
import leftTwigSVG from '../../assets/subscription/flower-1.svg'
import rightTwigSVG from '../../assets/subscription/flower-2.svg'
import { LoginAction } from "../../actions/userActions";
import Loader from "../../components/Loader/loader";
import bannerSVG from '../../assets/home-page/svgs/banner.svg';
// import specilitesIMG from '../../assets/home-page/images/specialities-img.png';
import promotiomIMG from '../../assets/home-page/images/promotion-img.png';
import './login.scss'
import _fetch from "../../_fetch";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function Login() {

	const history = useHistory();
	const dispatch = useDispatch();
	const query = useQuery();

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [err, setErr] = useState('');
	const [otp, setOtp] = useState('');
	const [otpSent, setOtpSent] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (query.get('verified')) {
			toast.success('Email Id verified successfully.');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function login(ev) {
		ev.preventDefault();

		if (!email || !validator.isEmail(email) || !password)
			return setErr('Please enter valid email id and password');

		setLoading(true);

		_fetch(process.env.REACT_APP_API + '/user/login', { method: "POST", body: { email, password } })
			.then(res => {
				if (res.success) {
					if (res.response.verified) {
						dispatch(LoginAction(res.response));
						history.push('/dashboard');
					} else {
						setOtpSent(true);
						toast.success('OTP sent to your email id.');
					}
				} else {
					toast.error(res.response);
				}
			})
			.catch(err => {
				console.log(err);
				toast.error('Some error occurred please try again later.')
			})
			.finally(() => {
				setLoading(false);
			});

		return false;
	}

	async function verifyOTP(ev) {
		ev.preventDefault();
		if (!otp || otp.length !== 6) return setErr('Please enter a valid OTP');
		try {
			setLoading(true);
			let res = await _fetch(process.env.REACT_APP_API + '/user/verify-otp', { method: 'POST', body: { email, otp } });
			if (res.success) {
				toast.success('OTP verified successfully');
				dispatch(LoginAction(res.response));
				history.push('/');
			} else {
				toast.error(res.response);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	}

	function facebookLogin(response) {
		console.log(response);
		if (response.accessToken)
			_fetch(process.env.REACT_APP_API + '/user/facebook', { method: "POST", body: { access_token: response.accessToken } })
				.then(res => {
					if (res.success) {
						dispatch(LoginAction(res.response));
						history.push('/dashboard');
					} else {
						toast.error(res.response);
					}
				})
				.catch(err => {
					console.log(err);
					toast.error('Some error occurred please try again later.')
				})
				.finally(() => {
					setLoading(false);
				});
		else toast.error('Some error occurred please try again');
	}

	function googleLogin(response) {
		console.log(response);
		if (response.accessToken)
			_fetch(process.env.REACT_APP_API + '/user/google', { method: "POST", body: { id_token: response.tokenObj.id_token } })
				.then(res => {
					if (res.success) {
						dispatch(LoginAction(res.response));
						history.push('/dashboard');
					} else {
						toast.error(res.response);
					}
				})
				.catch(err => {
					console.log(err);
					toast.error('Some error occurred please try again later.')
				})
				.finally(() => {
					setLoading(false);
				});
	}

	function googleLoginError(error) {
		if (error.error === 'idpiframe_initialization_failed') return;
		toast.error('Some error occurred please try again later');
	}

	return (
		<>
			<div
				className='container'
			>
				<Row>
					<Col lg={5} md={8} className="">
						<div className="d-none d-lg-flex">
							<img
								src={leftTwigSVG}
								alt='..'
								className='left-twig-svg'
								style={{
									position: 'absolute',
									zIndex: '3',
									height: '15%',
									width: '15%',
									right: '-12%',
									top: '-2%'
								}}
							/>
						</div>
						<div className="login-style ">
							<div className="navbar-header  mar-top-bot">
								<Link className="navbar-brand logo-space" to='/'>
									<img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/logo-keepwell.png`} alt="" style={{
										maxWidth: '200px',
										objectFit: 'contain',
									}} />
								</Link>
								{/* <button
						className="navbar-toggler"
						type="button"
						onClick={toggleCollapse}
					>
						<BiMenu size={20} color='white' className='navbar-toggler-icon' />
					</button> */}
							</div>
							<div>
								<h2 className="head-font mar-top-bot my-4">Welcome Back</h2>
							</div>
							<div className="btn-wrapper mar-top-bot text-center d-flex">

								<GoogleLogin
									clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
									buttonText="Login"
									onSuccess={googleLogin}
									onFailure={googleLoginError}
									cookiePolicy={'single_host_origin'}
									scopes='profile'
									render={renderProps => (
										<Button
											className="btn-neutral btn-icon flex-fill mr-2 btn-border"
											color="default"
											onClick={renderProps.onClick}
										>
											<span className="btn-inner--icon">
												<img
													alt="Login With Google"
													src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/google.svg`}
												/>
											</span>
											<span className="btn-inner--text">Login With Google</span>
										</Button>
									)}
									autoLoad={false}
								/>

							</div>
							<div className="text-center mar-top-bot text-dark text-lg my-4">
								Or sign in with credentials
							</div>

							{
								otpSent ?
									<Form role="form" onSubmit={verifyOTP}>
										<p
											style={{
												fontSize: 14,
												fontWeight: 400,
												color: '#fff',
												marginBottom: 20,
												textAlign: 'center'
											}}
										>
											We have sent an OTP to your email address. Please enter the OTP below to verify your email address.
										</p>

										<FormGroup>
											<InputGroup className="input-group-alternative mb-3">
												<InputGroupAddon addonType="prepend">
													<InputGroupText >
														<i className="ni ni-key-25" />
													</InputGroupText>
												</InputGroupAddon>
												<Input
													placeholder="OTP"
													type="text"
													onChange={ev => setOtp(ev.target.value)}
													value={otp}

												/>
											</InputGroup>
										</FormGroup>
										{err ? <p className='text-red text-xs font-weight-400'>{err}</p> : null}
										<div className="text-center">
											<Button className="my-4 mx-0 w-100 " color="white" type="submit" disabled={otp.length !== 6} onSubmit={verifyOTP}>
												{loading ? <Loader size={20} /> : 'Verify OTP'}
											</Button>
										</div>

									</Form>
									:
									<Form role="form" onSubmit={login}>

										<FormGroup className="mb-3">
											<InputGroup className="input-group-alternative">
												{/* <InputGroupAddon addonType="prepend">
											<InputGroupText >
												<i className="lni lni-envelope"></i>
											</InputGroupText>
										</InputGroupAddon> */}
												<Input
													placeholder="Email"
													type="email"
													required={true}
													className="input-text-style"
													onChange={(ev) => setEmail(ev.target.value)}
												/>
											</InputGroup>
										</FormGroup>

										<FormGroup>
											<InputGroup className="input-group-alternative">
												{/* <InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-lock-circle-open" />
											</InputGroupText>
										</InputGroupAddon> */}
												<Input
													placeholder="Password"
													type="password"
													className="input-text-style"
													required={true}
													onChange={(ev) => setPassword(ev.target.value)}
												/>
											</InputGroup>
										</FormGroup>

										{err ? <p className='text-danger text-xs font-weight-400'>{err}</p> : null}

										<div className="text-center">
											<Button className="my-4 mx-0 w-100" color="dark" type="submit" onSubmit={login}>
												{loading ? <Loader size={20} /> : 'Sign in'}
											</Button>
										</div>

									</Form>

							}
							<div className="d-flex mar-top-bot">
								<Col xs="6 ">
									<Link className="text-dark" to='/forgot-password' >
										<small>Forgot password?</small>
									</Link>
								</Col>
								<Col className="text-right" xs="6">
									<Link className="text-dark" to='/register' >
										<small>Create new account</small>
									</Link>
								</Col>
							</div>

							<div className="d-none d-lg-flex">
								<img
									src={rightTwigSVG}
									alt='..'
									className='right-twig-svg'
									style={{
										position: 'absolute',
										zIndex: '3',
										height: '15%',
										width: '15%',
										left: '-15%',
										top: '95%'
									}}
								/>
							</div>

						</div>
					</Col >

					<Col lg={5} className='d-none d-lg-flex' style={{
						marginTop: "4%",
						marginLeft: '12%'
					}}>
						<div >
							<div
								style={{
									width: '90%',
									height: '90%',
									position: 'absolute',
									top: '-35px',
									marginTop: '10',
									left: '0',
									backgroundColor: '#E4D0F4',
									borderRadius: '80px 0px 80px 0px',
									zIndex: '1',
								}}
								className='underlay'
							/>
							<div>
								<img
									src={leftTwigSVG}
									alt='..'
									className='left-twig-svg'
									style={{
										position: 'absolute',
										zIndex: '3',
										height: '25%',
										width: '25%',
										right: '-70px',
										top: '50px'
									}}
								/>
							</div>

							<div className="about-opening">

								<img
									src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Section-6-Img.jpg`}
									alt=""
									style={{
										height: '90%',
										width: '90%',
										top: '0',
										objectFit: 'cover',
										position: 'absolute',
										bottom: '0',
										right: '0',
										borderRadius: '80px 0px 80px 0px',
										zIndex: '2',
									}}
									className='overlay-img'
								/>


							</div>



						</div>
					</Col>
				</Row>
			</div>

			{/* <div
				id='promotion-page'
				className='d-flex flex-column'
				style={{
					backgroundImage: `url(${bannerSVG})`,
					marginTop: "12%",
					minWidth: '100vw',
					marginBottom: '0%',
				}}
			>
				<div
					className='container promotion-container d-flex flex-column flex-grow-1'
				>
					<Row
						className='flex-grow-1'
					>
						<Col
							xs={6}
							lg={6}
							className='d-flex flex-column justify-content-center'
						>
							<h2
								className='section-heading text-left'
							>
								{`Escape the mundane and\nembrace the extraordinary!\nUnleash your Iron Core Strength with us !`}
							</h2>
							<button
								className='book-now-btn mt-5'
								onClick={() => {
									history.push('/packages')
								}}
							>
								Book Now
							</button>

						</Col>
						<Col
							xs={6}
							lg={6}
							className='d-flex align-items-end justify-content-center'
						>
							<img
								src={promotiomIMG}
								alt='..'
								className='promotion-img'
							/>

						</Col>
					</Row>
				</div>
			</div> */}


		</>

	);
};

import React from "react";
import { useLocation } from "react-router-dom";

// reactstrap components
import { Container } from "reactstrap";

const restricted_path = ['/live-sessions', '/recorded-sessions'];

const AdminHeader = () => {

	let location = useLocation();

	return (
		<>
			<div className="header bg-gradient-info pb-8 pt-8">
				<Container fluid>
					<div className={`header-body d-lg-block ${restricted_path.includes(location.pathname) ? 'd-none' : 'd-block'}`}>
						{/* Card stats */}
					</div>
				</Container>
			</div>
		</>
	);
};

export default AdminHeader;

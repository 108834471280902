import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

const Footer = () => {
	return (
		<footer className="footer">
			<Row className="align-items-top justify-content-xl-between">
				<Col xs={12} lg={6}>
					<ul className="d-flex flex-column flex-md-row w-100 pl-0" style={{ listStyle: 'none' }}>
						<li className="d-flex flex-column flex-fill my-2">
							<div className="font-weight-600">Email Us</div>
							<a href="mailto:prapti.dutt@keepwellmantras.com" className="linkHoverGrowLine">prapti.dutt@keepwellmantras.com</a>
						</li>

						<li className="d-flex flex-column flex-fill my-2">
							<div className="font-weight-600">Contact Us</div>
							<a href="tel:07584236848" className="linkHoverGrowLine">07584236848</a>
						</li>

						<li className="d-flex flex-column flex-fill my-2">
							<div className="font-weight-600">Located</div>
							<p className="mb-0">London</p>
						</li>
					</ul>
				</Col>
				<Col xs={12} lg={6}>
					<div className="d-flex flex-row justify-content-start justify-content-lg-end">
						<a href='https://www.facebook.com/keepwellmantras' className="footer-social-item text-lg" target="_blank" rel="noopener noreferrer">
							<i className="lni lni-facebook"></i>
						</a>
						<a href='https://www.instagram.com/keepwell_mantras' className="footer-social-item text-lg" target="_blank" rel="noopener noreferrer">
							<i className="lni lni-instagram"></i>
						</a>
					</div>
				</Col>
				<Col xl="6" className='pt-2'>
					<div className="copyright text-center text-xl-left text-muted">
						© {new Date().getFullYear()}{" "}KeepWell Mantras
					</div>
				</Col>

				<Col xl="6">
					{/* <Nav className="nav-footer justify-content-center justify-content-xl-end">

						<NavItem>
							<NavLink
								href="#"
								rel="noopener noreferrer"
								target="_blank"
							>
								About Us
              				</NavLink>
						</NavItem>

						<NavItem>
							<NavLink
								href="#"
								rel="noopener noreferrer"
								target="_blank"
							>
								Blog
              				</NavLink>
						</NavItem>

					</Nav> */}
				</Col>
			</Row>
		</footer>
	);
};

export default Footer;

import { useEffect, useState } from "react"
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import "./styles.scss"
import { AiFillCheckCircle, AiOutlineArrowRight } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import Footer from "../../components/Footers/AuthFooter";
import Header2 from "../../components/Headers/Header2";
import { useCookies } from 'react-cookie';
import { useSelector } from "react-redux";
import AddressForm from "../Subscription/AddressForm";
import {toast} from "react-toastify";
import _fetch from "../../_fetch";
import Loader from '../../components/Loader/loader';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const flower1 = require('../../assets/home-page/images/noun-yoga-4437295 1.png');
const flower2 = require('../../assets/home-page/images/noun-yoga-3090940 1.png');
const flower3 = require('../../assets/home-page/images/noun-mindfulness-4196260 2.png');
const flower4 = require('../../assets/home-page/images/noun-lotus-1594771 2.png');

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

function PackageCard({ packageObj }) {

    const [showPointers, setShowPointers] = useState(false);

    return (
        <div className="package-card row-lg d-flex flex-column flex-lg-row w-100 overflow-hidden"
            onMouseEnter={() => setShowPointers(true)}
            onMouseLeave={() => setShowPointers(false)}
        >
            <div className="middle-section col-12 col-lg-10">
                <h3 className="h3font">
                    {packageObj.title}
                </h3>
                <p className="description">
                    {packageObj.description}
                </p>
                {
                    !showPointers ?
                        packageObj?.pointers?.length > 0 && <p className="learnmore" onClick={() => setShowPointers(true)}>
                            Learn More {" "}
                            <AiOutlineArrowRight className="align-self-center" />
                        </p> :
                        packageObj.pointers?.map(po => (
                            <div className="d-flex align-items-center">
                                <AiFillCheckCircle color="#C8A97D" className="mr-2" style={{ minWidth: "15px" }} />
                                <span>{po}</span>
                            </div>
                        ))
                }
            </div>
            <div className="price-div col-12 col-lg-2">
                <h3 className="text-white">{packageObj.price}</h3>
                <button className="button"
                    onClick={packageObj.onClick}
                >
                    Book Now
                </button>
            </div>
        </div>
    )
}

export default function Packages2() {

    const [selectedTab, setSelectedTab] = useState("CoreSculpt App");
    let history = useHistory();
    const {
        isFreeTrialAvailable, stripeAddress
    } = useSelector(state => state.user);
    const [modalVisible, setModalVisible] = useState(false);
    // 0 for Address, 1 for Payment
    const [elementType, setElementType] = useState(0);
    const [loading, setLoading] = useState(false);
    const [, setCookie] = useCookies(['cart', 'live_class_count', 'pt_package', 'diet_package', 'pt_count', 'workout_video_count']);

    const createStripeSubscription = async (isComingFromAddressForm = false) => {
        try {
            if (!isComingFromAddressForm) setLoading(true);
            let url = `${process.env.REACT_APP_API_V2}/stripe-payment/subscription`;
            let res = await _fetch(url, {
                method: 'POST',
                body: {
                    price_id: process.env.REACT_APP_STRIPE_BASIC_MONTHLY_PRICE_KEY,
                }
            });
            if (res.success) {
                if (res.response?.clientSecret) {
                    toast.error('You are not eligible for free trial!')
                } else {
                    toast.success('Free Trial started successfully, please check your email for download instructions!');
                    history.push('/subscription/success');
                }
            } else {
                toast.error(res.response);
            }
        } catch (err) {
            console.log(err);
            toast.error('Something went wrong!');
        } finally {
            if (!isComingFromAddressForm) setLoading(false);
        }
    }

    const createStripeUser = async (address) => {
        try {
            setLoading(true);
            if (!address) return toast.error('Please enter your address!');
            let url = `${process.env.REACT_APP_API_V2}/stripe-payment/customer`;
            let res = await _fetch(url, {
                method: 'POST',
                body: {
                    address: address,
                }
            });
            if (res.success) {
                await createStripeSubscription(true);
            } else {
                toast.error(res.response);
            }
        } catch (err) {
            console.log(err);
            toast.error('Something went wrong!');
        } finally {
            setLoading(false);
        }
    }

    const basicPackageFeatures = isFreeTrialAvailable ? 
    [
        "15 new workout videos every month",
        <button
            style={{
                backgroundColor: "#C8A97D",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                padding: "5px 10px",
                fontFamily: "DM Sans",
            }}
            onClick={() => {
                if (stripeAddress) {
                    createStripeSubscription();
                } else {
                    setModalVisible(true);
                }
            }}
        >
            Start Free Trial
        </button>,
        "Access to all workout categories",
        "Flexibility to choose any workout from a library of over 500 videos",
        "Unlimited play time of repeat watch",
        "New workout videos added every month",
        "Activity calendar tracker",
        "Measurement tracking",
        "Reward points and consistency badges",
        "Free video tips from Prapti & Habit Coaches",
        "Monthly newsletters with tips and tricks",
        "Membership of active WhatsApp group"
    ] : [
        "15 new workout videos every month",
        "Access to all workout categories",
        "Flexibility to choose any workout from a library of over 500 videos",
        "Unlimited play time of repeat watch",
        "New workout videos added every month",
        "Activity calendar tracker",
        "Measurement tracking",
        "Reward points and consistency badges",
        "Free video tips from Prapti & Habit Coaches",
        "Monthly newsletters with tips and tricks",
        "Membership of active WhatsApp group"
    ];

    const packages = {
        "CoreSculpt App": {
            description: `CoreSculpt App brings you a plethora of options with Circuit training, Bodyweight strength, Yoga, Pilates, Callisthenics, Resistance & strength training along with nutrition and habit coaching services into one comprehensive platform. Not easy to find such a synergy and an unmatched fusion that transforms your fitness journey into an inspiring adventure!\n\nCoreSculpt introduces you to the first ever library of over 500 follow-along videos by a single trainer! Say goodbye to mere instructions, sample videos and boring schedules, rather watch me, your fitness buddy, sweating it out alongside you, providing you unparalleled motivation in every class.\n\nRewards points, consistency badges, activity streak calendar, client inspirations, healthy eating blogs, my tips and tricks, and that community support – there is lots of fun features to let you empower your fitness journey like never before! CoreSculpt isn’t just another Fitness App, it is a culmination of years of dedication, passion, expertise, experience & knowledge and is an ever-evolving network of women.\n\nIn addition to the monthly subscription packages, you can add on the following features:`,
            pointers: [
                {
                    "text": "Sign up to a dedicated Habit coach",
                    "href": null,
                },
                {
                    "text": "Customised Fitness & nutrition 1 hour call with Prapti",
                    "href": null,
                },
                {
                    "text": "Customised nutrition plan with Prapti",
                    "href": null,
                },
                {
                    "text": "Join Live Classes conducted by Prapti",
                    "href": null,
                },
                {
                    "text": "Refer to the FAQs",
                    "href": 'https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/KWM-App-FAQs.pdf'
                },
            ],
            packages: [
                {
                    title: "Basic package £90 per month",
                    price: "£90/month",
                    description: "Introducing our revolutionary fitness platform that brings the gym experience right to your fingertips - the Ultimate Workout Video Library",
                    pointers: basicPackageFeatures,
                    image: flower1,
                    onClick: () => {
                        history.push("/coresculptapp#packages", {
                            isComingFromPackage: true,
                        })
                    }
                }, {
                    title: "Rockstar package £112 per month",
                    price: "£112/month",
                    description: "Are you tired of the same old workout routines? Do you crave diversity and excitement in your fitness journey? Look no further. With our subscription, you'll gain access to a treasure trove of over 500 workout videos that span a wide spectrum of fitness categories.",
                    image: flower2,
                    pointers: [
                        "20 new workout videos every month",
                        "Access to all workout categories",
                        "Flexibility to choose any workout from a library of over 500 videos",
                        "Unlimited play time of repeat watch",
                        "New workout videos added every month",
                        "Activity calendar tracker",
                        "Measurement tracking",
                        "Reward points and consistency badges",
                        "Free video tips from Prapti & Habit Coaches",
                        "Monthly newsletters with tips and tricks",
                        "Membership of active WhatsApp group"
                    ],
                    onClick: () => {
                        history.push("/coresculptapp#packages", {
                            isComingFromPackage: true,
                        })
                    }
                }, {
                    title: "Basic package £247 per quarter",
                    price: "£247/quarter",
                    description: "Introducing our revolutionary fitness platform that brings the gym experience right to your fingertips - the Ultimate Workout Video Library",
                    image: flower3,
                    pointers: [
                        "45 new workout videos every quarter",
                        "Access to all workout categories",
                        "Flexibility to choose any workout from a library of over 500 videos",
                        "Unlimited play time of repeat watch",
                        "New workout videos added every month",
                        "Activity calendar tracker",
                        "Measurement tracking",
                        "Reward points and consistency badges",
                        "Free video tips from Prapti & Habit Coaches",
                        "Monthly newsletters with tips and tricks",
                        "Membership of active WhatsApp group"
                    ],
                    onClick: () => {
                        history.push("/coresculptapp#packages", {
                            isComingFromPackage: true,
                        })
                    }
                }, {
                    title: "Rockstar package £315 per quarter",
                    price: "£315/quarter",
                    description: "Are you tired of the same old workout routines? Do you crave diversity and excitement in your fitness journey? Look no further. With our subscription, you'll gain access to a treasure trove of over 500 workout videos that span a wide spectrum of fitness categories.",
                    image: flower4,
                    pointers: [
                        "60 new workout videos every quarter",
                        "Access to all workout categories",
                        "Flexibility to choose any workout from a library of over 500 videos",
                        "Unlimited play time of repeat watch",
                        "New workout videos added every month",
                        "Activity calendar tracker",
                        "Measurement tracking",
                        "Reward points and consistency badges",
                        "Free video tips from Prapti & Habit Coaches",
                        "Monthly newsletters with tips and tricks",
                        "Membership of active WhatsApp group"
                    ],
                    onClick: () => {
                        history.push("/coresculptapp#packages", {
                            isComingFromPackage: true,
                        })
                    }
                }
            ]
        },
        "Nutrition Support": {
            description: `Nutrition plays a critical role in both weight loss and strength gains. A well-balanced diet that includes a variety of foods, including lean proteins, whole grains, fruits, vegetables, and healthy fats, can help you maintain a sustainable and enjoyable eating pattern while losing weight. If it is not sustainable, it is not worth it!\n\nThe nutrition plans are created and designed based on the initial consultation call with Prapti. It always begins with your current lifestyle needs and existing fitness levels. There is no one size fit all approach. My aim for your will be to not just achieve your weight loss or strength gain goals but to leave you with loads of knowledge and understanding so you can manage your health self independently. Plans will cater to any underlying medical condition you may have which will be discussed in detail beforehand and an option to choose from a set meal plan ideas or calorie counting diet approach will be discussed during the initial consultation call.\n\nPrapti Dutt holds a level 3 certification in Nutrition for physical activity and is an Advance clinical weight loss practitioner.`,
            pointers: [
                {
                    "text": "6 and 12 weeks package to choose",
                    "href": null
                },
                {
                    "text": "Personalized nutrition plans and lifestyle enhancements customized to your fitness goals",
                    "href": null
                },
                {
                    "text": "A diet plan / sheet will be provided after the initial consultation call",
                    "href": null
                },
                {
                    "text": "An initial form to be filled out",
                    "href": null
                },
                {
                    "text": "Weekly regular check-ins by a dedicated Habit coach",
                    "href": null
                },
                {
                    "text": "Check-in calls with Prapti can be booked through the Calendly link every 2 weeks",
                    "href": null
                },
                {
                    "text": "Focused on healthy and balanced eating habits.",
                    "href": null
                },
                {
                    "text": "All discounts or combo offers on the checkout",
                    "href": null
                },
                {
                    "text": "For best results, join my Live fitness classes or sign up for my CoreSculpt app",
                    "href": null
                }
            ],
            packages: [
                {
                    title: "One off consultation with Prapti £40",
                    price: "£40",
                    description: "Kickstart your fitness journey with a One-off Consultation for only £40. We aim to empower you with knowledge to manage your health independently while addressing any medical conditions.",
                    image: flower1,
                    onClick: () => {
                        setCookie('live_class_count', 0);
                        setCookie('workout_video_count', 0);
                        setCookie('diet_package', '60_MINS');
                        setCookie('pt_count', 0);

                        history.push('/cart');
                    },
                },
                {
                    title: "Nutrition Support plan for 6 Weeks",
                    price: "£180",
                    description: "Elevate your fitness with our 6 Weeks Nutrition Support plan. This comprehensive package, guided by a dedicated Habit Coach, focus on sustainable, tailored diet plans that consider your individual needs and fitness levels, aiming to impart lasting health knowledge.",
                    image: flower2,
                    onClick: () => {
                        setCookie('live_class_count', 0);
                        setCookie('workout_video_count', 0);
                        setCookie('diet_package', '6_WEEKS');
                        setCookie('pt_count', 0);

                        history.push('/cart');
                    },
                },
                {
                    title: "Nutrition Support plan for 12 Weeks",
                    price: "£320",
                    description: "Elevate your fitness with our 12 Weeks Nutrition Support plan. This comprehensive package, guided by a dedicated Habit Coach, focus on sustainable, tailored diet plans that consider your individual needs and fitness levels, aiming to impart lasting health knowledge.",
                    image: flower2,
                    onClick: () => {
                        setCookie('live_class_count', 0);
                        setCookie('workout_video_count', 0);
                        setCookie('diet_package', '12_WEEKS');
                        setCookie('pt_count', 0);

                        history.push('/cart');
                    },
                }
            ]
        },
        "Live Class": {
            description: "Join the Live classes conducted 6 days a week catering to all fitness levels! If you struggle to stay motivated and lack discipline to your workout routine - this is just right for you! Get fit from the comfort of your home! All classes are recorded so you catch up if you miss the live session.",
            pointers: [
                {
                    "text": "Follow along Live classes focused on Fat loss, body shaping, and core strength",
                    "href": null
                },
                {
                    "text": "Home-based Bodyweight workouts of 30 min to 45 min",
                    "href": null
                },
                {
                    "text": "No equipment needed: A pair of dumbbells and resistance bands are good to have",
                    "href": null
                },
                {
                    "text": "No gym equipment or access is needed.",
                    "href": null
                },
                {
                    "text": "Choose from a variety of classes catering to all fitness levels - MIITs, HIITs, Strength & Resistance Training",
                    "href": null
                },
                {
                    "text": "All classes are recorded and stored on the dashboard calendar",
                    "href": null
                },
                {
                    "text": "Unique classes with no two sessions ever the same! Lots of new exercises and variations to keep you addicted",
                    "href": null
                },
                {
                    "text": "Private WhatsApp community groups keeping the team spirits high",
                    "href": null
                },
                {
                    "text": "Some bonus challenges, skill drills, meal ideas, and recipe sharing",
                    "href": null
                },
                {
                    "text": "Add-on services include Nutrition customized plan and a dedicated habit coach",
                    "href": null
                },
                {
                    "text": "Current discounts coupons on the checkout page",
                    "href": null
                },
                {
                    "text": "Refer to the Class schedule",
                    "href": '/classes'
                },
                {
                    "text": "Refer to the FAQs",
                    "href": 'https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/KWM-Web-FAQs.pdf'
                },
                {
                    "text": "Please note expiry is from the start date (not booking date)",
                    "href": null
                }
            ],
            packages: [
                {
                    title: "PAYG",
                    price: "£8 / class",
                    description: "Join our live fitness classes, available six days a week, suitable for all fitness levels. Stay motivated, maintain discipline, and work out from home with ease. Plus, access recorded sessions for added convenience, ensuring you never miss a beat in your fitness journey.",
                    image: flower1,
                    onClick: () => {
                        setCookie('live_class_count', 1);
                        setCookie('workout_video_count', 0);
                        setCookie('pt_count', 0);

                        setCookie('diet_package', "");
                        history.push('/cart')
                    }
                },
                {
                    title: "Block of 10 Classes - 6 weeks expiry",
                    price: "Discount coupons on checkout",
                    description: "Join our live fitness classes, available six days a week, suitable for all fitness levels. Stay motivated, maintain discipline, and work out from home with ease. Plus, access recorded sessions for added convenience, ensuring you never miss a beat in your fitness journey.",
                    image: flower1,
                    onClick: () => {
                        setCookie('live_class_count', 10);
                        setCookie('workout_video_count', 0);
                        setCookie('pt_count', 0);

                        setCookie('diet_package', "");
                        history.push('/cart')
                    }
                },
                {
                    title: "Block of 20 Classes - 6 weeks expiry",
                    price: "Discount coupons on checkout",
                    description: "Join our live fitness classes, available six days a week, suitable for all fitness levels. Stay motivated, maintain discipline, and work out from home with ease. Plus, access recorded sessions for added convenience, ensuring you never miss a beat in your fitness journey.",
                    image: flower1,
                    onClick: () => {
                        setCookie('live_class_count', 20);
                        setCookie('workout_video_count', 0);
                        setCookie('pt_count', 0);

                        setCookie('diet_package', "");
                        history.push('/cart')
                    }
                },
                {
                    title: "Block of 30 Classes - 6 weeks expiry",
                    price: "Discount coupons on checkout",
                    description: "Join our live fitness classes, available six days a week, suitable for all fitness levels. Stay motivated, maintain discipline, and work out from home with ease. Plus, access recorded sessions for added convenience, ensuring you never miss a beat in your fitness journey.",
                    image: flower1,
                    onClick: () => {
                        setCookie('live_class_count', 30);
                        setCookie('workout_video_count', 0);
                        setCookie('pt_count', 0);

                        setCookie('diet_package', "");
                        history.push('/cart')
                    }
                },
                {
                    title: "Block of 50 Classes - 12 weeks expiry",
                    price: "Discount coupons on checkout",
                    description: "Join our live fitness classes, available six days a week, suitable for all fitness levels. Stay motivated, maintain discipline, and work out from home with ease. Plus, access recorded sessions for added convenience, ensuring you never miss a beat in your fitness journey.",
                    image: flower1,
                    onClick: () => {
                        setCookie('live_class_count', 50);
                        setCookie('workout_video_count', 0);
                        setCookie('pt_count', 0);

                        setCookie('diet_package', "");
                        history.push('/cart')
                    }
                },
                {
                    title: "Block of 60 Classes - 12 weeks expiry",
                    price: "Discount coupons on checkout",
                    description: "Join our live fitness classes, available six days a week, suitable for all fitness levels. Stay motivated, maintain discipline, and work out from home with ease. Plus, access recorded sessions for added convenience, ensuring you never miss a beat in your fitness journey.",
                    image: flower1,
                    onClick: () => {
                        setCookie('live_class_count', 60);
                        setCookie('workout_video_count', 0);
                        setCookie('pt_count', 0);

                        setCookie('diet_package', "");
                        history.push('/cart')
                    }
                },
            ]
        },
        "Combo Offers": {
            description: `If you are committed about your goals, bundled packages work really well with a success rate higher than individual services.  These offers are designed to provide value for money and closer association with our clients keeping them encourages and motivated through a variety of services.`,
            pointers: [],
            packages: [
                {
                    title: "20 classes + Nutrition - 6 weeks",
                    price: "Discount coupons on checkout",
                    description: "Elevate your well-being with our '20 Classes + Nutrition' combo plan, now with a special 10% discount on the 6-week package! This comprehensive offering includes 20 invigorating fitness classes tailored to your goals, along with expert nutritional guidance to fuel your journey towards a healthier you. Take advantage of this exclusive offer today and embark on a holistic path to fitness and wellness.",
                    image: flower1,
                    onClick: () => {
                        setCookie('live_class_count', 20);
                        setCookie('workout_video_count', 0);
                        setCookie('diet_package', '6_WEEKS');
                        setCookie('pt_count', 0);

                        history.push('/cart');
                    }
                },
                {
                    title: "25 classes + Nutrition - 6 weeks",
                    price: "Discount coupons on checkout",
                    description: "Experience the ultimate fitness and nutrition transformation with our '25 Classes + Nutrition' combo plan, featuring an enticing 15% discount on the 6-week package! You'll enjoy 25 dynamic fitness classes designed to suit your objectives, coupled with expert nutritional support to supercharge your wellness journey. Don't miss out on this exclusive opportunity to enhance your well-being with a comprehensive approach to health and fitness.",
                    image: flower1,
                    onClick: () => {
                        setCookie('live_class_count', 25);
                        setCookie('workout_video_count', 0);
                        setCookie('diet_package', '6_WEEKS');
                        setCookie('pt_count', 0);

                        history.push('/cart');
                    }
                },
                {
                    title: "30 classes + Nutrition - 6 weeks",
                    price: "Discount coupons on checkout",
                    description: "Unlock the full potential of your fitness and nutrition journey with our '30 Classes + Nutrition' combo plan, now available at an incredible 20% discount for the 6-week package! This comprehensive offering includes 30 empowering fitness classes tailored to your goals, complemented by expert nutritional guidance. Seize this exclusive opportunity to optimize your well-being and achieve your health and fitness aspirations.",
                    image: flower1,
                    onClick: () => {
                        setCookie('live_class_count', 30);
                        setCookie('workout_video_count', 0);
                        setCookie('diet_package', '6_WEEKS');
                        setCookie('pt_count', 0);

                        history.push('/cart');
                    }
                },
                {
                    title: "50 classes + Nutrition - 12 weeks",
                    price: "Discount coupons on checkout",
                    description: "Embark on a transformative wellness journey with our '50 Classes + Nutrition - 12 Weeks' combo plan, now with a generous 15% discount! This comprehensive package provides you with 50 invigorating fitness classes, precisely tailored to your goals, in addition to expert nutritional support for an extended 12-week period. Seize this exclusive offer to maximize your well-being and experience lasting health and fitness benefits.",
                    image: flower1,
                    onClick: () => {
                        setCookie('live_class_count', 50);
                        setCookie('workout_video_count', 0);
                        setCookie('diet_package', '12_WEEKS');
                        setCookie('pt_count', 0);

                        history.push('/cart');
                    }
                },
                {
                    title: "60 classes + Nutrition - 12 weeks",
                    price: "Discount coupons on checkout",
                    description: "Elevate your wellness journey to new heights with our '60 Classes + Nutrition - 12 Weeks' combo plan, now with an incredible 20% discount! This comprehensive package delivers 60 dynamic fitness classes, custom-fit to your goals, along with expert nutritional guidance spanning 12 weeks. Don't miss this exclusive opportunity to supercharge your health and fitness goals while enjoying significant savings.",
                    image: flower1,
                    onClick: () => {
                        setCookie('live_class_count', 60);
                        setCookie('workout_video_count', 0);
                        setCookie('diet_package', '12_WEEKS');
                        setCookie('pt_count', 0);

                        history.push('/cart');
                    }
                }
            ]
        },
        "Habit Coaching": {
            description: `Human behaviour is programmed to prefer and repeat the acts which does not involve much thinking or decision making. Brain prefers activities that does not cause it a cognitive overload. Habits are hacks for reducing the processing time for the brain. Who wouldn’t want that? A reduced workload with a lesser processing time! – Yes Please!\n\nThat is why the same car driving or cooking which made you so anxious during the initial learning days, has now become your second nature. Once you are used to something, brain does not have to go through the process of tiny logistics (Am I wearing seatbelt? Have I checked the blind spots? Have I added nutmeg to my cheese sauce Etc). Even when we are multitasking, these steps happen without any cognitive overload once its practised enough. Hence happier brain, more auto-pilot mode & lesser stress!\n\nThis is why, we at Keepwell Mantras believe the first step to a long-term success is to build exercise into a lifestyle habit & KWM Habit coaching is designed backed by Behavioural science & studies.`,
            pointers: [
                {
                    "text": "Conduct one-on-one introductory call to assist you in setting up and achieving your fitness and wellness goals",
                    "href": null
                },
                {
                    "text": "Develop and implement strategies to help you overcome obstacles and adopt healthier lifestyle habits.",
                    "href": null
                },
                {
                    "text": "Monitor your progress, track performance metrics, and adjust coaching strategies as needed.",
                    "href": null
                },
                {
                    "text": "Offer motivational support, encouragement, and accountability to keep clients engaged and committed to their fitness journey.",
                    "href": null
                },
                {
                    "text": "Utilize behavior change techniques and psychological principles to foster positive habits and mindset shifts.",
                    "href": null
                },
                {
                    "text": "Be your fitness accountability partner/friend sharing their own small/big wins over WhatsApp 😊",
                    "href": null
                }
            ],            
            packages: [
                {
                    title: "Habit Sculptor Plan (6 Weeks & 12 Weeks)",
                    price: "£120 / £225",
                    description: "Humans naturally gravitate towards habits that require less cognitive effort, making life more efficient and less stressful. Our brains embrace routines, turning once-complex tasks into second nature. At Keepwell Mantras, we leverage behavioral science to help you integrate exercise into your lifestyle, fostering lasting success.",
                    pointers: [
                        "Goal Setting with Habit assessment on fortnightly one-on-one calls",
                        "Customized Habit plan",
                        "Regular check ins",
                        "Mindset and Motivation",
                        "Accountability and sustainability change strategy"
                    ],
                    image: flower1,
                    onClick: () => history.push("/contact")
                },
                {
                    title: "Habits Makeover Plan (6 Weeks & 12 Weeks)",
                    price: "£150 / £275",
                    description: "Our brains favor low-effort, habitual behaviors to reduce cognitive strain. Everyday tasks become second nature, minimizing stress and cognitive load. Keepwell Mantras applies behavioral science to make exercise a lasting lifestyle habit for sustained success.",
                    pointers: [
                        "Goal Setting with Habit assessment on fortnightly one-on-one calls",
                        "Customized Habit plan",
                        "Regular check ins",
                        "Mindset and Motivation",
                        "Accountability and sustainability change strategy"
                    ],
                    image: flower1,
                    onClick: () => history.push("/contact")
                }
            ]
        },
        "Personal Training": {
            description: `If you are looking for personalized guidance, instruction, and support to help you achieve your fitness goals, which can be weight loss, strength gain, post-surgery recovery, stamina building, sports training or any specific skill training, then this service will be highly beneficial.\n\nThe exercise regime will be customized offering either face to face or online Personal training session. Any PT booked should be discussed and agreed before booking to ensure I have available slots for training.\n\nHere are some key aspects of personal training:`,
            pointers: [
                {
                    "text": "Customized Workout Plans: Exercise routines tailored to the individual's goals, fitness level, limitations, and preferences.",
                    "href": null
                },
                {
                    "text": "One-on-One Attention: Personal training sessions are conducted on a one-on-one basis, allowing myself to focus exclusively on your form, technique, and progress. This individualized attention helps prevent injuries and ensures that exercises are performed correctly.",
                    "href": null
                },
                {
                    "text": "Motivation and Accountability: Having personal level encouragement and support to help clients stay committed to their fitness routines.",
                    "href": null
                },
                {
                    "text": "Variety and Progression: Progress is extremely important and incorporating variety of exercises and training methods to keep workouts engaging and prevent plateaus. As clients progresses, the training can adjust the intensity, complexity, and type of exercises to ensure ongoing improvements.",
                    "href": null
                }
            ],            
            packages: [
                {
                    title: "30 mins PT",
                    price: "£35",
                    description: "Online / Face to Face - 30 minutes Personal Training session with 6 weeks expiry",
                    image: flower1,
                    onClick: () => {
                        setCookie('pt_count', 1);
                        setCookie('pt_package', '30_MINS_PT');
                        setCookie('live_class_count', 0);
                        setCookie('diet_package', "");
                        setCookie('workout_video_count', 0);
                        history.push('/cart');
                    }
                },
                {
                    title: "45 mins PT",
                    price: "£45",
                    description: "Online / Face to Face - 45 minutes Personal Training session with 6 weeks expiry",
                    image: flower1,
                    onClick: () => {
                        setCookie('pt_count', 1);
                        setCookie('pt_package', '45_MINS_PT');
                        setCookie('live_class_count', 0);
                        setCookie('diet_package', "");
                        setCookie('workout_video_count', 0);
                        history.push('/cart');
                    }
                },
            ]
        },
        "Custom": {
            description: null,
            pointers: [],
            packages: [
                {
                    title: "Customise my Program",
                    price: "£0 for enquiry",
                    description: "If you are looking for a bespoke package for your special day or want to customise the program as per your needs, or you are not sure what exactly fits you best,, please send as much information and we will get in touch with you in 3-5 business days.",
                    image: flower1,
                    onClick: () => history.push("/contact")
                },
            ]
        }
    }

    useEffect(() => {
        const hash = window.location.hash;
        let currentTab = hash.split("#")[1];
        currentTab = currentTab?.replace(/%20/g, " ");
        if (currentTab === "CoreSculpt App" || currentTab === "Nutrition Support" || currentTab === "Live Class" || currentTab === "Combo Offers" || currentTab === "Habit Coaching" || currentTab === "Personal Training" || currentTab === "Custom") {
            setSelectedTab(currentTab);
        }
    }, []);

    if (loading && !modalVisible) return (
        <>
            <div
                className='d-flex justify-content-center align-items-center'
                style={{
                    height: '100vh',
                    width: '100vw',
                }}
            >
                <Loader />
            </div>
        </>
    );

    return (
        <div id="packages-page">
            <Header2 />
            <div id="package-section" className="container">
                <h4>WHAT WE OFFER</h4>
                <h1 style={{ marginBottom: "10px" }}>Our Pricing Plans</h1>
                <h3 style={{ marginBottom: "10px" }}>Our pricing reflects our unwavering commitment to delivering exceptional quality, ensuring you get the best value for your investment.</h3>
                <div id="tabs">
                    <div className={`tabCell ${selectedTab === "CoreSculpt App" ? "tabCellActive" : ""}`}
                        onClick={() => {
                            setSelectedTab("CoreSculpt App");
                            window.location.hash = "CoreSculpt App";
                        }}
                    >
                        <p className="tabText">
                            CoreSculpt App
                        </p>
                    </div>
                    <div className={`tabCell ${selectedTab === "Nutrition Support" ? "tabCellActive" : ""}`}
                        onClick={() => {
                            setSelectedTab("Nutrition Support");
                            window.location.hash = "Nutrition Support";
                        }}
                    >
                        <p className="tabText">
                            Nutrition Support
                        </p>
                    </div>
                    <div className={`tabCell ${selectedTab === "Live Class" ? "tabCellActive" : ""}`}
                        onClick={() => {
                            setSelectedTab("Live Class");
                            window.location.hash = "Live Class";
                        }}
                    >
                        <p className="tabText">
                            Live Class
                        </p>
                    </div>
                    <div className={`tabCell ${selectedTab === "Combo Offers" ? "tabCellActive" : ""}`}
                        onClick={() => {
                            setSelectedTab("Combo Offers");
                            window.location.hash = "Combo Offers";
                        }}
                    >
                        <p className="tabText">
                            Combo Offers
                        </p>
                    </div>
                    <div className={`tabCell ${selectedTab === "Habit Coaching" ? "tabCellActive" : ""}`}
                        onClick={() => {
                            setSelectedTab("Habit Coaching");
                            window.location.hash = "Habit Coaching";
                        }}
                    >
                        <p className="tabText">
                            Habit Coaching
                        </p>
                    </div>
                    <div className={`tabCell ${selectedTab === "Personal Training" ? "tabCellActive" : ""}`}
                        onClick={() => {
                            setSelectedTab("Personal Training");
                            window.location.hash = "Personal Training";
                        }}
                    >
                        <p className="tabText">
                            Personal Training
                        </p>
                    </div>
                    <div className={`tabCell ${selectedTab === "Custom" ? "tabCellActive" : ""}`}
                        onClick={() => {
                            setSelectedTab("Custom");
                            window.location.hash = "Custom";
                        }}
                    >
                        <p className="tabText">
                            Custom
                        </p>
                    </div>
                </div>
                <div id="package">
                    {
                        packages[selectedTab]?.description && <div
                            className="description-card"
                        >
                            <p>
                                {packages[selectedTab]?.description}
                            </p>
                            {
                                packages[selectedTab]?.pointers?.length > 0 && <div
                                    className="d-flex flex-column"
                                >
                                    {
                                        packages[selectedTab]?.pointers?.map(po => (
                                            <div className="d-flex align-items-center my-1">
                                                <AiFillCheckCircle color="#C8A97D" className="mr-2" style={{ minWidth: "15px" }} />
                                                {
                                                    po.href ? <a href={po.href} target="_blank" className="pointer-with-href" rel="noreferrer">{po.text}</a> : <span>{po.text}</span>
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                    }
                    {
                        packages[selectedTab]?.packages.map(pac => <PackageCard packageObj={pac} />)
                    }
                </div>
            </div>
            <Footer />
            {
                modalVisible && (
                    <Modal isOpen={modalVisible} toggle={() => {
                        setModalVisible(false);
                        setElementType(0);
                    }}>
                        <ModalHeader toggle={() => {
                            setModalVisible(false);
                            setElementType(0);
                        }}>Start Free Trial</ModalHeader>
                        <ModalBody>
                            {
                                loading ? (
                                    <div
                                        className='w-100 h-100 d-flex justify-content-center align-items-center'
                                    >
                                        <Loader />
                                    </div>
                                ) : (
                                    <div>
                                        {
                                            elementType === 0 && (
                                                <Elements stripe={stripe}>
                                                    <AddressForm
                                                        onSubmit={createStripeUser}
                                                    />
                                                </Elements>
                                            )
                                        }
                                    </div>
                                )
                            }
                        </ModalBody>
                    </Modal>
                )
            }
        </div>
    )
}
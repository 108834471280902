import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Card, Container, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getEnrollments } from "../../actions/userActions.js";

import Header from "../../components/Headers/Header.js";
import Loader from "../../components/Loader/loader.js";
import _fetch from "../../_fetch.js";
import Reviews from "../Reviews.js";

export default function WorkoutVideoPlayer() {

	let { id } = useParams();
	const [url, setUrl] = useState('');
	const [class_data, setClassData] = useState({ title: '', description: '', cat_name: '' });
	const [loading, setLoading] = useState(false);
	let dispatch = useDispatch();

	useEffect(() => {
		setLoading(true);
		_fetch(`${process.env.REACT_APP_API}/classes/workout_video?category_id=${id}`)
			.then(res => {
				if (res.success) {
					setUrl(res.response.class_recording)
					setClassData({
						title: res.response.title,
						description: res.response.description,
						cat_name: res.response.cat_name
					})
					getEnrollments(dispatch);
				} else {
					toast.error(res.response);
				}
			})
			.catch(err => {
				console.log(err);
				toast.error(err.message);
			})
			.finally(() => {
				setLoading(false);
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) return (
		<>
			<Header />

			<Container className="mt--7" fluid>
				<Card className="bg-secondary shadow" style={{ height: 400 }}>
					<Loader />
				</Card>
			</Container>
		</>
	);


	return (
		<>
			<Header />
			{/* Page content */}
			<Container className="mt--7" fluid>
				<Card className="shadow border-0">

					<CardHeader className="border-0">
						<h3>{class_data.cat_name}</h3>
					</CardHeader>

					<CardBody className='row' style={{ minHeight: 500 }}>

						<Reviews className='justify-items-start mb-4' />

						<Row className='w-100'>
							<Col xs={{ size: 12, offset: 0 }} md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
								{
									url
										?
										<video src={url} style={{ minHeight: 450 }} className='w-100 h-auto' controls={true} autoPlay>
										</video>
										:
										'Something went wrong'
								}
							</Col>
							<Col xs={{ size: 12, offset: 0 }} md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
								<h4 className='text-lg font-weight-600 mt-4'>{class_data.title}</h4>
								<p className='mt-3'>
									{class_data.description}
								</p>
							</Col>
						</Row>

					</CardBody>
				</Card>
			</Container>
		</>
	);
};

import React, { useEffect, useState } from "react";
import {
    Container, Card, Row, Col
} from "reactstrap";
import _fetch from '../../_fetch.js';
import './styles.scss';
import { useLocation, useHistory } from "react-router-dom";
import Loader from "../../components/Loader/loader.js";
import CSVideoConfirmation from "../../components/Modals/CSVideoConfirmation/index.js";
import { useSelector } from "react-redux";
import CSVideoCard from "../../components/CSVideoCard/index.js";





export default function FocusAreaDetails() {
    const location = useLocation();
    const history = useHistory();
    const user = useSelector((st) => st.user);
    const categoryId = location?.state?.categoryId || "";
    const FocusAreaId = location?.state?.focusAreaId || "";
    const focusAreaDesc = location?.state?.description;
    const focusAreaTitle = location?.state?.name;
    const [loading, setLoading] = useState(true);
    const [focusAreasData, setFocusAreasData] = useState([])
    const [selectedToWatchVideo, setSelectedToWatchVideo] = useState(null);


    const getdata = async () => {
        try {
            let categoryData = await _fetch(`${process.env.REACT_APP_API_V2}/class?category=${categoryId}&focus_area=${FocusAreaId}`);
            if (categoryData.success) {
                setFocusAreasData(categoryData.response);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }

    };

    useEffect(() => {
        getdata();
    }, []);


    if (loading) {
        return (
            <>
                <Container className="mt-7" fluid>
                    <Card className="bg-secondary shadow" style={{ height: 400 }}>
                        <Loader />
                    </Card>
                </Container>
            </>
        )
    }


    return (
        <>
            <div id="core-sculpt-focus-area" className="w-100 bg-color mb-4">
                <div className="w-100">
                    <div class='d-flex flex-column justify-content-center align-items-center pt-4 pb-3 px-4'>
                        <h2 className="text-center">{focusAreaTitle}</h2>
                        <div class='d-flex justify-content-center align-items-center text-center'>
                            <h2>Step 3: Choose your video</h2>
                        </div>
                        <p className="text-center">{focusAreaDesc}</p>
                    </div>
                    <Row className="mx-1 mx-md-4">
                        {focusAreasData.map((elem) => (
                            <Col
                                key={elem?._id}
                                xs={12}
                                lg={6}
                                className="w-100"                            >
                                <CSVideoCard
                                    item={elem}
                                    onPress={() => {
                                        if (user.isSubscriptionPurchased && !!user?.currentSubscription?._id) {
                                            const isVideoWatched = user?.currentSubscription?.watchedVideos?.some(((watchedItem) => watchedItem.video_id?._id === elem._id))
                                            if (isVideoWatched) {
                                                history.push(`/core-sculpt/video/${elem._id}`)
                                            } else setSelectedToWatchVideo(elem._id)
                                        } else {
                                            history.push('/coresculptapp#packages')
                                        }
                                    }}
                                    isWatched={elem.isVideoWatchedAlready}
                                />
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            <CSVideoConfirmation
                isOpen={selectedToWatchVideo !== null}
                toggle={() => {
                    setSelectedToWatchVideo(null);
                }}
                onContinuePress={() => {
                    history.push(`/core-sculpt/video/${selectedToWatchVideo}`)
                    setSelectedToWatchVideo(null);
                }}
            />
        </>

    );

};

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink as NavLinkRRD, Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from 'react-cookie';
import {
	Collapse, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, NavbarBrand, Navbar, NavItem,
	NavLink, Nav, Container, Row, Col
} from "reactstrap";
import { LogoutAction } from "../../actions/userActions";
import _fetch from "../../_fetch";
import Reviews from "../../views/Reviews";


const Sidebar = ({ logo }) => {

	const [collapseOpen, setCollapseOpen] = useState();

	const history = useHistory();
	let dispatch = useDispatch();
	const { name, profile_picture, admin } = useSelector(st => st.user);
	const [cookie, setCookie] = useCookies(['cart', 'tour']);

	function logout() {
		_fetch(process.env.REACT_APP_API + '/user/login', { method: "DELETE" })
			.then(res => {
				if (res.success) {
					dispatch(LogoutAction);
					return history.push('/login');
				}
				toast.error('Some error occurred please try again later.')
			})
			.catch(err => {
				console.log(err);
				toast.error('Some error occurred please try again.')
			})
	}

	// toggles collapse between opened and closed (true/false)
	const toggleCollapse = () => {
		setCollapseOpen((data) => !data);
	};

	// closes the collapse
	const closeCollapse = () => {
		setCollapseOpen(false);
	};

	let navbarBrandProps;
	if (logo && logo.innerLink) {
		navbarBrandProps = {
			to: logo.innerLink,
			tag: Link,
		};
	} else if (logo && logo.outterLink) {
		navbarBrandProps = {
			href: logo.outterLink,
			target: "_blank",
		};
	}

	return (
		<Navbar
			className="navbar-vertical fixed-left navbar-light bg-white"
			expand="md"
			id="sidenav-main"
		>
			<Container fluid>

				{/* Toggler */}
				<button
					className="navbar-toggler"
					type="button"
					onClick={toggleCollapse}
				>
					<span className="navbar-toggler-icon" />
				</button>

				{/* Brand */}
				{
					logo ? (
						<NavbarBrand className="pt-0" {...navbarBrandProps}>
							<img
								alt={logo.imgAlt}
								className="navbar-brand-img"
								src={logo.imgSrc}
							/>
						</NavbarBrand>
					)
						:
						null
				}

				{/* User nav */}
				<Nav className="align-items-center d-md-none">

					<Link to='/buy-classes' className="d-flex pointer">
						<i className="lni lni-cart text-xl text-primary" />
						{
							cookie.cart &&
							<sup className='bg-danger lh-140 py-1 px-2 text-xs rounded-circle text-white'>
								{cookie.cart.split(',').length}
							</sup>
						}
					</Link>

					{/* USER PROFILE */}

					<UncontrolledDropdown nav>
						<DropdownToggle nav>
							<span className="avatar avatar-sm rounded-circle">
								{
									profile_picture
										?
										<img alt={name} src={profile_picture} />
										:
										<div className='text-lg'>{name?.length > 0 ? name[0] : ''}</div>
								}
							</span>
						</DropdownToggle>
						<DropdownMenu className="dropdown-menu-arrow" right>
							<DropdownItem className="noti-title" header tag="div">
								<h6 className="text-overflow m-0">Welcome!</h6>
							</DropdownItem>
							<DropdownItem to="/profile" tag={Link}>
								<i className="lni lni-user"></i>
								<span>My profile</span>
							</DropdownItem>
							{/* <DropdownItem to="/settings" tag={Link}>
								<i className="ni ni-settings-gear-65" />
								<span>Settings</span>
							</DropdownItem>
							<DropdownItem to="/support" tag={Link}>
								<i className="ni ni-support-16" />
								<span>Support</span>
							</DropdownItem> */}
							<DropdownItem divider />
							<DropdownItem href="#pablo" onClick={logout}>
								<i className="lni lni-power-switch"></i>
								<span>Logout</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>

				</Nav>

				{/* Collapse */}

				<Collapse navbar isOpen={collapseOpen}>

					{/* Collapse header */}
					<div className="navbar-collapse-header d-md-none">
						<Row>
							{logo ? (
								<Col className="collapse-brand" xs="6">
									{logo.innerLink ? (
										<Link to={logo.innerLink}>
											<img alt={logo.imgAlt} src={logo.imgSrc} />
										</Link>
									) : (
										<a href={logo.outterLink}>
											<img alt={logo.imgAlt} src={logo.imgSrc} />
										</a>
									)}
								</Col>
							) : null}
							<Col className="collapse-close" xs="6">
								<button
									className="navbar-toggler"
									type="button"
									onClick={toggleCollapse}
								>
									<span />
									<span />
								</button>
							</Col>
						</Row>
					</div>

					{/* Navigation */}
					<Nav navbar>
						{
							admin &&
							<React.Fragment>
								<NavItem>
									<NavLink
										to={'/admin/classes'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-list text-indigo"></i>
										All Sessions
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/add-class'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-circle-plus text-pink"></i>
										Add Session
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/app-category'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-circle-plus text-pink"></i>
										App Category
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/bulk-class-upload'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-circle-plus text-pink"></i>
										Bulk Class Upload
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/add-category'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-circle-plus text-pink"></i>
										Add Category
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/add-focus-area'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-circle-plus text-pink"></i>
										Add Focus Area
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/6-week-enrollments'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-circle-plus text-pink"></i>
										6 Week NSP Enrollments
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/60-min-enrollments'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-circle-plus text-pink"></i>
										60 Min NSP Calls
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/questions'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-circle-plus text-pink"></i>
										Questions
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/app-screen-data'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-circle-plus text-pink"></i>
										App Screen Data
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/app-coupons'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-circle-plus text-pink"></i>
										App Coupons
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/active_users'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-user text-blue"></i>
										All Active Users
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/subscribed-users'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-user text-blue"></i>
										All Subscribed Users
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/interested-users'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-user text-blue"></i>
										Interested Users
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/notification'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-alarm text-blue"></i>
										Notification
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/habit-coaches'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-user text-blue"></i>
										Habit Coaches
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/all_recipes'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-fresh-juice text-green"></i>
										All Recipes
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/testimonials'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-fresh-juice text-green"></i>
										Testimonial
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/transformations'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-fresh-juice text-green"></i>
										Transformation
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/web_content'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-fresh-juice text-green"></i>
										Web Content
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/enrollments'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-circle-plus text-pink"></i>
										Manage Enrollments
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										to={'/admin/affilations'}
										tag={NavLinkRRD}
										onClick={closeCollapse}
										activeClassName="active"
									>
										<i className="lni lni-pound text-pink"></i>
										Affilations
									</NavLink>
								</NavItem>
							</React.Fragment>
						}
						<NavItem>
							<NavLink
								to={'/buy-classes'}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName="active"
								className='px-3'
							>
								<div className='btn btn-primary w-100 text-white text-center'>
									Buy Classes
								</div>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								to={'/dashboard'}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName="active"
							>
								<i className={'ni ni-tv-2 text-purple'} />
								Dashboard
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								to={'/live-sessions'}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName="active"
								className='nav-live-class'
							>
								<i className={'lni lni-calendar text-blue'} />
								Live Classes &amp; Recordings
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								to={'/recorded-sessions'}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName="active"
								className='nav-workout-video'
							>
								<i className={'ni ni-collection text-green'} />
								Workout Videos
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								to={'/core-sculpt'}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName="active"
								className='nav-workout-video'
							>
								<i className={'ni ni-collection text-green'} />
								Core Sculpt
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								to={'/diet-package'}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName="active"
								className='nav-workout-video'
							>
								<i className="lni lni-fresh-juice text-orange"></i>
								Nutrition Support Plan
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								to={'/personal-training'}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName="active"
								className='nav-workout-video'
							>
								<i className="lni lni-fresh-juice text-orange"></i>
								Personal Training
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								onClick={() => {
									history.push('/my_recipes')
									setCookie('tour', 'started');
								}}
								className='pointer nav-tour'
							>
								<i className="lni lni-plus text-teal"></i>
								My Recipes
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								to={'/profile'}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName="active"
								className='nav-profile'
							>
								<i className={'lni lni-user text-yellow'} />
								My Profile
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								to={'/contact-us'}
								tag={NavLinkRRD}
								onClick={closeCollapse}
								activeClassName="active"
								className='nav-contact'
							>
								<i className="lni lni-support text-cyan"></i>
								Contact Us
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								onClick={() => {
									history.push('/dashboard')
									setCookie('tour', 'started');
								}}
								className='pointer nav-tour'
							>
								<i className="lni lni-map text-teal"></i>
								Website tour
							</NavLink>
						</NavItem>
						<NavItem onClick={logout}>
							<NavLink
								className='pointer'
							>
								<i className="lni lni-arrow-left-circle text-red"></i>
								Logout
							</NavLink>
						</NavItem>
					</Nav>

				</Collapse>

				<div className='d-none d-md-block mt-auto pt-3 border-top'>
					<h5 className='text-center' style={{ fontSize: 18 }}>Review us on</h5>
					<Reviews className='justify-content-center' />
				</div>

			</Container>
		</Navbar>
	);
};

export default Sidebar;

import React from 'react';

export default function Reviews({ className, size = 'sm' }) {
    return (
        <div className={`d-flex ${className}`}>
            <a href='https://www.facebook.com/keepwellmantras'
                className={`icon-${size} text-white rounded-circle shadow`}
                target="_blank" rel="noopener noreferrer"
            >
                <img
                    alt="Review on Facebook" className='img img-fluid'
                    src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/facebook.png`}
                />
            </a>
            <a href='https://g.page/r/CUI1G_-hZJ_qEAI/review'
                className={`icon-${size} text-white rounded-circle shadow mx-3`}
                target="_blank" rel="noopener noreferrer"
            >
                <img
                    alt="Review on Google" className='img img-fluid'
                    src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/google.svg`}
                />
            </a>
            <a href='https://uk.trustpilot.com/evaluate/keepwellmantras.com'
                className={`icon-${size} text-white rounded-circle shadow`}
                target="_blank" rel="noopener noreferrer"
            >
                <img
                    alt="Review on Trustpilot" className='img img-fluid'
                    src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/trustpilot.svg`}
                />
            </a>
        </div>
    );
}
export const updateCartUserInfo = (payload) => ({
    type: 'SET_CART_USER_INFO',
    payload
});

export const resetCart = () => ({
    type: 'RESET_CART'
});

export const setOpenCartOrder = (payload) => ({
    type: 'SET_OPEN_CART_ORDER',
    payload
});
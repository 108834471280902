import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Card, CardHeader, Table, Container, Button, CardBody, CardTitle, CardText, Collapse, Row, Col } from "reactstrap";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { getEnrollments } from "../../actions/userActions.js";
import Header from "../../components/Headers/Header.js";
import _fetch from '../../_fetch';
import Loader from "../../components/Loader/loader.js";
import Questionnaire from './questionnaire';
import "./styles.css";
import Enrollments from "../MyActivity/enrollments";
import Activities from "../MyActivity/activity";
import CSCalendar from "../../components/CSCalendar/index.js";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function PackageCard({ title, description, onClick, btnText, color, hover, setHover }) {
	return (
		<div className='col-xs-12 col-md-6 my-3 col-lg-4 my-3'>
			<Card className={`overflow-hidden shadow bg-${color} text-white h-100`}>
				<CardBody className='py-3 px-2 d-flex flex-column align-items-stretch'>
					<CardTitle tag="h5" style={{ fontSize: 18 }} className='mb-2 text-white'>
						{title}
					</CardTitle>
					<CardText className='text-white pointer d-flex justify-content-between' onClick={() => setHover(!hover)}>
						<span>{hover ? 'Show Less' : 'Show More'}</span>
						{
							hover ?
								<i className="lni lni-chevron-up-circle text-lg"></i>
								:
								<i className="lni lni-chevron-down-circle text-lg"></i>
						}
					</CardText>
					<Collapse isOpen={hover}>
						<div id='card-desc' className={`text-sm mb-2 ${hover ? 'card-desc-show' : 'card-desc-hidden'}`}>
							{description}
						</div>
					</Collapse>
					<div className='d-flex align-items-center justify-content-center mt-auto'>
						<Button color='white' onClick={onClick}>
							{btnText}
						</Button>
					</div>
				</CardBody>
			</Card>
		</div>
	)
}

export default function Dashboard() {

	const [, setCookie] = useCookies(['cart', 'live_class_count']);

	const [loading, setLoading] = useState(false);
	const [sessions, setSessions] = useState([]);
	const [visible, setVisible] = useState(false);
	const [hover, setHover] = useState(false);
	const [hover1, setHover1] = useState(false);
	const [questions, setQuestions] = useState([]);

	let dispatch = useDispatch();
	let history = useHistory();
	let invoice_id = useQuery().get('OrderID');

	async function getQuestions() {
		try {
			setLoading(true);
			let res = await _fetch(`${process.env.REACT_APP_API}/questions`)
			if (res.success && Array.isArray(res.response)) {
				setQuestions(res.response);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	}

	useEffect(() => {
		if (invoice_id) {
			_fetch(`${process.env.REACT_APP_API}/order?invoice_id=${invoice_id}`)
				.then(res => {
					if (res.success) {
						if (res.response === 'COMPLETED')
							return toast.success('Payment processed successfully.');
						else return toast.error('Payment failed.');
					} else {
						console.log(res.response);
					}
				})
				.catch(err => {
					console.log(err);
				})
		}
	}, [invoice_id])

	useEffect(() => {

		async function init() {
			try {
				setLoading(true);

				getEnrollments(dispatch);

				let res = await _fetch(`${process.env.REACT_APP_API}/classes?type=LIVE&limit=5&upcoming=true`);
				if (res.success) {
					console.log(res.response);
					setSessions(res.response);
				}

			} catch (err) {
				console.log(err);
			} finally {
				setLoading(false);
			}
		}

		init();
		getQuestions();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) return (
		<>
			<Header />

			<Container className="mt--7" fluid>
				<Card className="bg-secondary shadow" style={{ height: 400 }}>
					<Loader />
				</Card>
			</Container>
		</>
	);

	return (
		<>
			<Header />
			{/* Page content */}
			<Container fluid>
				<Row>
					<Col xs={12} className="mt--7">
						<Enrollments />
					</Col>
					<Col xs={12} lg={6}>
						<Card className="shadow mb-4">
							<CardHeader className="border-0">
								<h2 className="w-100 mb-0 text-center">Upcoming live Classes</h2>
							</CardHeader>
							<Table className="align-items-center table-flush" responsive>
								<thead className="thead-light">
									<tr>
										<th scope="col">Class</th>
										<th scope="col">Date</th>
										<th scope="col">Type</th>
									</tr>
								</thead>
								<tbody>
									{
										sessions.map(e => (
											<tr key={e._id}>
												<th scope="row">
													{e.title}
												</th>
												<td>
													{moment(e.date).format('llll')}
												</td>
												<td>{e.category.name}</td>
											</tr>
										))
									}
								</tbody>
							</Table>
						</Card>
					</Col>

					<Col xs={12} lg={6}>
						<Activities />
					</Col>
					<Col xs={12} lg={6} className="mb-4">
						<CSCalendar />
					</Col>
					<Col xs={12}>
						<div className='row align-items-stretch' style={{ minHeight: 500 }}>
							<PackageCard
								color="danger"
								title="Live Classes Packages"
								btnText="BUY NOW"
								onClick={() => {
									setCookie('live_class_count', 10);
									setCookie('workout_video_count', 0);
									history.push('/buy-classes');
								}}
								description={
									<ul className='text-white'>
										<li className="first-child">6 and 12 weeks package</li>
										<li>£{process.env.REACT_APP_PER_CLASS_PRICE} per session</li>
										<li>
											Refer to the latest offers and discounts on the checkout page
										</li>
										<li>Access to Live classes or recordings of live classes</li>
										<li>Flexibility to choose from 10 different variety of classes <br />
											- 4 categories of HIITs (High intensity - for intermediate to advance) <br />
											- 4 categories of MIITs ( Medium intensity - for beginners to intermediate)<br />
											- 2 categories of Strength workouts ( with dumbbells)</li>
										<li>Access to private members community</li>
									</ul>
								}
								{...{ hover, setHover }}
							/>
							<PackageCard
								color="orange"
								title="Workout Videos"
								btnText="BUY NOW"
								onClick={() => {
									setCookie('live_class_count', 0);
									setCookie('workout_video_count', 10);
									history.push('/buy-classes');
								}}
								description={
									<ul className='text-white'>
										<li className="first-child">6 and 12 weeks package</li>
										<li>£{process.env.REACT_APP_PER_CLASS_PRICE} per session</li>
										<li>Refer to the latest offers and discounts on the checkout page</li>
										<li>Access to over hundreds of live class recorded videos. Helps in customizing your schedule to cater to your fitness needs.</li>
										<li>Flexibility to choose from 9 categories of workouts:<br />
											- HIITs Lower Body 45 min <br />
											- HIITs Stamina 45 min<br />
											- HIITs Abs 45 min<br />
											- HIITs Full body 45 min<br />
											- Ultra HIITs 30 min<br />
											- Medium intensity (beginners)<br />
											- Medium intensity (intermediate)<br />
											- Strength Training (Full body with Dumbbells)<br />
											- Strength Training (Upper body with dumbbells)<br />
										</li>
										<li>Access to private members community</li>
									</ul>
								}
								{...{ hover, setHover }}
							/>
							<PackageCard
								color="green"
								title="Combo Live and Workout Videos"
								onClick={() => {
									setCookie('live_class_count', 5);
									setCookie('workout_video_count', 5);
									history.push('/buy-classes');
								}}
								btnText="BUY NOW"
								description={
									<ul className='text-white'>
										<li className="first-child">6 and 12 weeks package</li>
										<li>You can always combine a few live classes and few recorded workouts to create yourself a customized workout plan.</li>
										<li>£{process.env.REACT_APP_PER_CLASS_PRICE} per video access. Refer to the latest offers and block booking discounts on the checkout page.</li>
										<li>Access to over hundreds of live class recorded videos. Helps in customizing your schedule to cater to your fitness needs.</li>
										<li>Flexibility to choose from 9 categories of workouts:<br />
											- HIITs Lower Body 45 min<br />
											- HIITs Stamina 45 min<br />
											- HIITs Abs 45 min<br />
											- HIITs Full body 45 min<br />
											- Ultra HIITs 30 min<br />
											- Medium intensity (beginners)<br />
											- Medium intensity (intermediate)<br />
											- Strength Training (Full body with Dumbbells)<br />
											- Strength Training (Upper body with dumbbells)
										</li>
										<li>Access to private members community</li>
									</ul>
								}
								{...{ hover, setHover }}
							/>
							<PackageCard
								color="blue"
								title="Nutrition only support package"
								onClick={() => {
									setCookie('live_class_count', 0);
									setCookie('workout_video_count', 0);
									history.push('/diet-package');
								}}
								btnText="BUY NOW"
								description={
									<ul className='text-white'>
										<li className="first-child">£{process.env.REACT_APP_6_WEEK_DIET_PRICE} for 6 weeks, £{process.env.REACT_APP_12_WEEK_DIET_PRICE} for 12 weeks support</li>
										<li>Personalized nutrition plans and lifestyle enhancements customized to your fitness goals</li>
										<li>My diet plans will cater to any underlying medical condition you may have which will be discussed in detail beforehand</li>
										<li>Option to choose from a set meal plan ideas or calorie counting diet approach will be discussed during the initial consultation call.</li>
										<li>Bi - weekly check ins</li>
										<li>Close monitoring of food groups and macros required for your transformation</li>
										<li>I do not recommend or advise on any diet supplements or meal replacement products. I encourage healthy and balanced eating habits</li>
										<li>My nutrition coaching aims to empower you with the knowledge and understanding of staying in your healthiest self throughout your lifetime</li>
										<li>6 weeks or 12 weeks collaboration plans to choose from</li>
									</ul>
								}
								hover={hover1}
								setHover={setHover1}
							/>
							<PackageCard
								color="purple"
								title="Fitness &amp; Nutrition Combo package"
								onClick={() => {
									setCookie('live_class_count', 20);
									setCookie('workout_video_count', 0);
									history.push('/diet-package');
								}}
								btnText="BUY NOW"
								description={
									<ul className='text-white'>
										<li>Starting from £240</li>
										<li>You can always combine fitness workouts and nutrition support to get the max results in 6 weeks.</li>
										<li>Customized fitness plan with access to min 20 workouts. You can choose between Live classes or workout videos.</li>
										<li>Customized nutrition support plan for 6 weeks</li>
										<li>Access to private members community</li>
									</ul>
								}
								hover={hover1}
								setHover={setHover1}
							/>
							<PackageCard
								color="indigo"
								title="Personal Training &amp; Nutrition support"
								onClick={() => {
									setCookie('live_class_count', 20);
									setCookie('workout_video_count', 0);
									history.push('/contact')
								}}
								btnText="CONTACT TO DISCUSS"
								description={
									<ul className='text-white'>
										<li className="first-child"></li>
										<li>6 weeks package</li>
										<li>Customised Fitness plan with 1:1 sessions </li>
										<li>Customised nutrition support plan for 6 weeks (optional)</li>
										<li>Flexibility to choose from and include group sessions (optional) <br />
											- HIITs (High intensity - for intermediate to advance) <br />
											- MIITs ( Medium intensity - for beginners to intermediate)<br />
											- Strength workouts ( with dumbbells)</li>
										<li>Access to private members community</li>
									</ul>
								}
								hover={hover1}
								setHover={setHover1}
							/>
						</div>
					</Col>
				</Row>
				{/* <Modal isOpen={!!(file)} toggle={() => setFile(null)}>
					<ModalHeader toggle={() => setFile(null)}>Upload File</ModalHeader>
					<ModalBody>
						{file?.name}
					</ModalBody>
					<ModalFooter>
						<Button color="primary" onClick={() => upload()} className='mr-2' disabled={uploading}>
							{uploading ? <Loader size={20} /> : 'Upload'}
						</Button>
						<Button color="secondary" onClick={() => setFile(null)} disabled={uploading}>Cancel</Button>
					</ModalFooter>
				</Modal> */}

				<Questionnaire
					visible={visible}
					setVisible={setVisible}
					questions={questions}
				/>

			</Container>
		</>
	);
};

import React from "react";
import { Button, Row, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import { useSelector } from "react-redux";
import moment from "moment";

const mid = process.env.REACT_APP_TAKEPAYMENTS_MID;
const currencycode = "826";
const transactiontype = "SALE";
const orderdesc = 'KeepWell Mantras';
const address1 = '';
const address2 = '';
const address3 = '';
const address4 = '';
const city = '';
const state = '';
const postcode = '';
const countrycode = '';
const hashmethod = 'SHA1';
const callbackurl = `${process.env.REACT_APP_HOME}/dashboard`;
const resultdeliverymethod = 'SERVER';
const serverresulturl = `${process.env.REACT_APP_API}/order/response`;
const PaymentFormDisplaysResult = 'false';

export default function Payment({ order, setOrder }) {

    const { first_name, last_name, email } = useSelector(st => st.user);

    if(!order) return null;

    return (
        <Modal isOpen={true} toggle={() => setOrder(null)}>
            <ModalHeader>
                Enrollment Extension Summary
            </ModalHeader>

            <ModalBody className="bg-secondary shadow h-100">

                <Row>
                    <Col xs={7} md={8} className="text-dark">
                        {order.extension?.title}
                    </Col>
                    <Col xs={5} md={4} className='font-weight-600'>£{order.invoice_total / 100}</Col>
                </Row>
                <hr className="my-4" />

                <Row>
                    <Col xs={{ size: 8, offset: 2 }}>

                        <form method="POST" action={process.env.REACT_APP_TAKEPAYMENT_URL}>
                            <input type="hidden" name="MerchantID" value={mid} />
                            <input type="hidden" name="Amount" value={order.invoice_total} />
                            <input type="hidden" name="CurrencyCode" value={currencycode} />
                            <input type="hidden" name="OrderID" value={order.invoice_id} />
                            <input type="hidden" name="TransactionType" value={transactiontype} />
                            <input type="hidden" name="TransactionDateTime" value={moment(order.createdAt).utc().format('YYYY-MM-DD HH:mm:ss Z')} />
                            <input type="hidden" name="OrderDescription" value={orderdesc} />
                            <input type="hidden" name="CustomerName" value={`${first_name}${last_name ? ` ${last_name}` : ''}`} />
                            <input type="hidden" name="Address1" value={address1} />
                            <input type="hidden" name="Address2" value={address2} />
                            <input type="hidden" name="Address3" value={address3} />
                            <input type="hidden" name="Address4" value={address4} />
                            <input type="hidden" name="City" value={city} />
                            <input type="hidden" name="State" value={state} />
                            <input type="hidden" name="PostCode" value={postcode} />
                            <input type="hidden" name="CountryCode" value={countrycode} />
                            <input type="hidden" name="EmailAddress" value={email} />
                            <input type="hidden" name="HashMethod" value={hashmethod} />
                            <input type="hidden" name="CallbackURL" value={callbackurl} />
                            <input type="hidden" name="ResultDeliveryMethod" value={resultdeliverymethod} />
                            <input type="hidden" name="ServerResultURL" value={serverresulturl} />
                            <input type="hidden" name="PaymentFormDisplaysResult" value={PaymentFormDisplaysResult} />
                            <input type="hidden" name="HashDigest" value={order.pi} />

                            <Button type='submit' color='primary' className='w-100' >Complete Payment</Button>
                        </form>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

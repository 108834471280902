import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

export default function Unsubscribe() {
    return (
        <>
            <Container className="pb-5">
                <Row className="justify-content-center">
                    <Col lg="8" md="8">
                        <div className="card border-0 mb-0">
                            <div className="card-body px-lg-5 py-lg-5">
                                <div className="text-center text-muted mb-4">
                                    <h1>You have unsubscribed from our reminder mails.</h1>
                                    <p>If you wish to subscribe to our reminder mails, you can do it from the profile.</p>
                                </div>
                                <div className="text-center">
                                    <Link to="/">HOME</Link>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
import React, { useState } from 'react';
import Slider from "react-slick";
import { useSelector } from 'react-redux';
import { Container, Row, Col, Button } from 'reactstrap';
import { Link } from "react-router-dom";
import leftTwigSVG from '../../assets/subscription/flower-1.svg'
import rightTwigSVG from '../../assets/subscription/flower-2.svg'
import { AiOutlineUser, AiOutlineClose } from 'react-icons/ai';
// import './style.css';
import './testimonial.scss';
import Header2 from '../../components/Headers/Header2';
import Footer from '../../components/Footers/AuthFooter';
import CustomPagination from './CustomPagination';



export default function Testimonial({ slides, className }) {

    const testimonials = useSelector(st => st.app.testimonials);

    const TestimonialCardes = ({ testimonial, name, i }) => {
        const [isHovered, setIsHovered] = useState(false);

        const handleHover = () => {
            setIsHovered(!isHovered);
        };

        return (<>

            <div
                className='testimonial-card d-flex flex-column p-3'
                onMouseEnter={handleHover}
                onMouseLeave={handleHover}

            // onMouseLeave={handleHover}
            >
                <div className='p-3'>

                    {
                        i % 2 === 0 ?
                            <img
                                src={rightTwigSVG}
                                alt='..'
                                className='right-twig-svg'
                                style={{
                                    position: 'relative',
                                    zIndex: '0',
                                    height: '12%',
                                    width: '12%',

                                }}
                            /> :
                            <img
                                src={leftTwigSVG}
                                alt='..'
                                className='left-twig-svg'
                                style={{
                                    position: 'relative',
                                    zIndex: '0',
                                    height: '12%',
                                    width: '12%',

                                }}
                            />
                    }

                </div>

                <p
                    className='testimonial-card-text testimonial-card-name p-3'
                >

                    {isHovered ? testimonial : testimonial.length > 100 ? `${testimonial.substring(0, 200)}...` : testimonial}
                </p>
                <div
                    className='d-flex flex-row align-items-center testimonial-card-footer  p-3'
                >
                    <AiOutlineUser
                        size={35}
                        className='testimonial-card-icon'
                        color='white'
                    />
                    <div>
                        <h4
                            className='testimonial-card-head-name'
                        >
                            {name}
                        </h4>
                        <p
                            className='testimonial-card-subtext'
                        >
                            Client
                        </p>
                    </div>


                </div>

            </div>
        </>
        );
    };


    var settings = {
        vertical: true,
        verticalSwiping: true,
        infinite: true,
        speed: 500,
        dots: false,
        arrows: false,
        slidesToShow: 2,
        rows: 1,
        slidesPerRow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1480,
                settings: {
                    slidesToShow: 4,
                    rows: 3,
                    slidesPerRow: 2,
                    infinite: true,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    rows: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    rows: 1,
                    slidesPerRow: 1,
                    infinite: true,
                    slidesToScroll: 1,
                }
            }
        ]
    };



    return (
        <>
            <Header2 />


            <div className='about-sec'>
                <Container>
                    <Row>
                        <Col md="12">
                            <div className='section-head'>
                                <h3 className='head-font tag-head'>Testimonials</h3>
                            </div>
                        </Col>

                        <Col md="12">
                            <ol className="breadcrumb">
                                <li><Link to="/">Home</Link></li>
                                <li className='section-head'>></li>
                                <li className='section-head'>Testimonials</li>
                            </ol>
                        </Col>
                    </Row>
                </Container>
            </div>
            <hr></hr>

            <div className='testimonial-sec'>
                <div>
                    <img
                        src={leftTwigSVG}
                        alt='..'
                        className='left-twig-svg'
                        style={{
                            position: 'relative',
                            zIndex: '0',
                            height: '5%',
                            width: '5%',

                        }}
                    />
                </div>
                <div >
                    <h2 className='head-font'>What Our Clients Say About </h2>
                    <h2 className='head-font'>KeepwellMantras</h2>
                </div>
            </div>
            <div className="testimonial-down container padd-bot">


                <Slider  {...settings} className='row '>
                    {

                        testimonials.map((e, i) => {
                            // console.log(i);
                            return (
                                <div key={e._id} className='p-3'>
                                    <TestimonialCardes
                                        testimonial={e.content_1}
                                        name={e.name}
                                        i={i}

                                    />

                                </div>

                            )
                        })
                    }
                </Slider>




            </div >
            <Footer />
        </>
    )

}
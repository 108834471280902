import React, { useEffect, useState } from "react";
import { Card, CardHeader, Table, Container, Button, Badge, Modal, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";

import Header from "../../components/Headers/Header.js";
import _fetch from '../../_fetch';
import Loader from "../../components/Loader/loader.js";

export default function Classes() {

    const [loading, setLoading] = useState(false);
    const [classes, setClasses] = useState([]);
    let { id } = useParams();
    const [selectedClass, setSelectedClass] = useState(false);

    let history = useHistory();

    useEffect(() => {

        async function init() {
            try {
                setLoading(true);

                let res = await _fetch(`${process.env.REACT_APP_API}/enrollment?id=${id}`);
                if (res.success) {
                    let r = res.response;
                    setClasses({ ...r, classes: r.classes ? r.classes.map(e => ({ ...e._id, watch_start: e.watch_start })) : [] });
                }

            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        }

        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) return (
        <>
            <Header />

            <Container className="mt--7" fluid>
                <Card className="bg-secondary shadow" style={{ height: 400 }}>
                    <Loader />
                </Card>
            </Container>
        </>
    );

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Card className="shadow">

                    <CardHeader className="border-0">
                        <h3 className="mb-0">{classes.category_id?.name}</h3>
                    </CardHeader>

                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Title</th>
                                <th scope="col">Summary</th>
                                <th scope="col">Completion</th>
                                <th scope="col">Expires</th>
                                <th scope="col" />
                            </tr>
                        </thead>
                        <tbody>
                            {
                                classes.classes?.map(e => (
                                    <tr>
                                        <th scope="row">
                                            {e.title}
                                        </th>
                                        <td>
                                            {e.description}
                                        </td>
                                        <td>
                                            {
                                                e.watch_start ?
                                                    moment().isAfter(moment(e.watch_start).add(1, 'd'))
                                                        ?
                                                        <Badge color="" className="badge-dot">
                                                            <i className="bg-success" />
                                                            Completed
                                                        </Badge>
                                                        :
                                                        <Badge color="" className="badge-dot mr-4">
                                                            <i className="bg-info" />
                                                            In progress
                                                        </Badge>
                                                    :
                                                    <Badge color="" className="badge-dot mr-4">
                                                        <i className="bg-danger" />
                                                        Not Started
                                                    </Badge>
                                            }
                                        </td>
                                        <td>
                                            {
                                                e.watch_start ?
                                                    moment(e.watch_start).add(1, 'd').format('llll')
                                                    :
                                                    moment(classes.valid_till).format('llll')
                                            }
                                        </td>
                                        <td>
                                            <Button
                                                type='button' color='success' className='mr-auto btn-sm'
                                                onClick={() => {
                                                    e.watch_start ?
                                                        history.push(`/workout/${e._id}`)
                                                        :
                                                        setSelectedClass(e._id)
                                                }}
                                                disabled={e.watch_start && moment().isAfter(moment(e.watch_start).add(1, 'd'))}
                                            >
                                                Watch Now
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Card>
                <Modal isOpen={!!selectedClass} toggle={() => setSelectedClass('')}>
                    <ModalBody>
                        <p className='font-weight-500'>
                            This class will be accessible only for next 24 hours once you start watching.<br />
                            <br />
                            Do you want to continue?
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='danger' onClick={() => setSelectedClass('')}>No</Button>
                        <Button color='primary' onClick={() => history.push(`/workout/${selectedClass}`)}>Yes</Button>
                    </ModalFooter>
                </Modal>

            </Container>
        </>
    );
};

import { useEffect, useState } from "react"
import AdminHeader from "../../../components/Headers/AdminHeader";
import _fetch from '../../../_fetch';
import Loader from "../../../components/Loader/loader.js";
import {
    Card, CardHeader, Table, Container, Modal, ModalHeader, ModalBody, Row, Col, FormGroup, Input, Button
} from "reactstrap";
import { toast } from "react-toastify";

const initialScreenData = {
    text: '',
    description: '',
    screen: '',
    video_link: ''
}
export default function AppScreenData() {
    const [loading, setLoading] = useState(false);
    const [addAppScreen, setAddAppScreen] = useState(false);
    const [appScreens, setAppScreens] = useState([]);
    const [screenData, setScreenData] = useState(initialScreenData);
    const [selectedScreen, setSelectedScreen] = useState(null);
    const [appScreenVideo, setAppScreenVideo] = useState(null);

    const init = async () => {
        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_API_V2}/app-screen`;
            let res = await _fetch(url);
            if (res.success) {
                setAppScreens(res.response);
            }
        } catch (err) {
            console.log(err);
            toast.error('Something went wrong');
        } finally {
            setLoading(false);
        }
    }

    const onChange = (e) => {
        setScreenData({
            ...screenData,
            [e.target.name]: e.target.value
        })
    }

    const upload = async (doc) => {
        let res = await _fetch(`${process.env.REACT_APP_API}/media/admin_upload_public?isImage=false`);
        const formData = new FormData();
        let ext = doc.name.split('.');
        if (ext.length > 1) ext = '.' + ext[ext.length - 1];
        else ext = '.mp4';
        let url = `kwm_web/AppScreenVideo-${new Date().valueOf()}${ext}`

        formData.append('key', url);
        formData.append('acl', 'public-read');
        formData.append('Content-Type', doc.type);

        for (let a in res.response) {
            formData.append(a, res.response[a]);
        }

        formData.append('file', doc);
        res = await fetch(`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}`, {
            method: 'POST',
            body: formData
        });
        if (res.status === 204) {
            return `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/${url}`;
        }
        else {
            throw Error('File upload failed');
        }
    }

    const handleAddScreen = async () => {
        if (!screenData.screen) {
            return toast.error('Screen is required');
        }
        if (!screenData.text) {
            return toast.error('Text is required');
        }
        if (!screenData.description) {
            return toast.error('Description is required');
        }
        if (!appScreenVideo) {
            return toast.error('Video is required');
        }
        try {
            setLoading(true);
            let video_link = await upload(appScreenVideo);
            screenData.video_link = video_link;
            let url = `${process.env.REACT_APP_API_V2}/app-screen`;
            let res = await _fetch(url, {
                method: 'POST',
                body: screenData
            });
            if (res.success) {
                toast.success('Screen added successfully');
                setScreenData(initialScreenData);
                setAppScreenVideo(null);
                setAddAppScreen(false);
                await init();
            }
        } catch (err) {
            console.log(err);
            toast.error('Something went wrong');
        } finally {
            setLoading(false);
        }
    }

    const handleScreenUpdate = async () => {
        if (!selectedScreen) return toast.error('Please select a screen');
        try {
            setLoading(true);
            if (appScreenVideo) {
                screenData.video_link = await upload(appScreenVideo);
            }
            let url = `${process.env.REACT_APP_API_V2}/app-screen`;
            let res = await _fetch(url, {
                method: 'POST',
                body: screenData
            });
            if (res.success) {
                toast.success('Screen updated successfully');
                setScreenData(initialScreenData);
                setAppScreenVideo(null);
                setSelectedScreen(null);
                await init();
            }
        } catch (err) {
            console.log(err);
            toast.error('Something went wrong');
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        init();
    }, []);

    if (loading) return (
        <>
            <AdminHeader />
            <Container className="mt--7" fluid>
                <Card className="bg-secondary shadow" style={{ height: 400 }}>
                    <Loader />
                </Card>
            </Container>
        </>
    );

    return (
        <>
            <AdminHeader />
            <Container className="mt--9" fluid>
                <Card className="shadow">
                    <CardHeader className="border-0">
                        <div className='d-flex justify-content-between'>
                            <h3 className="mb-0">
                                All Screens ({appScreens.length})
                            </h3>
                            <button
                                className="btn btn-primary"
                                disabled={appScreens.length >= 3}
                                onClick={() => {
                                    setAddAppScreen(true);
                                    setScreenData(initialScreenData);
                                }}
                            >Add App Screen</button>
                        </div>
                    </CardHeader>
                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Screen</th>
                                <th scope="col">Text</th>
                                <th scope="col">Description</th>
                                <th scope="col">Video</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(appScreens) && appScreens?.map((appScreenItem) => (
                                    <tr key={appScreenItem._id}>
                                        <td>
                                            {appScreenItem.screen}
                                        </td>
                                        <td>
                                            {appScreenItem.text}
                                        </td>
                                        <td>
                                            {appScreenItem.description}
                                        </td>
                                        <td>
                                            <a href={appScreenItem.video_link} target="_blank" rel="noreferrer">Video</a>
                                        </td>
                                        <td>
                                            <button className="btn btn-primary" onClick={() => {
                                                setSelectedScreen(appScreenItem._id);
                                                setScreenData(appScreenItem);
                                            }}>Edit</button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Card>
                <Modal
                    isOpen={addAppScreen}
                    toggle={() => {
                        setAddAppScreen(!addAppScreen);
                        setScreenData(initialScreenData);
                        setAppScreenVideo(null);
                    }}
                >
                    <ModalHeader>
                        <h4>Add Screen</h4>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col lg='12'>
                                <FormGroup>
                                    {
                                        appScreenVideo && (
                                            <video
                                                src={URL.createObjectURL(appScreenVideo)}
                                                controls
                                                style={{
                                                    width: '100%',
                                                    height: 300,
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        )
                                    }
                                    <label
                                        className="btn btn-primary w-100"
                                        htmlFor="screen-video"
                                    >
                                        Add Video
                                    </label>
                                    <input
                                        type="file"
                                        id="screen-video"
                                        accept="video/*"
                                        multiple={false}
                                        onChange={(e) => {
                                            if (e.target.files.length) {
                                                setAppScreenVideo((e.target.files[0]));
                                            }
                                        }}
                                        hidden
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg='6'>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="screen-number"
                                    >
                                        Screen Number
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name="screen"
                                        id="screen-number"
                                        type="select"
                                        required={true}
                                        onChange={onChange}
                                        value={screenData.screen}
                                        defaultValue=''
                                    >
                                        <option disabled value=''>
                                            Select Screen Number
                                        </option>
                                        <option
                                            value='screen1'
                                        >
                                            Screen 1
                                        </option>
                                        <option
                                            value='screen2'
                                        >
                                            Screen 2
                                        </option>
                                        <option
                                            value='screen3'
                                        >
                                            Screen 3
                                        </option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col lg='6'>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="screen-text"
                                    >
                                        Text
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name="text"
                                        id="screen-text"
                                        type="text"
                                        required={true}
                                        onChange={onChange}
                                        value={screenData.text}
                                    />   
                                </FormGroup>
                            </Col>
                            <Col lg='12'>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="screen-description"
                                    >
                                        Description
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name='description'
                                        id="screen-description"
                                        type="textarea" required={true}
                                        onChange={onChange}
                                        value={screenData.description}
                                        placeholder="Add Description"
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="12" className="d-flex mt-2">
                                <Button type="primary" onClick={handleAddScreen}>
                                    Add Screen
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={selectedScreen !== null}
                    toggle={() => {
                        setSelectedScreen(null);
                        setScreenData(initialScreenData);
                        setAppScreenVideo(null);
                    }}
                >
                    <ModalHeader>
                        <h4>{`Edit Screen: ${screenData.screen}`}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col lg='12'>
                                <FormGroup>
                                    {
                                        appScreenVideo && (
                                            <video
                                                src={URL.createObjectURL(appScreenVideo)}
                                                controls
                                                style={{
                                                    width: '100%',
                                                    height: 300,
                                                    objectFit: 'contain'
                                                }}
                                            />
                                        )
                                    }
                                    <label
                                        className="btn btn-primary w-100"
                                        htmlFor="screen-video"
                                    >
                                        Add Video
                                    </label>
                                    <input
                                        type="file"
                                        id="screen-video"
                                        accept="video/*"
                                        multiple={false}
                                        onChange={(e) => {
                                            if (e.target.files.length) {
                                                setAppScreenVideo((e.target.files[0]));
                                            }
                                        }}
                                        hidden
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg='12'>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="screen-text"
                                    >
                                        Text
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name="text"
                                        id="screen-text"
                                        type="text"
                                        required={true}
                                        onChange={onChange}
                                        value={screenData.text}
                                    />   
                                </FormGroup>
                            </Col>
                            <Col lg='12'>
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="screen-description"
                                    >
                                        Description
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        name='description'
                                        id="screen-description"
                                        type="textarea" required={true}
                                        onChange={onChange}
                                        value={screenData.description}
                                        placeholder="Add Description"
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="12" className="d-flex mt-2">
                                <Button type="primary" onClick={handleScreenUpdate}>
                                    Update Screen
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </Container>
        </>
    )
}
export const footer_slide_data = [
    {
        "name": "img_1",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/1.jpg`
    },
    {
        "name": "img_2",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/2.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/3.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/4.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/5.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/6.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/7.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/8.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/9.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/10.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/11.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/12.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/13.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/14.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/15.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/16.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/17.jpg`
    },
    {
        "name": "img_3",
        "banner": `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/18.jpg`
    },
];
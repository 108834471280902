import Header2 from '../../components/Headers/Header2';
import Footer from '../../components/Footers/AuthFooter';
import { Container, Row, Col } from 'reactstrap';
import { Link, useHistory } from "react-router-dom";
import './about.scss';
import './styles.scss';
import leftTwigSVG from '../../assets/subscription/flower-1.svg'
import rightTwigSVG from '../../assets/subscription/flower-2.svg'
import bannerSVG from '../../assets/home-page/svgs/banner.svg';
import specilitesIMG from '../../assets/home-page/images/specialities-img.png';
import promotiomIMG from '../../assets/home-page/images/promotion-img.png';

export default function About() {

    let history = useHistory();

    return (
        <>
            <Header2 />


            <div className='about-sec'>
                <Container>
                    <Row>
                        <Col md="12">
                            <div className='section-head'>
                                <h3 className='head-font'>About Us</h3>
                            </div>
                        </Col>
                        <Col md="12">
                            <ol className="breadcrumb">
                                <li><Link to="/">Home</Link></li>
                                <li className='section-head'>></li>
                                <li className='section-head'>About Us</li>
                            </ol>
                        </Col>
                    </Row>
                </Container>
            </div>
            <hr></hr>

            <div className="about-area pad90 start-about">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-5 col-md-6 col-sm-12">
                            <div
                                style={{
                                    width: '90%',
                                    height: '90%',
                                    position: 'absolute',
                                    top: '-35px',
                                    marginTop: '10',
                                    left: '0',
                                    backgroundColor: '#E4D0F4',
                                    borderRadius: '80px 0px 80px 0px',
                                    zIndex: '1',
                                }}
                                className='underlay'
                            />
                            <div>
                                <img
                                    src={leftTwigSVG}
                                    alt='..'
                                    className='left-twig-svg'
                                    style={{
                                        position: 'absolute',
                                        zIndex: '4',
                                        height: '25%',
                                        width: '25%',
                                        right: '-30px',
                                        top: '-50px'
                                    }}
                                />
                            </div>

                            <div className="about-opening">

                                <img
                                    src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/KWM-Prapti-Transportmation-1.jpeg`}
                                    alt=""
                                    style={{
                                        height: '90%',
                                        width: '90%',
                                        top: '0',
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        bottom: '0',
                                        right: '0',
                                        borderRadius: '80px 0px 80px 0px',
                                        zIndex: '2',
                                    }}
                                    className='overlay-img'
                                />


                            </div>



                        </div>



                        <div className="col-lg-6 col-md-6 col-sm-12 ml-5" >
                            <img
                                src={rightTwigSVG}
                                alt='..'
                                className='right-twig-svg'
                                style={{
                                    position: 'absolute',
                                    top: "0px",
                                    zIndex: '0',
                                    height: '15%',
                                    width: '15%'
                                }}
                            />

                            <div className="aboutus-box about-about">
                                <h2 className='head-font'>MY JOURNEY TO KEEPWELL MANTRAS</h2>
                                <div className="about-desc">

                                    <p className="">I am also a mom to two beautiful boys and understand how challenging it is to find time for ourselves and yet be the best parent ever! My wellness approach is very adaptable and sustainable, I simply focus on a consistent approach towards fitness and healthy living.</p>
                                    <p className="">Prior to me venturing into my most loved profession as a fitness trainer, I have worked for Forbes 500 MNCs for over 12 years. I am a CFA and post graduate with diploma in Insurance.</p>
                                    <h3 className="more-about section-head ">More about me</h3>

                                    <p className="">After my second son was born, I decided to pursue my passion and took a bold yet most rewarding decision of my life to dedicate all my efforts towards health and fitness of my community.</p>
                                    <p className="">Ever since, I have built a tribe of my clients, whom I lovingly call as my “Fitness Family”, they are my Fitness Stars. For them, I am not just a fitness trainer but a wellness coach and a mentor. I offer bespoke service in Fitness and Nutrition and leave no stone unturned to achieve my Fitfam’s fitness goals.</p>
                                </div>
                            </div>
                            <div>

                            </div>
                        </div>
                        <div className="aboutus-box about-about">
                            <div className="about-desc">
                                <p className="">Having lead global corporate wellness initiatives for over 5 years, I believe in leading my example. I empower my clients to lead their lives by their conscious efforts and with my guidance throughout the process. I work for you and with you in your fitness journey.</p>
                                <h3 className="able section-head ">What it takes to transform</h3><br />
                                <p className="">I have transformed my fitness twice after each kid and have lost of over 3 stones of body weight within an year post delivery. My 2 boys were born with Caesarean section and due to longer duration of bed rest, I was unable to resume my fitness level until after 3 months. There is a lot that changes in a women’s body post delivery and healing takes its time. Everyone’s recovery period is different but starting slow and progressing gradually is the best way to resume strength.</p>
                                <p className="">Staying positive and emotionally strong will make your fitness journey fun and memorable. Losing weight in a healthy manner takes time and perseverance. I have coached many new mums and supported them in this fitness journey. Keeping the right balance of nutrition and fitness training should be your ultimate goal.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="trainers-area ">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-center">
                                <div className="title-bar full-width mb20">
                                    <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/ttl-bar.png`} alt="title-img" />
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-2">
                            <div className="trainer-pro">
                                <div className="trainer-img">
                                    <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Prapti-second-1.jpg`} alt="trainer-img" width="255" height="250" />

                                </div>
                                <div className="trainer-bio">

                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="trainer-pro">
                                <div className="trainer-img">
                                    <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Prapti-second-2.jpg`} alt="trainer-img" width="255" height="250" />

                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="trainer-pro">
                                <div className="trainer-img">
                                    <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Prapti-second-3.jpg`} alt="trainer-img" width="255" height="250" />

                                </div>

                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="trainer-pro">
                                <div className="trainer-img">
                                    <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Prapti-second-4.jpg`} alt="trainer-img" width="255" height="250" />

                                </div>

                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="trainer-pro">
                                <div className="trainer-img">
                                    <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Prapti-second-5.jpg`} alt="trainer-img" width="255" height="250" />
                                </div>
                            </div>
                        </div>

                        <div className="col-md-2">
                            <div className="trainer-pro">
                                <div className="trainer-img">
                                    <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Prapti-second-6.jpg`} alt="trainer-img" width="255" height="250" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="about-area pad90 last-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-left">
                                <h3 className='head-font'>Size 16 to size 0 journey!</h3>
                                <p></p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <div className="aboutus-box">
                                <div className="about-desc">
                                    <p className="">I wouldn’t have done this all by myself, loads of acts come in play together when you transform.
                                        I would love to share my journey to all those new mums or mums who haven’t gone back to their pre pregnancy fitness or even
                                        those who want to get there once and for all ! And also to everyone who can resonate with me being a small business.</p>
                                    <p className="">Long post and so was my journey ! Apologies in advance.</p>
                                    <p className="">A CFA, Post grad in finance and Dip CII – yet I left my profession to pursue my real calling to be a fitness trainer!</p>
                                    <p className="">Jan 2019 – Blessed with my second boy with another C-section. It took 3 months to walk normal without the crouch and even 1 floor on stairs was a marathon.
                                        By March 2019, I had gained another 10 kgs with, struggled with post partum depression and was just losing it. Starting weight 76 kgs.</p>
                                    <p className="">In April 2019, My mum left for India, and this gave me an opportunity to do my own meals and get movement in my life.
                                        Started with 20 min walks and doing 15 min stairs when my son napped ..I was exhausted and my legs used to cry but I didn’t stop! It took me 3 months to get better with meal planning.
                                        Slowly increased my exercises with 45 min walk everyday + core stretches and body weight circuits to get my core strength back.
                                        I also started running but it instead exhausted me even more with my sleepless nights! Had to stop.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="about-opening">
                                <div>
                                    <img
                                        src={leftTwigSVG}
                                        alt='..'
                                        className='left-twig-svg'
                                        style={{
                                            position: 'absolute',
                                            zIndex: '4',
                                            height: '25%',
                                            width: '25%',
                                            right: '-30px',
                                            top: '-50px'
                                        }}
                                    />
                                </div>

                                <div className="about-opening">

                                    <img
                                        src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/KWM-Prapti-Transportmation-2.jpeg`}
                                        alt=""
                                        style={{
                                            height: '90%',
                                            width: '90%',
                                            top: '0',
                                            objectFit: 'cover',
                                            position: 'absolute',
                                            bottom: '0',
                                            right: '0',
                                            borderRadius: '80px 0px 80px 0px',
                                            zIndex: '2',
                                        }}
                                        className='overlay-img'
                                    />


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 about-desc">
                            <p className="">Finally by Aug 2019, I got confident with intensity,
                                used to do youtube videos but couldn’t get a discipline as got easily bored! Kept losing weight but had another 10 kgs to shift and that belly wasn’t going anywhere!</p>
                            <p className="">I resumed by work after 9 months but still stuck to my discipline to workout every night before I slept but this time I stopped doing online videos.
                                I still remember that day – I told myself today I will do 45min of only those exercises that I love – no more following workouts! And that was a game changer! No equipment .. my own variations.
                                I did better, enjoyed it, did more and yes lost 3 kgs that month! Weight finally 65 kgs by end of Sep !</p>
                            <p className="">Oct – Dec 2019 – Increased my exercises to an hour everyday and my 10,000 steps walk every single day! It started to become an addiction of getting sore from home workouts!
                                I took only 1 day rest to focus on flexibility training with yoga moves to enhance my stretches. By end of Dec I was back to my pre pregnancy weight! It was 9 months and 15 kgs gone!</p>
                            <p className="">Jan 2020! Here came the real transformation – I quit my contract job to dedicate my time fully on health &amp; fitness. Sounds crazy? Yes, I was .. that’s what many said.
                                But, like they say in hindi ‘sapno ko paane ke liye samajhdaar nai, paagal hona padta hai’..</p><p className="">Finally launched KeepWell Mantras in Feb 2020 and turned my dream into a reality.
                                    The set backs &amp; emotional turmoil and dilemma of career change took longer than anticipated though. What didn’t change was my inner fire to prove myself .. it was all or none for me! I had a lot at stake..
                                    I am sure many of you might resonate with me and know what it takes to take such huge leaps of faith in yourself. It is a struggle when no one else sees what you already believe in.</p>
                            <p className="">Well, I can go on and on.. but hey ! after transforming fitness of over 350 lives and having done over 1000 live workouts, I today feel damn proud of my own self!
                                I have never been more happier, fitter and stronger physically and mentally! I now have a huge community of fitness lovers and we rock our goals everyday!</p>
                            <p className="">To summarize my weight loss of over 20 kgs in 1 year:</p>
                            <ul className="about-down">
                                <li>— Workouts and diet should always be done side by side</li>
                                <li>— At home workouts or gym or PT – there is no difference, its the mindset! I never went to the gym and had no PT</li>
                                <li>— I followed a full balanced diet – no keto no shakes no supplements no pills – literally simple basic home food</li>
                                <li>— I was off processed food totally – yes no indulgences until I had shifted 15 kgs</li>
                                <li>— I didn’t throw away my pre pregnancy clothes – and didn’t buy any new clothes until I was where I wanted to be</li>
                                <li>— I always kept small goals for short period of time which wasn’t just weight loss</li>
                                <li>— Don’t be so focussed on weight loss, be focussed on becoming stronger</li>
                                <li>— I breastfed my little one until he was 2 years – it doesn’t interfere with your weight loss</li>
                                <li>— Consistency is everything !</li>
                                <li>— Don’t ever give up</li>
                                <ul>
                                </ul>
                            </ul>
                        </div>
                    </div>

                </div>

            </div>
            <div
                id='promotion-page'
                className='d-flex flex-column'
                style={{
                    backgroundImage: `url(${bannerSVG})`
                }}
            >
                <div
                    className='container promotion-container d-flex flex-column flex-grow-1'
                >
                    <Row
                        className='flex-grow-1'
                    >
                        <Col
                            xs={6}
                            lg={6}
                            className='d-flex flex-column justify-content-center'
                        >
                            <h2
                                className='section-heading text-left'
                            >
                                {`Escape the mundane and\nembrace the extraordinary!\nUnleash your Iron Core Strength with us !`}
                            </h2>
                            <button
                                className='book-now-btn mt-5'
                                onClick={() => {
                                    history.push('/packages')
                                }}
                            >
                                Book Now
                            </button>

                        </Col>
                        <Col
                            xs={6}
                            lg={6}
                            className='d-flex align-items-end justify-content-center'
                        >
                            <img
                                src={promotiomIMG}
                                alt='..'
                                className='promotion-img'
                            />

                        </Col>
                    </Row>
                </div>
            </div>

            <Footer />
        </>
    );
}
import { combineReducers } from "redux";
import appReducer from "./app";
import filterReducer from "./filters";
import cartReducer from "./openCart";
import userReducer from "./user";

export default combineReducers({
    user: userReducer,
    app: appReducer,
    filter: filterReducer,
    cart: cartReducer
});

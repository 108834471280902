import Slider from "react-slick";

export default function AboutCoachSlider({
    settings,
    images,
}) {
    return (
        <Slider {...{ ...settings, slidesToShow: 3 }}>
            {
                images.map(e => e.img ?
                    (
                        <div className='px-2'>
                            <div className="coach-img mx-2">
                                <img src={e.img} alt="girl-img" className='rounded slick-carousel-image'></img>
                            </div>
                            <div className='my-2 text-xs text-center px-1 slick-carousel-caption'>
                                {e.caption}
                            </div>
                        </div>
                    )
                    : null
                )
            }
        </Slider>
    );
}
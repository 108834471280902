import React, { useEffect, useState } from 'react';
import './styles.scss';
import Header2 from '../../components/Headers/Header2';
import Footer from '../../components/Footers/AuthFooter';
import { FormGroup, Form, Input, FormFeedback } from 'reactstrap';
import validator from 'validator';
import _fetch from '../../_fetch';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { Link } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import leftTwigSVG from '../../assets/subscription/flower-1.svg'
import rightTwigSVG from '../../assets/subscription/flower-2.svg'


const initState = {
	first_name: '',
	last_name: '',
	message: '',
	email: '',
	phone_number: ''
};

export default function Contact() {

	const [data, setData] = useState('');
	const [message, setMessage] = useState('');

	const [err, setErr] = useState(initState);
	const [sending, setSending] = useState(false);

	const [contact, setContact] = useState(false);

	function onChange(ev) {
		setData({ ...data, [ev.target.name]: ev.target.value });
	}

	function submit(event) {
		event.preventDefault();
		let err = false;

		setErr(initState);

		let obj = {}

		if (!validator.isMobilePhone(data.phone_number)) {
			obj.phone_number = "Please enter a valid mobile number";
			err = true;
		}

		if (!validator.isEmail(data.email)) {
			obj.email = "Please enter a valid email";
			err = true;
		}

		if (data.first_name.length < 3) {
			obj.first_name = "Please enter your name";
			err = true;
		}

		if (data.last_name.length < 3) {
			obj.last_name = "Please enter your name";
			err = true;
		}

		if (!data.message) {
			obj.message = "Please enter a message for us";
			err = true;
		}

		console.log(obj);

		if (err) {
			setErr(obj);
			return;
		}

		setSending(true);

		_fetch(`${process.env.REACT_APP_API}/user/support_ticket`, {
			method: "POST",
			body: {
				first_name: data.first_name,
				last_name: data.last_name,
				message: data.message,
				email: data.email,
				phone: data.phone_number
			}
		})
			.then(res => {
				toast.success('Thank you for your message. We will get back to you in 48 hours. If you want to get a quicker response to your query kindly message or call on +44 7584236848.', { delay: 10000 });
				setData(initState);
				setContact(false);
				setMessage('Thank you for your message. We will get back to you in 48 hours. If you want to get a quicker response to your query kindly message or call on +44 7584236848.');
			})
			.catch(err => {
				console.log(err);
			})
			.finally(() => {
				setSending(false);
			})

		console.log(data);
	}

	useEffect(() => {
		setErr(initState);
		setData(initState);
	}, [contact]);


	return (
		<>
			<Header2 />

			<div className='about-sec'>
				<Container>
					<Row>
						<Col md="12">
							<div className='section-head'>
								<h3 className='head-font tag-head'>Contact Us</h3>
							</div>
						</Col>

						<Col md="12">
							<ol className="breadcrumb">
								<li><Link to="/">Home</Link></li>
								<li className='section-head'>></li>
								<li className='section-head'>Contact Us</li>
							</ol>
						</Col>
					</Row>
				</Container>
			</div>
			<hr></hr>

			<div className='container'>
				<div className='row d-flex'>
					<div className="col-lg-5 col-md-5 col-sm-12">




						<div className="google-map about-opening">
							<iframe
								style={{
									height: '600px',
									width: '100%',
									top: '0',
									objectFit: 'cover',
									position: 'relative',
									bottom: '0',
									right: '0',
									borderRadius: '0px 80px 0px 80px',
									zIndex: '2',
								}}
								className=' overlay-img'
								src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.680857716172!2d0.0362551657697277!3d51.50072377963405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a8644b68d1c7%3A0xc11899c1e05d0a18!2sWards%20Wharf%20Approach%2C%20London%20E16%202EQ%2C%20UK!5e0!3m2!1sen!2sin!4v1632231450247!5m2!1sen!2sin" title="E16 2EQ" allowfullscreen="" loading="lazy"></iframe>
						</div>

						{/* <img
								src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Section-6-Img.jpg`}
								alt=""
								style={{
									height: '90%',
									width: '90%',
									top: '0',
									objectFit: 'cover',
									position: 'absolute',
									bottom: '0',
									right: '0',
									borderRadius: '80px 0px 80px 0px',
									zIndex: '2',
								}}
								className='overlay-img'
							/> */}

					</div>
					<div className='col-12 col-md-6 col-lg-7 mar-top'>
						<div className='content-class-new'>
							<img
								src={rightTwigSVG}
								alt='..'
								className='right-twig-svg'
								style={{
									position: 'relative',
									zIndex: '0',
									height: '15%',
									width: '15%',

								}}
							/>
						</div>
						<div className='content-class-new'>
							<h2 className='head-font-new pt-3' style={{ fontSize: "64px" }}>Get In Touch</h2>
						</div>
						<div className='row d-flex pt-3 content-class' style={{ justifyContent: 'space-between' }}>
							<div >
								<h4 className='pb-2'>Address</h4>
								<p>LONDON E26 2EQ,</p>
								<p>United Kingdom</p>
							</div>
							<div >
								<h4 className='pb-2'>Our Contact</h4>
								<p>Phone: 07584236848</p>
								<p>Email: prapti.dutt@keepwellmantras.com</p>
							</div>
						</div>
						<hr className='horizontal-class'></hr>
						<div className='content-class-new'>
							<div
								className="d-flex"
								style={{
									gap: '20px',
									marginTop: '10px'
								}}
							>
								<Link to={{ pathname: "https://www.facebook.com/KeepWellMantras/" }} target="_blank" rel="noreferrer"
									style={{
										backgroundColor: 'black',
										borderRadius: '100px',
										height: '30px',
										width: '30px',
									}}
									className="d-flex justify-content-center align-items-center"
								>
									<i className="lni lni-facebook-filled"></i>
								</Link>
								<Link to={{ pathname: "https://www.instagram.com/Keepwell_mantras" }} target="_blank" rel="noopener noreferrer"
									style={{
										backgroundColor: 'black',
										borderRadius: '100px',
										height: '30px',
										width: '30px',
									}}
									className="d-flex justify-content-center align-items-center"
								>
									<i className="lni lni-instagram-original"></i>
								</Link>
								<Link to={{ pathname: "https://www.youtube.com/channel/UCaNDYGA8MyjJuvD-NELWKRQ" }} target="_blank" rel="noopener noreferrer"
									style={{
										backgroundColor: 'black',
										borderRadius: '100px',
										height: '30px',
										width: '30px',
									}}
									className="d-flex justify-content-center align-items-center"
								>
									<i className="lni lni-youtube"></i>
								</Link>
							</div>
						</div>
					</div>




				</div>
			</div >

			<div className='testimonial-secss'>
				<div>
					<img
						src={leftTwigSVG}
						alt='..'
						className='left-twig-svg'
						style={{
							position: 'relative',
							zIndex: '0',
							height: '5%',
							width: '5%',

						}}
					/>
				</div>
				<div >
					<h2 className='head-font-new'>Send us a message</h2>
				</div>
			</div>
			<div className='pad-bo'>
				<Container>
					<Row>
						<Col className='form-mar'>
							<div className="contact-form mt20 form-border-set">
								<Form role="form" onSubmit={submit}>
									<Row>
										<Col md="6">
											<FormGroup>
												<Input
													placeholder="First Name"
													type="text" name='first_name'
													onChange={onChange} invalid={!!err.first_name}
													className='form-class input-text-style'

												/>
												{err.first_name && <FormFeedback>{err.first_name}</FormFeedback>}
											</FormGroup>
										</Col>
										<Col md="6">
											<FormGroup>
												<Input
													placeholder="Last Name"
													type="text" name='last_name'
													onChange={onChange} invalid={!!err.last_name}
													className='form-class input-text-style'
												/>
												{err.last_name && <FormFeedback>{err.last_name}</FormFeedback>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="12">
											<FormGroup>
												<Input
													placeholder="Email"
													type="email" name='email'
													onChange={onChange} invalid={!!err.email}
													className='form-class input-text-style'
												/>
												{err.email && <FormFeedback>{err.email}</FormFeedback>}
											</FormGroup>
										</Col>
										<Col md="12">
											<FormGroup>
												<Input
													placeholder="Phone Number"
													type="tel" name='phone_number'
													onChange={onChange} invalid={!!err.phone_number}
													className='form-class input-text-style'
												/>
												{err.phone_number && <FormFeedback>{err.phone_number}</FormFeedback>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="12">
											<FormGroup>
												<Input
													placeholder="Message"
													type="textarea" id="text-area" aria-rowcount={2} name='message'
													onChange={onChange} invalid={!!err.message}
													className='form-class input-text-style'
												/>
												{err.message && <FormFeedback>{err.message}</FormFeedback>}
											</FormGroup>
										</Col>
									</Row>
									{message ? <div className='text-primary my-3'>{message}</div> : ''}
									<Row>
										<Col md="4">
											<div className="bttn full-width ">
												<button className="btn active full-width btn-dark form-class btn-colors" onClick={submit}>
													{sending && <Spinner style={{ width: 20, height: 20 }} />} Send Message
												</button>
											</div>
										</Col>
									</Row>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</div>

			{/* <div className="contact-area pad90">
				<Container>
					<Row>
						<Col md="12">
							<div className="section-title text-left">
								<div className="title-bar full-width mb20">
									<img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/ttl-bar.png`} alt="title-img" />
								</div>
								<h3>LEAVE YOUR Message</h3>
								<p>GET IN TOUCH WITH OUR FITNESS TEAM</p>
							</div>
						</Col>
					</Row>
					<Row>
						<Col md="8">
							<div className="contact-form mt20">
								<Form role="form" onSubmit={submit}>
									<Row>
										<Col md="12">
											<FormGroup>
												<Input
													placeholder="First Name"
													type="text" name='first_name'
													onChange={onChange} invalid={!!err.first_name}
												/>
												{err.first_name && <FormFeedback>{err.first_name}</FormFeedback>}
											</FormGroup>
										</Col>
										<Col md="12">
											<FormGroup>
												<Input
													placeholder="Last Name"
													type="text" name='last_name'
													onChange={onChange} invalid={!!err.last_name}
												/>
												{err.last_name && <FormFeedback>{err.last_name}</FormFeedback>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="12">
											<FormGroup>
												<Input
													placeholder="Email"
													type="email" name='email'
													onChange={onChange} invalid={!!err.email}
												/>
												{err.email && <FormFeedback>{err.email}</FormFeedback>}
											</FormGroup>
										</Col>
										<Col md="12">
											<FormGroup>
												<Input
													placeholder="Phone Number"
													type="tel" name='phone_number'
													onChange={onChange} invalid={!!err.phone_number}
												/>
												{err.phone_number && <FormFeedback>{err.phone_number}</FormFeedback>}
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col md="12">
											<FormGroup>
												<Input
													placeholder="Message"
													type="textarea" id="text-area" aria-rowcount={2} name='message'
													onChange={onChange} invalid={!!err.message}
												/>
												{err.message && <FormFeedback>{err.message}</FormFeedback>}
											</FormGroup>
										</Col>
									</Row>
									{message ? <div className='text-primary my-3'>{message}</div> : ''}
									<Row>
										<Col md="12">
											<div className="bttn full-width">
												<button className="btn active full-width btn-primary" onClick={submit}>
													{sending && <Spinner style={{ width: 20, height: 20 }} />} Submit
												</button>
											</div>
										</Col>
									</Row>
								</Form>
							</div>
						</Col>

						<Col md="4">
							<div className="location mt20">
								<h4>Contact</h4>
								<div className="ctc-content">
									<i className="lni lni-mobile"></i>
									<p>Phone: 07584236848</p>
								</div>
								<div className="ctc-content">
									<i className="lni lni-map-marker"></i>
									<p>LONDON E16 2EQ,
										<br /> United Kingdom
									</p>
								</div>
								<div className="ctc-content">
									<i className="lni lni-envelope"></i>
									<p>prapti.dutt@keepwellmantras.com</p>
								</div>
								<div className="ctc-content">
									<i className="lni lni-whatsapp"></i>
									<p>
										<a rel="noreferrer" target="_blank" href='https://web.whatsapp.com/send?phone=447584236848&text=Hi%2C%20I%20am%20interested%20in%20knowing%20more%20and%20would%20like%20some%20information%2C%20I%20have%20been%20redirected%20from%20your%20website.'>

											+44 7584236848
										</a>
									</p>
								</div>
							</div>
							<hr />
							<div className="opening-time pt20">
								<h4>Opening Time</h4>
								<ul>
									<li>
										<p>Monday <span>06:00 AM - 07:00 PM</span></p>
									</li>
									<li>
										<p>Tuesday <span>06:00 AM - 07:00 PM</span></p>
									</li>
									<li>
										<p>Wednesday <span>06:00 AM - 07:00 PM</span></p>
									</li>
									<li>
										<p>Thursday <span>06:00 AM - 07:00 PM</span></p>
									</li>
									<li>
										<p>Friday <span>06:00 AM - 07:00 PM</span></p>
									</li>
									<li>
										<p>Saturday <span>08:00 AM - 01:00 PM</span></p>
									</li>
									<li>
										<p>Sunday <span>08:00 AM - 01:00 PM</span></p>
									</li>
								</ul>
							</div>
						</Col>
					</Row>
				</Container>
			</div> */}
			{/* 
			<div className="google-map">
				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.680857716172!2d0.0362551657697277!3d51.50072377963405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a8644b68d1c7%3A0xc11899c1e05d0a18!2sWards%20Wharf%20Approach%2C%20London%20E16%202EQ%2C%20UK!5e0!3m2!1sen!2sin!4v1632231450247!5m2!1sen!2sin" title="E16 2EQ" width="100%" height="450px" style={{ border: "0" }} allowfullscreen="" loading="lazy"></iframe>
			</div> */}
			<Footer />
		</>
	);
}
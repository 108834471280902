import { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import _fetch from '../../_fetch';
import { useSelector } from 'react-redux';
import './styles.scss';
import moment from 'moment';

export default function CSCalendar() {

    const user = useSelector((st) => st.user);
    const activityColors = {
        HIIT: '#c58bf2',
        MI: '#FF3D00',
        ST: '#FF2CA4',
    };
    const HIITDot = {
        key: 'HIIT',
        color: '#c58bf2',
        selectedDotColor: '#c58bf2',
    };
    const MIDot = {
        key: 'MI',
        color: '#FF3D00',
        selectedDotColor: '#FF3D00',
    };
    const STDot = {
        key: 'ST',
        color: '#FF2CA4',
        selectedDotColor: '#FF2CA4',
    };
    const [loading, setLoading] = useState(true);
    const [markedActivity, setMarkedActivity] = useState({});

    const getDayCategory = (categories) => {
        let hiit = 0;
        let mi = 0;
        let st = 0;
        for (let i = 0; i < categories.length; i += 1) {
            if (categories[i].toLowerCase().includes('high')) {
                hiit += 1;
            } else if (categories[i].toLowerCase().includes('medium')) {
                mi += 1;
            } else if (categories[i].toLowerCase().includes('strength')) {
                st += 1;
            }
        }
        const dots = [];
        if (hiit > 0) dots.push(HIITDot);
        if (mi > 0) dots.push(MIDot);
        if (st > 0) dots.push(STDot);
        return dots;
    };

    const activityDataAggregation = (activity) => {
        const dateMap = {};
        for (let i = 0; i < activity.length; i += 1) {

            if (activity[i].video_id && activity[i].open_time) {
                const open_time = new Date(activity[i].open_time);
                const dateString = open_time.toISOString().split('T')[0];
                if (dateString in dateMap) {
                    dateMap[dateString].push(activity[i].video_id.category.name);
                } else {
                    dateMap[dateString] = [activity[i].video_id.category.name];
                }
            }
        }
        const selectedActivity = {};
        Object.entries(dateMap).forEach(([key, value]) => {
            selectedActivity[key] = {
                dots: getDayCategory(value),
            };
        });
        setMarkedActivity(selectedActivity);
    };

    const init = async () => {
        try {
            setLoading(true);
            let res = await _fetch(`${process.env.REACT_APP_API_V2}/subscription-activity`, {
                method: 'GET',
            });
            if (res.success) {
                activityDataAggregation(res.response?.activity);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        init();
    }, [user?.currentSubscription]);

    return (
        <div className='calendar-style'>
            <div className='d-flex justify-content-between pt-4 pb-3 mx-3'>
                <div style={{
                    color: 'black'
                }}>
                    CoreSculpt Calendar
                </div>
            </div>
            <div className='mx-2' style={{
                width: '100%'
            }}>
                <Calendar
                    tileContent={({ date }) => {
                        const dateString = moment(date).format('YYYY-MM-DD');
                        return (
                            <div style={{
                                width: 'auto',
                                height: '7px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {markedActivity[dateString] && markedActivity[dateString].dots.map((dot, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            backgroundColor: dot.color,
                                            width: '7px',
                                            height: '7px',
                                            borderRadius: '50%',
                                            display: 'inline-block',
                                            marginRight: '2px'
                                        }}
                                    />
                                ))}
                            </div>
                        );
                    }}
                />
                <div
                    className='mx-3 pb-3'
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {
                        Object.keys(activityColors).map((key, index) => (
                            <div className='d-flex justify-content-center align-items-center mx-2'>
                                <div
                                    style={{
                                        width: 7,
                                        height: 7,
                                        borderRadius: 100,
                                        backgroundColor: activityColors[key],
                                        marginRight: 5,
                                    }}
                                />
                                <div
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 500,
                                        color: activityColors[key],
                                    }}
                                >
                                    {key}
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>


        </div>
    );
}

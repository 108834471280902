import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import Loader from "../../components/Loader/loader";
import _fetch from "../../_fetch";

export default function CouponCodes() {

    const [loading, setLoading] = useState(false);
    const [promos, setPromos] = useState([]);
    const [show, setShow] = useState(true);

    useEffect(() => {

        async function init() {
            try {
                setLoading(true);
                let res = await _fetch(`${process.env.REACT_APP_API}/promo_code/active`);
                if (res.success && Array.isArray(res.response)) {
                    setPromos(res.response);
                }
            } catch (err) {
                toast.error('Promo code doesn\'t exist or has expired');
            } finally {
                setLoading(false);
            }
        }

        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const colors = ['green', 'orange', 'blue', 'red', 'purple'];

    if (loading) return (
        <Card className="bg-secondary shadow mt-4" style={{ height: 400 }}>
            <Loader />
        </Card>
    );

    return (
        <Card className="bg-secondary shadow mt-4">
            <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                    <Col xs="10" lg="12">
                        <h2 className="mb-0 text-lg-center">Discount Codes And Offers</h2>
                    </Col>
                    <Col xs='2' className="d-lg-none">
                        {
                            show ?
                                <i className="lni lni-chevron-up-circle text-dark text-lg" onClick={() => setShow(!show)}></i>
                                :
                                <i className="lni lni-chevron-down-circle text-dark text-lg" onClick={() => setShow(!show)}></i>
                        }
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className={show ? 'd-block' : 'd-none'}>
                {
                    promos.map((e, i) => (
                        <Card className='my-2 p-3' key={e.name}>
                            <div className={`row border-1 border-${colors[i % 5]}`}>
                                <div className='col-5 col-sm-4'>
                                    <div className={`h-100 w-100 py-2 py-5 bg-${colors[i % 5]} text-center text-white text-lg font-weight-bolder`}>
                                        {e.name}
                                    </div>
                                </div>
                                <div className='col-7 col-sm-8'>
                                    <div className='py-3 text-xs text-lg-md'>
                                        {e.description}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    ))
                }
            </CardBody>
        </Card>
    );
};

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
    Card, CardHeader, CardFooter, Container, Button, Input, CardBody, FormGroup,
    Modal, ModalHeader, ModalBody, ModalFooter, Row, Col
} from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AvatarEditor from "react-avatar-editor";

import _fetch from '../../_fetch';
import Loader from "../../components/Loader/loader";
import { useSelector } from "react-redux";
import Header from "../../components/Headers/Header.js";
import './styles.scss';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function AddRecipe() {

    const { _id, first_name, last_name } = useSelector(st => st.user);
    const [title, setTitle] = useState('');
    const [recipe, setRecipe] = useState('');
    const [author, setAuthor] = useState({ first_name: first_name, last_name: last_name });
    const [photoUrl, setPhotoURL] = useState('');
    const [allowComments, setAllowComments] = useState(false);
    let recipe_id = useQuery().get('id');
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(null);

    const editorRef = useRef();

    let history = useHistory();

    const getRecipe = async () => {
        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_API}/recipe/${recipe_id}`;
            let res = await _fetch(url);
            if (res.success) {
                setTitle(res.response.title);
                setRecipe(res.response.recipe);
                setPhotoURL(res.response.photo_url);
                setAuthor(res.response.author);
                setAllowComments(res.response.allow_comments);
            }

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    const addRecipe = async (publish) => {
        try {

            let body = {
                publish,
                title,
                allow_comments: allowComments,
                recipe,
                photo_url: photoUrl,
                author: author
            };
            let url = `${process.env.REACT_APP_API}/recipe`;
            let method = 'POST';

            if (recipe_id) {
                url += `/${recipe_id}`
                method = 'PUT';
            }

            setLoading(true);
            let res = await _fetch(url, { method, body });
            if (res.success) {
                toast.success('recipe updated successfully');
                history.push('/my_recipes');
            } else {
                toast.error(res.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const onFileChange = (evt) => {

        const temp = evt.target.files;

        console.log(temp);

        // push raw photo/video file into arr1
        for (let i = 0; i < temp.length; i++) {
            const file = temp[i];
            console.log(file);
            if (file.type.startsWith('image/'))
                return setFile(file);
        }
    }

    const upload = useCallback(async () => {
        try {
            setUploading(true);
            let res = await _fetch(`${process.env.REACT_APP_API}/media/image_signed_url`);

            const formData = new FormData();

            let ext = file.name.split('.');
            if (ext.length > 1) ext = '.' + ext[ext.length - 1];
            else ext = '.png';

            let url = `${_id}/recipes/pic-${new Date().getMilliseconds()}${ext}`

            formData.append('key', url);
            formData.append('acl', 'public-read');
            formData.append('Content-Type', file.type);

            for (let a in res.response) {
                formData.append(a, res.response[a]);
            }

            editorRef.current.getImageScaledToCanvas().toBlob(async (Blob) => {
                formData.append('file', Blob);
                res = await fetch(`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}`, {
                    method: 'POST',
                    body: formData
                });

                if (res.status === 204) {
                    setPhotoURL(`/${url}`);
                    setFile(null);
                }
            });
        } catch (err) {
            console.log(err);
            toast.error('Some error occurred while uploading image');
        } finally {
            setUploading(false);
        }
    }, [_id, file])

    useEffect(() => {
        if (recipe_id) {
            getRecipe();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const modules = useMemo(() => ({
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['image'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': [] }]
        ]
    }), []);

    if (loading) return (
        <>
            <Header />

            <Container className="mt--7" fluid>
                <Card className="bg-secondary shadow" style={{ height: 400 }}>
                    <Loader />
                </Card>
            </Container>
        </>
    );

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Card className="shadow">

                    <CardHeader className="border-bottom d-flex flex-column flex-lg-row justify-content-between">
                        <h3 className="mb-0">
                            Add Recipe
                        </h3>
                        <Button onClick={() => history.push('/my_recipes')}>
                            Back
                        </Button>
                    </CardHeader>

                    <CardBody className="p-4">

                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="title"
                            >
                                Recipe Title
                            </label>
                            <Input
                                className="form-control-alternative"
                                defaultValue={title}
                                id="title"
                                placeholder="Recipe Tile"
                                type="text" required={false}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </FormGroup>

                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="title"
                            >
                                Recipe Author
                            </label>
                            <Row>
                                <Col xs={24} md={6} lg={4}>
                                    <Input
                                        className="form-control-alternative"
                                        defaultValue={author?.first_name}
                                        id="first_name"
                                        placeholder="First Name"
                                        type="text" required={false}
                                        onChange={(e) => setAuthor({ ...author, first_name: e.target.value })}
                                    />
                                </Col>
                                <Col xs={24} md={6} lg={4}>

                                    <Input
                                        className="form-control-alternative"
                                        defaultValue={author?.last_name}
                                        id="last_name"
                                        placeholder="Last Name"
                                        type="text" required={false}
                                        onChange={(e) => setAuthor({ ...author, last_name: e.target.value })}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <label className="form-control-label">
                                Recipe Pictures
                            </label>
                            <div className="d-flex flex-column flex-lg-row align-items-start">
                                <label
                                    className="btn btn-primary btn-lg"
                                    htmlFor="input-profile-picture"
                                >
                                    Select Recipe Pictures
                                </label>
                                <input
                                    id="input-profile-picture"
                                    type="file" hidden accept='image/*'
                                    multiple={false}
                                    onChange={(ev) => {
                                        onFileChange(ev);
                                    }}
                                />
                                <img
                                    src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}${photoUrl}`}
                                    alt='Recipe'
                                    className="d-block rounded mx-3 rounded"
                                    style={{ maxWidth: 400, height: 'auto' }}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <label
                                className="form-control-label"
                            >
                                Recipe
                            </label>
                            <ReactQuill
                                theme="snow" modules={modules}
                                defaultValue={recipe} onChange={(r) => { console.log(r); setRecipe(r) }}
                                style={{ height: 250, borderRadius: 10 }}
                            />
                        </FormGroup>

                        {/* <FormGroup check inline style={{ marginTop: 50 }}>
                            <Input
                                className="form-control-alternative"
                                name='isFirstQuestion'
                                id="isFirstQuestion"
                                type="checkbox"
                                onChange={(ev) => setAllowComments(ev.target.checked)}
                                defaultChecked={allowComments}
                            />
                            <Label check
                                className="form-control-label"
                                htmlFor="isFirstQuestion"
                            >
                                Allow Comments
                            </Label>
                        </FormGroup> */}

                    </CardBody>

                    <CardFooter className="py-4 d-flex justify-content-end">
                        <Button className="me-2" onClick={() => addRecipe(false)} >Save As Draft</Button>
                        <Button color='primary' onClick={() => addRecipe(true)}>Publish</Button>
                    </CardFooter>

                    {/* Modal for recipe pic upload */}
                    <Modal isOpen={!!(file)} toggle={() => setFile(null)} wrapClassName='w-75'>
                        <ModalHeader toggle={() => setFile(null)}>Upload Picture</ModalHeader>
                        <ModalBody>
                            <AvatarEditor
                                ref={editorRef}
                                image={file}
                                width={400}
                                height={300}
                                border={20}
                                color={[255, 255, 255, 0.6]} // RGBA
                                rotate={0}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={() => upload()} className='mr-2' disabled={uploading}>
                                {uploading ? <Loader size={20} /> : 'Upload'}
                            </Button>
                            <Button color="secondary" onClick={() => setFile(null)} disabled={uploading}>Cancel</Button>
                        </ModalFooter>
                    </Modal>

                </Card>

            </Container>
        </>
    );
};

import React from "react";
import moment from "moment";
import { AiOutlineCheck } from "react-icons/ai";
import {
    Card, CardTitle,
    CardImg, CardBody, CardText,

} from "reactstrap";
import './styles.scss';

export default function CSVideoCard({
    item, onPress, isCardBG = true, progress = 75, isWatched = false, isSuggestedClass = true, videoLastWatched = 0
}) {

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds - (hours * 3600)) / 60);
        const secs = Math.floor(seconds - (hours * 3600) - (minutes * 60));
        let formatted = "";
        if (hours > 0) {
            formatted += `${hours}h `;
        }
        if (minutes > 0) {
            formatted += `${minutes}m `;
        }
        if (secs > 0) {
            formatted += `${secs}sec`;
        }
        if (formatted === "") {
            formatted = "0sec";
        }
        return formatted;
    };

    if (!item) {
        return null;
    }


    return (
        <Card className="my-2 d-flex" style={{
            borderRadius: '24px',
            backgroundColor: 'white',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
            transition: 'box-shadow 0.3s ease-in-out',
            cursor: "pointer"
        }}
            onMouseOver={(e) => { e.currentTarget.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)' }}
            onMouseOut={(e) => { e.currentTarget.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)' }}
            onClick={onPress}
        >
            <div className="d-flex p-3">
                <CardImg
                    alt="Card image cap"
                    src={item.thumbnail}
                    style={{ width: '40%', borderRadius: '12px', objectFit: 'cover' }}
                    className="img-height-fixed card-imgs"
                />
                <CardBody style={{ width: '60%' }} className="mt-2">
                    {
                        item?.intensity && item?.intensity?.length > 0 && (
                            <div
                                style={{
                                    backgroundColor: '#192126',
                                    position: 'absolute',
                                    top: 0,
                                    right: 25,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    borderBottomRightRadius: 10,
                                    borderBottomLeftRadius: 10,
                                }}
                            >
                                <CardText
                                    className="mb-0"
                                    style={{
                                        textAlign: 'center',
                                        color: "white",
                                        fontWeight: 500
                                    }}
                                >
                                    {
                                        item.intensity[0]
                                    }
                                </CardText>
                            </div>
                        )
                    }

                    <CardTitle tag="h3" className="mb-0 text-dark">
                        {item.title}
                    </CardTitle>
                    {
                        isSuggestedClass ? (
                            item.duration_in_seconds > 0 ? (
                                <p
                                    style={{
                                        color: '#c58bf2',
                                        fontSize: 15,
                                        fontWeight: 300
                                    }}
                                >
                                    {
                                        formatTime(item.duration_in_seconds)
                                    }
                                </p>
                            ) : (
                                <p
                                    style={{
                                        color: '#959596',
                                        fontSize: 13,
                                        fontWeight: 200
                                    }}
                                >
                                    {
                                        item?.description?.length > 90 ? `${item.description.substring(0, 90)}...` : item.description
                                    }

                                </p>
                            )
                        ) : (
                            <p
                                style={{
                                    color: '#c58bf2',
                                    fontSize: 15,
                                    fontWeight: 300
                                }}                            >
                                {
                                    moment(videoLastWatched).format('DD MMM YYYY hh:mm A')
                                }
                            </p>
                        )
                    }
                    {isWatched && (
                        <AiOutlineCheck style={{ position: 'absolute', top: 35, right: 25, color: 'green', fontSize: 20 }} />
                    )}
                    {
                        !isSuggestedClass && (
                            <div style={{ marginTop: "3%" }}>
                                <div
                                    style={{
                                        width: '95%',
                                        height: 20,
                                        position: 'relative',
                                        backgroundColor: '#F2F2F2',
                                        borderRadius: 4,
                                        overflow: 'hidden',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: `${progress}%`,
                                            height: '100%',
                                            backgroundColor: '#c58bf2',
                                            borderRadius: 4,
                                            position: 'absolute',
                                            left: 0,
                                            top: 0,
                                            bottom: 0,
                                        }}
                                    />
                                    <p
                                        style={{
                                            marginLeft: "10%",
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            fontSize: '13px',
                                            fontWeight: '900',
                                            bottom: 0,
                                            marginBottom: '0 !important',
                                            transform: 'translateY(-20%)',
                                        }}
                                    >
                                        {progress}
                                        %
                                    </p>
                                </div>
                            </div>
                        )
                    }
                </CardBody>
            </div>
        </Card>
    )
}
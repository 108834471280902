import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, FormGroup, Collapse } from "reactstrap";
import { toast } from "react-toastify";
import update from 'immutability-helper';

export default function Questionnaire({
    questions,
    visible,
    setVisible
}) {

    const [, setCookie] = useCookies(['live_class_count', 'diet_package', 'workout_video_count']);

    useEffect(() => {
        if (Array.isArray(questions)) {
            let _question = questions.find(e => e.isFirstQuestion) ?? questions[0];
            setStack([{..._question, expanded: true}]);
        }
    }, [questions, visible]);

    const [stack, setStack] = useState([]);

    let history = useHistory();

    function next(ev, i) {

        let id = ev.target.value;

        let next_question;

        let option = stack[i].options?.find(e => e._id === id);

        if (!option) return;

        if (option.next) {
            next_question = questions.find(e => e._id === option.next);
        } else {
            if (Array.isArray(option.cookies)) {
                let diet;
                option.cookies.forEach(e => {
                    if (e.name === 'diet_package') {
                        diet = e.value;
                        return;
                    }
                    setCookie(e.name, e.value)
                });

                if (diet) {
                    toast.info('Please fill the form for nutrition support package');
                    setVisible(false);
                    history.push(`/diet-package?plan=${diet}`);
                } else {
                    setVisible(false);
                    toast.info('Your cart is ready for checkout');
                    history.push('/buy-classes');
                }
                return;
            }
        }
        setStack([...stack.slice(0, i + 1).map(e => ({...e, expanded: false})), { ...next_question, expanded: true }]);

    }

    return (
        <Modal isOpen={visible} toggle={() => setVisible(!visible)}>

            <ModalHeader toggle={() => setVisible(!visible)}>
                Select your 6 week schedule
            </ModalHeader>

            <ModalBody className="pt-0">
                {
                    stack.map((q, i) => (
                        <React.Fragment>
                            <p
                                className="d-flex font-weight-600 text-dark mt-4 pointer"
                                onClick={() => setStack(update(stack, { [i]: { expanded: { $set: !q.expanded } } }))}
                            >
                                Question {i + 1}
                                {
                                    q.expanded ?
                                        <i className="lni lni-chevron-up-circle text-lg text-dark ml-auto"></i>
                                        :
                                        <i className="lni lni-chevron-down-circle text-lg text-dark ml-auto"></i>
                                }
                            </p>
                            <Collapse isOpen={q.expanded}>
                                <p>{q.question}</p>
                                {
                                    q.options?.map(e => (
                                        <FormGroup check key={e._id}>
                                            <Input
                                                name="option"
                                                type="radio"
                                                value={e._id}
                                                onChange={(ev) => next(ev, i)}
                                                id={e._id}
                                            />
                                            {' '}
                                            <Label check htmlFor={e._id}>
                                                {e.label}
                                            </Label>
                                        </FormGroup>
                                    ))
                                }
                            </Collapse>
                        </React.Fragment>
                    ))
                }
            </ModalBody>

            <ModalFooter>
            </ModalFooter>

        </Modal>
    );
};

import React from "react";
import { Button, Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import moment from "moment";

const mid = process.env.REACT_APP_TAKEPAYMENTS_MID;
const currencycode = "826";
const transactiontype = "SALE";
const orderdesc = 'KeepWell Mantras';
const address1 = '';
const address2 = '';
const address3 = '';
const address4 = '';
const city = '';
const state = '';
const postcode = '';
const countrycode = '';
const hashmethod = 'SHA1';
const callbackurl = `${process.env.REACT_APP_HOME}/dashboard`;
const resultdeliverymethod = 'SERVER';
const serverresulturl = `${process.env.REACT_APP_API}/order/response`;
//let serverresulturl = `https://83c8-103-92-43-1.ngrok.io/api/order/response`;
const PaymentFormDisplaysResult = 'false';

export default function Payment({ order }) {
    const { first_name, last_name, email } = useSelector(st => st.user);
    const [, , removeCookie] = useCookies(['cart', 'live_class_count']);


    const product_details = {
        "LIVE": {
            "promo_code_message": "This promo code is applicable only on Live Classes"
        },
        "RECORDED": {
            "promo_code_message": "This promo code is applicable only on Workout Videos"
        },
        "60_MINS": {
            "promo_code_message": "This promo code is applicable only on 60 minute nutrition support consultation call",
            "name": "60 Minutes Nutrition Consultation Call",
            "price": process.env.REACT_APP_60_MIN_CALL_PRICE
        },
        "6_WEEKS": {
            "promo_code_message": "This promo code is applicable only on 6 week nutrition support plan",
            "name": "6 Weeks Nutrition Support Plan",
            "price": process.env.REACT_APP_6_WEEK_DIET_PRICE
        },
        "12_WEEKS": {
            "promo_code_message": "This promo code is applicable only on 12 week nutrition support plan",
            "name": "12 Weeks Nutrition Support Plan",
            "price": process.env.REACT_APP_12_WEEK_DIET_PRICE
        },
        "30_MINS_PT": {
            "promo_code_message": "This promo code is applicable only on 30 minutes personal training",
            "name": "30 Minutes Personal Training",
            "price": process.env.REACT_APP_30_MIN_PT_PRICE * order.personal_training.count
        },
        "45_MINS_PT": {
            "promo_code_message": "This promo code is applicable only on 45 minutes personal training",
            "name": "45 Minutes Personal Training",
            "price": process.env.REACT_APP_45_MIN_PT_PRICE * order.personal_training.count
        },

    };

    return (
        <Card className="bg-secondary shadow h-100">
            <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                    <Col xs="8">
                        <h3 className="mb-0">Order Summary</h3>
                    </Col>
                </Row>
            </CardHeader>

            <CardBody>

                <Row>
                    <Col xs={7} md={8} className='font-weight-600'>Product</Col>
                    <Col xs={5} md={4} className='font-weight-600'>Subtotal</Col>
                </Row>
                <hr className="my-4" />

                {
                    order.live_classes.count ?
                        <React.Fragment>
                            <Row>
                                <Col xs={7} md={8}>
                                    Live Classes&emsp;
                                    <span className='font-weight-600'>
                                        <i className='ni ni-fat-remove text-lg align-text-bottom'></i>&nbsp;
                                        {order.live_classes.count}
                                    </span>
                                </Col>
                                <Col xs={5} md={4} className='font-weight-600'>£{order.live_classes.count * order.live_classes.price_per_class}</Col>
                            </Row>
                            <hr className="my-4" />
                        </React.Fragment>
                        :
                        null
                }

                {
                    order.recorded_classes.count
                        ?
                        <React.Fragment>
                            <Row>
                                <Col xs={7} md={8}>
                                    Workout Videos
                                    <span className='font-weight-600'>
                                        <i className='ni ni-fat-remove text-lg align-text-bottom'></i>&nbsp;
                                        {order.recorded_classes.count}
                                    </span>
                                </Col>
                                <Col xs={5} md={4} className='font-weight-600'>£{order.recorded_classes.count * order.recorded_classes.price_per_class}</Col>
                            </Row>
                            <hr className="my-4" />
                        </React.Fragment>
                        :
                        null
                }

                {
                    order.diet_package.plan ?
                        <React.Fragment>
                            <Row>
                                <Col xs={7} md={8}>
                                    {product_details[order.diet_package.plan].name}
                                </Col>
                                <Col xs={5} md={4} className='font-weight-600'>£{product_details[order.diet_package.plan].price}</Col>
                            </Row>
                            <hr className="my-4" />
                        </React.Fragment>
                        :
                        null
                }

                {
                    order.personal_training.plan ?
                        <React.Fragment>
                            <Row>
                                <Col xs={7} md={8}>
                                    {product_details[order.personal_training.plan].name}
                                    <span className='font-weight-600'>
                                        <i className='ni ni-fat-remove text-lg align-text-bottom'></i>&nbsp;
                                        {order.personal_training.count}
                                    </span>
                                </Col>
                                <Col xs={5} md={4} className='font-weight-600'>£{product_details[order.personal_training.plan].price}</Col>
                            </Row>
                            <hr className="my-4" />
                        </React.Fragment>
                        :
                        null
                }

                {
                    order.promo_code
                        ?
                        <React.Fragment>
                            <Row>
                                <Col xs={7} md={8} className='pr-4'>
                                    <div>Promo Code: <span className='font-weight-600'>{order.promo_code.name}</span></div>
                                    <div className='text-xs text-info'>
                                        {product_details[order.promo_code.class_type]?.promo_code_message}
                                    </div>
                                </Col>
                                <Col xs={5} md={4} className='font-weight-600'>
                                    {order.promo_code.discount}%
                                </Col>
                            </Row>
                            <hr className="my-4" />
                        </React.Fragment>
                        :
                        null
                }

                <Row>
                    <Col xs={7} md={8} className='font-weight-600'>Total</Col>
                    <Col xs={5} md={4} className='font-weight-600'>£{order.invoice_total / 100}</Col>
                </Row>
                <hr className="my-4" />

            </CardBody>

            <CardBody>

                <Row>
                    <Col xs={{ size: 8, offset: 2 }}>

                        <form
                            method="POST"
                            onSubmit={() => {
                                removeCookie('cart');
                                removeCookie('live_class_count');
                                removeCookie('workout_video_count');
                                removeCookie('diet_package');
                                removeCookie('pt_package');
                                removeCookie('pt_count')
                            }}
                            action={process.env.REACT_APP_TAKEPAYMENT_URL}
                        >

                            <input type="hidden" name="MerchantID" value={mid} />
                            <input type="hidden" name="Amount" value={order.invoice_total} />
                            <input type="hidden" name="CurrencyCode" value={currencycode} />
                            <input type="hidden" name="OrderID" value={order.invoice_id} />
                            <input type="hidden" name="TransactionType" value={transactiontype} />
                            <input type="hidden" name="TransactionDateTime" value={moment(order.createdAt).utc().format('YYYY-MM-DD HH:mm:ss Z')} />
                            <input type="hidden" name="OrderDescription" value={orderdesc} />
                            <input type="hidden" name="CustomerName" value={`${first_name}${last_name ? ` ${last_name}` : ''}`} />
                            <input type="hidden" name="Address1" value={address1} />
                            <input type="hidden" name="Address2" value={address2} />
                            <input type="hidden" name="Address3" value={address3} />
                            <input type="hidden" name="Address4" value={address4} />
                            <input type="hidden" name="City" value={city} />
                            <input type="hidden" name="State" value={state} />
                            <input type="hidden" name="PostCode" value={postcode} />
                            <input type="hidden" name="CountryCode" value={countrycode} />
                            <input type="hidden" name="EmailAddress" value={email} />
                            <input type="hidden" name="HashMethod" value={hashmethod} />
                            <input type="hidden" name="CallbackURL" value={callbackurl} />
                            <input type="hidden" name="ResultDeliveryMethod" value={resultdeliverymethod} />
                            <input type="hidden" name="ServerResultURL" value={serverresulturl} />
                            <input type="hidden" name="PaymentFormDisplaysResult" value={PaymentFormDisplaysResult} />
                            <input type="hidden" name="HashDigest" value={order.pi} />

                            <Button type='submit' color='primary' className='w-100' >Complete Payment</Button>
                        </form>
                    </Col>
                </Row>
            </CardBody>

        </Card>
    );
};

import React from "react";
import { Card, CardHeader, CardBody, FormGroup, Form, Input, Row, Col } from "reactstrap";
import { useDispatch } from 'react-redux';

import { updateCartUserInfo } from '../../actions/cartActions';

export default function UserInfo({ order }) {

    let dispatch = useDispatch();

    function onChange(ev) {
        dispatch(updateCartUserInfo({ [ev.target.name]: ev.target.value }));
    }

    return (
        <Card className="shadow">
            <CardHeader className="border-bottom">
                <Row className="align-items-center">
                    <Col xs="8">
                        <h3 className="mb-0">Billing Details</h3>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className="px-4">
                <Form>
                    <h6 className="heading-small text-muted mb-4">
                        User information
                    </h6>
                    <div className="pl-lg-4">
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-first-name"
                                    >
                                        First name
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-first-name" name="first_name"
                                        placeholder="First name" disabled={order?._id}
                                        type="text" onChange={onChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-last-name"
                                    >
                                        Last name
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-last-name" disabled={order?._id}
                                        placeholder="Last name" name="last_name"
                                        type="text" onChange={onChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-email"
                                    >
                                        Email Address
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-email" disabled={order?._id}
                                        placeholder="Email Address" name="email"
                                        type="email" onChange={onChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col lg="6">
                                <FormGroup>
                                    <label
                                        className="form-control-label"
                                        htmlFor="input-mobile"
                                    >
                                        Mobile No.
                                    </label>
                                    <Input
                                        className="form-control-alternative"
                                        id="input-mobile" name="mobile"
                                        placeholder="Mobile No." disabled={order?._id}
                                        type="text" onChange={onChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>

                </Form>
            </CardBody>
        </Card>
    );
};
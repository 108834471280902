import { Row, Col, Card, CardBody, Modal, Button, ModalBody, Form, FormGroup, Input, Container } from 'reactstrap';
import React, { useEffect, useState, useRef, useCallback } from 'react'
import { toast } from "react-toastify";
import validator from "validator";
import Slider from "react-slick";
import { BsFillCheckCircleFill } from 'react-icons/bs';
import GoogleLogin from 'react-google-login';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './style.css'
import _fetch from '../../_fetch';
import App1SVG from '../../assets/subscription/app-1.png'
import App2SVG from '../../assets/subscription/app-2.png'
import App3SVG from '../../assets/subscription/app-3.png'
import Flower1SVG from '../../assets/subscription/flower-1.svg'
import Flower2SVG from '../../assets/subscription/flower-2.svg'
import Flower3SVG from '../../assets/subscription/flower-3.svg'
import Flower4SVG from '../../assets/subscription/flower-4.svg'
import { LoginAction } from '../../actions/userActions';
import { footer_slide_data } from '../../components/Footers/footer_slide_data';
import Loader from "../../components/Loader/loader";
import AboutCoachSlider from '../../components/Sliders/AboutCoachSlider';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export default function Subscription() {
    const dispatch = useDispatch();
    const user = useSelector(st => st.user);
    const history = useHistory();
    const packagesRef = useRef(null);
    const {
        isComingFromPackage
    } = history.location.state || {};

    let monthlyProducts = [
        {
            name: 'BASIC PACKAGE',
            classes: '15',
            oldPrice: '120',
            price: '90',
            discount: '25',
            features: [
                {
                    type: 'text',
                    text: '15 new workout videos every month',
                },
                {
                    type: 'text',
                    text: 'Access to all workout categories',
                },
                {
                    type: 'text',
                    text: 'Flexibility to choose any workout from a library of over 500 videos',
                },
                {
                    type: 'text',
                    text: 'Unlimited play time of repeat watch',
                },
                {
                    type: 'text',
                    text: 'New workout videos added every month',
                },
                {
                    type: 'text',
                    text: 'Activity calendar tracker',
                },
                {
                    type: 'text',
                    text: 'Measurement tracking',
                },
                {
                    type: 'text',
                    text: 'Reward points and consistency badges',
                },
                {
                    type: 'text',
                    text: 'Free video tips from Prapti & Habit Coaches',
                },
                {
                    type: 'text',
                    text: 'Monthly newsletters with tips and tricks',
                },
                {
                    type: 'text',
                    text: 'Customised nutrition plan with Prapti (add on)',
                },
                {
                    type: 'list',
                    text: 'Membership of active WhatsApp group',
                    items: [
                        '- Recipe ideas',
                        '- Extra challenges',
                        '- Group chat support',
                        '- Accountability partners'
                    ]
                },
            ]
        },
        {
            name: 'ROCKSTAR PACKAGE',
            classes: '20',
            oldPrice: '150',
            price: '112',
            discount: '25',
            features: [
                {
                    type: 'text',
                    text: '20 new workout videos every month',
                },
                {
                    type: 'text',
                    text: 'Access to all workout categories',
                },
                {
                    type: 'text',
                    text: 'Flexibility to choose any workout from a library of over 500 videos',
                },
                {
                    type: 'text',
                    text: 'Unlimited play time of repeat watch',
                },
                {
                    type: 'text',
                    text: 'New workout videos added every month',
                },
                {
                    type: 'text',
                    text: 'Activity calendar tracker',
                },
                {
                    type: 'text',
                    text: 'Measurement tracking',
                },
                {
                    type: 'text',
                    text: 'Reward points and consistency badges',
                },
                {
                    type: 'text',
                    text: 'Free video tips from Prapti & Habit Coaches',
                },
                {
                    type: 'text',
                    text: 'Monthly newsletters with tips and tricks',
                },
                {
                    type: 'text',
                    text: 'Customised nutrition plan with Prapti (add on)',
                },
                {
                    type: 'list',
                    text: 'Membership of active WhatsApp group',
                    items: [
                        '- Recipe ideas',
                        '- Extra challenges',
                        '- Group chat support',
                        '- Accountability partners'
                    ]
                },
            ]
        }
    ];

    if (!user || !user._id || user.isFreeTrialAvailable) {
        monthlyProducts = [
            {
                name: '3 Days Free Trial',
                classes: '15',
                oldPrice: '120',
                price: '0',
                discount: '100%',
                features: [
                    {
                        type: 'text',
                        text: 'Once free trial ends you will be moved to the Basic Monthly Package',
                    },
                    {
                        type: 'text',
                        text: 'Access to all workout categories',
                    },
                    {
                        type: 'text',
                        text: 'Flexibility to choose any workout from a library of over 500 videos',
                    },
                    {
                        type: 'text',
                        text: 'Unlimited play time of repeat watch',
                    },
                    {
                        type: 'text',
                        text: 'New workout videos added every month',
                    },
                    {
                        type: 'text',
                        text: 'Activity calendar tracker',
                    },
                    {
                        type: 'text',
                        text: 'Measurement tracking',
                    },
                    {
                        type: 'text',
                        text: 'Reward points and consistency badges',
                    },
                    {
                        type: 'text',
                        text: 'Free video tips from Prapti & Habit Coaches',
                    },
                    {
                        type: 'text',
                        text: 'Monthly newsletters with tips and tricks',
                    },
                    {
                        type: 'text',
                        text: 'Customised nutrition plan with Prapti (add on)',
                    },
                    {
                        type: 'list',
                        text: 'Membership of active WhatsApp group',
                        items: [
                            '- Recipe ideas',
                            '- Extra challenges',
                            '- Group chat support',
                            '- Accountability partners'
                        ]
                    },
                ]
            },
            ...monthlyProducts,
        ]
    }

    const quaterlyProducts = [
        {
            name: 'BASIC PACKAGE',
            classes: '45',
            oldPrice: '330',
            price: '247',
            discount: '25',
            features: [
                {
                    type: 'text',
                    text: '45 new workout videos every quarter',
                },
                {
                    type: 'text',
                    text: 'Access to all workout categories',
                },
                {
                    type: 'text',
                    text: 'Flexibility to choose any workout from a library of over 500 videos',
                },
                {
                    type: 'text',
                    text: 'Unlimited play time of repeat watch',
                },
                {
                    type: 'text',
                    text: 'New workout videos added every month',
                },
                {
                    type: 'text',
                    text: 'Activity calendar tracker',
                },
                {
                    type: 'text',
                    text: 'Measurement tracking',
                },
                {
                    type: 'text',
                    text: 'Reward points and consistency badges',
                },
                {
                    type: 'text',
                    text: 'Free video tips from Prapti & Habit Coaches',
                },
                {
                    type: 'text',
                    text: 'Monthly newsletters with tips and tricks',
                },
                {
                    type: 'text',
                    text: 'Customised nutrition plan with Prapti (add on)',
                },
                {
                    type: 'list',
                    text: 'Membership of active WhatsApp group',
                    items: [
                        '- Recipe ideas',
                        '- Extra challenges',
                        '- Group chat support',
                        '- Accountability partners'
                    ]
                },
            ]
        },
        {
            name: 'ROCKSTAR PACKAGE',
            classes: '60',
            oldPrice: '420',
            price: '315',
            discount: '25',
            features: [
                {
                    type: 'text',
                    text: '60 new workout videos every quarter',
                },
                {
                    type: 'text',
                    text: 'Access to all workout categories',
                },
                {
                    type: 'text',
                    text: 'Flexibility to choose any workout from a library of over 500 videos',
                },
                {
                    type: 'text',
                    text: 'Unlimited play time of repeat watch',
                },
                {
                    type: 'text',
                    text: 'New workout videos added every month',
                },
                {
                    type: 'text',
                    text: 'Activity calendar tracker',
                },
                {
                    type: 'text',
                    text: 'Measurement tracking',
                },
                {
                    type: 'text',
                    text: 'Reward points and consistency badges',
                },
                {
                    type: 'text',
                    text: 'Free video tips from Prapti & Habit Coaches',
                },
                {
                    type: 'text',
                    text: 'Monthly newsletters with tips and tricks',
                },
                {
                    type: 'text',
                    text: 'Customised nutrition plan with Prapti (add on)',
                },
                {
                    type: 'list',
                    text: 'Membership of active WhatsApp group',
                    items: [
                        '- Recipe ideas',
                        '- Extra challenges',
                        '- Group chat support',
                        '- Accountability partners'
                    ]
                },
            ]
        }
    ];

    const section2Features = [
        'Medium Intensity - suitable for beginners to intermediate fitness level',
        'High/Ultra High Intensity - suitable for intermediate to advance fitness level',
        'Strength Training - suitable to all fitness levels (a pair of dumbbells are a must)',
    ];

    const section3Features = [
        'Customised Nutrition support',
        'One on One Coaching support',
        'Personalised Workout schedule',
        'Easy-to-follow workouts',
        ' Access to a library of over 500 unique workouts',
        'Unlimited play time of workout videos',
        'Pin your favourite workouts ',
        'Stream workouts to your TV',
        'Track your Activity and Progress',
        'Access to Habit coaching',
        'Community support from members (WhatsApp Groups)',
        'Free tips and motivation videos by Prapti',
        'Download workouts for offline access (coming soon)',
        'Earn points and bonus rewards for consistency',
    ];

    var settings = {
        infinite: true,
        speed: 500,
        focusOnSelect: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,

                }
            },
            {
                breakpoint: 600,
                settings: {
                    infinite: true,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const award_pics = [
        {
            img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award1.jpg`,
            caption: 'Outstanding Achievements in Business 2023 – Awarded by Honorable MP Bob Blackman, UK Parliament in collaboration with One Elements Organization'
        },
        {
            img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award.jpg`,
            caption: "'Remarkable Mentor’ by SheInspires Awards – May 2022 & 2023, awarded twice consecutively at UK Parliament by Honorable MP Bob Blackman"
        },
        {
            img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award2.jpeg`,
            caption: "Community Service award – Nov 22, At South Asian Women Leader Conference awarded by OneVision charity in the presence of Honorable MP Dean Russel, President of SAHA & Chief Inspector Police"
        },
        {
            img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award4.jpg`,
            caption: 'Business Community Leader – June 23 by EEL Professional Association for mentoring & supporting fellow small businesses.'
        },
        {
            img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award3.jpeg`,
            caption: 'British Fitness Awards – On the shortlist of top 25 Fitness Trainers of UK and KeepWell Mantras on the shortlist of Top 10 Sports groups of UK among circa 23000+ nominees'
        },
        {
            img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award6.jpeg`,
            caption: 'Mrs. & Ms. Asia Great Britain - Fitness mentor & Community engagement Lead for Beauty pageants'
        },
        {
            img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award5.jpeg`,
            caption: 'Frames of Mind, Newham Council – Regular volunteering for movement sessions for the Elderly'
        },
        {
            img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award7.jpeg`,
            caption: 'PerksAtWork –  One of the chosen Health & Wellness partners of a leading Corporate Employee Benefits company NextJump'
        },
        {
            img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award8.jpeg`,
            caption: 'SME British Made Awards 2023 - Best Fitness Entrepreneur'
        },
        {
            img: `${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/award9.jpeg`,
            caption: 'Health & Wellbeing 2024 - UK Parliament'
        }
    ];

    const [collapseOpen, setCollapseOpen] = useState(false);
    const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
    const [isInterestedModalOpen, setIsInterestedModalOpen] = useState(false);
    const [interestedUser, setInterestedUser] = useState({
        name: '',
        email: '',
        mobileNo: '',
        interestedFor: 'Core Sculpt Subscription',
    });
    const [loading, setLoading] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [_error, setError] = useState('');
    const [subscription, setSubscription] = useState(false);
    const location = useLocation();

    const pack_ref = useRef();


    const handleSubscribeNow = () => {
        // setIsInterestedModalOpen(true);
        // return;
        if (!user?._id) {
            setIsAccountModalOpen(true);
        } else {
            const url = `/subscription/create/${user._id}`
            history.push(url);
        }
    }

    function googleLogin(response) {
        if (response.accessToken)
            setLoading(true);
        _fetch(process.env.REACT_APP_API + '/user/google', { method: "POST", body: { id_token: response.tokenObj.id_token } })
            .then(res => {
                if (res.success) {
                    dispatch(LoginAction(res.response));
                    history.push(`/subscription/create/${res.response._id}`);
                } else {
                    toast.error(res.response);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error('Some error occurred please try again later.')
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function googleLoginError(error) {
        if (error.error === 'idpiframe_initialization_failed') return;
        toast.error('Some error occurred please try again later');
    }

    function login(ev) {
        ev.preventDefault();

        if (!email || !validator.isEmail(email) || !password) return toast.error('Please enter valid email id');

        setLoading(true);

        _fetch(process.env.REACT_APP_API + '/user/login', { method: "POST", body: { email, password } })
            .then(res => {
                if (res.success) {
                    if (res.response.verified) {
                        dispatch(LoginAction(res.response));
                        history.push(`/subscription/create/${res.response._id}`);
                    } else {
                        setOtpSent(true);
                        toast.success('OTP sent to your email id.');
                    }
                } else {
                    toast.error(res.response);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error('Some error occurred please try again later.')
            })
            .finally(() => {
                setLoading(false);
            });

        return false;
    }

    function register(ev) {
        ev.preventDefault();

        if (!email || !validator.isEmail(email)) return toast.error('Please enter a valid email id');
        else if (!password || !validator.isStrongPassword(password)) return toast.error('Password must be at least 8 character long, and must have at least 1 lower case letter, 1 upper case letter, 1 number and 1 special symbol.');

        setLoading(true);

        _fetch(process.env.REACT_APP_API + '/user', { method: 'POST', body: { first_name, last_name, email, password } })
            .then(res => {
                if (res.success) {
                    setOtpSent(true);
                    toast.success(res.response.message);
                } else {
                    toast.error(res.response);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error('Some error occurred please try again later.')
            })
            .finally(() => {
                setLoading(false);
            });

        return false;
    }

    async function verifyOTP(ev) {
        ev.preventDefault();
        if (!otp || otp.length !== 6) return toast.error('Please enter valid OTP');
        try {
            setLoading(true);
            let res = await _fetch(process.env.REACT_APP_API + '/user/verify-otp', { method: 'POST', body: { email, otp } });
            if (res.success) {
                toast.success('OTP verified successfully');
                dispatch(LoginAction(res.response));
                history.push(`/subscription/create/${res.response._id}`);
            } else {
                toast.error(res.response);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    async function addInterestedUser(ev) {
        ev.preventDefault();
        try {
            setLoading(true);
            if (!interestedUser.name) setError('Please enter your name');
            else if (!interestedUser.email || !validator.isEmail(interestedUser.email)) setError('Please enter valid email id');
            else if (interestedUser.mobileNo && !validator.isMobilePhone(interestedUser.mobileNo)) setError('Please enter valid mobile number');
            else {
                let res = await _fetch(process.env.REACT_APP_API_V2 + '/interested-user', { method: 'POST', body: interestedUser });
                if (res.success) {
                    toast.success('Thank you for showing interest. We will get back to you soon.');
                    setInterestedUser({
                        name: '',
                        email: '',
                        mobileNo: '',
                        interestedFor: 'Core Sculpt Subscription',
                    });
                    setError('');
                    setIsInterestedModalOpen(false);
                } else {
                    setError(res.response);
                }
            }
        } catch (error) {
            console.log(error);
            setError('Some error occurred please try again later.');
        } finally {
            setLoading(false);
        }
    }

    const toggleCollapse = () => {
        setCollapseOpen((data) => !data);
    };

    useEffect(() => {
        if (isComingFromPackage) {
            packagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else {
            window.scrollTo(0, 0);
        }
    }, []);

    function _focus(ref) {
        if (ref?.current?.offsetTop) {
            window.scrollTo({
                left: 0,
                top: ref.current.offsetTop + 600,
                behavior: 'smooth'
            });
            console.log(ref.current.offsetTop)
        }
    }

    const _scroll = useCallback((ref) => {
        setTimeout(() => {
            console.log(ref?.current)
            _focus(ref);
        }, 500);
    }, []);

    useEffect(() => {
        if (location.hash) {
            if (location.hash.slice(1) === 'packages') {
                _scroll(pack_ref);
            }
        }
    }, [location.hash, _scroll]);

    return (
        <div
            className="subscription-page mobile-app-landing-page"
        >
            <header className='px-2 px-lg-5 py-2'>
                <div className='d-flex align-items-center justify-content-between'>
                    <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Core+Sculpt+Logo+Final.png`} alt=""
                        style={{
                            width: '150px',
                            objectFit: 'contain',
                            marginTop: '1%',
                        }}
                        className='logo-img'
                    />
                    <div className='d-none d-lg-flex align-items-center justify-content-end'>
                        <Link to='/home' className='menu-link mx-2'>Home</Link>
                        <Link to='/about' className='menu-link mx-2'>About</Link>
                        <Link to='/testimonials' className='menu-link mx-2'>Testimonials</Link>
                        <Link to='/recipes' className='menu-link mx-2'>Recipes</Link>
                        <Link to='/packages' className='menu-link mx-2'>Packages</Link>
                        <Link to='/classes' className='menu-link mx-2'>Class Schedule</Link>
                        <Link to='/client-transformation' className='menu-link mx-2'>Client Transformation</Link>
                        <Link to='/contact' className='menu-link mx-2'>Contact Us</Link>
                        <a href='#' onClick={handleSubscribeNow} className='join-now my-3'>
                            Join Now
                        </a>
                    </div>

                    <button
                        className="navbar-toggler d-lg-none d-block"
                        type="button"
                        onClick={toggleCollapse}
                    >
                        <i className="lni lni-menu"></i>
                    </button>

                </div>

                <div className={'d-flex d-lg-none mobile-menu-container ' + (collapseOpen ? 'mobile-visible' : '')}>
                    <div className='filler' onClick={toggleCollapse}></div>
                    <div className='mobile-menu'>
                        <div className='d-flex flex-column'>

                            <i className='lni lni-close close-icon' onClick={toggleCollapse} ></i>

                            <Link to='/home' className='menu-link mt-4 mb-3'>Home</Link>
                            <Link to='/about' className='menu-link my-3'>About</Link>
                            <Link to='/testimonials' className='menu-link my-3'>Testimonials</Link>
                            <Link to='/recipes' className='menu-link my-3'>Recipes</Link>
                            <Link to='/packages' className='menu-link my-3'>Packages</Link>
                            <Link to='/classes' className='menu-link my-3'>Class Schedule</Link>
                            <Link to='/client-transformation' className='menu-link my-3'>Client Transformation</Link>
                            <Link to='/contact' className='menu-link my-3'>Contact Us</Link>
                            <a href='#' onClick={handleSubscribeNow} className='join-now my-3'>
                                Join Now
                            </a>
                        </div>
                    </div>
                </div>

            </header>
            <div
                className="subscription-feature-section section-1 d-flex flex-column align-items-center justify-content-between"
                style={{
                    backgroundColor: '#ffffff',
                    position: 'relative',
                }}
            >
                <div
                    style={{
                        padding: '3%',
                        zIndex: '2',
                        flex: '1',
                    }}
                    className='content-container d-flex flex-column justify-content-around align-items-center'
                >
                    <h1
                        style={{
                            fontWeight: '500',
                            fontSize: '40px',
                            color: '#292929',
                            fontFamily: 'Forum, cursive',
                            textAlign: 'center',
                        }}
                    >Making wellness an unfaltering habit</h1>
                    <h3
                        style={{
                            fontWeight: '600',
                            fontSize: '20px',
                            color: '#00adef',
                            textAlign: 'center',
                        }}
                    >
                        Bringing you the CoreSculpt App by Prapti Dutt
                    </h3>
                    <p
                        style={{
                            fontSize: '13px',
                            color: '#000000',
                            fontWeight: '400',
                            textAlign: 'center',
                        }}
                        className='content-description'
                    >
                        My program is designed with a mix of Bodyweight training, Calisthenics, Pilates, Resistance Training, Cardio & Yoga.  Your core is the Operating engine of your Body,
                        the Ultimate power machine! That is why I have meticulously designed my program with a key focus to strengthen the core muscles, with stellar attention to every single muscle group.
                    </p>
                    <div
                        className='d-flex flex-column flex-md-row justify-content-center align-items-center'
                    >
                        <button
                            style={{
                                color: 'white',
                                fontWeight: 'bold',
                                backgroundColor: '#212529',
                                border: 'none',
                                borderRadius: '0px 15px 0px 15px',
                                padding: '10px 20px',
                                margin: '0px 10px',
                                width: '200px',
                                height: '100%'
                            }}
                            className='mt-1 subscription-button'
                            onClick={() => _scroll(pack_ref)}
                        >
                            Subscribe Now
                        </button>
                        <button
                            style={{
                                color: 'white',
                                fontWeight: 'bold',
                                backgroundColor: '#212529',
                                border: 'none',
                                borderRadius: '0px 15px 0px 15px',
                                padding: '10px 20px',
                                margin: '0px 10px',
                                width: '200px',
                                height: '100%'
                            }}
                            className='mt-1 subscription-button'
                            onClick={() => {
                                window.open('https://play.google.com/store/apps/details?id=com.keepwellmantras', '_blank');
                            }}
                        >
                            Download Android App
                        </button>
                        <button
                            style={{
                                color: 'white',
                                fontWeight: 'bold',
                                backgroundColor: '#212529',
                                border: 'none',
                                borderRadius: '0px 15px 0px 15px',
                                padding: '10px 20px',
                                margin: '0px 10px',
                                width: '200px',
                                height: '100%'
                            }}
                            className='mt-1 subscription-button'
                            onClick={() => {
                                window.open('https://apps.apple.com/us/app/coresculpt/id6450386191', '_blank');
                            }}
                        >
                            Download iOS App
                        </button>
                    </div>
                    <div
                        className='img-container'
                        style={{
                            width: '85%',
                            height: '30%',
                            margin: '15px',
                            position: 'relative',
                        }}
                    >
                        <div
                            className='img-background-1'
                            style={{
                                position: 'absolute',
                                bottom: '0',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                width: '80%',
                                height: '75%',
                                backgroundColor: '#FEF0E3',
                                borderRadius: '60px 0px 60px 0px',
                                maxHeight: '300px',
                            }}
                        />
                        <img
                            src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/SubscriptionLandingRBG-2.png`}
                            alt=""
                            style={{
                                width: '100%',
                                position: 'absolute',
                                bottom: '0',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                objectFit: 'contain',
                                maxHeight: '100%',
                            }}
                        />
                    </div>
                    <div
                        style={{
                            width: '80%',
                            height: '250px',
                            position: 'relative',
                        }}
                        className='d-md-none'
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '80%',
                                backgroundColor: '#e0e0f9',
                                position: 'absolute',
                                bottom: '0',
                                borderRadius: '0px 50px 0px 50px',
                                zIndex: '1',
                            }}
                            className='img-background-2'
                        />
                        <img
                            src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/SubscriptionLandingRBG-1.png`}
                            alt=""
                            style={{
                                height: '100%',
                                position: 'absolute',
                                bottom: '0',
                                zIndex: '2',
                                left: '50%',
                                transform: 'translateX(-50%)',
                            }}
                        />
                    </div>
                </div>
                <img
                    src={App3SVG}
                    alt=""
                    style={{
                        position: 'absolute',
                        right: '0',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        objectFit: 'contain',
                        zIndex: '1',
                        width: '15%',
                        marginRight: '1%',
                    }}
                    className='app-3-svg'
                />
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        height: '80%',
                        width: '20%',
                        transform: 'translateY(-50%)',
                        zIndex: '1',
                        left: '0',
                        marginLeft: '1%',
                    }}
                    className='d-flex flex-column justify-content-around flower-img-container'
                >
                    <img
                        src={Flower1SVG}
                        alt=""
                        style={{
                            width: '40%',
                            objectFit: 'contain',
                            marginLeft: '55%'
                        }}
                        className='flower-1-svg'
                    />
                    <div
                        style={{
                            width: '100%',
                            height: '40%',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '80%',
                                backgroundColor: '#e0e0f9',
                                position: 'absolute',
                                bottom: '0',
                                borderRadius: '0px 50px 0px 50px',
                                zIndex: '1',
                            }}
                            className='img-background-2'
                        />
                        <img
                            src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/SubscriptionLandingRBG-1.png`}
                            alt=""
                            style={{
                                height: '100%',
                                position: 'absolute',
                                bottom: '0',
                                zIndex: '2',
                                left: '50%',
                                transform: 'translateX(-50%)',
                            }}
                        />
                    </div>
                    <img
                        src={Flower2SVG}
                        alt=""
                        style={{
                            width: '40%',
                            objectFit: 'contain',
                        }}
                        className='flower-1-svg'
                    />
                </div>
            </div>
            <div
                className="subscription-feature-section section-2"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Row
                    className='w-100 features'
                    style={{
                        padding: '0% 15%',
                        position: 'relative',
                        flex: '1',
                        overflow: 'hidden',
                        margin: '0',
                    }}
                    xs='1'
                    md='2'
                >
                    <Col
                        className='h-100 d-flex justify-content-end app-img-container'
                        style={{
                            order: '1',
                        }}
                    >
                        <div
                            style={{
                                height: '100%',
                                width: '100%',
                                position: 'relative',
                            }}
                            className='img-container'
                        >
                            <div
                                className='img-background'
                                style={{
                                    position: 'absolute',
                                    height: '90%',
                                    width: '90%',
                                    left: '0',
                                    top: '0',
                                    backgroundColor: '#E4D0F4',
                                    borderRadius: '40px 0px 40px 0px',
                                    zIndex: '1',
                                }}
                            />
                            <img
                                className='actual-img'
                                src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Section-2-Img.jpg`}
                                alt=""
                                style={{
                                    height: '90%',
                                    width: '90%',
                                    objectFit: 'cover',
                                    position: 'absolute',
                                    right: '0',
                                    bottom: '0',
                                    borderRadius: '40px 0px 40px 0px',
                                    zIndex: '2',
                                }}
                            />
                            <img
                                src={Flower3SVG}
                                alt=""
                                style={{
                                    width: '20%',
                                    position: 'absolute',
                                    top: '15%',
                                    right: '0',
                                    objectFit: 'contain',
                                    transform: 'translateX(50%)',
                                    maxWidth: '250px',
                                    zIndex: '3',
                                }}
                            />
                            <img
                                src={App1SVG}
                                alt=""
                                className='app-1-svg'
                                style={{
                                    position: 'absolute',
                                    bottom: '0',
                                    left: '0',
                                    objectFit: 'contain',
                                    zIndex: '4',
                                    width: '50%',
                                }}
                            />
                        </div>
                    </Col>
                    <Col
                        className='h-100 content-container d-flex flex-column justify-content-center align-items-start content-container'
                        style={{
                            padding: '5%',
                            order: '2',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: 'transparent',
                                zIndex: '2',
                            }}
                        >
                            <h1
                                style={{
                                    color: '#212529',
                                    fontSize: '50px',
                                    fontWeight: '500',
                                    fontFamily: 'Forum, cursive',
                                }}
                            >
                                Change Occurs <span style={{ textDecoration: 'line-through' }}>Outside</span> Inside Your Comfort Zone
                            </h1>
                            <p
                                style={{
                                    color: '#212529',
                                    fontWeight: '400',
                                    fontSize: '13px',
                                }}
                            >
                                Be it a beginner, intermediate or advanced fitness level, there's something (actually a lot) for each one
                                of you! My CoreSculpt app offers 3 key categories of home-workouts.
                            </p>
                            <div
                                className='d-flex flex-column'
                            >
                                {
                                    section2Features.map((feature, index) => (
                                        <div
                                            className='d-flex align-items-center list-item-container my-1'
                                        >
                                            <BsFillCheckCircleFill className='text-md mr-3 flex-shrink-0' style={{
                                                color: '#212529',
                                            }} />
                                            <p
                                                className='list-item'
                                                style={{
                                                    padding: '0',
                                                    margin: '0',
                                                    fontSize: '13px',
                                                    color: '#212529'
                                                }}
                                            >
                                                {feature}
                                            </p>
                                        </div>
                                    ))
                                }
                            </div>
                            <p
                                style={{
                                    color: '#212529',
                                    fontWeight: '400',
                                    fontSize: '13px',
                                    marginTop: '5%',
                                }}
                            >
                                The best part – a library of over 500 unique addictive ‘follow along’ videos to spoil you!
                            </p>
                        </div>
                    </Col>
                    <img
                        src={Flower2SVG}
                        alt=""
                        style={{
                            width: '10%',
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            objectFit: 'contain',
                            margin: '2.5%',
                            maxWidth: '200px',
                            minWidth: '80px',
                            zIndex: '1',
                        }}
                    />
                </Row>
            </div>
            <div
                className="subscription-feature-section section-3"
                style={{
                    backgroundColor: '#ffffff',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Row
                    className='w-100 h-100 features'
                    style={{
                        padding: '5% 10%',
                        overflow: 'hidden',
                        margin: '0',
                        position: 'relative',
                    }}
                >
                    <Col
                        xs='12'
                        md='8'
                        className='h-100 d-flex flex-column justify-content-center content-container'
                        style={{
                            order: '1',
                            padding: '5%',
                            position: 'relative',
                            zIndex: '1'
                        }}
                    >
                        <img
                            src={Flower2SVG}
                            alt=""
                            style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                width: '10%',
                                maxWidth: '200px',
                                minWidth: '80px',
                                zIndex: '-1',
                            }}
                        />
                        <h2
                            style={{
                                fontWeight: '500',
                                fontSize: '30px',
                                color: '#1D1D1D',
                                zIndex: '2',
                                fontFamily: 'Forum, cursive',
                            }}
                        >
                            CoreSculpt App Features
                        </h2>
                        <p
                            style={{
                                fontWeight: '400',
                                color: '#8C8C8C',
                                fontSize: '15px',

                            }}
                        >
                            My Core program is one of the highest-rated in the market (and for the right reasons! 😊).
                        </p>
                        <Row
                            xs='1'
                            md='2'
                        >
                            {
                                section3Features.map((feature, index) => (
                                    <Col
                                        className='d-flex  list-item-container my-2'
                                    >
                                        <BsFillCheckCircleFill className='text-md mr-3 flex-shrink-0' style={{
                                            color: '#1A1A1A', marginTop: "8px"
                                        }} />
                                        <p
                                            className='list-item'
                                            style={{
                                                padding: '0',
                                                margin: '0',
                                                color: '#292929',
                                                fontSize: '13px',
                                                fontWeight: '300',
                                            }}
                                        >
                                            {feature}
                                        </p>
                                    </Col>
                                ))
                            }

                        </Row>
                    </Col>
                    <Col
                        xs='12'
                        md='4'
                        className='h-100 app-img-container d-flex justify-content-center align-items-center'
                        style={{
                            order: '2',
                        }}
                    >
                        <img
                            src={App2SVG}
                            alt=""
                            style={{
                                width: '100%',
                                maxHeight: '95%',
                                objectFit: 'contain',
                            }}
                        />
                    </Col>
                </Row>

            </div>
            <div
                className="section-4"
                style={{
                    backgroundColor: '#ffffff',
                }}
            >
                <Row
                    className='w-100 h-100 features'
                    xs='1'
                    md='2'
                    style={{
                        padding: '0% 10%',
                        margin: '0',
                        position: 'relative',
                    }}
                >
                    <Col
                        className='img-container d-flex justify-content-center align-items-center'
                        style={{
                            order: '1',
                            padding: '5%',
                        }}
                    >
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                position: 'relative',
                            }}
                        >
                            <div
                                style={{
                                    width: '90%',
                                    height: '90%',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    backgroundColor: '#E4D0F4',
                                    borderRadius: '80px 0px 80px 0px',
                                    zIndex: '1',
                                }}
                                className='underlay'
                            />
                            <img
                                src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/SubscriptionLandingAC.jpg`}
                                alt=""
                                style={{
                                    height: '90%',
                                    width: '90%',
                                    objectFit: 'cover',
                                    position: 'absolute',
                                    bottom: '0',
                                    right: '0',
                                    borderRadius: '80px 0px 80px 0px',
                                    zIndex: '2',
                                    objectPosition: 'center top',
                                }}
                                className='overlay-img'
                            />
                            <img
                                src={Flower4SVG}
                                alt=""
                                style={{
                                    position: 'absolute',
                                    right: '0%',
                                    transform: 'translateX(50%)',
                                    top: '15%',
                                    width: '30%',
                                    zIndex: '3',
                                    maxWidth: '200px',
                                }}
                                className='flower-4'
                            />
                        </div>
                    </Col>
                    <Col
                        className='h-100 d-flex flex-column justify-content-center content-container'
                        style={{
                            order: '2',
                            padding: '5%',
                        }}
                    >
                        <h2
                            style={{
                                fontWeight: '500',
                                fontSize: '30px',
                                color: '#212529',
                                fontFamily: 'Forum , cursive'
                            }}
                            className='title'
                        >
                            Say Hello to your Coach Prapti Dutt
                        </h2>
                        <p
                            style={{
                                fontWeight: '400',
                                color: '#212529',
                                fontSize: '13px',
                            }}
                            className='details'
                        >
                            Hiya! So good to see you on my page and if you are here, you are already one step closer to a fitter you.<br /><br />
                            I am not saying this as someone who was gifted with a fit and functional body. I say this as a mum of two boys, who has fought my way through 2 C-sections, post-partum depression & Diastasis Recti to where I am now. My journey from size 16 to size 0 gave me a lot of learnings and insight to what really works, when our goal is a lot more than just weight loss , but to get stronger, to not feel exhausted all the time, to get rid of that adamant belly fat or get those toned Abs . Yes, I have been there, done all of those - Tiring cardio runs, excessively long walks, and Complex looking Gym equipment workouts! But all these just left me with exhaustion, joint pains and worst of all – And also no change in my waist size. I was always hungry and used to fall ill so often! Soon I realised, if it isn’t sustainable, it won’t give me results. That’s when I completely changed my training and came up with my own core workouts! They leave me energetic and vibrant all day long and best of all I can do these regularly and see my strength improving!<br /><br />
                            I offer uniqueness in every session to add an element of surprise and fun to look forward to. Because to me, fitness is just not for the body, but also for our mind. Fitness doesn’t stop at a goal, it’s a lifestyle!  There are many paths that life leads us and mine has led me to you, to this fulfilling journey of helping you get stronger & fitter. And I look forward to taking this journey with you, step by step!
                        </p>

                    </Col>
                    <img
                        src={Flower3SVG}
                        alt=""
                        className='flower-1'
                        style={{
                            position: 'absolute',
                            top: '0',
                            right: '0',
                            width: '10%',
                            maxWidth: '200px',
                            margin: '2.5%',
                            zIndex: '2',
                        }}
                    />

                </Row>
            </div>
            <div
                className="section-5" ref={pack_ref}
            >
                <div
                    className="d-flex justify-content-center align-items-center content-container"
                    style={{
                        width: '100%',
                        padding: '2% 10%',
                        overflow: 'hidden',
                        backgroundColor: '#e4d0f4',
                    }}
                >
                    <div
                        style={{
                            backgroundColor: '#FFFFFF',
                            height: '100%',
                            width: '100%',
                            borderRadius: '0px 60px 0px 60px',
                            padding: '7%',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                        className='d-flex flex-column justify-content-center align-items-center content-container-inner'
                    >
                        <img
                            src={Flower1SVG}
                            alt=""
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '15%',
                                objectFit: 'contain',
                                zIndex: '1',
                                minWidth: '80px',
                                maxWidth: '200px',
                                transform: 'translate(-20%, -20%)',
                            }}
                        />
                        <img
                            src={Flower2SVG}
                            alt=""
                            style={{
                                position: 'absolute',
                                bottom: '0',
                                right: '0',
                                width: '15%',
                                objectFit: 'contain',
                                zIndex: '1',
                                minWidth: '80px',
                                maxWidth: '200px',
                                transform: 'translate(20%, 20%)',
                            }}
                        />
                        <h2
                            style={{
                                fontWeight: '500',
                                fontFamily: 'Forum,cursive',
                                color: '#212529',
                                textAlign: 'center',
                                fontSize: '30px',
                                lineHeight: '1',
                                zIndex: '2',
                            }}
                        >
                            CoreSculpt App by Prapti Dutt
                        </h2>
                        <p
                            style={{
                                fontWeight: '300',
                                color: '#212529',
                                textAlign: 'center',
                                fontSize: '20px',
                                padding: '0',
                                margin: '0',
                                zIndex: '2',
                            }}
                        >
                            Unleash the Iron power of your Core with me using<br />the Ultimate Machine – Your own Body!
                        </p>
                        <p
                            style={{
                                fontWeight: '600',
                                color: '#212529',
                                textAlign: 'center',
                                fontSize: '20px',
                                padding: '0',
                                margin: '0',
                                zIndex: '2',
                            }}
                        >
                            Get addicted to home-workouts with me!
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        padding: '1% 5%',
                    }}
                    className='d-flex flex-column justify-content-center align-items-center'
                    ref={packagesRef}
                    id='packages'
                >
                    <h2
                        style={{
                            color: '#212529',
                            fontSize: '35px',
                            fontWeight: '500',
                            fontFamily: 'Forum, cursive',
                            textAlign: 'center',
                        }}
                    >
                        Do you wish to sign up to your journey with me?
                    </h2>
                    <button
                        style={{
                            fontWeight: '500',
                            color: '#FFFFFF',
                            backgroundColor: '#212529',
                            border: 'none',
                            borderRadius: '0px 15px 0px 15px',
                            padding: '12px 40px',
                            flexGrow: '0',
                        }}
                        className='subscription-button-product'
                        onClick={handleSubscribeNow}
                    >
                        Subscribe Now
                    </button>


                </div>
                <div
                    className='d-flex flex-column justify-content-center align-items-center mt-4'
                >
                    <div style={{
                        display: "flex", backgroundColor: '#E4E4E460',
                        borderRadius: '100px', overflow: "hidden", gap: '5px', fontWeight: '500',
                        maxWidth: '100%'
                    }}
                    >
                        <div
                            className={`${!subscription ? 'bgr' : ''}`}
                            style={{
                                padding: '15px 30px', borderRadius: '100px', alignItems: 'center',
                                display: 'flex', justifyContent: 'center', cursor: 'pointer'
                            }}
                            onClick={() => setSubscription(false)}
                        >
                            Monthly</div>
                        <div className={`${subscription ? 'bgl' : ''}`}
                            style={{
                                padding: '15px 30px', borderRadius: '100px', alignItems: 'center',
                                display: 'flex', justifyContent: 'center', cursor: 'pointer'
                            }}
                            onClick={() => setSubscription(true)}
                        >
                            Quarterly</div>
                    </div>
                </div>

                {
                    !subscription
                        ?
                        <Row
                            className={`w-100 features product-container ${monthlyProducts.length === 2 ? 'product-container-len-2' : 'product-container-len-3'}`}
                            xs='1'
                            md={monthlyProducts.length}
                            style={{
                                padding: '1% 5%',
                                margin: '0',
                                overflow: 'hidden',
                            }}
                        >
                            {
                                monthlyProducts.map((product, index) => (
                                    <Col
                                        className='h-100 py-3'
                                    >
                                        <Card
                                            key={index}
                                            className='h-100 product-card'
                                            style={{
                                                borderRadius: '0px 60px 0px 60px',
                                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
                                                border: '2px solid #808080',
                                                overflow: 'hidden',
                                                backgroundColor: index % 2 === 0 ? '#ffffff' : '#faf4ff',
                                            }}
                                        >
                                            <CardBody className='px-4 py-6'>
                                                <div>
                                                    <h3
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '1rem',
                                                            margin: '0px',
                                                            textDecoration: 'line-through',
                                                        }}
                                                    >
                                                        £{product.oldPrice} /month
                                                    </h3>
                                                    <h1
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '1.5rem',
                                                            margin: '0px',
                                                            fontFamily: 'Forum, cursive'
                                                        }}
                                                    >
                                                        £{product.price} /month
                                                    </h1>

                                                    <h3
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '0.8rem',
                                                            margin: '0px',
                                                            fontFamily: 'Forum, cursive'
                                                        }}
                                                    >
                                                        {product.name}
                                                    </h3>
                                                    <div>
                                                        <span
                                                            className='mr-2'
                                                            style={{
                                                                fontSize: '0.8rem',
                                                            }}
                                                        >
                                                            {product.classes} videos
                                                        </span>
                                                        <span
                                                            style={{
                                                                color: '#c58bf2',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.8rem',
                                                            }}
                                                        >
                                                            /MONTH
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className='pt-3'
                                                >
                                                    <h3
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '0.8rem',
                                                        }}
                                                    >
                                                        Included in your subscription
                                                    </h3>
                                                    <hr className='my-1' />
                                                    {
                                                        product.features.map((feature, index) => (
                                                            feature.type === 'text' ? (
                                                                <div className='d-flex flex-row'>
                                                                    <BsFillCheckCircleFill className='text-sm mr-2 mt-1 flex-shrink-0' style={{
                                                                        color: '#c58bf2',
                                                                    }} />
                                                                    <p className='subscription-features-bullet-point text-sm'>{
                                                                        feature.text
                                                                    }</p>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className='d-flex flex-column'
                                                                >
                                                                    <div className='d-flex flex-row'>
                                                                        <BsFillCheckCircleFill className='text-sm mr-2 mt-1 flex-shrink-0' style={{
                                                                            color: '#c58bf2',
                                                                        }} />
                                                                        <p className='subscription-features-bullet-point text-sm'>{
                                                                            feature.text
                                                                        }</p>
                                                                    </div>
                                                                    <div
                                                                        className='d-flex flex-column pl-4'
                                                                    >
                                                                        {
                                                                            feature.items.map((item, index) => (
                                                                                <div className='d-flex flex-row'>
                                                                                    <BsFillCheckCircleFill className='text-sm mr-2 mt-1 flex-shrink-0' style={{
                                                                                        color: '#c58bf2',
                                                                                    }} />
                                                                                    <p className='subscription-features-bullet-point text-sm'>{
                                                                                        item
                                                                                    }</p>
                                                                                </div>
                                                                            ))
                                                                        }

                                                                    </div>
                                                                </div>
                                                            )
                                                        ))
                                                    }
                                                    <button
                                                        style={{
                                                            fontWeight: '500',
                                                            color: '#FFFFFF',
                                                            backgroundColor: '#212529',
                                                            border: 'none',
                                                            borderRadius: '0px 15px 0px 15px',
                                                            padding: '12px 40px',
                                                            flexGrow: '0',
                                                            marginTop: '4%',
                                                            width: '100%',
                                                        }}
                                                        className='subscription-button-product'
                                                        onClick={handleSubscribeNow}
                                                    >
                                                        Subscribe Now
                                                    </button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                        :
                        <Row
                            className={`w-100 features product-container ${quaterlyProducts.length === 2 ? 'product-container-len-2' : 'product-container-len-3'}`}
                            xs='1'
                            md={quaterlyProducts.length}
                            style={{
                                padding: '1% 5%',
                                margin: '0',
                                overflow: 'hidden',
                            }}
                        >
                            {
                                quaterlyProducts.map((product, index) => (
                                    <Col
                                        className='h-100 py-3'
                                    >
                                        <Card
                                            key={index}
                                            className='h-100 product-card'
                                            style={{
                                                borderRadius: '0px 60px 0px 60px',
                                                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.05)',
                                                border: '2px solid #808080',
                                                overflow: 'hidden',
                                                backgroundColor: index % 2 === 0 ? '#ffffff' : '#faf4ff',
                                            }}
                                        >
                                            <CardBody className='px-4 py-6'>
                                                <div>
                                                    <h3
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '1rem',
                                                            margin: '0px',
                                                            textDecoration: 'line-through',
                                                        }}
                                                    >
                                                        £{product.oldPrice} /quarter
                                                    </h3>
                                                    <h1
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '1.5rem',
                                                            margin: '0px',
                                                            fontFamily: 'Forum, cursive'
                                                        }}
                                                    >
                                                        £{product.price} /quarter
                                                    </h1>

                                                    <h3
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '0.8rem',
                                                            margin: '0px',
                                                            fontFamily: 'Forum, cursive'
                                                        }}
                                                    >
                                                        {product.name}
                                                    </h3>
                                                    <div>
                                                        <span
                                                            className='mr-2'
                                                            style={{
                                                                fontSize: '0.8rem',
                                                            }}
                                                        >
                                                            {product.classes} videos
                                                        </span>
                                                        <span
                                                            style={{
                                                                color: '#c58bf2',
                                                                fontWeight: 'bold',
                                                                fontSize: '0.8rem',
                                                            }}
                                                        >
                                                            /QUARTER
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className='pt-3'
                                                >
                                                    <h3
                                                        style={{
                                                            fontWeight: 'bold',
                                                            fontSize: '0.8rem',
                                                        }}
                                                    >
                                                        Included in your subscription
                                                    </h3>
                                                    <hr className='my-1' />
                                                    {
                                                        product.features.map((feature, index) => (
                                                            feature.type === 'text' ? (
                                                                <div className='d-flex flex-row'>
                                                                    <BsFillCheckCircleFill className='text-sm mr-2 mt-1 flex-shrink-0' style={{
                                                                        color: '#c58bf2',
                                                                    }} />
                                                                    <p className='subscription-features-bullet-point text-sm'>{
                                                                        feature.text
                                                                    }</p>
                                                                </div>
                                                            ) : (
                                                                <div
                                                                    className='d-flex flex-column'
                                                                >
                                                                    <div className='d-flex flex-row'>
                                                                        <BsFillCheckCircleFill className='text-sm mr-2 mt-1 flex-shrink-0' style={{
                                                                            color: '#c58bf2',
                                                                        }} />
                                                                        <p className='subscription-features-bullet-point text-sm'>{
                                                                            feature.text
                                                                        }</p>
                                                                    </div>
                                                                    <div
                                                                        className='d-flex flex-column pl-4'
                                                                    >
                                                                        {
                                                                            feature.items.map((item, index) => (
                                                                                <div className='d-flex flex-row'>
                                                                                    <BsFillCheckCircleFill className='text-sm mr-2 mt-1 flex-shrink-0' style={{
                                                                                        color: '#c58bf2',
                                                                                    }} />
                                                                                    <p className='subscription-features-bullet-point text-sm'>{
                                                                                        item
                                                                                    }</p>
                                                                                </div>
                                                                            ))
                                                                        }

                                                                    </div>
                                                                </div>
                                                            )
                                                        ))
                                                    }
                                                    <button
                                                        style={{
                                                            fontWeight: '500',
                                                            color: '#FFFFFF',
                                                            backgroundColor: '#212529',
                                                            border: 'none',
                                                            borderRadius: '0px 15px 0px 15px',
                                                            padding: '12px 40px',
                                                            flexGrow: '0',
                                                            marginTop: '4%',
                                                            width: '100%',
                                                        }}
                                                        className='subscription-button-product'
                                                        onClick={handleSubscribeNow}
                                                    >
                                                        Subscribe Now
                                                    </button>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))
                            }
                        </Row>
                }

                <div
                    style={{
                        minHeight: '10vh',
                        width: '100%',
                    }}
                    className='d-flex justify-content-center align-items-center'
                >


                </div>
            </div>
            <div
                className="section-6 d-flex flex-column justify-content-space-between align-items-center"
                style={{
                    backgroundColor: '#ffffff',
                    justifyContent: 'space-between',
                }}
            >
                <Container
                    className='mb-5'
                >
                    <Row>
                        <Col xs="12">
                            <h2
                                style={{
                                    color: '#212529',
                                    fontSize: '35px',
                                    fontWeight: '500',
                                    fontFamily: 'Forum, cursive',
                                    textAlign: 'center',
                                }}
                            >
                                Awards & Accolades
                            </h2>
                        </Col>

                        <Col xs={12}>
                            <AboutCoachSlider
                                settings={settings}
                                images={award_pics}
                            />
                        </Col>
                    </Row>
                </Container>
                <div
                    style={{
                        width: '100%',
                        overflow: 'hidden',
                    }}
                >
                    <Slider
                        dots={false} infinite={true} slidesToScroll={1} speed={500} autoplay={true} autoplaySpeed={3000}
                        slidesToShow={6} pauseOnHover={true}
                        prevArrow={<div><i className="lni lni-chevron-left"></i></div>}
                        nextArrow={<div><i className="lni lni-chevron-right"></i></div>}

                    >
                        {
                            footer_slide_data.map(e => (
                                <div key={e.name}>
                                    <img src={e.banner} alt="" className='img img-fluid' />
                                </div>
                            ))
                        }
                    </Slider>
                </div>
            </div>
            <Modal
                isOpen={isAccountModalOpen}
                toggle={() => {
                    setIsAccountModalOpen(!isAccountModalOpen);
                }}
                centered={true}
                size='lg'
                className='account-modal'
            >
                <ModalBody>
                    <Row
                        className='p-2'
                    >
                        <Col
                            xs='12'
                            md='6'
                            className='d-flex flex-column justify-content-center align-items-center'
                        >
                            <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Core+Sculpt+Logo+Final.png`} alt=""
                                style={{
                                    width: '120px',
                                    objectFit: 'contain',
                                }}
                                className='logo-img'
                            />
                            <h3
                                style={{
                                    fontWeight: '600',
                                    fontFamily: 'Forum, cursive',
                                }}
                                className='my-3'
                            >
                                Welcome to CoreSculpt
                            </h3>
                            <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
                                buttonText="Login"
                                onSuccess={googleLogin}
                                onFailure={googleLoginError}
                                cookiePolicy={'single_host_origin'}
                                scopes='profile'
                                render={renderProps => (
                                    <Button
                                        className="btn-icon flex-fill mx-0 w-100 common-field-box"
                                        onClick={renderProps.onClick}
                                        style={{
                                            width: '100%',
                                            borderRadius: '12px',
                                            color: 'black',
                                            backgroundColor: '#ffffff',
                                            boxShadow: 'none',
                                            border: '2px solid #ececec',
                                        }}
                                    >
                                        <span className="btn-inner--icon">
                                            <img
                                                alt="Login With Google"
                                                src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/google.svg`}
                                            />
                                        </span>
                                        <span className="btn-inner--text">Continue with Google</span>
                                    </Button>
                                )}
                                autoLoad={false}
                            />
                            <Button
                                className="flex-fill my-2 mx-0 w-100 common-field-box"
                                onClick={() => {
                                    setIsLogin(!isLogin)
                                    setOtpSent(false);
                                }}
                            >
                                <span className="btn-inner--text">{
                                    isLogin ? 'Create an Account' : 'Login'
                                }</span>
                            </Button>
                            <div
                                style={{
                                    width: '100%',
                                    borderTop: '2px solid #ececec',
                                    position: 'relative',
                                }}
                                className='d-flex justify-content-center align-items-center my-2'
                            >
                                <p
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        transform: 'translateY(-50%)',
                                        margin: '0',
                                        color: '#212529',
                                        backgroundColor: '#ffffff',
                                        padding: '0 10px',
                                    }}
                                >
                                    Or
                                </p>
                            </div>
                            {
                                isLogin ? (
                                    <Form
                                        className='w-100'
                                        onSubmit={
                                            otpSent ? verifyOTP : login
                                        }
                                    >
                                        {
                                            otpSent ? (
                                                <React.Fragment>
                                                    <p
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            color: '#212529',
                                                            marginBottom: 20,
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        We have sent an OTP to your email address. Please enter the OTP below to verify your email address.
                                                    </p>
                                                    <FormGroup
                                                        className='my-2 w-100'
                                                    >
                                                        <Input
                                                            type="text"
                                                            name="otp"
                                                            id="otp"
                                                            placeholder="OTP"
                                                            onChange={(e) => {
                                                                setOtp(e.target.value);
                                                            }}
                                                            className='common-field-box'
                                                            value={otp}
                                                        />
                                                    </FormGroup>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <FormGroup
                                                        className='my-2 w-100'
                                                    >
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            placeholder="Email"
                                                            onChange={(e) => {
                                                                setEmail(e.target.value);
                                                            }}
                                                            className='common-field-box'
                                                            value={email}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup
                                                        className='my-2 w-100'
                                                    >
                                                        <Input
                                                            type="password"
                                                            name="password"
                                                            id="password"
                                                            placeholder="Password"
                                                            onChange={(e) => {
                                                                setPassword(e.target.value);
                                                            }}
                                                            className='common-field-box'
                                                            value={password}
                                                        />
                                                    </FormGroup>
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            loading ? (
                                                <div
                                                    className='w-100 py-2 my-2 d-flex justify-content-center align-items-center continue-btn-field-box'
                                                >
                                                    <Loader
                                                        size={25}
                                                        thickness={0.1}
                                                    />
                                                </div>
                                            ) : (
                                                <Button
                                                    className='w-100 my-2 continue-btn-field-box'
                                                    type='submit'
                                                    onSubmit={
                                                        otpSent ? verifyOTP : login
                                                    }
                                                >
                                                    Continue
                                                </Button>
                                            )
                                        }
                                    </Form>
                                ) : (
                                    <Form
                                        className='w-100'
                                        onSubmit={
                                            otpSent ? verifyOTP : register
                                        }
                                    >
                                        {
                                            otpSent ? (
                                                <React.Fragment>
                                                    <p
                                                        style={{
                                                            fontSize: 14,
                                                            fontWeight: 400,
                                                            color: '#212529',
                                                            marginBottom: 20,
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        We have sent an OTP to your email address. Please enter the OTP below to verify your email address.
                                                    </p>
                                                    <FormGroup
                                                        className='my-2 w-100'
                                                    >
                                                        <Input
                                                            type="text"
                                                            name="otp"
                                                            id="otp"
                                                            placeholder="OTP"
                                                            onChange={(e) => {
                                                                setOtp(e.target.value);
                                                            }}
                                                            className='common-field-box'
                                                            value={otp}
                                                        />
                                                    </FormGroup>
                                                </React.Fragment>
                                            ) : (
                                                <React.Fragment>
                                                    <FormGroup
                                                        className='my-2 w-100'
                                                    >
                                                        <Input
                                                            type="text"
                                                            name="first_name"
                                                            id="first_name"
                                                            placeholder="First Name"
                                                            onChange={(e) => {
                                                                setFirstName(e.target.value);
                                                            }}
                                                            className='common-field-box'
                                                            value={first_name}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup
                                                        className='my-2 w-100'
                                                    >
                                                        <Input
                                                            type="text"
                                                            name="last_name"
                                                            id="last_name"
                                                            placeholder="Last Name"
                                                            onChange={(e) => {
                                                                setLastName(e.target.value);
                                                            }}
                                                            className='common-field-box'
                                                            value={last_name}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup
                                                        className='my-2 w-100'
                                                    >
                                                        <Input
                                                            type="email"
                                                            name="email"
                                                            id="email"
                                                            placeholder="Email"
                                                            onChange={(e) => {
                                                                setEmail(e.target.value);
                                                            }}
                                                            className='common-field-box'
                                                            value={email}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup
                                                        className='my-2 w-100'
                                                    >
                                                        <Input
                                                            type="password"
                                                            name="password"
                                                            id="password"
                                                            placeholder="Password"
                                                            onChange={(e) => {
                                                                setPassword(e.target.value);
                                                            }}
                                                            className='common-field-box'
                                                            value={password}
                                                        />
                                                    </FormGroup>
                                                    <span
                                                        className='d-block text-center w-100 m-0'
                                                        style={{
                                                            fontSize: '12px',
                                                        }}
                                                    >
                                                        By signing up, you agree to our <a href="/terms" target="_blank">Terms & Conditions</a> and <a href="/privacy" target="_blank">Privacy Policy</a>.
                                                    </span>
                                                </React.Fragment>
                                            )
                                        }
                                        {
                                            loading ? (
                                                <div
                                                    className='w-100 py-2 my-2 d-flex justify-content-center align-items-center continue-btn-field-box'
                                                >
                                                    <Loader
                                                        size={25}
                                                        thickness={0.1}
                                                    />
                                                </div>
                                            ) : (
                                                <Button
                                                    className='w-100 my-2 continue-btn-field-box'
                                                    type='submit'
                                                    onSubmit={
                                                        otpSent ? verifyOTP : register
                                                    }
                                                >
                                                    Continue
                                                </Button>
                                            )
                                        }
                                    </Form>
                                )
                            }
                        </Col>
                        <Col
                            xs='0'
                            md='6'
                            className='img-container overflow-hidden'
                        >
                            <div
                                className='w-100 h-100 position-relative'
                            >
                                <div
                                    style={{
                                        width: '90%',
                                        height: '90%',
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        backgroundColor: '#E4D0F4',
                                        borderRadius: '80px 0px 80px 0px',
                                        zIndex: '1',
                                    }}
                                    className='underlay'
                                />
                                <img
                                    src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Section-6-Img.jpg`}
                                    alt=""
                                    style={{
                                        height: '90%',
                                        width: '90%',
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        bottom: '0',
                                        right: '0',
                                        borderRadius: '80px 0px 80px 0px',
                                        zIndex: '2',
                                    }}
                                    className='overlay-img'
                                />

                            </div>
                        </Col>
                    </Row>
                </ModalBody>

            </Modal>
            <Modal
                isOpen={isInterestedModalOpen}
                toggle={() => {
                    setIsInterestedModalOpen(!isInterestedModalOpen);
                }}
                centered={true}
                size='lg'
                className='interested-user-modal'
            >
                <ModalBody>
                    <Row
                        className='p-2'
                        style={{
                            minHeight: '60vh'
                        }}
                    >
                        <Col
                            xs='12'
                            md='6'
                            className='d-flex flex-column justify-content-center align-items-center'
                        >
                            <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Core+Sculpt+Logo+Final.png`} alt=""
                                style={{
                                    width: '120px',
                                    objectFit: 'contain',
                                }}
                                className='logo-img'
                            />
                            <h3
                                style={{
                                    fontWeight: '600',
                                    fontFamily: 'Forum, cursive',
                                }}
                                className='my-3'
                            >
                                Welcome to CoreSculpt
                            </h3>
                            <Form
                                className='w-100'
                                onSubmit={addInterestedUser}
                            >
                                <React.Fragment>
                                    <p
                                        style={{
                                            fontSize: 14,
                                            fontWeight: 400,
                                            color: '#212529',
                                            marginBottom: 20,
                                            textAlign: 'center'
                                        }}
                                    >
                                        So glad to see you here! Please enter your details below to get notified when we launch.
                                    </p>
                                    <FormGroup
                                        className='my-2 w-100'
                                    >
                                        <Input
                                            type="text"
                                            name="name"
                                            id="name"
                                            placeholder="Name"
                                            onChange={(e) => {
                                                setInterestedUser({
                                                    ...interestedUser,
                                                    name: e.target.value,
                                                });
                                            }}
                                            className='common-field-box'
                                            value={interestedUser.name}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        className='my-2 w-100'
                                    >
                                        <Input
                                            type="email"
                                            name="email"
                                            id="email"
                                            placeholder="Email"
                                            onChange={(e) => {
                                                setInterestedUser({
                                                    ...interestedUser,
                                                    email: e.target.value,
                                                });
                                            }}
                                            className='common-field-box'
                                            value={interestedUser.email}
                                        />
                                    </FormGroup>
                                    <FormGroup
                                        className='my-2 w-100'
                                    >
                                        <Input
                                            type="tel"
                                            name="mobileNo"
                                            id="mobileNo"
                                            placeholder="Mobile No."
                                            onChange={(e) => {
                                                setInterestedUser({
                                                    ...interestedUser,
                                                    mobileNo: e.target.value,
                                                });
                                            }}
                                            className='common-field-box'
                                            value={interestedUser.mobileNo}
                                        />
                                    </FormGroup>
                                </React.Fragment>
                                <span
                                    style={{
                                        fontSize: 16,
                                        fontWeight: 400,
                                        color: 'red',
                                    }}
                                >
                                    {_error}
                                </span>
                                {
                                    loading ? (
                                        <div
                                            className='w-100 py-2 my-2 d-flex justify-content-center align-items-center continue-btn-field-box'
                                        >
                                            <Loader
                                                size={25}
                                                thickness={0.1}
                                            />
                                        </div>
                                    ) : (
                                        <Button
                                            className='w-100 my-2 continue-btn-field-box'
                                            type='submit'
                                            onSubmit={addInterestedUser}
                                        >
                                            Continue
                                        </Button>
                                    )
                                }
                            </Form>
                        </Col>
                        <Col
                            xs='0'
                            md='6'
                            className='img-container overflow-hidden'
                        >
                            <div
                                className='w-100 h-100 position-relative'
                            >
                                <div
                                    style={{
                                        width: '90%',
                                        height: '90%',
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        backgroundColor: '#E4D0F4',
                                        borderRadius: '80px 0px 80px 0px',
                                        zIndex: '1',
                                    }}
                                    className='underlay'
                                />
                                <img
                                    src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Section-6-Img.jpg`}
                                    alt=""
                                    style={{
                                        height: '90%',
                                        width: '90%',
                                        objectFit: 'cover',
                                        position: 'absolute',
                                        bottom: '0',
                                        right: '0',
                                        borderRadius: '80px 0px 80px 0px',
                                        zIndex: '2',
                                    }}
                                    className='overlay-img'
                                />

                            </div>
                        </Col>
                    </Row>
                </ModalBody>

            </Modal>

        </div >
    );
}
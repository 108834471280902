import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import moment from 'moment';

import { Card, Container, CardBody, CardHeader, Col, Row } from "reactstrap";

import Header from "../../components/Headers/Header.js";
import Loader from "../../components/Loader/loader.js";
import _fetch from "../../_fetch.js";
import Reviews from "../Reviews.js";

export default function Watch() {

	let history = useHistory();
	const [url, setUrl] = useState('');
	const [class_data, setClassData] = useState({ title: '', description: '', cat_name: '' });
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		console.log(history.location.state)
		if (!history.location?.state) return history.push('/live-sessions');
		setLoading(true);
		_fetch(`${process.env.REACT_APP_API}/classes/s3_link?class_id=${history.location.state}`)
			.then(res => {
				if (res.success) {
					console.log(res.response.s3_link)
					setUrl(res.response.s3_link);
					setClassData({
						title: res.response.title,
						description: res.response.description,
						cat_name: res.response.cat_name,
						date: moment(res.response.date).format('ll')
					});
					history.replace('/watch', null);
				} else {
					toast.error(res.response);
				}
			})
			.catch(err => {
				console.log(err);
				toast.error(err.message);
			})
			.finally(() => {
				setLoading(false);
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loading) return (
		<>
			<Header />

			<Container className="mt--7" fluid>
				<Card className="bg-secondary shadow" style={{ height: 400 }}>
					<Loader />
				</Card>
			</Container>
		</>
	);


	return (
		<>
			<Header />
			{/* Page content */}
			<Container className="mt--7" fluid>
				<Card className="shadow border-0">

					<CardHeader className="border-0">
						<h3>{class_data.cat_name} <span className="pl-3 text-muted text-sm">{class_data.date}</span></h3>
						<Reviews />
					</CardHeader>

					<CardBody className='row' style={{ minHeight: 500 }}>
						<Row className='w-100'>
							<Col xs={{ size: 12, offset: 0 }} md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
								{
									url
										?
										<video src={url} style={{ minHeight: 450 }} className='w-100 h-auto' controls={true} autoPlay>
										</video>
										:
										'Something went wrong'
								}
							</Col>
							<Col xs={{ size: 12, offset: 0 }} md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
								<h4 className='text-lg font-weight-600 mt-4'>{class_data.title}</h4>
								<p className='mt-3'>
									{class_data.description}
								</p>
							</Col>
						</Row>

					</CardBody>
				</Card>
			</Container>
		</>
	);
};

import moment from 'moment';
import _fetch from '../_fetch';

export const LoginAction = (userInfo) => ({
    type: 'LOGIN',
    payload: userInfo
});

export const LogoutAction = {
    type: "LOGOUT"
};

export const setLiveClasses = (live_classes_count) => ({
    type: 'UPDATE_LIVE_CLASSES_COUNT',
    ...live_classes_count
});

export const setRecordedClasses = (classes) => ({
    type: 'UPDATE_RECORDED_CLASSES',
    ...classes
});

export const setDiet = (diet) => ({
    type: 'UPDATE_DIET',
    diet
});

export const setConsultation = (calls) => ({
    type: 'UPDATE_CONSULTATION',
    calls
});

export const setExpiredClasses = (expired) => ({
    type: 'SET_EXPIRED_CLASSES',
    expired
})

export const getUserData = async () => {
    try {
        let result = await fetch(process.env.REACT_APP_API + '/user', { credentials: 'include' });
        if (result.status === 200) {
            let res = await result.json();
            if (res.success) {
                return res.response;
            }
        }
        return null;

    } catch (err) {
        console.log(err);
        return null;
    }
}

export const getEnrollments = async (dispatch) => {
    const data = await _fetch(`${process.env.REACT_APP_API}/enrollment`);
    if (data.success) {
        let l = data.response.live_classes;
        let r = data.response.recorded_classes;

        console.log(l);

        dispatch(setLiveClasses({
            total: l.reduce((total, e) => total + e.class_count, 0),
            watched: l.reduce((total, e) => total + e.classes?.length ?? 0, 0),
            enrollments: l
        }));
        dispatch(setRecordedClasses({
            total: r.reduce((total, e) => total + e.class_count, 0),
            watched: r.reduce((total, e) => total + e.classes.filter((e) => e.watch_start && moment().isAfter(moment(e.watch_start))).length, 0),
            enrollments: r
        }));
        dispatch(setDiet(data.response.diet));
        dispatch(setConsultation(data.response.consultation_call));
        dispatch(setExpiredClasses(data.response.expired));
    }
}
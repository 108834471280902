import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Col, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import Header2 from '../../components/Headers/Header2';
import Footer from '../../components/Footers/AuthFooter';
import UserInfo from './userInfo';
import OrderDetail from './orderDetail';
import CouponCodes from './discountCoupons';
import _fetch from '../../_fetch';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function OpenCart() {

    const [order, setOrder] = useState({});
    let { _id } = useSelector(st => st.user);
    let history = useHistory();
    let invoice_id = useQuery().get('OrderID');

    useEffect(() => {
        if (_id) {
            history.replace('/buy-classes');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id]);

    useEffect(() => {
        if (invoice_id) {
            _fetch(`${process.env.REACT_APP_API}/order?invoice_id=${invoice_id}`)
                .then(res => {
                    if (res.success) {
                        if (res.response === 'COMPLETED')
                            return toast.success('Payment processed successfully.');
                        else return toast.error('Payment failed.');
                    } else {
                        console.log(res.response);
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }, [invoice_id]);

    return (
        <>
            <Header2 />

            <div className='container py-5'>
                <Row>
                    <Col xs={12} lg={6} className='my-2'>
                        <UserInfo order={order} />
                        <div className='d-none d-lg-block'>
                            <CouponCodes />
                        </div>
                    </Col>
                    <Col xs={12} lg={6} className='my-2'>
                        <OrderDetail order={order} setOrder={setOrder} />
                        <div className='d-block d-lg-none'>
                            <CouponCodes />
                        </div>
                    </Col>
                </Row>
            </div>

            <Footer />
        </>
    )

}
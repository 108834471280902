const initialState = {
    home_headline: [],
    dashboard_headline: '',
    transformations: [],
    transformation_pics: [],
    testimonials: [],
    youtube_links: []
};

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case 'INITIALIZE': {
            return ({
                ...state,
                ...action.payload
            });
        }
        default: return state;
    }
}
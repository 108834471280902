import React, { useState } from 'react';
import { Container, Card, CardBody, CardHeader, Button, Col, Row } from 'reactstrap';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Headers/Header';


export default function DietPackageForm() {

    const [, setCookies] = useCookies(['diet_package']);
    const [data, setData] = useState('6_WEEKS');
    let history = useHistory();

    const diet_packages = {
        "60_MINS": {
            name: "60 Minutes Nutrition Consultation Call",
            goal: "The goal is to fill up the nutrition gaps. We will go over your existing diet and lifestyle and discuss the right eating strategies and lifestyle modifications required to achieve your fitness goals. During our consultation call, I will do a thorough study of your existing food diary to provide you with key action points to take away from the call and implement in your diet. The service includes consultation and guidance with easy to adopt lifestyle changes. If you have been eating healthy but stuck with a weight loss plateau or just need guidance from time to time, this is the perfect plan.",
            duration: "1 time - 60 minute call",
            check_ins: "",
            fee: "£40 per session",
            roll_over: "20% off on consecutive call bookings"
        },
        "6_WEEKS": {
            name: "6 Weeks Nutrition Support Plan",
            goal: (
                <React.Fragment>
                    To implement a 6 week duration plan of action with the right nutrition and lifestyle enhancements customized to your fitness goals. The initial consultation call will be followed by a diet sheet with sample meal ideas customised to your existing food habits and lifestyle. Option to choose from a set meal plan ideas or calorie counting diet approach will be discussed during the call.
                    <br />
                    This service enables you to reach out to me for support throughout the 6 weeks process for any queries or questions you may have. A bi-weekly catch up will be conducted to go through the results and changes on diet as needed. We will be monitoring the results through changes in scale, body stats and progress pics. Clients should be ideally providing these to me before our bi-weekly catch up calls.
                </React.Fragment>
            ),
            duration: "6 Weeks",
            check_ins: "Bi-weekly check ins",
            fee: `£${process.env.REACT_APP_6_WEEK_DIET_PRICE}`,
            roll_over: "25% off on consecutive plan booking"
        },
        "12_WEEKS": {
            name: "12 Weeks Nutrition Support Plan",
            goal: "To implement a 12 week duration plan of action with the right nutrition and lifestyle enhancements customized to your fitness goals. The initial consultation call will be followed by a diet sheet with sample meal ideas customised to your existing food habits and lifestyle. Option to choose from a set meal plan ideas or calorie counting diet approach will be discussed during the call. This service enables you to reach out to me for support throughout the 12 weeks process for any queries or questions you may have. A bi-weekly catch up will be conducted to go through the results and changes on diet as needed. We will be monitoring the results through changes in scale, body stats and progress pics. Clients should be ideally providing these to me before our bi-weekly catch up calls.",
            duration: "12 Weeks",
            check_ins: "Every 2 weeks",
            fee: `£${process.env.REACT_APP_12_WEEK_DIET_PRICE}`,
            roll_over: "25% off on consecutive plan booking"
        }
    }

    async function redirectToPaymentPage(ev) {
        setCookies('diet_package', data)
        history.push('/buy-classes');
    }

    return (
        <React.Fragment>
            <Header />
            <Container className="mt--7" fluid>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-transparent w-100">
                        <h2 className="mb-0 w-100 text-center">Fill Up Personal Training Registration Form</h2>
                    </CardHeader>
                    <CardBody>

                        <Row className='mb-4'>
                            <Col xs={12} md={4} className='my-3'>
                                <Card
                                    onClick={() => setData('12_WEEKS')}
                                    className={`p-4 text-center h-100 text-xl font-weight-600 pointer shadow card-lift--hover ${data === '12_WEEKS' ? 'bg-primary text-white' : ''}`}
                                >
                                    12 Weeks Nutrition Support Plan
                                </Card>
                            </Col>
                            <Col xs={12} md={4} className='my-3'>
                                <Card
                                    onClick={() => setData('6_WEEKS')}
                                    className={`p-4 text-center text-xl h-100 font-weight-600 pointer shadow card-lift--hover ${data === '6_WEEKS' ? 'bg-primary text-white' : ''}`}
                                >
                                    6 Weeks Nutrition Support Plan
                                </Card>
                            </Col>
                            <Col xs={12} md={4} className='my-3'>
                                <Card
                                    onClick={() => setData('60_MINS')}
                                    className={`p-4 text-center text-xl font-weight-600 pointer shadow card-lift--hover ${data === '60_MINS' ? 'bg-primary text-white' : ''}`}
                                >
                                    60 Minutes Nutrition Consultation Call
                                </Card>
                            </Col>
                        </Row>

                        <Card className='mb-4'>
                            <CardBody>
                                <div className='d-flex align-items-start pb-4 mb-4 border-bottom'>
                                    <i className='lni lni-flag mx-auto mt-1' style={{ fontSize: '2rem' }} />
                                    <div className='flex-fill ml-3'>
                                        <h4 className='text-lg'>Goal</h4>
                                        <p className='mb-0'>
                                            {diet_packages[data].goal}
                                        </p>
                                    </div>
                                </div>
                                <Row>
                                    <Col xs={12} md={6} lg={data === '60_MINS' ? 4 : 3}>
                                        <div className='d-flex align-items-start'>
                                            <i className='lni lni-timer mx-auto mt-1' style={{ fontSize: '2rem' }} />
                                            <div className='flex-fill ml-3'>
                                                <h4 className='text-lg'>Duration</h4>
                                                <p className='mb-0'>
                                                    {diet_packages[data].duration}
                                                </p>
                                            </div>
                                        </div>

                                    </Col>
                                    {
                                        diet_packages[data].check_ins &&
                                        <Col xs={12} md={6} lg={3}>
                                            <div className='d-flex align-items-start'>
                                                <i className='lni lni-phone-set mx-auto mt-1' style={{ fontSize: '2rem' }} />
                                                <div className='flex-fill ml-3'>
                                                    <h4 className='text-lg'>Progress check ins </h4>
                                                    <p className='mb-0'>
                                                        {diet_packages[data].check_ins}
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                    <Col xs={12} md={6} lg={data === '60_MINS' ? 4 : 3}>
                                        <div className='d-flex align-items-start'>
                                            <i className='lni lni-pound mx-auto mt-1' style={{ fontSize: '2rem' }} />
                                            <div className='flex-fill ml-3'>
                                                <h4 className='text-lg'>Fee</h4>
                                                <p className='mb-0'>
                                                    {diet_packages[data].fee}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={data === '60_MINS' ? 4 : 3}>
                                        <div className='d-flex align-items-start'>
                                            <i className='lni lni-calendar mx-auto mt-1' style={{ fontSize: '2rem' }} />
                                            <div className='flex-fill ml-3'>
                                                <h4 className='text-lg'>Roll over plan</h4>
                                                <p className='mb-0'>
                                                    {diet_packages[data].roll_over}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='d-flex align-items-start mb-4 pt-4 mt-4 border-top'>
                                    <i className='lni lni-book mx-auto mt-1' style={{ fontSize: '2rem' }} />
                                    <div className='flex-fill ml-3'>
                                        <h4 className='text-lg'>Terms And Conditions</h4>
                                        <div className='d-flex align-items-start mb-2'>
                                            <i className='lni lni-checkmark mt-1 mx-auto' />
                                            <div className='flex-fill ml-3'>
                                                The client is required to fill in a client questionnaire prior to starting the process requiring all important information to conduct an in depth analysis of the current lifestyle and diet. This will enable me to provide you a customized plan rightly suiting your existing food habits and lifestyle.
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-start mb-2'>
                                            <i className='lni lni-checkmark mt-1 mx-auto' />
                                            <div className='flex-fill ml-3'>
                                                Our success lies in team work which is highly dependent on being jointly accountable towards your transformation journey. Goal is to attain a healthy weight loss whilst improving overall health through simple lifestyle changes. As a coach I will be trying my best to make the plan sustainable and achievable through easy to adopt lifestyle changes wherever needed.
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-start mb-2'>
                                            <i className='lni lni-checkmark mt-1 mx-auto' />
                                            <div className='flex-fill ml-3'>
                                                Regular updates through scale movements, body stats measurements and overall general well being are the only way we will know the success or opportunity to modify anything. Clients are required to keep the coach updated of these from time to time in this process.
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-start mb-2'>
                                            <i className='lni lni-checkmark mt-1 mx-auto' />
                                            <div className='flex-fill ml-3'>
                                                Once a plan is booked, refunds are not issued due to your unavailability or vacation or any other personal reasons. However, a delayed start date is possible.
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-start mb-2'>
                                            <i className='lni lni-checkmark mt-1 mx-auto' />
                                            <div className='flex-fill ml-3'>
                                                To get the best results from the nutrition plan, it is highly recommended to incorporate physical activity as an important element. I offer discounts when my clients opt in for both diet and workouts combo packages.
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <Button color='primary' onClick={redirectToPaymentPage} className='mt-4'>
                                    Book Now
                                </Button>

                            </CardBody>
                        </Card>

                    </CardBody>
                </Card >
            </Container >
        </React.Fragment>
    );
}
import React from "react";
import { Link, useHistory } from 'react-router-dom';
import { Container, Col, Row } from "reactstrap";
import LogoSVG from '../../assets/footer/svgs/logo.svg';
import YogaSVG from '../../assets/footer/svgs/yoga.svg';

export default function Footer() {
	const history = useHistory();
	return (
		<footer>
			<section
				style={{
					backgroundColor: '#764979',
					paddingTop: '40px',
					position: 'relative',
				}}
			>
				<img
					src={YogaSVG}
					alt="yoga"
					style={{
						position: 'absolute',
						top: '50%',
						right: '0px',
						width: 'auto',
						height: '60%',
						objectFit: 'contain',
						transform: 'translateY(-50%)',
					}}
				/>
				<Container
					style={{
						paddingBottom: '40px',
					}}
				>
					<Row>
						<Col
							xs="12"
							className="d-flex justify-content-center align-items-center flex-column"
							style={{
								marginBottom: '40px'
							}}
						>
							<p
								style={{
									color: '#e4dbe4',
									fontWeight: '500',
									fontFamily: 'DM Sans, sans-serif',
									maxWidth: '500px',
									textAlign: 'center',
									fontSize: '1.5rem',
									whiteSpace: 'pre-line'
								}}
								className="mt-4"
							>
								{
									`Monday - Friday: 06:00 AM - 07:00 PM\nSaturday - Sunday: 08:00 AM - 01:00 PM`
								}
							</p>
						</Col>
						<Col
							xs={12}
							sm={6}
							lg={3}
							className="d-flex justify-content-center align-items-center flex-column"
						>
							<img
								src={LogoSVG}
								alt="logo"
								style={{
									width: '100%',
									objectFit: 'contain',
									maxWidth: '250px',
								}}
							/>
						</Col>
						<Col
							xs={12}
							sm={6}
							lg={3}
							className="d-flex flex-column"
						>
							<h3
								style={{
									color: 'white',
									fontWeight: '800',
									fontFamily: 'Abhaya Libre, serif',
								}}
								className="text-center text-sm-left"
							>
								About Us
							</h3>
							<p
								style={{
									color: 'white',
									fontWeight: '500',
									fontFamily: 'DM Sans, sans-serif',
								}}
								className="text-center text-sm-left"
							>Prapti Dutt, the owner of KeepWell Mantras is a certified level three Personal Trainer, a group Fitness instructor and an advanced clinical weight loss practitioner with a decade full of experience in fitness and wellness.</p>
						</Col>
						<Col
							xs={12}
							sm={6}
							lg={3}
							className="d-flex flex-column justify-content-center align-items-center justify-content-sm-start align-items-sm-start"
						>
							<h3
								style={{
									color: 'white',
									fontWeight: '800',
									fontFamily: 'Abhaya Libre, serif',
								}}
							>
								Connect With Us
							</h3>
							<img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/qr-code.jpg`} alt="QR-img" style={{
								borderRadius: '4px',
								width: '100px',
								height: '100px',
								objectFit: 'contain',
							}} />
							<div
								className="d-flex"
								style={{
									gap: '20px',
									marginTop: '10px'
								}}
							>
								<Link to={{ pathname: "https://www.facebook.com/KeepWellMantras/" }} target="_blank" rel="noreferrer"
									style={{
										backgroundColor: '#5e3161',
										borderRadius: '100px',
										height: '30px',
										width: '30px',
									}}
									className="d-flex justify-content-center align-items-center"
								>
									<i className="lni lni-facebook-filled"></i>
								</Link>
								<Link to={{ pathname: "https://www.instagram.com/Keepwell_mantras" }} target="_blank" rel="noopener noreferrer"
									style={{
										backgroundColor: '#5e3161',
										borderRadius: '100px',
										height: '30px',
										width: '30px',
									}}
									className="d-flex justify-content-center align-items-center"
								>
									<i className="lni lni-instagram-original"></i>
								</Link>
								<Link to={{ pathname: "https://www.youtube.com/channel/UCaNDYGA8MyjJuvD-NELWKRQ" }} target="_blank" rel="noopener noreferrer"
									style={{
										backgroundColor: '#5e3161',
										borderRadius: '100px',
										height: '30px',
										width: '30px',
									}}
									className="d-flex justify-content-center align-items-center"
								>
									<i className="lni lni-youtube"></i>
								</Link>
							</div>
						</Col>
						<Col
							xs={12}
							sm={6}
							lg={3}
							className="d-flex flex-column"
						>
							<h3
								style={{
									color: 'white',
									fontWeight: '800',
									fontFamily: 'Abhaya Libre, serif',
								}}
								className="text-center text-sm-left"
							>
								Contact Us
							</h3>
							<p
								style={{
									color: 'white',
									fontWeight: '500',
									fontFamily: 'DM Sans, sans-serif',
								}}
							><i className="lni lni-map-marker mr-3" aria-hidden="true"></i>London</p>
							<p
								style={{
									color: 'white',
									fontWeight: '500',
									fontFamily: 'DM Sans, sans-serif',
									whiteSpace: 'nowrap',
								}}
							><i className="lni lni-envelope mr-3" aria-hidden="true"></i>prapti.dutt@keepwellmantras.com</p>
							<p
								className="mb-0"
								style={{
									color: 'white',
									fontWeight: '500',
									fontFamily: 'DM Sans, sans-serif',
								}}
							><i className="lni lni-mobile mr-3" aria-hidden="true"></i>07584236848</p>
						</Col>
					</Row>
				</Container>
				<div
					style={{
						borderTop: '1px solid #bba4bc',
					}}
				>
					<h4
						style={{
							color: '#bba4bc',
							fontWeight: '500',
							fontFamily: 'DM Sans, sans-serif',
							textAlign: 'center',
							padding: '20px 0',
						}}
					>Copyright 2023, keepwellmantras.com All Rights Reserved.</h4>
				</div>
			</section>
		</footer>
	);
};
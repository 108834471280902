export default function HabitCoachSuccess() {

    return (
        <div
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <p
                style={{
                    fontSize: "2rem",
                    fontWeight: "bold",
                }}
            >Redirecting...</p>
        </div>
    )
}
import { AddressElement } from '@stripe/react-stripe-js';
export default function AddressForm({
    onSubmit,
}) {
    return (
        <form>
            <h3>
                Address Details
            </h3>
            <AddressElement options={{ mode: 'billing' }} onChange={(e) => {
                if (e.complete) {
                    onSubmit(e.value.address)
                }
            }} />
        </form>
    );
}
import React, { useState } from "react";
import {
    Button, Modal, ModalBody
} from "reactstrap";
import { toast } from "react-toastify";

import _fetch from "../../../_fetch.js";
import './styles.scss';

const initialState = {
    type: '',
    title: '',
    description: '',
    date: '',
    time: '',
    s3_link: '',
    duration_in_minutes: 0,
    meeting_link: '',
    recording_link: '',
    category: '',
    order: 0,
    _id: '',
    meta: ''
};

export default function DeleteVideoModal({
    id, isOpen, toggle, refresh
}) {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(initialState);

    const deleteVideo = async () => {
        try {
            setLoading(true);
            await _fetch(`${process.env.REACT_APP_API}/media/video?class_id=${id}`, { method: 'DELETE' });
            setData({ ...data, s3_link: '' });
            toggle(false);
            refresh();
        } catch (err) {
            console.log(err);
            toast.error('Some error occurred while deleting the video');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={() => toggle(false)} size="lg">
            <ModalBody className="bg-secondary">
                <div>
                    <p>Are you sure, you want to delete this video?</p>

                    <div className="d-flex mt-4">
                        <Button disabled={loading} onClick={() => toggle(false)}>Cancel</Button>
                        <Button disabled={loading} color='danger' onClick={deleteVideo}>Delete Video</Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

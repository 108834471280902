import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Form,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Col,
	Row,
	CardFooter
} from "reactstrap";
import GoogleLogin from 'react-google-login';
import leftTwigSVG from '../../assets/subscription/flower-1.svg'
import rightTwigSVG from '../../assets/subscription/flower-2.svg'
import validator from "validator";
import Loader from "../../components/Loader/loader";

import _fetch from "../../_fetch";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

export default function ForgotPassword() {

	const history = useHistory();
	const query = useQuery();

	const [email, setEmail] = useState('');
	const [err, setErr] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (query.get('verified')) {
			toast.success('Email Id verified successfully.');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function forgot(ev) {
		ev.preventDefault();

		if (!email || !validator.isEmail(email))
			return setErr('Please enter valid email id and password');

		setLoading(true);

		_fetch(process.env.REACT_APP_API + `/user/forgot_password?email=${email}`)
			.then(res => {
				if (res.success) {
					toast.success(res.response?.message);
					history.push('/reset_pass', {
						id: res.response?.id,
					});
				} else {
					toast.error(res.response);
				}
			})
			.catch(err => {
				console.log(err);
				toast.error('Some error occurred please try again later.')
			})
			.finally(() => {
				setLoading(false);
			});

		return false;
	}

	return (
		<>
			<div
				className='container'
			>
				<Row>
					<Col lg={5} md={8} className="">
						<div className="d-none d-lg-flex">
							<img
								src={leftTwigSVG}
								alt='..'
								className='left-twig-svg'
								style={{
									position: 'absolute',
									zIndex: '3',
									height: '15%',
									width: '15%',
									right: '-12%',
									top: '-2%'
								}}
							/>
						</div>
						<div className="login-style ">
							<div className="navbar-header  mar-top-bot">
								<Link className="navbar-brand logo-space" to='/'>
									<img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/logo-keepwell.png`} alt="" style={{
										maxWidth: '200px',
										objectFit: 'contain',
									}} />
								</Link>
								{/* <button
						className="navbar-toggler"
						type="button"
						onClick={toggleCollapse}
					>
						<BiMenu size={20} color='white' className='navbar-toggler-icon' />
					</button> */}
							</div>
							<div>
								<h2 className="head-font mar-top-bot my-4">
									Reset Password
								</h2>
							</div>
							{/* <div className="btn-wrapper mar-top-bot text-center d-flex">

								<GoogleLogin
									clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}
									buttonText="Login"
									onSuccess={googleLogin}
									onFailure={googleLoginError}
									cookiePolicy={'single_host_origin'}
									scopes='profile'
									render={renderProps => (
										<Button
											className="btn-neutral btn-icon flex-fill mr-2 btn-border"
											color="default"
											onClick={renderProps.onClick}
										>
											<span className="btn-inner--icon">
												<img
													alt="Login With Google"
													src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/google.svg`}
												/>
											</span>
											<span className="btn-inner--text">Login With Google</span>
										</Button>
									)}
									autoLoad={false}
								/>

							</div> */}
							{/* <div className="text-center mar-top-bot text-dark text-lg my-4">
								Or sign in with credentials
							</div> */}


							<CardBody className="p-4">

								<Form role="form" onSubmit={forgot}>

									<FormGroup className="mb-3">
										<InputGroup className="input-group-alternative">
											{/* <InputGroupAddon addonType="prepend">
												<InputGroupText>
													<i className="ni ni-email-83" />
												</InputGroupText>
											</InputGroupAddon> */}
											<Input
												id="exampleEmail"
												name="email"
												placeholder="Enter Email Address"
												className="input-text-style"
												type="email"
												onChange={(ev) => setEmail(ev.target.value)}
											/>
										</InputGroup>
									</FormGroup>

									{err ? <p className='text-danger text-xs font-weight-400'>{err}</p> : null}

									<div className="text-center">
										<Button className="my-4 mx-0 w-100" color="dark" type="submit" onSubmit={forgot}>
											{loading ? <Loader size={20} /> : 'Reset Password'}
										</Button>
									</div>

								</Form>
							</CardBody>
							<div className="d-flex mar-top-bot">
								<Col xs="6">
									<Link
										className="text-dark"
										to='/login'
									>
										<small>Login</small>
									</Link>
								</Col>
								<Col className="text-right" xs="6">
									<Link className="text-dark" to='/register' >
										<small>Create new account</small>
									</Link>
								</Col>
							</div>

							<div className="d-none d-lg-flex">
								<img
									src={rightTwigSVG}
									alt='..'
									className='right-twig-svg'
									style={{
										position: 'absolute',
										zIndex: '3',
										height: '15%',
										width: '15%',
										left: '-15%',
										top: '95%'
									}}
								/>
							</div>

						</div>
					</Col >

					<Col lg={5} className='d-none d-lg-flex' style={{
						marginTop: "4%",
						marginLeft: '12%'
					}}>
						<div >
							<div
								style={{
									width: '90%',
									height: '90%',
									position: 'absolute',
									top: '-35px',
									marginTop: '10',
									left: '0',
									backgroundColor: '#E4D0F4',
									borderRadius: '80px 0px 80px 0px',
									zIndex: '1',
								}}
								className='underlay'
							/>
							<div>
								<img
									src={leftTwigSVG}
									alt='..'
									className='left-twig-svg'
									style={{
										position: 'absolute',
										zIndex: '3',
										height: '25%',
										width: '25%',
										right: '-70px',
										top: '50px'
									}}
								/>
							</div>

							<div className="about-opening">

								<img
									src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/Section-6-Img.jpg`}
									alt=""
									style={{
										height: '90%',
										width: '90%',
										top: '0',
										objectFit: 'cover',
										position: 'absolute',
										bottom: '0',
										right: '0',
										borderRadius: '80px 0px 80px 0px',
										zIndex: '2',
									}}
									className='overlay-img'
								/>


							</div>



						</div>
					</Col>
				</Row>
			</div>
			{/* <Col lg="5" md="7">
				<Card className="bg-pink shadow">

					<CardHeader className="bg-transparent text-xl font-weight-500">
						Reset Password
					</CardHeader>

					<CardBody className="p-5 border-top">

						<Form role="form" onSubmit={forgot}>

							<FormGroup className="mb-3">
								<InputGroup className="input-group-alternative">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<i className="ni ni-email-83" />
										</InputGroupText>
									</InputGroupAddon>
									<Input
										placeholder="Enter Registered Email Address"
										type="email"
										required={true}
										onChange={(ev) => setEmail(ev.target.value)}
									/>
								</InputGroup>
							</FormGroup>

							{err ? <p className='text-danger text-xs font-weight-400'>{err}</p> : null}

							<div className="text-center">
								<Button className="my-4 mx-0 w-100" color="white" type="submit" onSubmit={forgot}>
									{loading ? <Loader size={20} /> : 'Reset Password'}
								</Button>
							</div>

						</Form>
					</CardBody>
					<CardFooter className='bg-transparent border-top'>
						<Row>
							<Col xs="6">
								<Link
									className="text-white"
									to='/login'
								>
									<small>Login</small>
								</Link>
							</Col>
							<Col className="text-right" xs="6">
								<Link
									className="text-white"
									to='/register'
								>
									<small>Create new account</small>
								</Link>
							</Col>
						</Row>
					</CardFooter>
				</Card>
			</Col> */}
		</>
	);
};

import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardFooter, Table, Container, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';

import Header from "../../components/Headers/Header";
import _fetch from '../../_fetch';
import Loader from "../../components/Loader/loader.js";
import { changeRecipePage } from "../../actions/filterActions.js";

const pageLength = 10;

export default function MyRecipes() {

    let dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [recipes, setRecipes] = useState([]);
    let history = useHistory();

    const getRecipes = async () => {
        try {
            setLoading(true);

            let url = `${process.env.REACT_APP_API}/recipe`;

            let res = await _fetch(url);
            if (res.success) {
                setRecipes(res.response.recipes);
                setTotalPages(Math.ceil(res.response.total / pageLength));
            }

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getRecipes();
    }, []);

    if (loading) return (
        <>
            <Header />

            <Container className="mt--7" fluid>
                <Card className="bg-secondary shadow" style={{ height: 400 }}>
                    <Loader />
                </Card>
            </Container>
        </>
    );

    return (
        <>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Card className="shadow">

                    <CardHeader className="border-bottom d-flex flex-column flex-lg-row justify-content-between">
                        <h3 className="mb-0">
                            Your Recipes
                        </h3>
                        <Button onClick={()=>history.push('/add_recipe')}>
                            Add Recipe
                        </Button>
                    </CardHeader>

                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Title</th>
                                <th scope="col">Views</th>
                                <th scope="col">Likes</th>
                                <th scope="col">Status</th>
                                <th scope="col">Suggested Changes</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                recipes.map((e, i) => (
                                    <tr key={i}>
                                        <th scope="row">
                                            {e.title}
                                        </th>
                                        <td>{e.views}</td>
                                        <td>{e.likes}</td>
                                        <td>{e.status}</td>
                                        <td>{e.admin_comments}</td>
                                        <td className='w-25'>
                                            <Button
                                                size='sm' color='info'
                                                onClick={() => history.push(`/recipe?id=${e._id}`)}
                                            >
                                                View
                                            </Button>
                                            <Button
                                                size='sm' color='info'
                                                onClick={() => history.push(`/add_recipe?id=${e._id}`)}
                                            >
                                                Edit
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>

                    <CardFooter className="py-4">
                        <nav aria-label="...">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="next >"
                                onPageChange={(page) => dispatch(changeRecipePage(page))}
                                pageRangeDisplayed={3}
                                pageCount={totalPages}
                                previousLabel="< previous"
                                renderOnZeroPageCount={null}
                                className="pagination justify-content-end mb-0"
                                pageClassName='justify-content-end mb-0'
                                initialPage={0}
                            />
                        </nav>
                    </CardFooter>

                </Card>

            </Container>
        </>
    );
};

import React, { useState } from "react";

import OrderForm from './orderForm.js';
import Payment from './payment.js';

export default function OrderDetail() {

    const [finalOrder, setFinalOrder] = useState(null);

    return finalOrder ? <Payment order={finalOrder} /> : <OrderForm setFinalOrder={setFinalOrder} />;
}
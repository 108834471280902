import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import {
    Card, CardHeader, Table, Container, Modal, ModalHeader, ModalBody, FormGroup, Input, Button, Col, Row
} from "reactstrap";
import AdminHeader from "../../../components/Headers/AdminHeader.js";
import _fetch from '../../../_fetch';
import Loader from "../../../components/Loader/loader.js";
import { toast } from "react-toastify";

export default function AllSubscribedUsers() {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState('');
    const [excelData, setExcelData] = useState([]);
    const [coupons, setCoupons] = useState([]);
    const [selectedCouponId, setSelectedCouponId] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [addCouponModalOpen, setAddCouponModalOpen] = useState(false);
    const [isAllUsers, setIsAllUsers] = useState(false);
    const headers = [
        { label: "Name", key: "name" },
        { label: "Email", key: "email" },
        { label: "Subscription Tier", key: "subscription_tier" },
        { label: "Remaining Videos", key: "remaining_video" },
    ];

    async function getExportUser() {
        try {
            setLoading(true);

            let url = `${process.env.REACT_APP_API}/admin/subscribed-users`;

            let res = await _fetch(url);
            if (res.success) {

                let arr = []
                res.response.forEach((cur, i) => {
                    let temp = {};
                    temp.name = `${cur?.first_name} ${cur?.last_name}`
                    temp.email = cur?.email;
                    temp.subscription_tier = cur?.currentSubscription?.subscription_tier;
                    temp.remaining_video = cur?.currentSubscription?.video_count - cur?.currentSubscription?.watchedVideos?.length;
                    arr.push(temp);
                });
                setExcelData(arr);
            }

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const getUsers = async () => {
        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_API}/admin/user?isSubscribed=1`;
            let res = await _fetch(url);
            if (res.success) {
                setUsers(res.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const getAllUsers = async () => {
        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_API}/admin/user`;
            let res = await _fetch(url);
            if (res.success) {
                setUsers(res.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const getCoupons = async () => {
        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_API_V2}/app-coupon`;
            let res = await _fetch(url);
            if (res.success) {
                setCoupons(res.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    const handleAddCouponToUsers = async () => {
        try {
            setLoading(true);
            let url = `${process.env.REACT_APP_API_V2}/app-coupon/add-to-users`;
            let res = await _fetch(url, {
                method: 'POST',
                body: {
                    coupon_id: selectedCouponId,
                    users: selectedUsers
                }
            });
            if (res.success) {
                toast.success(res.response);
                setAddCouponModalOpen(false);
                setSelectedCouponId(null);
                setSelectedUsers([]);
            } else {
                toast.error(res.response);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getUsers();
        getCoupons();
    }, []);

    useEffect(() => {
        if (isAllUsers) {
            getAllUsers();
        } else {
            getUsers();
        }
    }, [isAllUsers]);
    if (loading) return (
        <>
            <AdminHeader />
            <Container className="mt--7" fluid>
                <Card className="bg-secondary shadow" style={{ height: 400 }}>
                    <Loader />
                </Card>
            </Container>
        </>
    );
    return (
        <>
            <AdminHeader />
            <Container className="mt--9" fluid>
                <Card className="shadow">

                    <CardHeader className="border-0">
                        <div className='d-flex justify-content-between'>
                            <div
                                className="d-flex align-items-center"
                            >
                                <Input
                                    type="select"
                                    defaultValue={isAllUsers}
                                    onChange={(e) => {
                                        setIsAllUsers(e.target.value === 'true');
                                    }}
                                >
                                    <option value={false}>Subscribed Users</option>
                                    <option value={true}>All Users</option>
                                </Input>
                                <h3
                                    className="mb-0 ml-2"
                                >
                                    {`(${users.length})`}
                                </h3>
                            </div>
                            <div
                                className="d-flex"
                            >
                                {excelData.length ?
                                    <CSVLink
                                        data={excelData} headers={headers}
                                        className="btn btn-primary"
                                        onClick={() => setExcelData([])}
                                    >
                                        <i className="lni lni-download"></i>Download Excel
                                    </CSVLink>
                                    :
                                    <button className="btn btn-primary" onClick={getExportUser}>Create Excel</button>
                                }
                                <button className="btn btn-primary mx-2" onClick={() => {
                                    if (selectedUsers.length) {
                                        setAddCouponModalOpen(true);
                                    } else {
                                        toast.error('Please select users to add coupon');
                                    }
                                }}>
                                    {
                                        selectedUsers.length ? `Add Coupon to ${selectedUsers.length} users` : `Add Coupon to users`
                                    }
                                </button>
                            </div>
                        </div>
                    </CardHeader>

                    <Table className="align-items-center table-flush" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col"
                                    style={{
                                        width: 50,
                                        cursor: 'pointer',
                                        color: selectedUsers.length ? 'blue' : 'inherit',
                                        userSelect: 'none',
                                        borderRadius: 5
                                    }}
                                    onClick={() => {
                                        setSelectedUsers([]);
                                    }}
                                >
                                    {
                                        selectedUsers.length > 0 && `Clear`
                                    }
                                </th>
                                <th scope="col">Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Subscription Tier</th>
                                <th scope="col">Remaining Videos</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(users) && users?.map((e, i) => (
                                    <tr key={e._id}>
                                        <th scope="row">
                                            <input
                                                type="checkbox"
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        setSelectedUsers([...selectedUsers, e.target.value]);
                                                    } else {
                                                        setSelectedUsers(selectedUsers.filter((cur) => cur !== e.target.value));
                                                    }
                                                }}
                                                value={e._id}
                                                checked={selectedUsers.includes(e._id)}
                                            />
                                        </th>
                                        <th scope="row">
                                            {e?.first_name} {e?.last_name}
                                        </th>
                                        <td>{e?.email}</td>
                                        <td>
                                            {e.currentSubscription?.subscription_tier || 'NA'}
                                        </td>
                                        <td>
                                            {e.currentSubscription?.video_count - e.currentSubscription?.watchedVideos?.length || 'NA'}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </Table>
                </Card>
                <Modal
                    isOpen={addCouponModalOpen}
                    toggle={() => {
                        setAddCouponModalOpen(!addCouponModalOpen);
                    }}
                >
                    <ModalHeader>
                        <h4>{`Add Coupon to ${selectedUsers.length} users`}</h4>
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col
                                xs={12}
                            >
                                <FormGroup>
                                    <Input
                                        type="select"
                                        onChange={(e) => {
                                            setSelectedCouponId(e.target.value);
                                        }}
                                    >
                                        <option value={null}>Select Coupon</option>
                                        {
                                            Array.isArray(coupons) && coupons.map((e, i) => (
                                                <option key={e._id} value={e._id}>{e.coupon_code}</option>
                                            ))
                                        }
                                    </Input>
                                </FormGroup>
                                <p>
                                    {
                                        selectedCouponId && `Are you sure you want to add this coupon to ${selectedUsers.length} users?`
                                    }
                                </p>
                                <Button
                                    color="primary"
                                    disabled={!selectedCouponId}
                                    onClick={handleAddCouponToUsers}
                                >
                                    Add Coupon
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </Container>
        </>
    )
}
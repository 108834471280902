import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useCookies } from 'react-cookie';
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, Navbar, Nav, Container, Media } from "reactstrap";
import { LogoutAction } from "../../actions/userActions";
import _fetch from "../../_fetch";

export default function UserNavBar({ brandText }) {

    let history = useHistory();
    let dispatch = useDispatch();
    const { first_name, profile_picture } = useSelector(st => st.user);
    const [cookie] = useCookies(['cart', 'live_class_count']);

    function logout() {
        _fetch(process.env.REACT_APP_API + '/user/login', { method: "DELETE" })
            .then(res => {
                if (res.success) {
                    dispatch(LogoutAction);
                    return history.push('/login');
                }
                toast.error('Some error occurred please try again later.')
            })
            .catch(err => {
                console.log(err);
                toast.error('Some error occurred please try again.')
            })
    }

    return (
        <>
            <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
                <Container fluid className='align-items-start'>

                    <div className='ml-2'>
                        <Link
                            className="mb-0 text-white text-lg d-none d-lg-inline-block"
                            to="/dashboard"
                        >
                            Welcome {first_name} to your personal dashboard !
                        </Link>
                    </div>
                    <Nav className="align-items-center d-none d-md-flex" navbar>


                        <Link to='/buy-classes' className="d-flex align-items-center pointer btn btn-info">
                            <i className="lni lni-cart text-xl text-white" />
                            <span className='align-middle pr-2'>
                                My Cart
                            </span>
                            {(cookie.live_class_count || cookie.workout_video_count) &&
                                <span className='bg-primary lh-140 py-1 px-2 text-xs rounded-circle text-white'>
                                    {
                                        (cookie.workout_video_count ? parseInt(cookie.workout_video_count) : 0)
                                        +
                                        (cookie.live_class_count ? parseInt(cookie.live_class_count) : 0)
                                    }
                                </span>
                            }
                        </Link>

                        <UncontrolledDropdown nav>
                            <DropdownToggle className="pr-0" nav>
                                <Media className="align-items-center">
                                    <span className="avatar avatar-sm rounded-circle">
                                        {
                                            profile_picture
                                                ?
                                                <img alt={first_name} src={profile_picture} />
                                                :
                                                <div className='text-lg'>{first_name?.length > 0 ? first_name[0] : ''}</div>
                                        }
                                    </span>
                                    <span className="ml-2 d-none d-lg-block mb-0 text-sm font-weight-bold">
                                        {first_name}
                                    </span>
                                </Media>
                            </DropdownToggle>

                            <DropdownMenu className="dropdown-menu-arrow" right>
                                <DropdownItem className="noti-title" header tag="div">
                                    <h6 className="text-overflow m-0">Welcome!</h6>
                                </DropdownItem>
                                <DropdownItem to="/profile" tag={Link}>
                                    <i className="lni lni-user"></i>
                                    <span>My profile</span>
                                </DropdownItem>
                                <DropdownItem divider />
                                <DropdownItem onClick={logout}>
                                    <i className="lni lni-power-switch"></i>
                                    <span>Logout</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </Nav>

                </Container>
            </Navbar>
        </>
    );
};

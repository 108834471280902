import React from 'react';

export default function Loader({ size = 150, thickness = 0.04 }) {
	return (
		<div
			className='loader m-auto'
			style={{
				width: size,
				height: size,
				borderRadius: size,
				borderWidth: `${thickness * size}px`,
				borderStyle: 'solid'
			}}
		/>
	);
}
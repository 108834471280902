import * as Sentry from '@sentry/react';
const initialState = {
    email: '',
    first_name: '',
    last_name: '',
    _id: '',
    scope: '',
    classes: [],
    live_classes_count: 0,
    expired: [],
    enrollments: [],
    live_enrollments: [],
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {

        case 'LOGIN':
            Sentry.setUser(action.payload);
            return ({
                ...state,
                ...action.payload
            });

        case 'LOGOUT':
            return ({
                email: '',
                first_name: '',
                last_name: '',
                _id: '',
                admin: false,
                enrollments: [],
                live_enrollments: [],
                watched_live_classes: 0,
                watched_recorded_classes: 0,
                total_live_classes: 0,
                total_recorded_classes: 0,
                expired: []
            });

        case 'UPDATE_LIVE_CLASSES_COUNT':
            return ({
                ...state,
                total_live_classes: action.total,
                watched_live_classes: action.watched,
                live_enrollments: action.enrollments
            });

        case 'UPDATE_RECORDED_CLASSES':
            return ({
                ...state,
                total_recorded_classes: action.total,
                watched_recorded_classes: action.watched,
                enrollments: action.enrollments
            });

        case 'UPDATE_DIET':
            return ({
                ...state,
                diet: action.diet
            });

        case 'UPDATE_CONSULTATION':
            return ({
                ...state,
                calls: action.calls
            });

        case 'SET_EXPIRED_CLASSES':
            return ({
                ...state,
                expired: action.expired
            });

        default: return state;
    }
}
import React from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import './styles.scss';

export default function CSVideoConfirmation({
    isOpen,
    toggle,
    onContinuePress = () => { }
}) {

    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
        >
            <ModalBody className='p-0' style={{ position: 'relative', borderRadius: '20px' }}>
                <div style={{ position: 'relative', width: '100%', height: 'auto', borderRadius: '20px' }}>
                    <div className='linear-gradient'></div>
                    <img src='https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/medium_intensity.webp' style={{
                        width: '100%',
                        height: 'auto',
                        zIndex: 1,
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                    }} alt='img' />

                    <div className='d-flex flex-column p-3' style={{
                        position: 'absolute',
                        bottom: 0,
                        color: 'white',
                        zIndex: 3
                    }}>
                        <h2 className='text-white font-weight-bold'>
                            Are you Sure?
                        </h2>
                        <p className='text-white font-weight-bold'>
                            Watching this video may deduct 1 video from your subscription.
                        </p>
                    </div>
                </div>

                <div className='d-flex flex-column justify-content-center align-items-center p-2 mt-2 mb-2'>
                    <Button
                        className='mb-3'
                        style={{
                            width: '80%',
                            borderRadius: '20px',
                            background: 'linear-gradient(92.99deg, #EEA4CE -20.95%, #C58BF2 52.48%)',
                            color: 'white',
                            borderColor: 'none'
                        }}
                        onClick={onContinuePress}
                    >
                        Continue
                    </Button>
                    <h4
                        className='text-font-size mt-2'
                        onClick={toggle}
                        style={{
                            color: 'red',
                            cursor: 'pointer'
                        }}>Cancel</h4>
                </div>
            </ModalBody>
        </Modal>
    );
}

import React, { useState } from 'react';
// import './style.css';
import Header2 from '../../components/Headers/Header2';
import Footer from '../../components/Footers/AuthFooter';
import { Link } from "react-router-dom";
import { TabContent, TabPane, NavLink, Collapse } from 'reactstrap';
import classnames from 'classnames';


export default function Testimonial({ slides, className }) {

    const [toggleQuestion, setToggequestion] = useState(0);

    const [activeTab, setActiveTab] = useState('4');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <>
            <Header2 />

            <div className="pricing-plan-bg page-head parallax overlay">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-center">
                                <h3>CLASSES</h3>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <ol className="breadcrumb">
                                <li><Link to="/">HOME</Link></li>
                                <li>।</li>
                                <li>CLASSES</li>
                            </ol>
                        </div>

                    </div>

                </div>

            </div>


            <div className="single-tab-area pad90">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-4 col-md-12">

                            <div className="single-tab">
                                <ul id="single-tab" className="nav nav-tabs single-tab-link">
                                    <li className="nav-item full-width">
                                        <NavLink
                                            className={classnames({ active: activeTab === '4' })}
                                            onClick={() => { toggle('4'); }}
                                        >
                                            Schedule
                                        </NavLink>
                                    </li>
                                    <li className="nav-item full-width">
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { toggle('1'); }}
                                        >
                                            6 Weeks Fitness Challenge
                                        </NavLink>
                                    </li>
                                    <li className="nav-item full-width">
                                        <NavLink
                                            className={classnames({ active: activeTab === '2' })}
                                            onClick={() => { toggle('2'); }}
                                        >
                                            HIIT<span>s</span> - HIGH INTENSITY INTERVAL TRAINING
                                        </NavLink>
                                    </li>
                                    <li className="nav-item full-width">
                                        <NavLink
                                            className={classnames({ active: activeTab === '3' })}
                                            onClick={() => { toggle('3'); }}
                                        >
                                            MIIT<span>s</span> - MEDIUM INTENSITY INTERVAL TRAINING
                                        </NavLink>
                                    </li>


                                </ul>
                            </div>

                            <div className="subscribe-box">
                                <div className="subs-title">
                                    <i className="lni lni-whatsapp" aria-hidden="true"></i>
                                    <p>Click the button below to connect with me</p>
                                    <div className="bttn">
                                        <a rel="noreferrer" target="_blank" className="btn active btn-primary" href='https://web.whatsapp.com/send?phone=447584236848&text=Hi%2C%20I%20am%20interested%20in%20knowing%20more%20and%20would%20like%20some%20information%2C%20I%20have%20been%20redirected%20from%20your%20website.'>
                                            Click Here
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-lg-8 col-md-12">

                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <div className="services-content">


                                        <div className="service-title">

                                            <div className="cta-box pad90 services_page">
                                                <div className="row">
                                                    <div className="col-md-5 col-sm-12">
                                                        <div className="services-img">
                                                            <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/service-5.png`} alt=" services img" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 col-sm-12">
                                                        <h5 className="mb300">6 Weeks Fitness Challenge</h5>
                                                        <p className="mb20">WHAT TO EXPECT </p>

                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> Access to unique workouts live and recording. ( medium &amp; high intensity)</p>
                                                                <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> Extra fitness tasks and nutrition tips weekly</p>
                                                                <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> Access to my private challenge groups</p>


                                                                <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> Progress tracking and accountability</p>
                                                                <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> Meal ideas / recipe sharing</p>
                                                                <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> Guidance and mentoring</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>




                                                <p className="button-upper">Anything else - Please feel free to ask!</p>
                                                <div className="bttn">
                                                    <Link to="/login" className="btn active btn-primary">Buy Now</Link>
                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-lg-6">

                                                    <div id="accordion" className="mt70" role="tablist">
                                                        <div className="card">
                                                            <div className="card-header plus-img" role="tab" id="headingOne">
                                                                <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/plus_icon.png`} alt="plus icon" />
                                                                <NavLink className="faq-list" onClick={() => setToggequestion(1)}>

                                                                    When do we start?</NavLink>
                                                            </div>
                                                            <Collapse isOpen={toggleQuestion === 1 ? true : false}>
                                                                <div className="card-body">
                                                                    <p>You can join in anytime and commit for your 6 weeks of fitness challenge!</p>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header plus-img" role="tab" id="headingTwo">
                                                                <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/plus_icon.png`} alt="plus icon" />
                                                                <NavLink className="faq-list" onClick={() => setToggequestion(2)}>How do I book for 6 weeks?</NavLink>

                                                            </div>
                                                            <Collapse isOpen={toggleQuestion === 2 ? true : false}>
                                                                <div className="card-body">
                                                                    <p>You need to register yourself on the website and <Link > create your account on Fitness Stars dashboard </Link>. All classes are booked and accessed on it. If you wish to try a session before you book, contact me !</p>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header plus-img" role="tab" id="headingThree">
                                                                <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/plus_icon.png`} alt="plus icon" />
                                                                <NavLink className="faq-list" onClick={() => setToggequestion(3)}>Is it suitable for beginners?</NavLink>

                                                            </div>
                                                            <Collapse isOpen={toggleQuestion === 3 ? true : false}>
                                                                <div className="card-body">
                                                                    <p>Yes!<br />
                                                                        I do 10 live sessions in a week. 4 high intensity, 4 medium intensity and 2 weight training sessions. Medium intensity and weight trainings are suitable for beginners.</p>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header plus-img" role="tab" id="headingfive">
                                                                <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/plus_icon.png`} alt="plus icon" />
                                                                <NavLink className="faq-list" onClick={() => setToggequestion(4)}>What are the packages for Nutrition support?</NavLink>

                                                            </div>
                                                            <Collapse isOpen={toggleQuestion === 4 ? true : false}>
                                                                <div className="card-body">
                                                                    <p>I offer a customised diet support consultation package to get you the best results during these 6 weeks. Please refer to the <Link> packages</Link> section on the website for more information.</p>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-lg-6">

                                                    <div id="accordion" className="mt70" role="tablist">

                                                        <div className="card">
                                                            <div className="card-header plus-img" role="tab" id="headingsix">
                                                                <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/plus_icon.png`} alt="plus icon" />
                                                                <NavLink className="faq-list" onClick={() => setToggequestion(5)}>What's more for the challenge members?</NavLink>

                                                            </div>
                                                            <Collapse isOpen={toggleQuestion === 5 ? true : false}>
                                                                <div className="card-body">
                                                                    <p>Access to my members only WhatsApp &amp; Facebook groups. You will be part of a fitness loving global community and will be getting extra fitness &amp; nutrition tasks from time to time.</p>

                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header plus-img" role="tab" id="headingOne">
                                                                <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/plus_icon.png`} alt="plus icon" />
                                                                <NavLink className="faq-list" onClick={() => setToggequestion(6)}>How to attend these sessions?</NavLink>

                                                            </div>
                                                            <Collapse isOpen={toggleQuestion === 6 ? true : false}>
                                                                <div className="card-body">
                                                                    <p>For all Live Classes &amp; Recordings Access go to your Live Class calendar on your <Link> Fitness Stars personal dashboard</Link>. You can either join Live classes by clicking on 'Join LIVE' at the scheduled time or you can access the recording of the live class by clicking on 'Watch Now' button. The classes are catered to all fitness levels from beginners to advance.</p>
                                                                </div>
                                                            </Collapse>
                                                        </div>
                                                        <div className="card">
                                                            <div className="card-header plus-img" role="tab" id="headingTwo">
                                                                <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/plus_icon.png`} alt="plus icon" />
                                                                <NavLink className="faq-list" onClick={() => setToggequestion(7)}>What are the different types of sessions I do?</NavLink>

                                                            </div>
                                                            <Collapse isOpen={toggleQuestion === 7 ? true : false}>
                                                                <div className="card-body">
                                                                    <p>3 types of sessions in a week:
                                                                        <br />
                                                                        1. HIIT - High intensity sessions : These are best suited to people who have been into fitness for a while. However, I always give less intense variations of all exercises in the session.<br />
                                                                        2. Medium Intensity sessions in evenings : These are perfect for beginners to intermediate fitness level. The focus will be to enhance stamina and strength.<br />
                                                                        3. Weight training sessions: This are suitable for all levels (beginners can use lighter weights)

                                                                    </p>
                                                                </div>
                                                            </Collapse>
                                                        </div>


                                                    </div>



                                                </div>

                                            </div>
                                            <div className="video-class">
                                                <p className="video-heading">6 WEEK FITNESS CHALLENGE</p>
                                                <div className="embed-responsive embed-responsive-21by9">
                                                    <iframe title="asasd" className="embed-responsive-item" src="https://www.youtube.com/embed/TbNNiu_Atjg"></iframe>
                                                </div>
                                            </div>


                                        </div>

                                    </div>
                                </TabPane>

                                <TabPane tabId="2">
                                    <div className="services-content">
                                        <div className="service-title">
                                            <h5 className="mb300">HIIT<span>s</span> - High intensity interval training</h5>
                                            <p className="quote mt20">Suitable for intermediate and advance level</p>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <div className="class-time">
                                                            <p className="schedule-heading">HIGH INTENSITY SCHEDULE (45 - 50 MIN)</p>
                                                            <div className="row">
                                                                <div className="col-md-6 bold-type">
                                                                    <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> TUESDAY – 6:30 AM – STAMINA / CORE / BALANCE</p>
                                                                    <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> WEDNESDAY – 6:30 AM – RESISTANCE TRAINING (WITH DUMMBELLS)</p>
                                                                    <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> THURSDAY – 6:30 AM – LEGS / LOWER BODY
                                                                    </p>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> FRIDAY – 6:30 AM – ABS</p>
                                                                    <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> SATURDAY – 8:00 AM – FULL BODY
                                                                    </p>
                                                                    <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> SUNDAY – 8:00 AM – UPPER BODY (WITH DUMMBELLS)</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="service-box">
                                                            <div className="price-box high">
                                                                <div className="price-quantity">

                                                                    <div className="price-dtl packages">
                                                                        <ul className="line-color">
                                                                            <li className="first-child">45 - 50 min live workouts</li>
                                                                            <li>Recordings available throughout the week</li>
                                                                            <li>Six days a week</li>
                                                                            <li>Every day has a different focus ( Stamina / Strength training / Lower body / Full body / Abs / Upper body)</li>
                                                                            <li>Plenty of new exercises and techniques to train each and every muscle</li>
                                                                            <li>Variations - my USP - no 2 session are ever the same</li>
                                                                            <li>Modification of all exercises to suit your fitness level</li>
                                                                            <li>No equipment needed (except strength training day)</li>
                                                                        </ul>
                                                                        <div className="price-btn bttn">
                                                                            <button type="submit" className="btn btn-primary">Buy now</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                                <div className="video-class">
                                                    <p className="video-heading">FULL BODY HIGH INTENSITY WORKOUT</p>
                                                    <div className="embed-responsive embed-responsive-21by9">
                                                        <iframe title="sfsdf" className="embed-responsive-item" src="https://www.youtube.com/embed/_S6lzO_aZ-o"></iframe>
                                                    </div>
                                                </div>

                                                <div className="video-class">
                                                    <p className="video-heading">STRENGTH TRAINING WITH DUMBBELLS WORKOUT</p>
                                                    <div className="embed-responsive embed-responsive-21by9">
                                                        <iframe title="sdfs" className="embed-responsive-item" src="https://www.youtube.com/embed/FHtGIgVV-3E"></iframe>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                </TabPane>

                                <TabPane tabId="3">
                                    <div className="services-content">

                                        <div className="service-title">
                                            <h5 className="mb300">MIIT<span>s</span> - MEDIUM INTENSITY INTERVAL TRAINING</h5>
                                            <p className="quote mt20">Suitable for Beginners to Intermediate fitness level
                                            </p>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="class-time">
                                                        <p className="schedule-heading">MEDIUM INTENSITY FULL BODY SCHEDULE (40 - 45 MIN)</p>
                                                        <div className="row">
                                                            <div className="col-md-12 bold-type">
                                                                <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> TUESDAY – 6:15 PM </p>
                                                                <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> WEDNESDAY – 6:15 PM</p>
                                                                <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> THURSDAY – 6:15 PM </p>
                                                                <p className="mt20"><img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/tick-icon.png`} width="20" height="20" alt=" services img" /> FRIDAY – 6:15 PM </p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="service-box">
                                                        <div className="price-box high">
                                                            <div className="price-quantity">

                                                                <div className="price-dtl packages">
                                                                    <ul className="line-color">
                                                                        <li className="first-child">40 - 45 min live workouts</li>
                                                                        <li>Recordings available throughout the week</li>
                                                                        <li>4 days a week</li>
                                                                        <li>Full body circuits</li>
                                                                        <li>Plenty of new exercises and techniques to train each and every muscle
                                                                        </li>
                                                                        <li>Variations - my USP - no 2 session are ever the same</li>
                                                                        <li>Modification of all exercises to suit your fitness level</li>
                                                                        <li>No equipment needed (except strength training day)</li>
                                                                    </ul>
                                                                    <div className="price-btn bttn">
                                                                        <button type="submit" className="btn btn-primary">Buy now</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="video-class">
                                                <p className="video-heading">THIS MEDIUM INTENSITY WORKOUT (MIIT)</p>
                                                <div className="embed-responsive embed-responsive-21by9">
                                                    <iframe title="sda" className="embed-responsive-item" src="https://www.youtube.com/embed/ZYbJIaKapL4"></iframe>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </TabPane>

                                <TabPane tabId="4">
                                    <div className="services-content">

                                        <div className="service-title">
                                            <h5 className="mb300">Live Class schedule</h5>
                                            <p className="quote mt20">All classes are conducted live and are recorded. The recordings of the live classes will be available on your personal dashboard soon after the class is finished. You can access them anytime in the day. The calendar holds recordings of classes of previous weeks.</p>
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-md-10">
                                                        <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/schedule.jpeg`} alt='..' className='img img-fluid'/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </TabPane>

                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    )

}
import React, { useEffect, useState } from "react";
import { Button, Card, CardHeader, CardBody, Input, Row, Col, InputGroup, InputGroupAddon, FormGroup, Label } from "reactstrap";
import { useCookies } from "react-cookie";
import NumericInput from 'react-numeric-input';

import _fetch from '../../_fetch';
import Loader from '../../components/Loader/loader';
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const init_state = {
    discount: 0,
    min_class_count: 0,
    max_class_count: 0,
    class_type: ''
};

const REACT_APP_PER_CLASS_PRICE = parseInt(process.env.REACT_APP_PER_CLASS_PRICE);
console.log(REACT_APP_PER_CLASS_PRICE)
export default function OrderForm({ setOrder }) {

    const [applied_promo_code, setAppliedPromoCode] = useState(init_state);
    const [promo_code, setPromoCode] = useState('');
    const [live_classes, setLiveClasses] = useState(0);
    const [recorded_classes, setRecordedClasses] = useState(0);
    const [total, setTotal] = useState(0);
    const [applying, setApplying] = useState(false);
    const [redirecting, setRedirecting] = useState(false);
    const [consent, setConsent] = useState(false);
    const [diet_plan, setDietPlan] = useState('');
    const [pt_plan, setPtPlan] = useState("");
    const [pt_cnt, setPtCnt] = useState(0);

    const [cookie, setCookie, removeCookie] = useCookies(['cart', 'live_class_count', 'diet_package', 'workout_video_count', 'promo_code']);

    let { user } = useSelector(st => st.cart);

    useEffect(() => {

        if (cookie.live_class_count && !isNaN(parseInt(cookie.live_class_count, 10))) {
            setLiveClasses(parseInt(cookie.live_class_count, 10));
        } else setLiveClasses(10);

        if (cookie.diet_package) {
            setDietPlan(cookie.diet_package);
        }

        if (cookie.pt_package) {
            setPtPlan(cookie.pt_package);
        }
        if (cookie.pt_count && !isNaN(parseInt(cookie.pt_count, 10))) {
            setPtCnt(parseInt(cookie.pt_count, 10));
        }

        if (cookie.workout_video_count && !isNaN(parseInt(cookie.workout_video_count, 10))) {
            setRecordedClasses(parseInt(cookie.workout_video_count, 10));
        } else setRecordedClasses(10);

        if (cookie.promo_code) {
            setPromoCode(cookie.promo_code);
            ApplyPromoCode(cookie.promo_code);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        const total_classes = live_classes;
        let live_class_discount = 0;
        let recorded_class_discount = 0;
        let diet_discount = 0;
        let diet_package_price = 0;
        let pt_discount = 0;
        let pt_package_price = 0;
        let promo = applied_promo_code;

        if (diet_plan === '6_WEEKS') {
            diet_package_price = process.env.REACT_APP_6_WEEK_DIET_PRICE;
        } else if (diet_plan === '60_MINS') {
            diet_package_price = 40;
        } else if (diet_plan === '12_WEEKS') {
            diet_package_price = parseInt(process.env.REACT_APP_12_WEEK_DIET_PRICE, 10);
        }

        if (pt_plan === "30_MINS_PT") {
            pt_package_price = parseInt(process.env.REACT_APP_30_MIN_PT_PRICE, 10);
        } else if (pt_plan === "45_MINS_PT") {
            pt_package_price = parseInt(process.env.REACT_APP_45_MIN_PT_PRICE, 10);
        }
        
        switch (promo.class_type) {
            case 'LIVE':
                if (promo.min_class_count <= live_classes && promo.max_class_count >= live_classes) {
                    live_class_discount = promo.discount;
                }
                break;
            case 'CLASSES_ONLY':
                if (promo.min_class_count <= total_classes && promo.max_class_count >= total_classes) {
                    live_class_discount = promo.discount;
                    recorded_class_discount = promo.discount;
                }
                break;
            case '6_WEEKS':
                console.log(total_classes, promo.min_class_count, promo.max_class_count)
                if (promo.min_class_count <= total_classes && promo.max_class_count >= total_classes) {
                    if (diet_plan === '6_WEEKS')
                        diet_discount = promo.discount;
                }
                break;
            case '12_WEEKS':
                console.log(total_classes, promo.min_class_count, promo.max_class_count)
                if (promo.min_class_count <= total_classes && promo.max_class_count >= total_classes) {
                    if (diet_plan === '12_WEEKS')
                        diet_discount = promo.discount;
                }
                break;
            case '60_MINS':
                if (promo.min_class_count <= total_classes && promo.max_class_count >= total_classes) {
                    if (diet_plan === '60_MINS')
                        diet_discount = promo.discount;
                }
                break;
            case "30_MINS_PT":
                if (
                    promo.min_class_count <= total_classes &&
                    promo.max_class_count >= total_classes
                ) {
                    if (pt_plan === "30_MINS_PT") pt_discount = promo.discount;
                }
                break;
            case "45_MINS_PT":
                if (
                    promo.min_class_count <= total_classes &&
                    promo.max_class_count >= total_classes
                ) {
                    if (pt_plan === "45_MINS_PT") pt_discount = promo.discount;
                }
                break;
            default:
                if (promo.min_class_count <= total_classes && promo.max_class_count >= total_classes) {
                    live_class_discount = promo.discount;
                    recorded_class_discount = promo.discount;
                    diet_discount = promo.discount;
                }
        }

        // remove promo code if class count lies outside promo code limits
        if (
            promo.discount &&
            !(
                live_class_discount ||
                recorded_class_discount ||
                diet_discount ||
                pt_discount
            )
        ) {
            toast.error(
                `This promo code can be applied ${["LIVE", "RECORDED"].includes(promo.class_type)
                    ? `on ${promo.class_type.toLowerCase()} classes `
                    : ""
                }only if total ${["LIVE", "RECORDED"].includes(promo.class_type)
                    ? `${promo.class_type.toLowerCase()} `
                    : ""
                } classes are between ${promo.min_class_count} and ${promo.max_class_count
                }`
            );
            setPromoCode('');
            removeCookie('promo_code');
            return setAppliedPromoCode(init_state);
        }

        let total =
            (
                live_classes * REACT_APP_PER_CLASS_PRICE * (100 - live_class_discount) +
                diet_package_price * (100 - diet_discount) +
                pt_package_price * pt_cnt * (100 - pt_discount)) /
            100;
        setTotal(total);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [live_classes, applied_promo_code, diet_plan, pt_cnt]);

    function ApplyPromoCode(_promo_code) {

        if (!_promo_code) return;

        setApplying(true);
        _fetch(`${process.env.REACT_APP_API}/promo_code?promo_code=${typeof _promo_code === 'string' ? _promo_code : promo_code}`)
            .then(res => {
                if (res.success) {
                    return setAppliedPromoCode(res.response);
                }
                toast.error('Promo code doesn\'t exist or has expired');
                setAppliedPromoCode(init_state);
                removeCookie('promo_code');
            })
            .catch(err => {
                console.log(err);
                setAppliedPromoCode(init_state);
                toast.error('Promo code doesn\'t exist or has expired');
                removeCookie('promo_code');
            })
            .finally(() => setApplying(false));
    }

    const redirectToPaymentPage = async function () {

        try {

            let body = {};

            if (promo_code) body.promo_code = promo_code;
            if (live_classes) body.live_classes_count = live_classes;
            if (diet_plan) body.diet_plan = diet_plan;
            if (pt_plan && pt_cnt) {
                body.pt_package = pt_plan;
                body.pt_count = pt_cnt;
            }

            console.log(body);

            if (!user.first_name) {
                toast.error('Please enter your first name')
                return;
            }
            if (!user.last_name) {
                toast.error('Please enter your first name')
                return;
            }
            if (!user.email) {
                toast.error('Please enter your email')
                return;
            }

            body = {
                ...body,
                ...user
            };

            setRedirecting(true);
            let order_data = await _fetch(`${process.env.REACT_APP_API}/order`, {
                method: 'POST',
                body
            });

            if (order_data.success) {
                setRedirecting(false);
                setOrder(order_data.response);
            } else {
                setRedirecting(false);
                return toast.error(order_data.response);
            }

        } catch (err) {
            console.log(err);
            setRedirecting(false);
        }
    }

    const promo_code_applicable_message = {
        LIVE: "This promo code is applicable only on Live Classes",
        RECORDED: "This promo code is applicable only on Workout Videos",
        "60_MINS": "This promo code is applicable only on 60 minute nutrition support consultation call",
        "6_WEEKS": "This promo code is applicable only on 6 week nutrition support plan",
        "12_WEEKS": "This promo code is applicable only on 12 week nutrition support plan",
    };

    const diet_prices = {
        "60_MINS": process.env.REACT_APP_60_MIN_CALL_PRICE,
        "6_WEEKS": process.env.REACT_APP_6_WEEK_DIET_PRICE,
        "12_WEEKS": process.env.REACT_APP_12_WEEK_DIET_PRICE,
    }

    const pt_prices = {
        "30_MINS_PT": process.env.REACT_APP_30_MIN_PT_PRICE,
        "45_MINS_PT": process.env.REACT_APP_45_MIN_PT_PRICE,
    };

    return (
        <Card className="shadow">
            <CardHeader className="border-bottom">
                <Row className="align-items-center">
                    <Col xs="8">
                        <h3 className="mb-0">Order Summary</h3>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className="px-4">

                <Row>
                    <Col xs={7} md={8} className='font-weight-600'>Product</Col>
                    <Col xs={5} md={4} className='font-weight-600'>Subtotal</Col>
                </Row>
                <hr className="my-4" />

                <Row>
                    <Col xs={9} md={8}>
                        Live Classes<br />&#38; Recordings&emsp;
                        <span className='font-weight-600'>
                            <i className='ni ni-fat-remove text-lg align-text-bottom'></i>&nbsp;
                            <NumericInput className='card-total-class'
                                min={0} step={1}
                                onChange={(val) => {
                                    setCookie('live_class_count', val);
                                    setLiveClasses(val)
                                }}
                                value={live_classes}
                            />
                        </span>
                    </Col>
                    <Col xs={3} md={4} className='font-weight-600'>£{live_classes * REACT_APP_PER_CLASS_PRICE}</Col>
                </Row>
                <hr className="my-4" />


                {/* <Row>
                    <Col xs={9} md={8}>
                        Workout Videos&emsp;
                        <span className='font-weight-600'>
                            <i className='ni ni-fat-remove text-lg align-text-bottom'></i>&nbsp;
                            <NumericInput className='card-total-class'
                                min={0} step={1}
                                onChange={(val) => {
                                    setCookie('workout_video_count', val);
                                    setRecordedClasses(val)
                                }}
                                value={recorded_classes}
                            />
                        </span>
                    </Col>
                    <Col xs={3} md={4} className='font-weight-600'>£{recorded_classes * REACT_APP_PER_CLASS_PRICE}</Col>
                </Row>
                <hr className="my-4" /> */}

                <Row>
                    <Col xs={9} md={8}>
                        <Input
                            className="py-2"
                            type="select"
                            name="select"
                            id="input-diet_plan"
                            value={diet_plan}
                            onChange={(ev) => {
                                setDietPlan(ev.target.value);
                                if (ev.target.value !== "") {
                                    setCookie("diet_package", ev.target.value);
                                }
                            }}
                        >
                            <option value="">Select Diet Plan</option>
                            <option value="60_MINS">60 Minutes Diet Consultation Call</option>
                            <option value="6_WEEKS">6 Week Nutrition Support Plan</option>
                            <option value="12_WEEKS">12 Week Nutrition Support Plan</option>
                        </Input>
                    </Col>
                    <Col xs={3} md={4} className="font-weight-600">
                        {diet_plan === "" ? "£0" : "£" + diet_prices[diet_plan]}
                    </Col>
                </Row>
                <hr className="my-4" />

                <Row>
                    <Col xs={9} md={8}>
                        <Input
                            className="py-2 my-2"
                            type="select"
                            name="select"
                            id="input-pt_count"
                            value={pt_plan}
                            onChange={(ev) => {
                                setPtPlan(ev.target.value);
                                if (ev.target.value === "") {
                                    setPtCnt(0);
                                } else {
                                    setCookie("pt_package", ev.target.value);
                                }
                            }}
                        >
                            <option value="">Select PT Package</option>
                            <option value="30_MINS_PT">30 Min PT</option>
                            <option value="45_MINS_PT">45 Min PT</option>
                        </Input>
                        <NumericInput
                            className="card-total-class"
                            min={0}
                            step={1}
                            onChange={(val) => {
                                setCookie("pt_count", val);
                                setPtCnt(val);
                            }}
                            value={pt_cnt}
                        />
                    </Col>
                    <Col xs={3} md={4} className="font-weight-600">
                        {pt_plan === "" ? "£0" : "£" + pt_prices[pt_plan] * pt_cnt}
                    </Col>
                </Row>
                <hr className="my-4" />

                <Row>
                    <Col xs={12} md={8} className='pr-4'>
                        <InputGroup>
                            <Input
                                placeholder="promo code" value={promo_code}
                                onChange={(ev) => setPromoCode(ev.target.value)}
                            />
                            <InputGroupAddon addonType="append">
                                <Button color="primary" onClick={ApplyPromoCode} disabled={applying}>
                                    {applying ? <Loader size={25} /> : null} Apply
                                </Button>
                            </InputGroupAddon>
                        </InputGroup>
                        {
                            promo_code_applicable_message[applied_promo_code.class_type]
                                ?
                                <div className='text-xs text-info'>
                                    {promo_code_applicable_message[applied_promo_code.class_type]}
                                </div>
                                :
                                null
                        }
                    </Col>
                    <Col xs={12} md={4} className='font-weight-600 my-2'>
                        {applied_promo_code.discount}% Discount
                    </Col>
                </Row>
                <hr className="my-4" />

                <Row>
                    <Col xs={9} md={8} className='font-weight-600'>Total</Col>
                    <Col xs={3} md={4} className='font-weight-600'>£{total}</Col>
                </Row>

                <hr className="my-4" />

                <p className='text-xs' style={{ lineHeight: '14px' }}>
                    You assume the risk of your participation in any activity during this program. You agree that you are voluntarily participating in the aforementioned activities and assume all risk of injury, illness, damage, or loss to you or your property that might result, including, without limitation, any loss or theft of any personal property, whether arising out of the negligence of Trainer or otherwise. By execution of this agreement, you hereby agree to indemnify and hold harmless Trainer from any loss, liability, damage, or cost Trainer may incur due to the provision of fitness training by Trainer to you.
                </p>
                <p className='text-xs' style={{ lineHeight: '14px' }}>
                    You acknowledge that Trainer offers a service to his/her clients encompassing the entire recreational and/or fitness spectrum / nutrition programme. You agree on behalf of yourself to release and discharge Trainer from any and all claims or causes of action (known or unknown) arising out of the negligence of Trainer, whether active or passive.
                </p>
                <p className='text-xs' style={{ lineHeight: '14px' }}>
                    All bookings have a 6 or 12-week expiry depending on the amount of classes booked and no refunds will be issued once booked.
                </p>

                <FormGroup check>
                    <Label check className='mb-3'>
                        <Input type="checkbox" id="checkbox2" onClick={() => setConsent(!consent)} />{' '}
                        I consent
                    </Label>
                </FormGroup>

                <Row>
                    <Col xs={6} className='text-center'>
                        <Button
                            type='button' color='danger' className='w-100'
                            onClick={() => {
                                removeCookie('cart');
                                removeCookie('live_class_count');
                                removeCookie('workout_video_count');
                                removeCookie('diet_package');
                                removeCookie('promo_code');
                                setLiveClasses(0);
                                setDietPlan('');
                                setRecordedClasses(0);
                                setPromoCode('');
                                setAppliedPromoCode(init_state);
                                setTotal(0);
                                setPtPlan('');
                                setPtCnt(0);
                            }} disabled={redirecting}
                        >
                            Clear Cart
                        </Button>
                    </Col>
                    <Col xs={6} className='text-center'>
                        <Button
                            type='button' color='primary' className='w-100'
                            onClick={redirectToPaymentPage} disabled={redirecting || !consent}
                        >
                            {
                                redirecting ?
                                    <React.Fragment><Loader size={20} />Finalizing order ...</React.Fragment>
                                    :
                                    'Confirm Order'
                            }
                        </Button>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
};

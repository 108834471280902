import React, { useEffect, useState } from "react";
import {
    PaginationItem,
    PaginationLink,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Button,
    Pagination,
    InputGroup,
    Input,
    InputGroupText,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import leftTwigSVG from '../../assets/subscription/flower-1.svg'
import rightTwigSVG from '../../assets/subscription/flower-2.svg'
import Header2 from "../../components/Headers/Header2";
import Footer from "../../components/Footers/AuthFooter";
import _fetch from "../../_fetch";
import Loader from "../../components/Loader/loader.js";
import './styles.scss';

const pageLength = 12;

export default function AllPublishedRecipes() {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [pages, setPages] = useState([]);
    const [recipes, setRecipes] = useState([]);
    const [search, setSearch] = useState("");
    let history = useHistory();

    const limit = 12;

    const getRecipes = async () => {

        try {
            setLoading(true);

            let url = `${process.env.REACT_APP_API}/recipe?page=${page}&limit=${limit}`;

            if (search) {
                url += `&keyword=${search}`;
            }

            let res = await _fetch(url);
            if (res.success) {
                setRecipes(res.response.recipes);
                const tp = Math.ceil(res.response.total / pageLength);
                if (tp) {
                    setTotalPages(tp);
                    let pageNumbers = [];
                    for (
                        let i = 0;
                        i < tp;
                        i++
                    ) {
                        pageNumbers.push(
                            <PaginationItem active={page === i ? true : false}>
                                <PaginationLink onClick={() => {
                                    setPage(i);
                                }} href="#">
                                    {i + 1}
                                </PaginationLink>
                            </PaginationItem>
                        );
                    }
                    setPages(pageNumbers);
                }
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getRecipes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <>
            <Header2 />


            <div className='about-sec'>
                <Container>
                    <Row>
                        <Col md="12">
                            <div className='section-head'>
                                <h3 className='head-font tag-head'>Recipes</h3>
                            </div>
                        </Col>

                        <Col md="12">
                            <ol className="breadcrumb">
                                <li><Link to="/">Home</Link></li>
                                <li className='section-head'>></li>
                                <li className='section-head'>Recipes</li>
                            </ol>
                        </Col>
                    </Row>
                </Container>
            </div>
            <hr></hr>

            <div className='testimonial-sec'>
                <div>
                    <img
                        src={rightTwigSVG}
                        alt='..'
                        className='right-twig-svg'
                        style={{
                            position: 'relative',
                            zIndex: '0',
                            height: '5%',
                            width: '5%',

                        }}
                    />
                </div>
                <div >
                    <h2 className='head-font'>Delight in Nutritious Creations: Healthy </h2>
                    <h2 className='head-font'>Recipes by Our Community Members.</h2>
                </div>
            </div>

            <div>
                <div className="container about-secs" >
                    <div className="row d-flex">
                        {recipes.map((recipe, i) => (
                            <div className="col-12 col-md-6 col-lg-4 padd-bott">
                                <Card
                                    style={{
                                        // width: '18rem'
                                        marginTop: '2%',
                                        marginBottom: '2%',

                                    }}
                                >
                                    <img
                                        alt="Sample"
                                        src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}${recipe.photo_url}`}
                                        style={{
                                            borderRadius: '6%'
                                        }}
                                    />
                                </Card>
                                <div className="d-flex flex-column position-static ">
                                    <h3 className="mb-0 about-desc">{recipe.title}</h3>
                                </div>
                            </div>


                        ))}
                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
}

import React, { useEffect, useState } from 'react';
import './new_styles.scss';
import './responsive.scss';
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import { useCookies } from 'react-cookie';
import {BiMenu} from 'react-icons/bi';

export default function Header2() {

    const { _id } = useSelector(st => st.user);
    const [collapseOpen, setCollapseOpen] = useState();
    const [cookie] = useCookies(['live_class_count', 'workout_video_count']);
    // const { home_headline } = useSelector(st => st.app);

    useEffect(() => console.log(_id), [_id]);

    const toggleCollapse = () => {
        setCollapseOpen((data) => !data);
    };

    // closes the collapse
    const closeCollapse = () => {
        setCollapseOpen(false);
    };

    return (
        <header className="header fixed-top pt-0 pt-md-2">
            <nav className="navbar navbar-expand-lg"
                style={{
                    backgroundColor: 'transparent',
                }}
            >
                <div className='container-fluid'>
                    <div className="navbar-header d-flex flex-row align-items-center justify-content-between">
                        <Link className="navbar-brand" to='/'>
                            <img src={`${process.env.REACT_APP_AWS_BUCKET_PUBLIC}/kwm_web/logo-keepwell.png`} alt="" style={{
                                maxWidth: '200px',
                                objectFit: 'contain',
                            }} />
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            onClick={toggleCollapse}
                        >
                            <BiMenu size={20} color='white' className='navbar-toggler-icon'/>
                        </button>
                    </div>

                    <Collapse navbar isOpen={collapseOpen}>

                        {/* Navigation */}
                        <Nav navbar className='ml-auto flex-wrap'>
                            <NavItem>
                                <NavLink
                                    to={'/home'}
                                    tag={NavLinkRRD}
                                    onClick={closeCollapse}
                                    activeClassName="active"
                                >
                                    Home
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={'/about'}
                                    tag={NavLinkRRD}
                                    onClick={closeCollapse}
                                    activeClassName="active"
                                >
                                    About
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={'/testimonials'}
                                    tag={NavLinkRRD}
                                    onClick={closeCollapse}
                                    activeClassName="active"
                                >
                                    Testimonials
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={'/affilations'}
                                    tag={NavLinkRRD}
                                    onClick={closeCollapse}
                                    activeClassName="active"
                                >
                                    Affilations
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={'/recipes'}
                                    tag={NavLinkRRD}
                                    onClick={closeCollapse}
                                    activeClassName="active"
                                >
                                    Recipes
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={'/packages'}
                                    tag={NavLinkRRD}
                                    onClick={closeCollapse}
                                    activeClassName="active"
                                >
                                    Packages
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={'/coresculptapp'}
                                    tag={NavLinkRRD}
                                    onClick={closeCollapse}
                                    activeClassName="active"
                                >
                                    CoreSculpt App
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={'/client-transformation'}
                                    tag={NavLinkRRD}
                                    onClick={closeCollapse}
                                    activeClassName="active"
                                >
                                    Client Transformation
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={'/contact'}
                                    tag={NavLinkRRD}
                                    onClick={closeCollapse}
                                    activeClassName="active"
                                >
                                    Contact Us
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={'/cart'}
                                    tag={NavLinkRRD}
                                    onClick={closeCollapse}
                                    activeClassName="active"
                                >
                                    <span className='align-middle pr-2'>
                                        My Cart
                                    </span>
                                    {(cookie.live_class_count) &&
                                        <span className='bg-primary text-xs text-white'
                                            style={{
                                                borderRadius: '100px',
                                                padding: '5px',
                                            }}
                                        >
                                            {
                                                (!isNaN(cookie.live_class_count) ? parseInt(cookie.live_class_count) : 0)
                                            }
                                        </span>
                                    }
                                </NavLink>
                            </NavItem>
                            {
                                _id ?
                                    <NavItem>
                                        <NavLink
                                            to={'/dashboard'}
                                            tag={NavLinkRRD}
                                            onClick={closeCollapse}
                                            activeClassName="active"
                                        >
                                            Dashboard
                                        </NavLink>
                                    </NavItem>
                                    :
                                    <React.Fragment>
                                        <NavItem>
                                            <NavLink
                                                to={'/login'}
                                                tag={NavLinkRRD}
                                                onClick={closeCollapse}
                                                activeClassName="active"
                                            >
                                                Login
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                to={'/register'}
                                                tag={NavLinkRRD}
                                                onClick={closeCollapse}
                                                activeClassName="active"
                                            >
                                                Register
                                            </NavLink>
                                        </NavItem>
                                    </React.Fragment>
                            }
                        </Nav>

                    </Collapse>

                </div>
            </nav>

            {/* <Ticker speed={3}>
				{() => (
					<h3
						className='d-inline pointer' style={{ whiteSpace: "nowrap" }}
						onClick={() => _id ? history.push('/dashboard') : history.push('/register')}
					>
						{home_headline.map(e => <span>{e}&emsp;·&emsp;</span>)}
					</h3>
				)}
			</Ticker> */}
        </header>
    );
}
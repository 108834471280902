const initialState = {
    type: 'LIVE',
    date: '',
    category: '',
    status_page: 'PENDING_APPROVAL',
    admin_recipe_page: 0
};

export default function filterReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_DATE': {
            return ({
                ...state,
                date: action.payload
            });
        }
        case 'SET_TYPE': {
            return ({
                ...state,
                type: action.payload
            });
        }
        case 'SET_CATEGORY': {
            return ({
                ...state,
                category: action.payload
            });
        }
        case 'SET_RECIPE_PAGE': {
            return ({
                ...state,
                admin_recipe_page: action.payload
            });
        }
        case 'SET_RECIPE_STATUS': {
            return ({
                ...state,
                recipe_status: action.payload
            });
        }
        default: return state;
    }
}
import React, { useEffect, useState } from "react";
import {
    Container, Button, Card, CardBody, CardHeader, CardTitle,
    CardText, CardImg, CardFooter, Collapse, Modal, ModalBody,
    ModalFooter
} from "reactstrap";

import _fetch from '../../_fetch';
import Header from "../../components/Headers/Header.js";
import './styles.scss';
import { useHistory } from "react-router-dom";
import Loader from "../../components/Loader/loader";
import { useSelector } from "react-redux";
import moment from "moment";
import AddClassModal from "../Admin/AddClass/AddClassModal";

const CategoryCard = ({ e, classes, setEditClass }) => {

    const [hover, setHover] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    let history = useHistory();
    const { admin } = useSelector(st => st.user);

    return (
        <div className='col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 my-3'>
            <Card className='overflow-hidden shadow'>
                <CardImg src={e.thumbnail} />
                <CardBody>
                    <CardTitle tag="h5" style={{ fontSize: 18 }} className='mb-2'>
                        {e.name} <span className='text-muted'>({e.duration?.min}-{e.duration?.max}mins)</span>
                    </CardTitle>
                    <CardText className='text-primary pointer d-flex justify-content-between' onClick={() => setHover(!hover)}>
                        <span>{hover ? 'Show Less' : 'Show More'}</span>
                        {
                            hover ?
                                <i className="lni lni-chevron-up-circle text-lg"></i>
                                :
                                <i className="lni lni-chevron-down-circle text-lg"></i>
                        }
                    </CardText>
                    <Collapse isOpen={hover}>
                        <CardText id='card-desc' className={`text-sm ${hover ? 'card-desc-show' : 'card-desc-hidden'}`}>
                            {e.description}
                        </CardText>
                    </Collapse>
                </CardBody>
                <CardFooter className='d-flex align-items-center justify-content-center'>

                    <Button color='primary' className='m-2' onClick={() => {
                        if (classes?.some(e => moment(e.expires).isAfter(moment()))) {
                            history.push(`/workout/${e._id}`)
                        } else {
                            setModalOpen(true);
                        }
                    }}>
                        <i className="lni lni-play mr-2"></i>Play
                    </Button>
                    {
                        admin &&
                        <Button
                            onClick={() => {
                                setEditClass({
                                    title: e.name,
                                    order: e.max_order + 1,
                                    category: e._id,
                                    duration: e.duration?.min,
                                    type: "RECORDED"
                                });
                            }}
                            className='m-2'
                        >
                            Add Class
                        </Button>
                    }

                </CardFooter>
            </Card>
            <Modal isOpen={modalOpen} toggle={() => setModalOpen(false)}>
                <ModalBody>
                    <p className='font-weight-500'>
                        Watching this recording will reduce 1 video from your purchased workout videos.<br />
                        This class will be accessible only for next 12 hours once you start watching.<br />
                        <br />
                        Do you want to continue?
                    </p>
                </ModalBody>
                <ModalFooter>
                    <Button color='danger' onClick={() => setModalOpen(false)}>No</Button>
                    <Button color='primary' onClick={() => history.push(`/workout/${e._id}`)}>Yes</Button>
                </ModalFooter>
            </Modal>
        </div>
    )

}

export default function Recordings() {

    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [recorded_classes_count, setRecordedClassesCount] = useState(0);
    const { total_recorded_classes, watched_recorded_classes } = useSelector(st => st.user);
    const [classes, setClasses] = useState([]);
    const [editClass, setEditClass] = useState(null);

    let history = useHistory();

    async function getCategory() {
        try {
            setLoading(true);
            let res = await _fetch(`${process.env.REACT_APP_API}/category?get_max_order=true&&platform=WEB`)
            if (res.success && Array.isArray(res.response)) {
                setCategories(res.response);
            }

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    async function init() {
        getCategory();
        try {

            let res = await _fetch(`${process.env.REACT_APP_API}/classes/last_watched`);
            if (res.success) {
                setClasses(res.response);
            }

        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isNaN(total_recorded_classes) && !isNaN(watched_recorded_classes))
            setRecordedClassesCount(total_recorded_classes - watched_recorded_classes)
    }, [total_recorded_classes, watched_recorded_classes]);

    if (loading) {
        return (
            <>
                <Header />

                <Container className="mt--7" fluid>
                    <Card className="bg-secondary shadow" style={{ height: 400 }}>
                        <Loader />
                    </Card>
                </Container>
            </>
        );
    }

    return (
        <>
            <Header />

            {/* Page content */}
            <Container className="mt--7" fluid>
                <Card className="bg-secondary shadow">

                    <CardHeader className="bg-white border-0">
                        <div className='d-flex flex-column flex-xl-row justify-content-between'>
                            <div>
                                <div className='d-flex mb-2 align-items-center'>
                                    <span className="font-weight-600">Available Workout Videos: {recorded_classes_count}</span>
                                    <Button color='primary'
                                        onClick={() => {
                                            history.push('/buy-classes')
                                        }}
                                        className='recorded-class-checkout d-none d-lg-block ml-auto'
                                    >
                                        Buy Videos
                                    </Button>
                                </div>
                                <div>
                                    Choose your workouts from a variety of categories listed below. Click on 'show more' to read about each. Just click on play button to start watching the class and ensure to complete it within 12 hours once played. Every 12 hours, the system offers a new class for each category. Enjoy!
                                </div>
                            </div>

                            <Button color='primary'
                                onClick={() => {
                                    history.push('/buy-classes')
                                }}
                                className='my-2 my-md-0 recorded-class-checkout d-block d-lg-none align-self-end'
                            >
                                Buy Videos
                            </Button>
                        </div>
                    </CardHeader>

                    <CardBody>

                        <div className='row align-items-stretch cat-card' style={{ minHeight: 500 }}>
                            {
                                categories.map((e, i) => (
                                    <CategoryCard
                                        e={e} key={i}
                                        classes={classes.find(c => c._id === e._id)?.classes}
                                        setEditClass={setEditClass}
                                    />
                                ))
                            }
                        </div>

                    </CardBody>
                </Card>

                {
                    (!!editClass)
                        ?
                        <AddClassModal
                            isOpen={true}
                            toggle={() => setEditClass(null)}
                            {...editClass}
                            refresh={() => getCategory()}
                        />
                        :
                        null
                }
            </Container>
        </>
    );
};

import { useLocation, useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loader from '../../components/Loader/loader';
import { toast } from "react-toastify";
import _fetch from '../../_fetch';
import { Button } from "reactstrap";
import moment from "moment";


const mid = process.env.REACT_APP_TAKEPAYMENTS_MID;
const currencycode = "826";
const transactiontype = "SALE";
let orderdesc = 'KWM - Habit Coach Booking';
const address1 = '';
const address2 = '';
const address3 = '';
const address4 = '';
const city = '';
const state = '';
const postcode = '';
const countrycode = '';
const hashmethod = 'SHA1';
const callbackurl = `${process.env.REACT_APP_HOME}/habit-coach/success`;
const resultdeliverymethod = 'SERVER';
const serverresulturl = `${process.env.REACT_APP_API}/v2/habit-coach/webhook`;
// let serverresulturl = 'https://1826-182-69-178-7.ngrok-free.app/api/v2/habit-coach/webhook';
const PaymentFormDisplaysResult = 'false';

export default function BookHabitCoach() {
    const location = useLocation();
    const history = useHistory();
    const searchParams = new URLSearchParams(location.search);
    const user_id = searchParams.get('user_id');
    const habit_coach_id = searchParams.get('habit_coach_id');
    const [loading, setLoading] = useState(false);
    const [orderResponse, setOrderResponse] = useState(null);
    const helpVideoLink = 'https://kwm-user-data.s3.eu-west-2.amazonaws.com/kwm_web/Habit+Coach+Info+Final.mp4';


    useEffect(() => {
        if (!user_id || !habit_coach_id) {
            history.replace('/login');
        }
    }, [user_id, habit_coach_id, history]);

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true);
                const url = `${process.env.REACT_APP_API_V2}/habit-coach/book`;
                const res = await _fetch(url, {
                    method: 'POST',
                    body: {
                        habit_coach_id: habit_coach_id,
                        user_id: user_id,
                    }
                });
                if (res.success) {
                    setOrderResponse(res.response);
                } else {
                    toast.error(res.response);
                }
            } catch (err) {
                toast.error(err.message);
            } finally {
                setLoading(false);
            }
        }
        init();
    }, [habit_coach_id, user_id]);

    if (loading || !orderResponse) return (
        <>
            <div
                className='d-flex justify-content-center align-items-center'
                style={{
                    height: '100vh',
                    width: '100vw',
                }}
            >
                <Loader />

            </div>
        </>
    );

    return (
        <div
            className='d-flex flex-column justify-content-center align-items-center'
            style={{
                minHeight: '100vh',
                width: '100vw',
            }}
        >
            <div
                className='container'
            >
                <h1
                    style={{
                        fontFamily: 'Abhaya Libre, serif',
                        textAlign: 'center',
                        fontWeight: '800',
                    }}
                >Book Habit Coach</h1>
                <p
                    style={{
                        textAlign: 'center',
                        fontFamily: 'DM Sans, sans-serif',
                    }}
                >
                    KeepWell Mantras habit coaching program is an opt-in service available for the existing and new members of KeepWell Mantras and is designed to help the members into making small incremental daily changes which form powerful habits. Prapti designs the workouts in a specific way to increase the core strength and lean muscle mass. But consistency is key and habit coaching is aimed at improving the consistency of KeepWell Mantras members. Our habit coaches are chosen members of the KeepWell mantras team, who are trained to provide you guidance on forming healthy nutrition and fitness habits, help you with structuring the habits aligned to your current lifestyle, thereby making it easier, simpler and increasing the chances of you sticking to the new health habits. The habit coaches are personally handpicked and assigned by Prapti
                    Dutt. The habit coaches are also active members of the fitness group who themselves are evolving in their fitness journey, overcoming similar odds and struggles as you. So no doubt they will also act as perfect accountability partners for you as they are in this with you together. And you can be rest assured, they will understand and be your most non judgmental partner in this journey. And everything you share with your habit coach will be treated with respect and confidentiality.
                </p>

                <span
                    style={{
                        textAlign: 'center',
                        fontFamily: 'DM Sans, sans-serif',
                        fontWeight: '600',
                        fontSize: '1.2rem',
                        display: 'block',
                        whiteSpace: 'pre-line',
                    }}
                >
                    {`Motivation is great and needed, but also overrated. Motivation can go through highs and lows. But a habit has the energy to be sustained even when motivation is low. Habit is a lifestyle to be lived, not a finish line to be crossed\n– James Clear, Atomic habits`}
                </span>
            </div>
            <div
                style={{
                    width: '100%',
                }}
                className='d-flex justify-content-center align-items-center my-2'
            >
                <iframe
                    title='Habit Coach Info'
                    src={helpVideoLink}
                    style={{
                        width: '90%',
                        border: 'none',
                        height: '50vh',
                        borderRadius: '10px',
                        overflow: 'hidden',
                    }}
                ></iframe>
            </div>



            <form
                method='POST'
                action={process.env.REACT_APP_TAKEPAYMENT_URL}
                className='my-5'
            >
                <input type="hidden" name="MerchantID" value={mid} />
                <input type="hidden" name="Amount" value={orderResponse.price * 100} />
                <input type="hidden" name="CurrencyCode" value={currencycode} />
                <input type="hidden" name="OrderID" value={orderResponse.invoiceId} />
                <input type="hidden" name="TransactionType" value={transactiontype} />
                <input type="hidden" name="TransactionDateTime" value={moment(orderResponse.createdAt).utc().format('YYYY-MM-DD HH:mm:ss Z')} />
                <input type="hidden" name="OrderDescription" value={orderdesc} />
                <input type="hidden" name="CustomerName" value={`${orderResponse?.user?.first_name}${orderResponse?.user?.last_name ? ` ${orderResponse?.user?.last_name}` : ''}`} />
                <input type="hidden" name="Address1" value={address1} />
                <input type="hidden" name="Address2" value={address2} />
                <input type="hidden" name="Address3" value={address3} />
                <input type="hidden" name="Address4" value={address4} />
                <input type="hidden" name="City" value={city} />
                <input type="hidden" name="State" value={state} />
                <input type="hidden" name="PostCode" value={postcode} />
                <input type="hidden" name="CountryCode" value={countrycode} />
                <input type="hidden" name="EmailAddress" value={orderResponse.user?.email} />
                <input type="hidden" name="HashMethod" value={hashmethod} />
                <input type="hidden" name="CallbackURL" value={callbackurl} />
                <input type="hidden" name="ResultDeliveryMethod" value={resultdeliverymethod} />
                <input type="hidden" name="ServerResultURL" value={serverresulturl} />
                <input type="hidden" name="PaymentFormDisplaysResult" value={PaymentFormDisplaysResult} />
                <input type="hidden" name="HashDigest" value={orderResponse.paymentIntent} />

                <Button type='submit' color='primary' className='w-100' >Complete Payment</Button>

            </form>

        </div>
    );
}
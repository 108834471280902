import React, { useState } from 'react';
import { Container, Card, CardBody, CardHeader, Button, Col, Row } from 'reactstrap';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';

import Header from '../../components/Headers/Header';


export default function PersonalTrainingForm() {

    const [, setCookies] = useCookies(['pt_package', 'pt_count']);
    const [data, setData] = useState('30_MINS_PT');
    let history = useHistory();

    const pt_packages = {
        "30_MINS_PT": {
            name: "30 Minutes Personal Training",
            goal: "",
            duration: "1 time - 30 Minutes",
            check_ins: "",
            fee: `£${process.env.REACT_APP_30_MIN_PT_PRICE}`,
            roll_over: ""
        },
        "45_MINS_PT": {
            name: "45 Minutes Personal Training",
            goal: "",
            duration: "1 time - 45 Minutes",
            check_ins: "",
            fee: `£${process.env.REACT_APP_45_MIN_PT_PRICE}`,
            roll_over: ""
        },
    }

    async function redirectToPaymentPage(ev) {
        setCookies('pt_package', data);
        setCookies('pt_count', 1);
        history.push('/buy-classes');
    }

    return (
        <React.Fragment>
            <Header />
            <Container className="mt--7" fluid>
                <Card className="bg-secondary shadow">
                    <CardHeader className="bg-transparent w-100">
                        <h2 className="mb-0 w-100 text-center">Fill Up Your Personal Training registration Form</h2>
                    </CardHeader>
                    <CardBody>

                        <Row className='mb-4'>
                            <Col xs={12} md={6} className='my-3'>
                                <Card
                                    onClick={() => setData('30_MINS_PT')}
                                    className={`p-4 text-center h-100 text-xl font-weight-600 pointer shadow card-lift--hover ${data === '30_MINS_PT' ? 'bg-primary text-white' : ''}`}
                                >
                                    30 Minute Personal Training
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className='my-3'>
                                <Card
                                    onClick={() => setData('45_MINS_PT')}
                                    className={`p-4 text-center text-xl h-100 font-weight-600 pointer shadow card-lift--hover ${data === '45_MINS_PT' ? 'bg-primary text-white' : ''}`}
                                >
                                    45 Minute Personal Training
                                </Card>
                            </Col>

                        </Row>

                        <Card className='mb-4'>
                            <CardBody>
                                <div className='d-flex align-items-start pb-4 mb-4 border-bottom'>
                                    <i className='lni lni-flag mx-auto mt-1' style={{ fontSize: '2rem' }} />
                                    <div className='flex-fill ml-3'>
                                        <h4 className='text-lg'>Goal</h4>
                                        <p className='mb-0'>
                                            {pt_packages[data].goal}
                                        </p>
                                    </div>
                                </div>
                                <Row>
                                    <Col xs={12} md={6} lg={data === '60_MINS' ? 4 : 3}>
                                        <div className='d-flex align-items-start'>
                                            <i className='lni lni-timer mx-auto mt-1' style={{ fontSize: '2rem' }} />
                                            <div className='flex-fill ml-3'>
                                                <h4 className='text-lg'>Duration</h4>
                                                <p className='mb-0'>
                                                    {pt_packages[data].duration}
                                                </p>
                                            </div>
                                        </div>

                                    </Col>
                                    {
                                        pt_packages[data].check_ins &&
                                        <Col xs={12} md={6} lg={3}>
                                            <div className='d-flex align-items-start'>
                                                <i className='lni lni-phone-set mx-auto mt-1' style={{ fontSize: '2rem' }} />
                                                <div className='flex-fill ml-3'>
                                                    <h4 className='text-lg'>Progress check ins </h4>
                                                    <p className='mb-0'>
                                                        {pt_packages[data].check_ins}
                                                    </p>
                                                </div>
                                            </div>
                                        </Col>
                                    }
                                    <Col xs={12} md={6} lg={data === '60_MINS' ? 4 : 3}>
                                        <div className='d-flex align-items-start'>
                                            <i className='lni lni-pound mx-auto mt-1' style={{ fontSize: '2rem' }} />
                                            <div className='flex-fill ml-3'>
                                                <h4 className='text-lg'>Fee</h4>
                                                <p className='mb-0'>
                                                    {pt_packages[data].fee}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6} lg={data === '60_MINS' ? 4 : 3}>
                                        <div className='d-flex align-items-start'>
                                            <i className='lni lni-calendar mx-auto mt-1' style={{ fontSize: '2rem' }} />
                                            <div className='flex-fill ml-3'>
                                                <h4 className='text-lg'>Roll over plan</h4>
                                                <p className='mb-0'>
                                                    {pt_packages[data].roll_over}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='d-flex align-items-start mb-4 pt-4 mt-4 border-top'>
                                    <i className='lni lni-book mx-auto mt-1' style={{ fontSize: '2rem' }} />
                                    <div className='flex-fill ml-3'>
                                        <h4 className='text-lg'>Terms And Conditions</h4>
                                        <div className='d-flex align-items-start mb-2'>
                                            <i className='lni lni-checkmark mt-1 mx-auto' />
                                            <div className='flex-fill ml-3'>
                                                The client is required to fill in a client questionnaire prior to starting the process requiring all important information to conduct an in depth analysis of the current lifestyle and diet. This will enable me to provide you a customized plan rightly suiting your existing food habits and lifestyle.
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-start mb-2'>
                                            <i className='lni lni-checkmark mt-1 mx-auto' />
                                            <div className='flex-fill ml-3'>
                                                Our success lies in team work which is highly dependent on being jointly accountable towards your transformation journey. Goal is to attain a healthy weight loss whilst improving overall health through simple lifestyle changes. As a coach I will be trying my best to make the plan sustainable and achievable through easy to adopt lifestyle changes wherever needed.
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-start mb-2'>
                                            <i className='lni lni-checkmark mt-1 mx-auto' />
                                            <div className='flex-fill ml-3'>
                                                Regular updates through scale movements, body stats measurements and overall general well being are the only way we will know the success or opportunity to modify anything. Clients are required to keep the coach updated of these from time to time in this process.
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-start mb-2'>
                                            <i className='lni lni-checkmark mt-1 mx-auto' />
                                            <div className='flex-fill ml-3'>
                                                Once a plan is booked, refunds are not issued due to your unavailability or vacation or any other personal reasons. However, a delayed start date is possible.
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-start mb-2'>
                                            <i className='lni lni-checkmark mt-1 mx-auto' />
                                            <div className='flex-fill ml-3'>
                                                To get the best results from the nutrition plan, it is highly recommended to incorporate physical activity as an important element. I offer discounts when my clients opt in for both diet and workouts combo packages.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>


                        <Button color='primary' onClick={redirectToPaymentPage} className='mt-4'  >
                            Book Now
                        </Button>
                    </CardBody>
                </Card>
            </Container >
        </React.Fragment>
    );
}
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import Loader from "../../components/Loader/loader";
import _fetch from "../../_fetch";

export default function UserInfo() {

    const [loading, setLoading] = useState(false);
    const [promos, setPromos] = useState([]);
    useEffect(() => {

        async function init() {
            try {
                setLoading(true);
                let res = await _fetch(`${process.env.REACT_APP_API}/promo_code/active`);
                if (res.success && Array.isArray(res.response)) {
                    setPromos(res.response);
                }
            } catch (err) {
                toast.error('Promo code doesn\'t exist or has expired');
            } finally {
                setLoading(false);
            }
        }

        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const colors = ['green', 'orange', 'blue', 'red', 'purple'];
    if (loading) return (
        <Card className="bg-secondary shadow" style={{ height: 400 }}>
            <Loader />
        </Card>
    );

    return (
        <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                    <Col xs="12">
                        <h2 className="mb-0 text-center">Discount Codes And Offers</h2>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody>
                {
                    promos.map((e, i) => (
                        <Card className='my-2 mx-2'>
                            <div className={`row border-5 border-${colors[i % 5]}`}>
                                <div className='col-4 px-1 col-lg-3 col-xl-3'>
                                    <div className={`h-100 w-100 d-flex align-items-center justify-content-center bg-${colors[i % 5]} text-center text-white text-lg font-weight-bolder`}>
                                        {e.name}
                                    </div>
                                </div>
                                <div className='col-8 px-0 col-lg-9 col-xl-9'>
                                    <div className='d-flex align-items-center justify-content-center p-2'>
                                        {e.description}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    ))
                }
            </CardBody>
        </Card>
    );
};
